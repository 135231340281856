export const localize = {
    "vi": {
        "DHR_403_loi": "Bạn không có quyền truy cập để xem trang này",
        "DHR_404_loi": "Trang bạn đang tìm kiếm có thể đã bị xóa, có tên thay đổi hoặc tạm thời không có.",
        "DHR_AC_vui_long_chon_y_kien_cua_minh": "Anh/Chị vui lòng chọn ý kiến của mình tương ứng với mức độ đồng ý cho những dịch vụ cung cấp dưới đây",
        "DHR_An_trua": "Ăn trưa",
        "DHR_Anh_dai_dien": "Ảnh đại diện",
        "DHR_Ap_dung_mot_toan_tu_cho_tat_ca_du_lieu_luong": "Áp dụng một toán tử cho tất cả dữ liệu lương",
        "DHR_Ap_dung_toan_tu_theo_tung_du_lieu_luong": "Áp dụng toán tử theo từng dữ liệu lương",
        "DHR_BANG_CONG_THANG": "Bảng công tháng",
        "DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Bạn chắc chắn muốn sao chép giá trị này?",
        "DHR_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "Bạn chỉ được điều chỉnh giảm 0.5 phép",
        "DHR_Ban_chua_chon_du_lieu_tren_luoi": "Bạn chưa chọn dữ liệu trên lưới",
        "DHR_Ban_chua_chon_nhan_vien": "Bạn chưa chọn nhân viên",
        "DHR_Ban_chua_nhap.Ban_co_muon_bo_sung_khong": "Bạn chưa nhập. Bạn có muốn bổ sung không ?",
        "DHR_Ban_chua_nhap_ngay_di_ca": "Bạn chưa nhập ngày đi ca",
        "DHR_Ban_chua_nhap_so_thu_tu": "Bạn chưa nhập số thứ tự",
        "DHR_Ban_co_chac_chan_bo_duyet": "Bạn có chắc chắn bỏ duyệt?",
        "DHR_Ban_co_chac_chan_muon_duyet": "Bạn có chắc muốn duyệt?",
        "DHR_Ban_co_chac_chan_muon_huy.Neu_huy_thi_se_khong_the_phuc_hoi": "Bạn có chắc chắn muốn huỷ? Nếu huỷ thì sẽ KHÔNG THỂ phục hồi",
        "DHR_Ban_co_chac_chan_muon_huy?_Neu_huy_thi_se_KHONG_THE_khoi_phuc_du_lieu_truoc_do": "Bạn có chắc chắn muốn huỷ? Nếu huỷ thì sẽ KHÔNG THỂ khôi phục dữ liệu trước đó",
        "DHR_Ban_co_chac_chan_muon_tu_choi": "Bạn có chắc muốn từ chối?",
        "DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Bạn có chắc chắn muốn xóa dữ liệu này ?",
        "DHR_Ban_co_chac_chan_xoa": "Bạn có chắc chắn xoá ?",
        "DHR_Ban_co_chac_chan_xoa_ung_vien_khoi_tin_tuyen_dung": "Bạn có chắc chắn xoá ứng viên khỏi tin tuyển dụng ?",
        "DHR_Ban_co_chac_cho_phep_nhan_vien_cap_nhat_muc_tieu_da_duyet?": "Bạn có chắc cho phép nhân viên cập nhật mục tiêu đã duyệt ?",
        "DHR_Ban_co_chac_muon_bo_trang_thai_nay?": "Bạn có chắc muốn bỏ trạng thái này?",
        "DHR_Ban_co_chac_muon_cap_nhat?": "Bạn có chắc muốn cập nhật ?",
        "DHR_Ban_co_chac_muon_dang_ky_muc_tieu_khong?": "Bạn có muốn đăng ký mục tiêu không?",
        "DHR_Ban_co_chac_muon_gui_mail_nay": "Bạn có chắc muốn gửi mail này?",
        "DHR_Ban_co_chac_muon_huy_phep?": "Bạn có chắc muốn hủy phép?",
        "DHR_Ban_co_chac_muon_luu_khong?": "Bạn có chắc muốn lưu không?",
        "DHR_Ban_co_chac_muon_roi_nhom_khong": "Bạn có chắc muốn rời khỏi nhóm không?",
        "DHR_Ban_co_chac_muon_xoa?": "Bạn có chắc muốn xoá?",
        "DHR_Ban_co_chac_muon_xoa_(nhung)_dinh_kem_nay?": "Bạn có chắc muốn xóa (những) đính kèm này?",
        "DHR_Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?": "Bạn có chắc muốn xóa Mục tiêu khỏi bản review không?",
        "DHR_Ban_co_chac_muon_xoa_nhan_vien?": "Bạn có chắc muốn xóa nhân viên ?",
        "DHR_Ban_co_chac_muon_xoa_thanh_vien_nay_khoi_nhom_khong": "Bạn có chắc muốn xóa thành viên này khỏi nhóm không?",
        "DHR_Ban_co_du_lieu_chua_duoc_luu_Ban_co_muon_tiep_tuc": "Bạn có dữ liệu chưa được lưu. Bạn có muốn tiếp tục?",
        "DHR_Ban_co_muon": "Bạn có muốn",
        "DHR_Ban_co_muon_cap_nhat_han_hoan_thanh": "Bạn có muốn cập nhật hạn hoàn thành không?",
        "DHR_Ban_co_muon_cap_nhat_lai_lich_di_ca_theo_ngay_hieu_luc?": "Bạn có muốn cập nhật lại lịch đi ca theo ngày hiệu lực?",
        "DHR_Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien": "Bạn có muốn cập nhật thông tin tăng ca nhân viên",
        "DHR_Ban_co_muon_cap_nhat_trang_thai_muc_tieu": "Bạn có muốn cập nhật trạng thái mục tiêu không?",
        "DHR_Ban_co_muon_cap_thong_tin_tang_ca_cho_nhan_vien": "Bạn có muốn cập nhật thông tin tăng ca nhân viên",
        "DHR_Ban_co_muon_gui_den_mail_nay": "Bạn có muốn gửi đến mail này?",
        "DHR_Ban_co_muon_gui_mail_nay": "Bạn có muốn gửi mail này?",
        "DHR_Ban_co_muon_gui_mail_nhac_lich_cho_ung_vien_nay": "Bạn có muốn gửi mail nhắc lịch cho ứng viên này?",
        "DHR_Ban_co_muon_thay_doi_trang_thai_phong_van": "Bạn có muốn thay đổi trạng thái phỏng vấn",
        "DHR_Ban_co_muon_thay_doi_trang_thai_ung_vien_xac_nhan": "Bạn có muốn thay đổi trạng thái ứng viên xác nhận",
        "DHR_Ban_co_muon_thay_the_nguoi_duyet": "Bạn có muốn thay thế người duyệt?",
        "DHR_Ban_co_muon_tra_loi_den_mail_nay": "Bạn có muốn trả lời đến mail này?",
        "DHR_Ban_co_muon_xoa_lich_phong_van": "Bạn có muốn xóa phỏng vấn",
        "DHR_Ban_dang_xem_muc_tieu_cua": "Bạn đang xem mục tiêu của",
        "DHR_Ban_do_muc_tieu": "Bản đồ mục tiêu",
        "DHR_Ban_giao": "Bàn giao",
        "DHR_Ban_muon": "Bạn muốn",
        "DHR_Ban_muon_xoa": "Bạn muốn xóa",
        "DHR_Ban_phai_chon_doanh_nghiep": "Bạn phải chọn doanh nghiệp",
        "DHR_Ban_phai_chon_ket_qua_danh_gia": "Bạn phải chọn kết quả đánh giá.",
        "DHR_Ban_phai_gan_ca_mac_dinh": "Bạn phải gán ca mặc định",
        "DHR_Ban_phai_gan_mau_ca": "Bạn phải gán mẫu ca",
        "DHR_Ban_phai_nhap_cac_thong_tin": "Bạn phải nhập các thông tin",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_00h00m": "Bạn phải nhập đúng định dạng vd: 00h 00m",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11": "Bạn phải nhập đúng định dạng vd: 11:11",
        "DHR_Ban_phai_nhap_ngay_hieu_luc": "Bạn phải nhập ngày hiệu lực.",
        "DHR_Ban_phai_nhap_thong_tin": "Bạn phải nhập thông tin",
        "DHR_Ban_vui_long_chon_du_lieu_tren_luoi": "Bạn vui lòng chọn dữ liệu trên lưới",
        "DHR_Ban_vui_long_kiem_tra_Ngay_danh_gia_phai_trong_khoang_tu_den": "Bạn vui lòng kiểm tra Ngày đánh giá phải trong khoảng thời gian hiệu lực từ-đến.",
        "DHR_Bang1": "Bằng",
        "DHR_Bang_cau_hoi_phong_van": "Bảng câu hỏi phỏng vấn",
        "DHR_Bang_muc_tieu": "Bảng mục tiêu",
        "DHR_Bang_tham_chieu_KQ": "Bảng tham chiếu KQ",
        "DHR_Bang_tong_hop": "Bảng tổng hợp",
        "DHR_Bao_cao": "Báo cáo",
        "DHR_Bao_cao_cong": "Báo cáo công",
        "DHR_Bao_cao_tuyen_dung": "Báo cáo tuyển dụng",
        "DHR_Bao_hiem_xa_hoi": "Bảo hiểm xã hội",
        "DHR_Bao_hiem_y_te": "Bảo hiểm y tế",
        "DHR_Bat_buoc": "Bắt buộc",
        "DHR_Bat_buoc_nhap": "Bắt buộc nhập",
        "DHR_Bat_buoc_nhapB": "Bắt buộc nhập",
        "DHR_Bat_buoc_nhap_du_lieu": "Bắt buộc nhập dữ liệu",
        "DHR_Benh_vien_KCB": "Bệnh viện KCB",
        "DHR_Bo_duyet": "Bỏ duyệt",
        "DHR_Bo_duyet_thanh_cong": "Bỏ duyệt thành công.",
        "DHR_Bo_qua": "Bỏ qua",
        "DHR_Bo_qua_thong_bao_thanh_cong": "Bỏ qua thông báo thành công",
        "DHR_Bo_sao_chep": "Bỏ sao chép",
        "DHR_Bo_sung": "Bổ sung",
        "DHR_Bo_sung_gio_vao_ra": "Bổ sung giờ vào - ra",
        "DHR_Bo_sung_gio_vao_ra_thuc_te": "Bổ sung giờ vào ra thực tế",
        "DHR_Bo_tat_ca": "Bỏ tất cả",
        "DHR_Bo_xac_nhan": "Bỏ xác nhận",
        "DHR_Boi": "Bởi",
        "DHR_Buoc": "Bước",
        "DHR_CCLD": "CCLĐ",
        "DHR_CCLD_da_cap_phat": "CCLĐ đã cấp phát",
        "DHR_CDN_chua_san_sang": "CDN chưa sẵn sàng",
        "DHR_CMND": "CMND",
        "DHR_CV_ung_vien": "CV ứng viên",
        "DHR_Ca": "Ca",
        "DHR_Ca_lam_viec": "Ca làm việc",
        "DHR_Ca_mac_dinh": "Ca mặc định",
        "DHR_Ca_nhan": "Cá nhân",
        "DHR_Ca_ra_vao": "Ca ra vào",
        "DHR_Ca_vao_ra": "Ca - Vào - Ra",
        "DHR_Ca_xoay_vong": "Ca xoay vòng",
        "DHR_Cac_cau_hoi_phong_van_su_dung_cho_vi_tri_tuyen_dung": "Các câu hoỉ phỏng vấn được sử dụng cho vị trí tuyển dụng",
        "DHR_Cac_gop_y_khac": "Các góp ý khác",
        "DHR_Cac_tham_so_la_bat_buoc": "Các tham số là bắt buộc",
        "DHR_Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.": "Các thay đổi đã thực hiện sẽ không được lưu",
        "DHR_Cac_tieu_chi_la_bat_buoc_nhap": "Các chỉ tiêu bắt buộc nhập",
        "DHR_Cac_truong_khong_bang_nhau": "Các trường không bằng nhau",
        "DHR_Cach_tinh_ket_qua": "Cách tính kết quả",
        "DHR_Cai_dat": "Cài đặt",
        "DHR_Cap": "Cấp",
        "DHR_Cap_khen_thuong": "Cấp khen thưởng",
        "DHR_Cap_ky_luat": "Cấp kỷ luật",
        "DHR_Cap_nhat": "Cập nhật",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong": "Cập nhật cách tính kết quả mục tiêu thành công",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_that_bai": "Cập nhật cách tính kết quả mục tiêu thất bại",
        "DHR_Cap_nhat_gia_tri_%p_thanh_cong": "Cập nhật giá trị %p thành công",
        "DHR_Cap_nhat_gia_tri_%p_that_bai": "Cập nhật giá trị %p thất bại",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_thanh_cong": "Cập nhật hạn hoàn thành mục tiêu thành công",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_that_bai": "Cập nhật hạn hoàn thành mục tiêu thất bại",
        "DHR_Cap_nhat_ket_qua": "Cập nhật kết quả",
        "DHR_Cap_nhat_ket_qua_muc_tieu_thanh_cong": "Cập nhật kết quả mục tiêu thành công",
        "DHR_Cap_nhat_ket_qua_muc_tieu_that_bai": "Cập nhật kết quả mục tiêu thất bại",
        "DHR_Cap_nhat_mo_ta_muc_tieu_thanh_cong": "Cập nhật mô tả mục tiêu thành công",
        "DHR_Cap_nhat_mo_ta_muc_tieu_that_bai": "Cập nhật mô tả mục tiêu thất bại",
        "DHR_Cap_nhat_ngay_hoan_thanh_thanh_cong": "Cập nhật ngày hoàn thành thành công",
        "DHR_Cap_nhat_ngay_hoan_thanh_that_bai": "Cập nhật ngày hoàn thành thất bại",
        "DHR_Cap_nhat_nhom_thanh_cong": "Cập nhật nhóm thành công",
        "DHR_Cap_nhat_nhom_that_bai": "Cập nhật nhóm thất bại",
        "DHR_Cap_nhat_sau_thai_san": "Cập nhật sau thai sản",
        "DHR_Cap_nhat_ten_muc_tieu_thanh_cong": "Cập nhật tên mục tiêu thành công",
        "DHR_Cap_nhat_ten_muc_tieu_that_bai": "Cập nhật tên mục tiêu thất bại",
        "DHR_Cap_nhat_thanh_cong": "Cập nhật thành công.",
        "DHR_Cap_nhat_thong_tin_cho_phieu_danh_gia": "Cập nhật thông tin cho phiếu đánh giá",
        "DHR_Cap_nhat_thong_tin_khac_cho_phieu_danh_gia": "Cập nhật thông tin khác cho phiếu đánh giá",
        "DHR_Cap_nhat_thong_tin_nhanh": "Cập nhật thông tin nhanh",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_thanh_cong": "Cập nhật thứ tự kết quả then chốt thành công",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_that_bai": "Cập nhật thứ tự kết quả then chốt thất bại",
        "DHR_Cap_nhat_trang_thai_hop_dong_lao_dong": "Cập nhật trạng thái hợp đồng lao động",
        "DHR_Cap_nhat_trang_thai_muc_tieu_thanh_cong": "Cập nhật trạng thái mục tiêu thành công",
        "DHR_Cap_nhat_trang_thai_muc_tieu_that_bai": "Cập nhật trạng thái mục tiêu thất bại",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_thanh_cong": "Cập nhật trọng số kết quả then chốt thành công",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_that_bai": "Cập nhật trọng số kết quả then chốt thất bại",
        "DHR_Cap_nhat_trong_so_thanh_cong": "Cập nhật trọng số thành công",
        "DHR_Cap_nhat_trong_so_that_bai": "Cập nhật trọng số thất bại",
        "DHR_Cap_phat": "Cấp phát",
        "DHR_Cap_phat_thiet_bi_lao_dong": "Cấp phát thiết bị lao động",
        "DHR_Cap_phat_trang_thiet_bi_lao_dong": "Cấp phát trang thiết bị lao động",
        "DHR_Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet": "Cấp tiếp theo đã duyệt. Bạn không thể bỏ duyệt",
        "DHR_Cau_hoi_phong_van": "Câu hỏi phỏng vấn",
        "DHR_Cham_cong_chi_tiet": "Chấm công chi tiết",
        "DHR_Cham_cong_theo_du_an": "Chấm công theo dự án",
        "DHR_Cham_diem_theo_tieu_chi": "Chấm điểm theo tiêu chí",
        "DHR_Cham_tien_do": "Chậm tiến độ",
        "DHR_Chao_buoi_sang": "Chào buổi sáng!",
        "DHR_Chao_mung_ban": "Chào mừng bạn",
        "DHR_Chao_mung_login": "Chào mừng đến \\n hệ sinh thái DigiNet",
        "DHR_Che_do_bao_hiem_duoc_duyet": "Chế độ bảo hiểm được duyệt",
        "DHR_Che_do_con_nho": "Chế độ con nhỏ",
        "DHR_Che_do_sinh_con_nho": "Chế độ sinh con nhỏ",
        "DHR_Che_do_tro_cap": "Chế độ trợ cấp",
        "DHR_Chi_con_lai_can_quyet_toan": "Chi phí còn lại cần quyết toán",
        "DHR_Chi_dinh_lam_quan_tri_vien": "Chỉ định làm quản trị viên",
        "DHR_Chi_dinh_lam_thanh_vien": "Chỉ định làm thành viên",
        "DHR_Chi_dinh_lam_thanh_vien_vai_tro_quan_tri": "Chỉ định làm thành viên vai trò quản trị",
        "DHR_Chi_dinh_thanh_vien_vai_tro_quan_tri": "Chỉ định thành viên vai trò quản trị",
        "DHR_Chi_hien_thi_du_lieu_da_chon": "Chỉ hiển thị dữ liệu đã chọn",
        "DHR_Chi_nguoi_phong_van": "Chỉ người phỏng vấn",
        "DHR_Chi_phi": "Chi phí",
        "DHR_Chi_phi_binh_quan": "Chi phí bình quân",
        "DHR_Chi_phi_con_lai_can_quyet_toan": "Chi phí còn lại cần quyết toán",
        "DHR_Chi_phi_cong_tac": "Chi phí công tác",
        "DHR_Chi_phi_tam_ung": "Chi phí tạm ứng",
        "DHR_Chi_tam_ung": "Chi tạm ứng",
        "DHR_Chi_tiet": "Chi tiết",
        "DHR_Chi_tiet_bang_muc_tieu": "Chi tiết bảng mục tiêu",
        "DHR_Chi_tiet_cong_cu_lao_dong": "Chi tiết công cụ lao động",
        "DHR_Chi_tiet_dieu_chinh": "Chi tiết điều chỉnh",
        "DHR_Chi_tiet_dieu_chinh_thong_tin_gia_dinh": "Chi tiết điều chỉnh thông tin gia đình",
        "DHR_Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD": "Chi tiết đánh giá nhân viên và HĐLĐ",
        "DHR_Chi_tiet_nhan_vien": "Chi tiết nhân viên",
        "DHR_Chi_tiet_nhom": "Chi tiết nhóm",
        "DHR_Chi_tiet_phep": "Chi tiết phép",
        "DHR_Chi_tiet_phieu": "Chi tiết phiếu",
        "DHR_Chi_tiet_thong_tin_ung_tuyen_cua_ung_vien": "Chi tiết thông tin ứng tuyển của ứng viên",
        "DHR_Chi_tiet_tin_tuyen_dung": "Chi tiết tin tuyển dụng",
        "DHR_Chi_tiet_xep_loai_danh_gia_nhan_vien": "Chi tiết xếp loại đánh giá nhân viên",
        "DHR_Chi_tieu": "Chỉ tiêu",
        "DHR_Chi_ung_vien": "Chỉ ứng viên",
        "DHR_Chinh_sua": "Chỉnh sửa",
        "DHR_Chinh_sua_tang_ca_thanh_cong": "Chỉnh sửa tăng ca thành công",
        "DHR_Chinh_sua_thong_tin_tang_ca": "Chỉnh sửa thông tin tăng ca",
        "DHR_Chinh_sua_thong_tin_tuyen_dung": "Chỉnh sửa thông tin tuyển dụng",
        "DHR_Chinh_sua_tin_tuyen_dung": "Chỉnh sửa tin tuyển dụng",
        "DHR_Chinh_tri": "Chính trị",
        "DHR_Cho_duyet": "Chờ duyệt",
        "DHR_Cho_phep": "Cho phép",
        "DHR_Cho_phep_cap_nhat_nhom_muc_tieu_da_duyet": "Cho phép cập nhật nhóm mục tiêu đã duyệt",
        "DHR_Cho_phep_nhan_vien_dieu_chinh_chon_so": "Cho phép nhân viên điều chỉnh trọng số",
        "DHR_Cho_phep_nhan_vien_huy_muc_tieu": "Cho phép nhân viên hủy mục tiêu",
        "DHR_Cho_phep_sao_chep_ket_qua_cua_cap_truoc": "Cho phép sao chép kết quả của cấp trước",
        "DHR_Cho_tat_ca_nhan_vien": "cho tất cả nhân viên",
        "DHR_Chon": "Chọn",
        "DHR_Chon_CCLD": "Chọn CCLĐ",
        "DHR_Chon_ban_giao": "Chọn mẫu bàn giao",
        "DHR_Chon_bao_cao": "Chọn báo cáo",
        "DHR_Chon_co_cau_to_chuc": "Chọn cơ cấu tổ chức",
        "DHR_Chon_du_an": "Chọn dự án",
        "DHR_Chon_giai_doan": "Chọn giai đoạn",
        "DHR_Chon_ky": "Chọn kỳ",
        "DHR_Chon_ky_luong": "Chọn kỳ lương",
        "DHR_Chon_lai_mau_email_truoc_khi_gui": "Chọn lại mẫu email trước khi gửi",
        "DHR_Chon_mau_bao_cao": "Chọn mẫu báo cáo",
        "DHR_Chon_mau_mail_co_san": "Chọn mẫu mail có sẵn",
        "DHR_Chon_mau_mail_gui_cho_ung_vien": "Chọn mẫu mail gửi cho ứng viên",
        "DHR_Chon_ngay": "Chọn ngày",
        "DHR_Chon_ngay_den": "Chọn ngày đến",
        "DHR_Chon_ngay_tu": "Chọn ngày từ",
        "DHR_Chon_nguoi_duyet_thay_the": "Chọn người thay thế duyệt",
        "DHR_Chon_nguoi_phong_van": "Chọn người phỏng vấn",
        "DHR_Chon_nhan_vien": "Chọn nhân viên",
        "DHR_Chon_nhom_luong": "Chọn nhóm lương",
        "DHR_Chon_nhom_nhan_vien": "Chọn nhóm nhân viên",
        "DHR_Chon_phong_ban": "Chọn phòng ban",
        "DHR_Chon_tat_ca": "Chọn tất cả",
        "DHR_Chon_tu_danh_muc_muc_tieu": "Chọn từ danh mục mục tiêu",
        "DHR_Chu_de": "Chủ đề",
        "DHR_Chu_ky": "Chu kỳ",
        "DHR_Chu_nhat": "Chủ nhật",
        "DHR_Chu_so_huu": "Chủ sở hữu",
        "DHR_Chua_chon_ung_vien": "Chưa chọn ứng viên",
        "DHR_Chua_chuyen_ket_qua": "Chưa chuyển kết quả",
        "DHR_Chua_co_lich_phong_van_nao": "Chưa có lịch phỏng vấn nào",
        "DHR_Chua_co_lien_ket_nao": "Chưa có liên kết nào",
        "DHR_Chua_dap_ung": "Chưa đáp ứng",
        "DHR_Chua_dat": "Chưa đạt",
        "DHR_Chua_duoc_nhap_ban_co_muon_luu_khong": "Chưa được nhập, bạn có muốn lưu không?",
        "DHR_Chua_hoan_thanh": "Chưa hoàn thành",
        "DHR_Chua_thuoc_tin_tuyen_dung_nao": "Chưa thuộc tin tuyển dụng nào",
        "DHR_Chua_tinh_ket_qua": "Chưa tính kết quả",
        "DHR_Chuc_danh": "Chức danh",
        "DHR_Chuc_danh_cong_viec": "Chức danh công việc",
        "DHR_Chuc_danh_kiem_nhiem": "Chức danh kiêm nhiệm",
        "DHR_Chuc_mung_sinh_nhat": "Chúc mừng sinh nhật",
        "DHR_Chuc_vu": "Chức vụ",
        "DHR_Chuc_vu_bat_buoc_nhap": "Chức vụ bắt buộc nhập",
        "DHR_Chuc_vu_kiem_nhiem": "Chức vụ kiêm nhiệm",
        "DHR_Chuc_vu_moi": "Chức vụ mới",
        "DHR_Chung_minh_nhan_dan": "Chứng minh nhân dân",
        "DHR_Chung_minh_thu_ho_chieu": "Chứng minh thư, hộ chiếu",
        "DHR_Chuyen_mon": "Chuyên môn",
        "DHR_Chuyen_nganh": "Chuyên ngành",
        "DHR_Chuyen_tiep": "Chuyển tiếp",
        "DHR_Chuyen_tin_tuyen_dung": "Chuyển tin tuyển dụng",
        "DHR_Chuyen_tin_tuyen_dung_thanh_cong": "Chuyển tin tuyển dụng thành công",
        "DHR_Co": "Có",
        "DHR_Co1": "Có",
        "DHR_Co_cau_chuc_danh": "Cơ cấu chức danh",
        "DHR_Co_cau_to_chuc": "Cơ cấu tổ chức",
        "DHR_Co_du_lieu_da_duoc_sua_Ban_co_muon_tiep_tuc?": "Có dữ liệu đã được sửa. Bạn có muốn tiếp tục?",
        "DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý.",
        "DHR_Co_review": "Có review",
        "DHR_Co_xet_duyet": "Có xét duyệt",
        "DHR_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "Còn dòng chưa được nhập liệu. Bạn có muốn lưu không?",
        "DHR_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "Còn dòng chưa được nhập liệu. Bạn có muốn lưu không?",
        "DHR_Con_thong_tin_truong": "Còn thông tin trường",
        "DHR_Con_truong": "Còn trường",
        "DHR_Cong_cu_lao_dong": "Công cụ lao động",
        "DHR_Cong_hanh_chinh_trong_ngay": "Công hành chính trong ngày",
        "DHR_Cong_thuc": "Công thức",
        "DHR_Cong_trinh/Cong_ty": "Công trình/Công ty",
        "DHR_Cong_trinh_Cong_ty": "Công trình / Công ty",
        "DHR_Cong_ty": "Công ty",
        "DHR_Cong_ty_thanh_toan": "Công ty thanh toán",
        "DHR_Cong_viec": "Công việc",
        "DHR_Cong_viec_moi": "Công việc mới",
        "DHR_Cong_viec_truoc_day": "Công việc trước đây",
        "DHR_Cu": "Cũ",
        "DHR_DRH_Ca_mac_dinh": "Ca mặc định",
        "DHR_DRH_Danh_sach_phong_ban": "Danh sách phòng ban",
        "DHR_DRH_Hoc_van_va_chuyen_mon": " Học vấn và chuyên môn",
        "DHR_DRH_Ten_phong_ban_EN": "Tên phòng ban (EN)",
        "DHR_Da_chot": "Đã chốt",
        "DHR_Da_chuyen": "Đã chuyển",
        "DHR_Da_chuyen1": "Đã chuyển",
        "DHR_Da_chuyen_ket_qua": "Đã chuyển kết quả",
        "DHR_Da_chuyen_kq": "Đã chuyển KQ",
        "DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu": "Đã xảy ra lỗi, không thể lưu dữ liệu",
        "DHR_Da_dien_ra": "Đã diễn ra",
        "DHR_Da_duyet": "Đã duyệt",
        "DHR_Da_hoan_thanh": "Đã hoàn thành",
        "DHR_Da_huy_thanh_cong": "Đã huỷ thành công",
        "DHR_Da_nghi_viec": "Đã nghỉ việc",
        "DHR_Da_sao_chep_CCLD": "Đã sao chép CCLĐ",
        "DHR_Da_sao_chep_cap_phat_truoc": "Đã sao chép cấp phát trước",
        "DHR_Da_tinh": "Đã tính",
        "DHR_Da_tinh_ket_qua": "Đã tính kết quả",
        "DHR_Da_ton_tai_lich_phong_van_cua_ung_cu_vien_ban_co_muon_xoa_khong": "Đã tồn tại lịch phỏng vấn của ứng cử viên, bạn có muốn xoá không ?",
        "DHR_Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong": "Đã tồn tại ứng viên bạn có tiếp tục lưu không ?",
        "DHR_Da_xac_nhan": "Đã xác nhận",
        "DHR_Da_xay_ra_loi,_khong_the_luu_du_lieu": "Đã xảy ra lỗi, không thể lưu dữ liệu",
        "DHR_Dan_quyen": "Dán quyền",
        "DHR_Dan_toc": "Dân tộc",
        "DHR_Dang_Lam_Viec": "Đang làm việc",
        "DHR_Dang_chuan_bi_du_lieu_Xin_vui_long_cho": "Đang chuẩn bị dữ liệu. Xin vui lòng chờ.",
        "DHR_Dang_cong_khai": "Đăng công khai",
        "DHR_Dang_cong_khai_W25DESC": "Tin đăng chính thức trên website tuyển dụng và mọi người đều có thể xem.",
        "DHR_Dang_ky_cong_tac": "Đăng ký công tác",
        "DHR_Dang_ky_di_tre_ve_som": "Đăng ký đi trễ về sớm",
        "DHR_Dang_ky_gio_tang_ca_thuc_te": "Đăng ký giờ tăng ca thực tế",
        "DHR_Dang_ky_muc_tieu": "Đăng ký mục tiêu",
        "DHR_Dang_ky_nghi": "Đăng ký nghỉ",
        "DHR_Dang_ky_nghi_viec": "Đăng ký nghỉ việc",
        "DHR_Dang_ky_quyet_toan_chi_phi_cong_tac": "Đăng ký quyết toán chi phí công tác",
        "DHR_Dang_ky_sau": "Đăng ký sau",
        "DHR_Dang_ky_tang_ca": "Đăng ký tăng ca",
        "DHR_Dang_ky_tang_ca_ho": "Đăng ký tăng ca hộ",
        "DHR_Dang_ky_tang_ca_thanh_cong": "Đăng ký tăng ca thành công",
        "DHR_Dang_ky_thai_san": "Quản lý chế độ thai sản và con nhỏ",
        "DHR_Dang_ky_truoc": "Đăng ký trước:",
        "DHR_Dang_ky_truoc_d": "Đăng ký trước",
        "DHR_Dang_nhap": "Đăng nhập",
        "DHR_Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "DHR_Dang_noi_bo": "Đăng nội bộ",
        "DHR_Dang_noi_bo_W25DESC": "Tin mở trong nội bộ nhưng không xuất hiện trên website tuyển dụng.",
        "DHR_Dang_review": "Đang review",
        "DHR_Dang_tham_gia": "Đang tham gia",
        "DHR_Dang_thuc_hien": "Đang thực hiện",
        "DHR_Dang_tin_tuyen_dung": "Đăng tin tuyển dụng",
        "DHR_Dang_xuat": "Dạng xuất",
        "DHR_Dang_xuat1": "Đăng xuất",
        "DHR_Danh_gia": "Đánh giá",
        "DHR_Danh_gia_chung": "Đánh giá chung/ Overall Assessments",
        "DHR_Danh_gia_hop_dong_thu_viec": "Đánh giá hợp đồng thử việc",
        "DHR_Danh_gia_nhan_vien": "Đánh giá nhân viên",
        "DHR_Danh_gia_nhan_vien_dinh_ky": "Đánh giá nhân viên định kỳ",
        "DHR_Danh_gia_nhan_vien_het_han_HDLD": "Đánh giá nhân viên hết hạn HĐLĐ",
        "DHR_Danh_gia_phong_ban": "Đánh giá phòng ban",
        "DHR_Danh_muc_loai_chi_phi": "Danh mục loại chi phi",
        "DHR_Danh_sach_bang_tinh_KQTH": "Danh sách bảng tính KQTH",
        "DHR_Danh_sach_bang_tong_hop": "Danh sách bảng tổng hợp",
        "DHR_Danh_sach_cap_phat_thiet_bi_lao_dong": "Danh sách cấp phát thiết bị lao động",
        "DHR_Danh_sach_duyet_bang_muc_tieu": "Danh sách duyệt bảng mục tiêu",
        "DHR_Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan": "Danh sách duyệt kết quả mục tiêu cá nhân",
        "DHR_Danh_sach_khen_thuong": "Danh sách khen thuởng",
        "DHR_Danh_sach_ky_luat": "Danh sách kỷ luật",
        "DHR_Danh_sach_muc_tieu": "Danh sách mục tiêu",
        "DHR_Danh_sach_nhan_vien": "Danh sách nhân viên",
        "DHR_Danh_sach_nhan_vien_danh_gia": "Danh sách nhân viên đánh giá",
        "DHR_Danh_sach_nhan_vien_tham_gia": "Danh sách nhân viên tham gia",
        "DHR_Danh_sach_phong_ban": "Danh sách phòng ban",
        "DHR_Danh_sach_tin_da_dang": "Danh sách tin đã đăng",
        "DHR_Danh_sach_tin_tuyen_dung": "Danh sách tin tuyển dụng",
        "DHR_Danh_sach_trang_thiet_bi_lao_dong": "Danh sách trang thiết bị lao động",
        "DHR_Danh_sach_ung_vien": "Danh sách ứng viên",
        "DHR_Danh_sach_vai_tro": "Danh sách vai trò",
        "DHR_Dap_ung": "Đáp ứng",
        "DHR_Dat": "Đạt",
        "DHR_De_nghi_tuyen": "Đề nghị tuyển",
        "DHR_De_nghi_tuyen_ung_vien_nay": "Đề nghị tuyển ứng viên này",
        "DHR_De_xuat_dao_tao": "Đề xuất đào tạo",
        "DHR_De_xuat_dieu_chinh_lao_dong": "Đề xuất điều chuyển lao động",
        "DHR_De_xuat_dieu_chinh_luong": "Đề xuất điều chỉnh lương",
        "DHR_De_xuat_khen_thuong": "Đề xuất khen thưởng",
        "DHR_De_xuat_kiem_nhiemU": "Đề xuất kiêm nhiệm",
        "DHR_De_xuat_ky_luat": "Đề xuất kỷ luật",
        "DHR_De_xuat_offer": "Đề xuất offer",
        "DHR_De_xuat_thay_doi_chuc_danh": "Đề xuất thay đổi chức danh",
        "DHR_De_xuat_tuyen_dung": "Đề xuất tuyển dụng",
        "DHR_Deadline": "Deadline",
        "DHR_Den": "Đến",
        "DHR_Den_ngay": "Đến ngày",
        "DHR_Di_dong": "Di động",
        "DHR_Di_tre": "Đi trễ",
        "DHR_Di_xe": "Đi xe",
        "DHR_Dia_chi": "Địa chỉ",
        "DHR_Dia_chi_lien_lac": "Địa chỉ liên lạc",
        "DHR_Dia_chi_tam_tru": "Địa chỉ tạm trú",
        "DHR_Dia_chi_thuong_tru": "Địa chỉ thường trú",
        "DHR_Dia_diem": "Địa điểm",
        "DHR_Dia_diem_phong_van": "Địa điểm phỏng vấn",
        "DHR_Diem": "Điểm",
        "DHR_Diem_den": "Điểm đến",
        "DHR_Diem_di": "Điểm đi",
        "DHR_Diem_ky_vong": "Điểm kỳ vọng",
        "DHR_Diem_so_khong_hop_le": "Điểm số không hợp lệ!",
        "DHR_Diem_trung_binh": "Điểm trung bình",
        "DHR_Dien_giai": "Diễn giải",
        "DHR_Dien_thoai": "Điện thoại",
        "DHR_Dien_thoai_noi_bo": "Điện thoại nội bộ",
        "DHR_Dieu_chinh": "Điều chỉnh",
        "DHR_Dieu_chinh_luong": "Điều chỉnh lương",
        "DHR_Dieu_chinh_nguoi_phu_trach": "Điều chỉnh người phụ trách",
        "DHR_Dieu_chinh_thong_tin": "Điều chỉnh thông tin",
        "DHR_Dieu_chinh_thong_tin_gia_dinh": "Điều chỉnh thông tin gia đình",
        "DHR_Dieu_kien_tinh_huong": "Điều kiện tính hưởng",
        "DHR_Dinh_dang_file_khong_hop_le": "Định dạng file không hợp lệ",
        "DHR_Dinh_dang_mau_khong_hop_le.Cho_phep_cac_loai_mau_.xlxs_.docx_.html": "Định dạng mẫu không hợp lệ. Cho phép các loại mẫu .xlsx, .docx, html",
        "DHR_Dinh_dang_ngay_khong_dung": "Định dạng ngày không hợp lệ!",
        "DHR_Dinh_kem": "Đính kèm",
        "DHR_Dinh_vi_khoa": "Định vị bị khoá",
        "DHR_Do_tuoi": "Độ tuổi",
        "DHR_Do_uu_tien": "Độ ưu tiên",
        "DHR_Doanh_nghiep": "Doanh nghiệp",
        "DHR_Doi_mat_khau": "Đổi mật khẩu",
        "DHR_Doi_mat_khau_thanh_cong": "Đổi mật khẩu thành công",
        "DHR_Doi_tuong": "Đối tượng",
        "DHR_Doi_tuong_ap_dung": "Đối tượng áp dụng",
        "DHR_Doi_tuong_cong_phep": "Đối tượng công/ phép",
        "DHR_Doi_tuong_lao_dong": "Đối tượng lao động",
        "DHR_Doi_tuong_tinh_luong": "ĐT tính lương",
        "DHR_Don_tu": "Đơn từ",
        "DHR_Don_tu_cua_toi": "Đơn từ của tôi",
        "DHR_Don_tu_toi_duyet": "Đơn từ tôi duyệt",
        "DHR_Don_ung_tuyen": "Đơn ứng tuyển",
        "DHR_Don_vi": "Đơn vị",
        "DHR_Don_vi_da_lam_viec": "Đơn vị đã làm việc",
        "DHR_Don_vi_moi": "Đơn vị mới",
        "DHR_Don_vi_tinh": "Đơn vị tính",
        "DHR_Dong1": "Đóng",
        "DHR_Dong_W25DESC": "Tin đã đóng và không ai có thể apply.",
        "DHR_Dong_y": "Đồng ý",
        "DHR_Du_an": "Dự án",
        "DHR_Du_an_cong_trinh": "Dự án công trình",
        "DHR_Du_an_moi": "Dự án mới",
        "DHR_Du_lieu": "Dữ liệu",
        "DHR_Du_lieu_ca_nhan,_ban_khong_co_quyen_xem": "Dữ liệu cá nhân, bạn không có quyền xem",
        "DHR_Du_lieu_cham_phep": "Dữ liệu chấm phép",
        "DHR_Du_lieu_chua_duoc_luu._Ban_co_muon_luu?": "Dữ liệu chưa được lưu. Bạn có muốn lưu?",
        "DHR_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Dữ liệu chưa được lưu. Bạn có muốn lưu không?",
        "DHR_Du_lieu_da_bi_thay_doi_ban_co_muon_luu_khong": "Dữ liệu đă bị thay đổi. Bạn có muốn lưu không ?",
        "DHR_Du_lieu_dac_biet": "Dữ liệu đặc biệt",
        "DHR_Du_lieu_dau_vao_la_bat_buoc": "Dữ liệu đầu vào là bắt buộc",
        "DHR_Du_lieu_phep_tong_hop": "Dữ liệu phép tổng hợp",
        "DHR_Du_lieu_xuat_excel_rong": "Báo cáo không có dữ liệu",
        "DHR_Dung_luong_File_khong_duoc_lon_hon": "Dung lượng File không được lớn hơn",
        "DHR_Duyet": "Duyệt",
        "DHR_Duyet_bang_muc_tieu": "Duyệt bảng mục tiêu",
        "DHR_Duyet_bo_sung_thoi_gian_vao_ra": "Duyệt bổ sung thời gian vào ra",
        "DHR_Duyet_chung": "Duyệt chung",
        "DHR_Duyet_cong_tac": "Duyệt công tác",
        "DHR_Duyet_dang_ky_tang_ca_ho": "Duyệt đăng ký tăng ca hộ",
        "DHR_Duyet_danh_gia_nhan_vien_va_HDLD": "Duyệt đánh giá nhân viên và HĐLĐ",
        "DHR_Duyet_de_xuat_chinh_sach_che_do": "Duyệt đề xuất chính sách chế độ",
        "DHR_Duyet_de_xuat_dao_tao": "Duyệt đề xuất đào tạo",
        "DHR_Duyet_de_xuat_dieu_chuyen_lao_dong": "Duyệt đề xuất điều chuyển lao động",
        "DHR_Duyet_de_xuat_khen_thuong": "Duyệt đề xuất khen thưởng",
        "DHR_Duyet_de_xuat_kiem_nhiem": "Duyệt đề xuất kiêm nhiệm",
        "DHR_Duyet_de_xuat_nghi_viec": "Duyệt đề xuất nghỉ việc",
        "DHR_Duyet_de_xuat_offer": "Duyệt đề xuất offer",
        "DHR_Duyet_de_xuat_thay_doi_chuc_danh": "Duyệt đề xuất thay đổi chức danh",
        "DHR_Duyet_de_xuat_tuyen_dung": "Duyệt đề xuất tuyển dụng",
        "DHR_Duyet_di_tre_ve_som": "Duyệt đi trễ về sớm",
        "DHR_Duyet_dieu_chinh_luong": "Duyệt điều chỉnh lương",
        "DHR_Duyet_dieu_chinh_thong_tin": "Duyệt điều chỉnh thông tin",
        "DHR_Duyet_dieu_chinh_thu_nhap": "Duyệt điều chỉnh thu nhập",
        "DHR_Duyet_gio_tang_ca_thuc_te": "Duyệt giờ tăng ca thực tế",
        "DHR_Duyet_gio_tang_ca_thuc_te_hang_loat": "Duyệt giờ tăng ca thực tế hàng loạt",
        "DHR_Duyet_gio_vao_ra": "Duyệt giờ vào - ra",
        "DHR_Duyet_hang_loat": "Duyệt hàng loạt",
        "DHR_Duyet_ket_qua_muc_tieu_ca_nhan": "Duyệt kết quả mục tiêu cá nhân",
        "DHR_Duyet_khen_thuong_hang_loat": "Duyệt khen thưởng hàng loạt",
        "DHR_Duyet_khong_thanh_cong": "Duyệt không thành công.",
        "DHR_Duyet_ky_luat": "Duyệt kỷ luật",
        "DHR_Duyet_ky_luat_hang_loat": "Duyệt kỷ luật hàng loạt",
        "DHR_Duyet_mien_nhiem": "Duyệt miễn nhiệm",
        "DHR_Duyet_muc_tieu": "Duyệt mục tiêu",
        "DHR_Duyet_nghi_phep": "Duyệt nghỉ phép",
        "DHR_Duyet_nghi_thai_san": "Duyệt nghỉ thai sản",
        "DHR_Duyet_quyet_toan_chi_phi": "Duyệt quyết toán chi phí",
        "DHR_Duyet_quyet_toan_chi_phi_cong_tac": "Duyệt quyết toán chi phí công tác",
        "DHR_Duyet_sai_sot_cong": "Duyệt sai sót công",
        "DHR_Duyet_tang_ca": "Duyệt tăng ca",
        "DHR_Duyet_tang_ca_hang_loat": "Duyệt tăng ca hàng loạt",
        "DHR_Duyet_thanh_cong": "Duyệt thành công",
        "DHR_Email": "Email",
        "DHR_Email_ca_nhan": "Email cá nhân",
        "DHR_Email_gui_den_ung_vien": "Email gửi đến ứng viên",
        "DHR_Email_ho_tro": "hr@diginet.com.vn",
        "DHR_Email_khong_hop_le": "Email không hợp lệ",
        "DHR_Email_tu_dong": "Email tự động",
        "DHR_Export_danh_sach_ung_vien": "Export danh sách ứng viên",
        "DHR_Facebook_profile": "Facebook Profile",
        "DHR_File_da_duoc_dinh_kem": "File đã được đính kèm",
        "DHR_File_khong_dung_dinh_dang": "File không đúng định dạng",
        "DHR_File_vuot_qua_dung_luong_cho_phep": "File vượt quá dung lượng cho phép",
        "DHR_File_vuot_qua_so_luong_cho_phep": "File vượt quá số lượng cho phép",
        "DHR_Form_id": "FormID",
        "DHR_Gan_ca": "Gán ca",
        "DHR_Gan_ca_lam_viec": "Gán ca làm việc",
        "DHR_Gan_ca_mac_dinh": "Gán ca mặc định",
        "DHR_Gan_ca_xoay_vong": "Gán ca xoay vòng",
        "DHR_Ghi_chep_noi_bo": "Ghi chép nội bộ",
        "DHR_Ghi_chu": "Ghi chú",
        "DHR_Ghi_chu_duyet": "Ghi chú duyệt",
        "DHR_Ghi_chu_huy": "Ghi chú hủy",
        "DHR_Ghi_chu_phong_van": "Ghi chú phỏng vấn",
        "DHR_Ghi_chu_rieng_tu_nguoi_tao_lich_phong_van": "Ghi chú riêng từ người tạo lịch phổng vấn",
        "DHR_Ghi_chu_rieng_tu_tu_nguoi_tao_phong_van_voi_ung_vien_nay": "Ghi chú riêng tư từ người tạo phỏng vấn với ứng viên",
        "DHR_Ghi_chu_sau_thai_san": "Ghi chú sau thai sản",
        "DHR_Ghi_chu_tu_nguoi_tao_phong_van": "Ghi chú từ người tạo phỏng vấn",
        "DHR_Ghi_chu_xac_nhan": "Ghi chú xác nhận",
        "DHR_Gia_dinh": "Gia đình",
        "DHR_Gia_tri": "Giá trị",
        "DHR_Gia_tri_bat_buoc_nhap": "Giá trị bắt buộc nhập!",
        "DHR_Gia_tri_lon_nhat": "Giá trị lớn nhất",
        "DHR_Gia_tri_tu_phai_nho_hon_gia_tri_den": "Giá trị từ phải nhỏ hơn giá trị đến.",
        "DHR_Giai_doan": "Giai đoạn",
        "DHR_Giai_doan1": "Giai đoạn",
        "DHR_Giai_doanF": "Giai đoạn",
        "DHR_Giai_doan_hien_co": "Giai đoạn hiện có",
        "DHR_Giai_trinh_nguyen_nhan": "Giải trình nguyên nhân",
        "DHR_Giay_phep_lao_dong": "Giấy phép lao động",
        "DHR_Gio_cong": "Giờ công",
        "DHR_Gio_di_ca": "Giờ đi ca",
        "DHR_Gio_nghi_giua_ca": "Giờ nghỉ giữa ca",
        "DHR_Gio_phong_van": "Giờ phỏng vấn",
        "DHR_Gio_ra": "Giờ ra",
        "DHR_Gio_tang_ca": "Giờ tăng ca",
        "DHR_Gio_tang_ca_den": "Giờ tăng ca đến",
        "DHR_Gio_tang_ca_khong_hop_le": "Giờ tăng ca không hợp lệ",
        "DHR_Gio_tang_ca_tu": "Giờ tăng ca từ",
        "DHR_Gio_vao": "Giờ vào",
        "DHR_Gio_vao_ra": "Giờ vào ra",
        "DHR_Gioi_han_toi_da_tong_trong_so_muc_tieu": "Giới hạn tối đa tổng trọng số mục tiêu",
        "DHR_Gioi_thieu": "Giới thiệu",
        "DHR_Gioi_thieu_ve_nhom": "Giới thiệu về nhóm",
        "DHR_Gioi_tinh": "Giới tính",
        "DHR_Go_vai_tro_quan_tri_vien": "Gỡ vai trò quản trị viên",
        "DHR_Gui": "Gửi",
        "DHR_Gui_email": "Gửi email",
        "DHR_Gui_lai_ma": "Gửi lại mã",
        "DHR_Gui_mail": "Gửi mail",
        "DHR_Gui_mail_thanh_cong": "Gửi mail thành công",
        "DHR_Gui_thanh_cong": "Gửi thành công",
        "DHR_HOP_DONG_LAO_DONG": "HỢP ĐỒNG LAO ĐỘNG",
        "DHR_Hai_long_can_cai_tien_them": "Hài lòng cần cải tiến thêm",
        "DHR_Han_hoan_thanh": "Hạn hoàn thành",
        "DHR_Han_review_cuoi": "Hạn review cuối",
        "DHR_Hang_loat": "Hàng loạt",
        "DHR_Hanh_dong": "Hành động",
        "DHR_Hanh_vi_vi_pham_ky_luat": "Hành vi vi phạm kỷ luật",
        "DHR_He_so": "Hệ số",
        "DHR_He_so_luong": "Hệ số lương",
        "DHR_He_so_luong_%p": "Hệ số lương %p",
        "DHR_He_thong_da_gui_ma_den_email": "Hệ thống đã gửi mã đến email",
        "DHR_He_thong_dang_trong_qua_trinh_nang_cap": "Hệ thống đang trong quá trình nâng cấp.",
        "DHR_Hien_muc_luong": "Hiện mức lương",
        "DHR_Hien_thi_cac_du_lieu_da_chon": "Hiển thị các dữ liệu đã chọn",
        "DHR_Hien_thi_du_lieu_da_chon": "Hiển thị dữ liệu đã chọn",
        "DHR_Hien_thi_khong_su_dung": "Hiển thị không sử dụng",
        "DHR_Hien_thi_tat_ca": "Hiển thị tất cả",
        "DHR_Hien_thi_theo_tuan": "Hiển thị theo tuần",
        "DHR_Hien_thi_vai_tro_khong_con_su_dung": "Hiển thị vai trò không còn sử dụng",
        "DHR_Hinh_thuc": "Hình thức",
        "DHR_Hinh_thuc_huong_luong": "Hình thức hưởng lương",
        "DHR_Hinh_thuc_khen_thuong": "Hình thức khen thưởng",
        "DHR_Hinh_thuc_ky_luat": "Hình thức kỷ luật",
        "DHR_Hinh_thuc_lam_viec": "Hình thức làm việc",
        "DHR_Hinh_thuc_nghi_viec": "Hình thức nghỉ việc",
        "DHR_Hired": "Hired",
        "DHR_Ho_chieu": "Hộ chiếu",
        "DHR_Ho_so_bao_hiem": "Hồ sơ bảo hiểm",
        "DHR_Ho_so_nhan_vien": "Hồ sơ nhân viên",
        "DHR_Ho_ten_ung_cu_vien": "Họ tên ứng cử viên",
        "DHR_Ho_tro": "Hỗ trợ",
        "DHR_Ho_va_ten": "Họ và tên",
        "DHR_Hoan_tat": "Hoàn tất",
        "DHR_Hoan_thanh": "Hoàn thành",
        "DHR_Hoan_toan_hai_long": "Hoàn toàn hài lòng",
        "DHR_Hoc_van_chuyen_mon": "Học vấn - Chuyên môn",
        "DHR_Hoc_van_va_chuyen_mon": "Học vấn và chuyên môn",
        "DHR_Hom_nay": "Hôm nay",
        "DHR_Home_page": "HomePage",
        "DHR_Huong_dan_su_dung": "Hướng dẫn sử dụng",
        "DHR_Huong_dan_xoa_nhan_vien": "Hướng dẫn: nhấn và giữ trên dòng nhân viên để thực hiện xóa",
        "DHR_Huy": "Hủy",
        "DHR_Huy_bo": "Hủy bỏ",
        "DHR_Huy_dang_ky_tang_ca": "Hủy đăng ký tăng ca",
        "DHR_Huy_duyet_thanh_cong": "Hủy duyệt thành công.",
        "DHR_Huy_hoan_tat": "Hủy hoàn tất",
        "DHR_Huy_muc_tieu": "Hủy mục tiêu",
        "DHR_Huy_muc_tieu_thanh_cong": "Hủy mục tiêu thành công",
        "DHR_Huy_phep": "Hủy phép",
        "DHR_Huy_phep_thanh_cong": "Hủy phép thành công",
        "DHR_Huy_tang_ca": "Hủy tăng ca",
        "DHR_In_HDLD": "In HĐLĐ",
        "DHR_KSD": "KSD",
        "DHR_Ke_hoach_dao_tao": "Kế hoạch đào tạo",
        "DHR_Ke_hoach_tong_the": "Kế hoạch tổng thể",
        "DHR_Kenh_tuyen_dung": "Kênh tuyển dụng",
        "DHR_Keo_tap_tin_vao_day": "Kéo tập tin vào đây",
        "DHR_Keo_va_tha_tap_tin_dinh_kem_vao_day": "Kéo và thả tập tin đính kèm vào đây!",
        "DHR_Keo_va_tha_tep_tin_vao_day": "Kéo và thả tệp tin vào đây",
        "DHR_Ket_qua": "Kết quả",
        "DHR_Ket_qua_%p": "Kết quả %p",
        "DHR_Ket_qua_danh_gia": "Kết quả đánh giá",
        "DHR_Ket_qua_danh_gia_chung": "Kết quả đánh giá chung",
        "DHR_Ket_qua_review": "Kết quả review",
        "DHR_Ket_qua_then_chot": "Kết quả then chốt",
        "DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "KeyID đang rỗng hoặc thiếu, không tải được danh sách đính kèm",
        "DHR_Khac": "Khác",
        "DHR_Khao_sat_y_kien_khach_hang": "Khảo sát ý kiến khách hàng",
        "DHR_Khen_thuong_hang_loat": "Khen thưởng hàng loạt",
        "DHR_Kho": "Kho",
        "DHR_Kho_thu_hoi": "Kho thu hồi",
        "DHR_Khoa_dao_tao": "Khóa đào tạo",
        "DHR_Khoan_chi": "Khoản chi",
        "DHR_Khoan_chi_de_nghi_quyet_toan": "Khoản chi đề nghị quyết toán",
        "DHR_Khoan_chi_phi_du_kien": "Khoản chi phí dự kiến",
        "DHR_Khoan_de_nghi_quyet_toan": "Khoản đề nghị quyết toán",
        "DHR_Khoan_dieu_chinh_thu_nhap": "Khoản điều chỉnh thu nhập",
        "DHR_Khoang_dieu_chinh_thu_nhap": "Khoảng điều chỉnh thu nhập",
        "DHR_Khoang_thoi_gian": "Khoảng thời gian",
        "DHR_Khoi_la_bat_buoc_nhap": "Khối bắt buộc nhập",
        "DHR_Khong": "Không",
        "DHR_Khong_cho_phep": "Không cho phép",
        "DHR_Khong_co_dong_du_lieu_duoc_cap_nhat": "Không có dòng dữ liệu được cập nhật",
        "DHR_Khong_co_tap_tin_dinh_kem": "Không có tập tin đính kèm",
        "DHR_Khong_co_thong_bao": "Không có thông báo",
        "DHR_Khong_co_thong_bao_nhac_nho_nao": "Không có thông báo nhắc nhở nào",
        "DHR_Khong_co_ung_vien_duoc_chon_moi": "Không có ứng viên được chọn mới",
        "DHR_Khong_dat": "Không đạt",
        "DHR_Khong_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "DHR_Khong_duoc_phep_luu": "Không được phép lưu",
        "DHR_Khong_duoc_phep_nghi_qua_mot_ngay": "Không được phép nghỉ quá 1 ngày",
        "DHR_Khong_gui_email": "Không gửi email",
        "DHR_Khong_hien_muc_luong": "Không hiện mức lương",
        "DHR_Khong_la_bang_tong_hop": "Không là bảng tổng hợp",
        "DHR_Khong_luu": "Không lưu",
        "DHR_Khong_review": "Không review",
        "DHR_Khong_su_dung": "Không sử dụng",
        "DHR_Khong_ten": "Không tên",
        "DHR_Khong_the_dinh_kem": "Không thể đính kèm",
        "DHR_Khong_the_duyet": "Không thể duyệt",
        "DHR_Khong_tim_thay_hoac_khong_du_tt": "Không tìm thấy chỉ tiêu đánh giá hoặc tham số chưa đủ!",
        "DHR_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "DHR_Khong_trang_thai": "Không trạng thái",
        "DHR_Khong_xet_duyet": "Không xét duyệt",
        "DHR_Kich_co_toi_da": "Kích cỡ tối đa",
        "DHR_Kiem_nhiem": "Kiêm nhiệm",
        "DHR_Kien_thuc": "Kiến thức",
        "DHR_Kinh_nghiem": "Kinh nghiệm",
        "DHR_Kinh_nghiem_den_khong_duoc_nho_hon_kinh_nghiem_tu": "Kinh nghiệm đến không được nhỏ hơn kinh nghiệm từ",
        "DHR_Kinh_nghiem_tu_khong_duoc_lon_hon_kinh_nghiem_den": "Kinh nghiệm từ không được nhỏ hơn kinh nghiệm đến",
        "DHR_Ky": "Kỳ",
        "DHR_Ky_cong": "Kỳ công",
        "DHR_Ky_luat_hang_loat": "Kỷ luật hàng loạt",
        "DHR_Ky_luong": "Kỳ lương",
        "DHR_Ky_nang": "Kỹ năng",
        "DHR_La_bang_tong_hop": "Là bảng tổng hợp",
        "DHR_Lam_moi": "Làm mới",
        "DHR_Lap_de_xuat_chinh_sach_che_do": "Lập đề xuất chính sách chế độ",
        "DHR_Lap_de_xuat_dao_tao": "Lập đề xuất đào tạo",
        "DHR_Lap_de_xuat_dieu_chinh_thu_nhap": "Lập đề xuất điều chỉnh thu nhập",
        "DHR_Lap_de_xuat_dieu_chuyen_lao_dong": "Lập đề xuất điều chuyển lao động",
        "DHR_Lap_de_xuat_khen_thuong": "Lập đề xuất khen thưởng",
        "DHR_Lap_de_xuat_kiem_nhiem": "Lập đề xuất kiêm nhiệm",
        "DHR_Lap_de_xuat_ky_luat": "Lập đề xuất kỷ luật",
        "DHR_Lap_de_xuat_mien_nhiem": "Lập đề xuất miễn nhiệm",
        "DHR_Lap_de_xuat_thay_doi_chuc_danh": "Lập đề xuất thay đổi chức danh",
        "DHR_Lap_de_xuat_tuyen_dung": "Lập đề xuất tuyển dụng",
        "DHR_Lap_khen_thuong_hang_loat": "Lập khen thuởng hàng loạt",
        "DHR_Lap_ky_luat_hang_loat": "Lập kỷ luật hàng loạt",
        "DHR_Lap_lich_di_ca": "Lập lịch đi ca",
        "DHR_Lap_lich_hang_loat": "Lập lịch hàng loạt",
        "DHR_Lap_lich_tang_ca": "Lập lịch tăng ca",
        "DHR_Lap_lich_tang_ca_hang_loat": "Lập lịch tăng ca hàng loạt",
        "DHR_Lap_loai_chi_phi": "Lập loại chi phí",
        "DHR_Lap_nghi_phep": "Lập nghỉ phép",
        "DHR_Lap_phieu_danh_gia_nhan_vien": "Lập phiếu đánh giá nhân viên",
        "DHR_Lap_phieu_danh_gia_phong_ban": "Lập phiếu đánh giá phòng ban",
        "DHR_Lay_ty_le_hoan_thanh_danh_gia_theo_cap": "Lấy tỷ lệ hoàn thành đánh giá theo cấp",
        "DHR_Lich_su": "Lịch sử",
        "DHR_Lich_su_BHXH": "Lịch sử BHXH",
        "DHR_Lich_su_dao_tao": "Lịch sử đào tạo",
        "DHR_Lich_su_dctt": "Lịch sử ĐCTT",
        "DHR_Lich_su_dieu_chinh": "Lịch sử điều chỉnh",
        "DHR_Lich_su_dieu_chinh_thong_tin": "Lịch sử điều chỉnh thông tin",
        "DHR_Lich_su_duyet": "Lịch sử duyệt",
        "DHR_Lich_su_lao_dong": "Lịch sử lao động",
        "DHR_Lich_su_nhan_su": "Lịch sử nhân sự",
        "DHR_Lich_su_tac_dong": "Lịch sử tác động",
        "DHR_Linh_vuc_dao_tao": "Lĩnh vực đào tạo",
        "DHR_Linkedin_profile": "Linkedin Profile",
        "DHR_Loai": "Loại",
        "DHR_Loai_HDLD": "Loại HĐLĐ",
        "DHR_Loai_che_do": "Loại chế độ",
        "DHR_Loai_danh_gia": "Loại đánh giá",
        "DHR_Loai_doi_tuong_ap_dung": "Loại đối tượng áp dụng",
        "DHR_Loai_don": "Loại đơn",
        "DHR_Loai_hinh_cong_viec": "Loại hình công việc",
        "DHR_Loai_hop_dong": "Loại hợp đồng",
        "DHR_Loai_hop_dong_lao_dong": "Loại hợp đồng lao động",
        "DHR_Loai_mail": "Loại mail",
        "DHR_Loai_muc_tieu": "Loại mục tiêu",
        "DHR_Loai_ngay": "Loại ngày",
        "DHR_Loai_nhanh": "Loại nhanh",
        "DHR_Loai_phan_bo": "Loại phân bổ",
        "DHR_Loai_phep": "Loại phép",
        "DHR_Loai_phieu": "Loại phiếu",
        "DHR_Loai_phong_van": "Loại phỏng vấn",
        "DHR_Loai_phu_luc": "Loại phụ lục",
        "DHR_Loai_quy_trinh": "Loại quy trình",
        "DHR_Loai_tach_ca": "Loại tách ca",
        "DHR_Loai_tach_tang_ca": "Loại tách tăng ca",
        "DHR_Loai_tach_tang_ca1": "Loại tách tăng ca",
        "DHR_Loai_tang_ca": "Loại tăng ca",
        "DHR_Loai_tap_tin_ho_tro": "Loại tập tin hỗ trợ",
        "DHR_Loai_thu_nhap": "Loại thu nhập",
        "DHR_Loai_tien": "Loại tiền",
        "DHR_Loc_chon_hanh_dong": "Lọc chọn hành động",
        "DHR_Loc_chon_nhan_vien": "Tiêu chí lọc",
        "DHR_Loc_trang_thiet_bi_lao_dong": "Lọc trang thiết bị lao động",
        "DHR_Loi_chua_xac_dinh": "Lỗi chưa xác định",
        "DHR_Loi_khong_lay_duoc_token_CDN": "Lỗi không lấy được CDN token",
        "DHR_Lon_hon_hoac_bang": "Lớn hơn hoặc bằng",
        "DHR_Lua_chon": "Lựa chọn",
        "DHR_Luong": "Lương",
        "DHR_Luong_co_ban": "Lương cơ bản",
        "DHR_Luong_co_ban_%p": "Lương cơ bản %p",
        "DHR_Luong_cong_viec": "Luồng công việc",
        "DHR_Luong_dong_bao_hiem": "Lương đóng bảo hiểm",
        "DHR_Luong_tu_phai_nho_hon_luong_den": "Lương từ phải nhỏ hơn lương đến",
        "DHR_Luong_yeu_cau": "Lương yêu cầu",
        "DHR_Luu": "Lưu",
        "DHR_Luu_&_nhap_tiep": "Lưu & nhập tiếp",
        "DHR_Luu_dinh_kem_khong_thanh_cong": "Lưu đính kèm không thành công!",
        "DHR_Luu_khong_thanh_cong": "Lưu không thành công!",
        "DHR_Luu_lich_su_khong_thanh_cong": "Lưu lịch sử không thành công",
        "DHR_Luu_lich_su_thanh_cong": "Lưu lịch sử thành công",
        "DHR_Luu_thanh_cong": "Lưu thành công",
        "DHR_Luu_thong_tin_chung_tu_khong_thanh_cong": "Lưu thông tin chứng từ không thành công",
        "DHR_Luu_va_cap_nhat_thanh_cong": "Lưu và cập nhật thành công",
        "DHR_Luu_va_nhap_tiep": "Lưu và Nhập tiếp",
        "DHR_Ly_do": "Lý do",
        "DHR_Ly_do_cap_phat": "Lý do cấp phát",
        "DHR_Ly_do_dao_tao": "Lý do đào tạo",
        "DHR_Ly_do_huy_dang_ky_tang_ca": "Lý do hủy đăng ký tăng ca",
        "DHR_Ly_do_huy_phep": "Lý do hủy phép",
        "DHR_Ly_do_tam_ung": "Lý do tạm ứng",
        "DHR_Ly_do_tang_ca": "Lý do tăng ca",
        "DHR_Ly_do_thu_hoi": "Lý do thu hồi",
        "DHR_Ly_do_tu_choi_2": "Lý do từ chối",
        "DHR_Ly_do_tuyen": "Lý do tuyển",
        "DHR_Ma": "Mã",
        "DHR_Ma_CCLD": "Mã CCLĐ",
        "DHR_Ma_HDLD": "Mã HĐLĐ",
        "DHR_Ma_NV": "Mã NV",
        "DHR_Ma_UV": "Mã UV",
        "DHR_Ma_bang_muc_tieu": "Mã bảng mục tiêu",
        "DHR_Ma_bang_tong_hop": "Mã bảng tổng hợp",
        "DHR_Ma_ca": "Mã ca",
        "DHR_Ma_cham_cong": "Mã chấm công",
        "DHR_Ma_chuc_vu_moi": "Mã chức vụ mới",
        "DHR_Ma_cong_tac": "Mã công tác",
        "DHR_Ma_doi_tuong": "Mã đối tượng",
        "DHR_Ma_du_an": "Mã dự án",
        "DHR_Ma_hop_dong": "Mã hợp đồng",
        "DHR_Ma_loai_tach": "Mã loại tách",
        "DHR_Ma_ly_do": "Mã lý do",
        "DHR_Ma_nghiep_vu": "Mã nghiệp vụ",
        "DHR_Ma_nguoi_dung": "Mã người dùng",
        "DHR_Ma_nhan_vien": "Mã nhân viên",
        "DHR_Ma_nhan_vien_Ten_nhan_vien": "Mã nhân viên, Tên nhân viên",
        "DHR_Ma_nhan_vien_phu": "Mã nhân viên phụ",
        "DHR_Ma_nhom_muc_tieu": "Mã nhóm mục tiêu",
        "DHR_Ma_phieu": "Mã phiếu",
        "DHR_Ma_phong_ban": "Mã phòng ban",
        "DHR_Ma_so_thue_ca_nhan": "Mã số thuế cá nhân",
        "DHR_Ma_the_cham_cong": "Mã thẻ chấm công",
        "DHR_Ma_thong_ke": "Mã thống kê",
        "DHR_Ma_ung_vien_Ten_ung_vien": "Mã ứng viên, Tên ứng viên",
        "DHR_Ma_vai_tro": "Mã vai trò",
        "DHR_Ma_vat_tu": "Mã vật tư",
        "DHR_Ma_vat_tu_ten_vat_tu": "Mã vật tư, Tên vật tư",
        "DHR_Mail_rong": "Mail rỗng",
        "DHR_Man_hinh": "Màn hình",
        "DHR_Man_hinh_mac_dinh_khi_dang_nhap": "Màn hình mặc định khi đăng nhập",
        "DHR_Mat_khau": "Mật khẩu",
        "DHR_Mat_khau_co_hieu_luc_trong_vong_5_phut": "Mật khẩu có hiệu lực trong vòng 5 phút",
        "DHR_Mat_khau_cu": "Mật khẩu cũ",
        "DHR_Mat_khau_khong_dung_vui_long_thu_lai": "Mật khẩu không đúng vui lòng thử lại",
        "DHR_Mat_khau_moi": "Mật khẩu mới",
        "DHR_Mat_khau_phai_co_it_nhat_8_ky_tu": "Mật khẩu phải có ít nhất 8 ký tự, trong đó phải có 1 ký tự chữ hoa, 1 ký tự chữ thường, 1 ký tự số và 1 ký tự đặc biệt",
        "DHR_Mat_khau_xac_nhan_khong_chinh_xac": "Mật khẩu xác nhận không chính xác",
        "DHR_Mau_bao_cao": "Mẫu báo cáo",
        "DHR_Mau_bao_cao_khong_duoc_tim_thay": "Mẫu báo báo không được tìm thấy",
        "DHR_Mau_ca": "Mẫu ca",
        "DHR_Mau_email_hoac_phong_van": "Mẫu email hoặc phỏng vấn",
        "DHR_Mau_giao_dien": "Màu giao diện",
        "DHR_Mau_mail_co_san": "Mẫu mail có sẵn",
        "DHR_Mau_sac": "Màu sắc",
        "DHR_Mau_sac_hien_thi": "Màu sắc hiển thị",
        "DHR_Menu_id": "MenuID",
        "DHR_Mo_rong": "Mở rộng",
        "DHR_Mo_ta": "Mô tả",
        "DHR_Mo_ta_cong_viec": "Mô tả công việc",
        "DHR_Mo_ta_ly_do_khac": "Mô tả lý do khác",
        "DHR_Mo_ta_mau_sac": "Mô tả màu sắc",
        "DHR_Mo_tat_ca": "Mở tất cả",
        "DHR_Moi": "mới",
        "DHR_Moi_bo_sung": "Mới bổ sung",
        "DHR_Mot_thang_truoc": "Một tháng trước",
        "DHR_Mot_tuan_truoc": "Một tuần trước",
        "DHR_Muc_dich_cong_tac": "Mục đích công tác",
        "DHR_Muc_do_dong_y": "Mức độ đồng ý",
        "DHR_Muc_luong": "Mức lương",
        "DHR_Muc_luong_den": "Mức lương đến",
        "DHR_Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu": "Mức lương đến không được nhỏ hơn mức lương từ",
        "DHR_Muc_luong_du_kien": "Mức lương dự kiến",
        "DHR_Muc_luong_tu": "Mức lương từ",
        "DHR_Muc_luong_tu_khong_duoc_lon_muc_luong_den": "Mức lương từ không được lớn hơn mức lương đến",
        "DHR_Muc_luong_va_phu_cap": "Mức lương và phụ cấp",
        "DHR_Muc_tieu1": "Mục tiêu",
        "DHR_Muc_tieu_ca_nhan": "Mục tiêu cá nhân",
        "DHR_Muc_tieu_co_cau_to_chuc": "Mục tiêu cơ cấu tổ chức",
        "DHR_Muc_tieu_kiem_nhiem": "Mục tiêu kiêm nhiệm",
        "DHR_Muc_tieu_nhan_vien": "Mục tiêu nhân viên",
        "DHR_NV_chua_tham_gia_khoa_dao_tao": "NV chưa tham gia khoá đào tạo",
        "DHR_Nam": "Năm",
        "DHR_Nam1": "Nam",
        "DHR_NamU": "Nam",
        "DHR_Nam_sinh": "Năm sinh",
        "DHR_Ngan_hang": "Ngân hàng",
        "DHR_Ngay": "Ngày",
        "DHR_Ngay1": "Ngày",
        "DHR_Ngay3": "Ngày",
        "DHR_Ngay_bat_dau": "Ngày bắt đầu",
        "DHR_Ngay_bat_dau_nghi_viec": "Ngày bắt đầu nghỉ việc",
        "DHR_Ngay_bat_dau_thu_viec": "Ngày bắt đầu thử việc",
        "DHR_Ngay_bo_sung": "Ngày bổ sung",
        "DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac": "Ngày bổ sung giờ vào ra không có ca . Bạn vui lòng chọn ngày khác",
        "DHR_Ngay_cap": "Ngày cấp",
        "DHR_Ngay_cap_BHXH": "Ngày cấp BHXH",
        "DHR_Ngay_cap_CMND": "Ngày cấp CMND",
        "DHR_Ngay_cap_ho_chieu": "Ngày cấp hộ chiếu",
        "DHR_Ngay_cap_phat": "Ngày cấp phát",
        "DHR_Ngay_cap_phat_CCLD_truoc": "Ngày cấp phát CCLĐ trước",
        "DHR_Ngay_cap_the_can_cuoc": "Ngày cấp thẻ căn cước",
        "DHR_Ngay_cham_cong": "Ngày chấm công",
        "DHR_Ngay_chot_van_tay": "Ngày chốt vân tay",
        "DHR_Ngay_cong": "Ngày công",
        "DHR_Ngay_da_nghi_phep": "Ngày đã nghỉ phép",
        "DHR_Ngay_dang_ky_KCB": "Ngày đăng ký KCB",
        "DHR_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "Ngày đăng ký từ không được lớn hơn ngày đăng ký đến",
        "DHR_Ngay_danh_gia": "Ngày đánh giá",
        "DHR_Ngay_danh_gia_bat_buoc_nhap": "Ngày đánh giá bắt buộc nhập",
        "DHR_Ngay_de_xuat": "Ngày đề xuất",
        "DHR_Ngay_de_xuat_tam_ung": "Ngày đề xuất tạm ứng",
        "DHR_Ngay_den": "Ngày đến",
        "DHR_Ngay_di": "Ngày đi",
        "DHR_Ngay_di/ve": "Ngày đi / về",
        "DHR_Ngay_di_/_ve": "Ngày đi/về",
        "DHR_Ngay_di_ca": "Ngày đi ca",
        "DHR_Ngay_di_lam_du_kien": "Ngày đi làm dự kiến",
        "DHR_Ngay_di_lam_thuc_te": "Ngày đi làm thực tế",
        "DHR_Ngay_dieu_chinh": "Ngày điều chỉnh",
        "DHR_Ngay_du_sinh": "Ngày dự sinh",
        "DHR_Ngay_duyet": "Ngày duyệt",
        "DHR_Ngay_het_han": "Ngày hết hạn",
        "DHR_Ngay_het_han_ho_chieu": "Ngày hết hạn hộ chiếu",
        "DHR_Ngay_hieu_luc": "Ngày hiệu lực",
        "DHR_Ngay_hieu_luc_den": "Ngày hiệu lực đến",
        "DHR_Ngay_hieu_luc_tu": "Ngày hiệu lực từ",
        "DHR_Ngay_hoan_thanh": "Ngày hoàn thành",
        "DHR_Ngay_ket_thuc": "Ngày kết thúc",
        "DHR_Ngay_ket_thuc_che_do": "Ngày kết thúc chế độ",
        "DHR_Ngay_ket_thuc_thu_viec": "Ngày kết thúc thử việc",
        "DHR_Ngay_khoi_hanh": "Ngày khởi hành",
        "DHR_Ngay_ky_phu_luc": "Ngày ký phụ lục",
        "DHR_Ngay_lam_viec": "Ngày làm việc",
        "DHR_Ngay_lap": "Ngày lập",
        "DHR_Ngay_nghi": "Ngày nghỉ",
        "DHR_Ngay_nghi_viec": "Ngày nghỉ việc",
        "DHR_Ngay_nhan": "Ngày nhận",
        "DHR_Ngay_nhan_ho_so": "Ngày nhận hồ sơ",
        "DHR_Ngay_nop_don": "Ngày nộp đơn",
        "DHR_Ngay_phep": "Ngày phép",
        "DHR_Ngay_phieu": "Ngày phiếu",
        "DHR_Ngay_phong_van": "Ngày phỏng vấn",
        "DHR_Ngay_sinh": "Ngày sinh",
        "DHR_Ngay_sinh_con": "Ngày sinh con",
        "DHR_Ngay_tac_dong": "Ngày tác động",
        "DHR_Ngay_tach_tang_ca": "Ngày tách tăng ca",
        "DHR_Ngay_tang_ca": "Ngày tăng ca",
        "DHR_Ngay_tao": "Ngày tạo",
        "DHR_Ngay_tham_gia_BHXH": "Ngày tham gia BHXH",
        "DHR_Ngay_tham_gia_bao_hiem": "Ngày tham gia bảo hiểm",
        "DHR_Ngay_thu_hoi": "Ngày thu hồi",
        "DHR_Ngay_thuc_hien": "Ngày thực hiện",
        "DHR_Ngay_tu": "Ngày từ",
        "DHR_Ngay_vao": "Ngày vào",
        "DHR_Ngay_vao_lam": "Ngày vào làm",
        "DHR_Ngay_vao_lam_du_kien": "Ngày vào làm dự kiến",
        "DHR_Ngay_ve": "Ngày về",
        "DHR_Ngay_xin_nghi_viec": "Ngày xin nghỉ việc",
        "DHR_Ngay_xin_thoi_viec": "Ngày xin thôi việc",
        "DHR_Nghi_phep": "Nghỉ phép",
        "DHR_Nghi_phep_trong_ngay": "Nghỉ phép trong ngày",
        "DHR_Nghi_thai_san": "Nghỉ thai sản",
        "DHR_Nghi_viec": "Nghỉ việc",
        "DHR_Nghiep_vu": "Nghiệp vụ",
        "DHR_Ngoai_te": "Ngoại tệ",
        "DHR_Nguoi_PV": "Người PV",
        "DHR_Nguoi_dai_dien": "Người đại diện",
        "DHR_Nguoi_danh_gia": "Người đánh giá",
        "DHR_Nguoi_danh_gia_bat_buoc_nhap": "Người đánh giá bắt buộc nhập",
        "DHR_Nguoi_dao_tao": "Người đào tạo",
        "DHR_Nguoi_dieu_chinh": "Người điều chỉnh",
        "DHR_Nguoi_dung": "Người dùng",
        "DHR_Nguoi_duyet": "Người duyệt",
        "DHR_Nguoi_lap": "Người lập",
        "DHR_Nguoi_lien_he": "Người liên hệ",
        "DHR_Nguoi_lien_he_so_1": "Người liên hệ số 1",
        "DHR_Nguoi_lien_he_so_2": "Người liên hệ số 2",
        "DHR_Nguoi_nhan_email": "Người nhận email",
        "DHR_Nguoi_nuoc_ngoai": "Người nước ngoài",
        "DHR_Nguoi_phong_van": "Người phỏng vấn",
        "DHR_Nguoi_phu_trach": "Người phụ trách",
        "DHR_Nguoi_quan_ly": "Người quản lý",
        "DHR_Nguoi_quan_ly_truc_tiep": "Người QL trực tiếp",
        "DHR_Nguoi_tao": "Người tạo",
        "DHR_Nguoi_tao_tin": "Người tạo tin",
        "DHR_Nguoi_thuc_hien": "Người thực hiện",
        "DHR_Nguoi_uy_quyen": "Người ủy quyền",
        "DHR_Nguoi_xem_phong_van": "Người xem phỏng vấn",
        "DHR_Nguon": "Nguồn",
        "DHR_Nguon_ung_tuyen": "Nguồn ứng tuyển",
        "DHR_Nguon_ung_vien": "Nguồn ứng viên",
        "DHR_Nguyen_quan": "Nguyên quán",
        "DHR_Nhac_lich": "Nhắc lịch",
        "DHR_Nhac_nho1": "Nhắc nhở",
        "DHR_Nhan_Vien_Chua_co_ca": "Nhân viên chưa có ca",
        "DHR_Nhan_vao_de_tai_hinh_hoac_keo_tha_hinh_vao_day": "Nhấn vào để tải hình hoặc kéo thả hình vào đây",
        "DHR_Nhan_vien": "Nhân viên",
        "DHR_Nhan_vien_chua_duoc_cap_phat_CCLD": "Nhân viên chưa được cấp phát CCLD",
        "DHR_Nhan_vien_da_duoc_thu_hoi_ccld_khong_duoc_phep_xoa": "Nhân viên đã được thu hồi CCLĐ, không được phép xóa.",
        "DHR_Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen": "Nhân viên kiêm nhiệm nhiều chức danh. Xác định mục tiêu tạo cho chức danh nào để quy trình duyệt/ review mục tiêu gửi đến đúng cấp có thẩm quyền.",
        "DHR_Nhan_vien_tu_danh_gia_het_HDLD": "Nhân viên tự đánh giá HĐLĐ",
        "DHR_Nhan_xet_chung": "Nhận xét chung",
        "DHR_Nhan_xet_ung_vien": "Nhận xét ứng viên",
        "DHR_Nhap": "Nhập",
        "DHR_Nhap_": "Nháp",
        "DHR_Nhap_Ten_Dang_Nhap": "Nhập tên đăng nhập",
        "DHR_Nhap_W25DESC": "Tin đang trong quá trình chuẩn bị và không hiển thị ra bên ngoài.",
        "DHR_Nhap_de_tim_kiem": "Nhập để tìm kiếm",
        "DHR_Nhap_email": "Nhập email",
        "DHR_Nhap_ma_bao_mat": "Nhập mã bảo mật",
        "DHR_Nhap_mat_khau": "Nhập mật khẩu",
        "DHR_Nhap_tiep": "Nhập tiếp",
        "DHR_Nhap_tu_tim_kiem": "Nhập từ tìm kiếm",
        "DHR_Nhat_ky_cong_tac": "Nhật ký công tác",
        "DHR_Nhieu_ngay": "Nhiều ngày",
        "DHR_Nho_hon_hoac_bang1": "Nhỏ hơn hoặc bằng",
        "DHR_Nho_mat_khau": "Nhớ mật khẩu",
        "DHR_Nho_mat_khau1": "Nhớ mật khẩu",
        "DHR_Nhom": "Nhóm",
        "DHR_Nhom_chi_tieu": "Nhóm chỉ tiêu",
        "DHR_Nhom_hanh_vi_vi_pham_ky_luat": "Nhóm hành vi vi phạm kỷ luật",
        "DHR_Nhom_luong": "Nhóm lương",
        "DHR_Nhom_muc_tieu": "Nhóm mục tiêu",
        "DHR_Nhom_nhan_vien": "Nhóm nhân viên",
        "DHR_Nhung_nguoi_co_quyen_xem_toan_bo_TTUV_va_xu_ly_quy_trinh_tuyen_dung": "Nhưng người có quyền xem toàn bộ thông tin ứng viên và xử lý quy trình tuyển dụng",
        "DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "Những ý kiến này sẽ được Công ty chúng tôi ghi nhận và làm cơ sở để nâng cao chất lượng dịch vụ nhằm đáp ứng nhu cầu của khách hàng ngày càng tốt hơn",
        "DHR_No": "Nợ",
        "DHR_No_data": "Không có dữ liệu",
        "DHR_Noi_bo": "Nội bộ",
        "DHR_Noi_cap": "Nơi cấp",
        "DHR_Noi_cap_BHXH": "Nơi cấp BHXH",
        "DHR_Noi_cap_CMND": "Nơi cấp CMND",
        "DHR_Noi_cap_han_ho_chieu": "Nơi cấp hạn hộ chiếu",
        "DHR_Noi_cap_ho_chieu": "Nơi cấp hộ chiếu",
        "DHR_Noi_cap_the_can_cuoc": "Nơi cấp thẻ căn cước",
        "DHR_Noi_dang_ky_KCB": "Nơi đăng ký KCB",
        "DHR_Noi_dung": "Nội dung",
        "DHR_Noi_dung_can_tim": "Nội dung cần tìm",
        "DHR_Noi_dung_can_tim_kiem": "Nội dung cần tìm kiếm",
        "DHR_Noi_dung_dao_tao": "Nội dung đào tạo",
        "DHR_Noi_dung_mail_khong_duoc_de_trong": "Nội dung mail không được để trống",
        "DHR_Noi_dung_nhat_ky": "Nội dung nhật ký",
        "DHR_Noi_dung_tim_kiem": "Nội dung tìm kiếm",
        "DHR_Noi_dung_tin_tuyen_dung": "Nội dung tin tuyển dụng",
        "DHR_Noi_sinh": "Nơi sinh",
        "DHR_Nu": "Nữ",
        "DHR_Nuoc_ngoai": "Nước ngoài",
        "DHR_Phai_chon_it_nhat_1_trang_thai": "Phải chọn ít nhất 1 trạng thái",
        "DHR_Phai_la_so_lon_hon_hoac_bang_khong": "Phải là số lớn hơn hoặc bằng không",
        "DHR_Phai_la_so_lon_hon_khong": "Phải là số lớn hơn không",
        "DHR_Phai_la_so_nguyen_duong": "Phải là số nguyên dương",
        "DHR_Phai_lon_hon_gia_tri_tu": "Phải lớn hơn giá trị từ",
        "DHR_Phai_nhap_so_phut_cho_it_nhat_mot_loai_xin_phep": "Phải nhập số phút cho ít nhất một loại xin phép",
        "DHR_Phai_trong_khoang_tu_0_den_100": "Phải trong khoảng từ 0 đến 100",
        "DHR_Phan_bo_chi_phi": "Phân bổ chi phí",
        "DHR_Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong": "Phân bổ một toán tử cho tất cả dữ liệu lương",
        "DHR_Phan_bo_toan_tu": "Phân bổ toán tử",
        "DHR_Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong": "Phân bố toán tử cho tất cả dữ liệu lương",
        "DHR_Phan_bo_toan_tu_theo_tung_du_lieu_luong": "Phân bổ toán tử theo từng dữ liệu lương",
        "DHR_Phan_quyen": "Phân quyền",
        "DHR_Phan_quyen_chuc_nang": "Phân quyền chức năng",
        "DHR_Phan_quyen_du_lieu": "Phân quyền dữ liệu",
        "DHR_Phan_quyen_ess": "Phân quyền ESS",
        "DHR_Phan_quyen_tat_ca": "Phân quyền tất cả",
        "DHR_Phan_quyen_theo_vai_tro": "Phân quyền theo vai trò",
        "DHR_Phan_tram_hoan_thanh": "Phần trăm hoàn thành",
        "DHR_Phep": "Phép",
        "DHR_Phep_bu": "Phép bù",
        "DHR_Phep_con_lai": "Phép còn lại",
        "DHR_Phep_da_nghi": "Phép đã nghỉ",
        "DHR_Phep_da_nghi_/_het_han": "Phép đã nghỉ / hết hạn",
        "DHR_Phep_het_han_chua_su_dung": "Phép hết hạn chưa sử dụng",
        "DHR_Phep_nam": "Phép năm",
        "DHR_Phep_tham_nien": "Phép thâm niên",
        "DHR_Phep_ton": "Phép tồn",
        "DHR_Phien_ban": "Phiên bản",
        "DHR_Phieu_KCB_den_ngay": "Phiếu KCB đến ngày",
        "DHR_Phieu_KCB_tu_ngay": "Phiếu KCB từ ngày",
        "DHR_Phieu_cham_cong": "Phiếu chấm công",
        "DHR_Phieu_cua_toi": "Phiếu của tôi",
        "DHR_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "Phiếu đăng ký đã được duyệt bạn không được phép thay đổi",
        "DHR_Phieu_danh_gia": "Phiếu đánh giá",
        "DHR_Phieu_danh_gia_nhan_vien": "Phiếu đánh giá nhân viên",
        "DHR_Phieu_huy": "Phiếu hủy",
        "DHR_Phong_ban": "Phòng ban",
        "DHR_Phong_ban_moi": "Phòng ban mới",
        "DHR_Phong_van": "Phỏng vấn",
        "DHR_Phong_van_online": "Phỏng vấn Online",
        "DHR_Phong_van_qua_dien_thoai": "Phỏng vấn qua điện thoại",
        "DHR_Phong_van_tai_van_phong": "Phỏng vấn tại văn phòng",
        "DHR_Phu_luc": "Phụ lục",
        "DHR_Phuong": "Phường",
        "DHR_Phuong_phap_danh_gia": "Phương pháp đánh giá",
        "DHR_Phuong_phap_dieu_chinh_luong": "Phương pháp điều chỉnh lương",
        "DHR_Phuong_phap_do": "Phương pháp đo",
        "DHR_Phuong_phap_tinh": "Phương pháp tính",
        "DHR_Qua_han": "Quá hạn",
        "DHR_Quan": "Quận",
        "DHR_Quan_he": "Quan hệ",
        "DHR_Quan_he_gia_dinh": "Quan hệ gia đình",
        "DHR_Quan_ly": "Quản lý",
        "DHR_Quan_ly_cong_tac_va_chi_phi": "Quản lý công tác và chi phí",
        "DHR_Quan_tri": "Quản trị",
        "DHR_Quan_tri_vien": "Quản trị viên",
        "DHR_Que_quan": "Quê quán",
        "DHR_Quen_mat_khau": "Quên mật khẩu",
        "DHR_Quoc_gia": "Quốc gia",
        "DHR_Quoc_tich": "Quốc tịch",
        "DHR_Quy": "Quý",
        "DHR_Quy_doi_phep": "Quy đổi phép",
        "DHR_Quy_nay": "Quý này",
        "DHR_Quy_trinh": "Quy trình",
        "DHR_Quy_trinh_ap_dung": "Quy trình áp dụng",
        "DHR_Quy_trinh_tao_muc_tieu": "Quy trình tạo mục tiêu",
        "DHR_Quy_trinh_tuyen_dung_Workflow": "Quy trình tuyển dụng (Workflow)",
        "DHR_Quy_truoc": "Quý trước",
        "DHR_Quy_uoc_nhu_sau": "Quy ước như sau",
        "DHR_Quyen_han": "Quyền hạn",
        "DHR_Quyet_toan": "Quyết toán",
        "DHR_Ra_som": "Ra sớm",
        "DHR_Review_muc_tieu": "Review mục tiêu",
        "DHR_Roi_khoi_nhom": "Rời khỏi nhóm",
        "DHR_SDT_ho_tro": "090 840 2668",
        "DHR_SLA": "SLA",
        "DHR_STT": "STT",
        "DHR_Sai_sot_cong": "Sai sót công",
        "DHR_Sang_loc_ung_vien": "Sàng lọc ứng viên",
        "DHR_Sao_chep_CCLD": "Sao chép CCLĐ",
        "DHR_Sao_chep_cap_phat_truoc": "Sao chép cấp phát trước",
        "DHR_Sao_chep_quyen": "Sao chép quyền",
        "DHR_Sao_chep_thanh_cong": "Sao chép thành công.",
        "DHR_Sao_chep_tin_dang": "Sao chép tin đăng",
        "DHR_Sau_thai_san:": "Sau thai sản:",
        "DHR_Scheduled": "Scheduled",
        "DHR_Sinh_nhat": "Sinh nhật",
        "DHR_Size_ao": "Size áo",
        "DHR_Size_giay": "Size giày",
        "DHR_Size_quan": "Size quần",
        "DHR_Sl_nam": "SL Nam",
        "DHR_Sl_nu": "SL Nữ",
        "DHR_So": "Số",
        "DHR_So_CMND": "Số CMND",
        "DHR_So_Fax": "Số Fax",
        "DHR_So_HDLD": "Số HĐLĐ",
        "DHR_So_bao_hiem": "Số bảo hiểm",
        "DHR_So_can_cuoc": "Số căn cước",
        "DHR_So_cap_review": "Số cấp review",
        "DHR_So_cong": "Số công",
        "DHR_So_cong_va_so_phep_phai_co_gia_tri": "Số công và số phép phải có giá trị",
        "DHR_So_dien_thoai": "Số điện thoại",
        "DHR_So_dien_thoai_khong_hop_le": "Số điện thoại không hợp lệ",
        "DHR_So_dong": "Số dòng",
        "DHR_So_gio": "Số giờ",
        "DHR_So_gio_dao_tao": "Số giờ đào tạo",
        "DHR_So_gio_tang_ca": "Số giờ tăng ca",
        "DHR_So_gio_vuot": "Số giờ vượt",
        "DHR_So_ho_chieu": "Số hộ chiếu",
        "DHR_So_luong": "Số lượng",
        "DHR_So_luong_can_tuyen": "Số lượng cần tuyển",
        "DHR_So_luong_cho_phep": "Số lượng cho phép",
        "DHR_So_luong_cong": "Số lượng công",
        "DHR_So_luong_da_nghi_den_ky_hien_tai": "Số lượng đã nghỉ đến kỳ hiện tại",
        "DHR_So_luong_de_xuat": "Số lượng đề xuất",
        "DHR_So_luong_duoc_cap": "Số lượng được cấp",
        "DHR_So_luong_nhan_vien_khong_bang_de_xuat": "Số lượng nhân viên không phù hợp với số lượng đề xuất. Bạn có muốn lưu không?",
        "DHR_So_luong_phai_lon_hon_0": "Số lượng phải lớn hơn 0",
        "DHR_So_luong_phep": "Số lượng phép",
        "DHR_So_luong_phep_khong_hop_le": "Số lượng phép không hợp lệ",
        "DHR_So_luong_ton_den_cuoi_nam": "Số lượng tồn đến cuối năm",
        "DHR_So_luong_ton_den_ky_hien_tai": "Số lượng tồn đến kỳ hiện tại",
        "DHR_So_luong_ton_kho_khong_du_cap_phat": "Số lượng tồn kho không đủ cấp phát",
        "DHR_So_luong_tuyen": "Số lượng tuyển",
        "DHR_So_luong_ung_vien": "Số lượng ứng viên",
        "DHR_So_nam_kinh_nghiem": "Số năm kinh nghiệm",
        "DHR_So_ngay": "Số ngày",
        "DHR_So_ngay_con_lai": "Số ngày còn lại",
        "DHR_So_ngay_cong": "Số ngày công",
        "DHR_So_ngay_da_nghi": "Số ngày đã nghỉ",
        "DHR_So_ngay_hieu_luc": "Số ngày hiệu lực",
        "DHR_So_ngay_hieu_luc_phep": "Số ngày hiệu lực phép",
        "DHR_So_ngay_nghi_trong_ky": "Số ngày nghỉ trong kỳ",
        "DHR_So_ngay_vi_pham": "Số ngày vi phạm",
        "DHR_So_nha": "Số nhà",
        "DHR_So_phep": "Số phép",
        "DHR_So_phu_luc": "Số phụ lục",
        "DHR_So_phut": "Số phút",
        "DHR_So_quyet_dinh": "Số quyết định",
        "DHR_So_so_BHXH": "Số sổ BHXH",
        "DHR_So_so_bao_hiem_xa_hoi": "Số sổ BHXH",
        "DHR_So_thang": "Số tháng",
        "DHR_So_thang_da_su_dung": "Số tháng đã sử dụng",
        "DHR_So_thang_dao_tao": "Số tháng đào tạo",
        "DHR_So_thang_khong_duoc_qua_lon": "Số tháng không được quá lớn",
        "DHR_So_thang_su_dung": "Số tháng sử dụng",
        "DHR_So_the_BHYT": "Số thẻ BHYT",
        "DHR_So_the_bao_hiem_y_te": "Số thẻ BHYT",
        "DHR_So_the_can_cuoc": "Số thẻ căn cước",
        "DHR_So_thu_tu": "Số thứ tự",
        "DHR_So_tien": "Số tiền",
        "DHR_So_tien_de_xuat_tam_ung": "Số tiền đề xuất tạm ứng",
        "DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu": "Số tiền đến không được nhỏ hơn số tiền từ",
        "DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den": "Số tiền từ không được lớn hơn số tiền đến",
        "DHR_Started": "Started",
        "DHR_Su_dung": "Sử dụng",
        "DHR_Sua": "Sửa",
        "DHR_Sua_binh_luan_khong_thanh_cong": "Sửa bình luận không thành công",
        "DHR_Sua_binh_luan_thanh_cong": "Sửa bình luận thành công",
        "DHR_Sua_du_lieu": "Sửa dữ liệu",
        "DHR_Sua_phong_van": "Sửa phỏng vấn",
        "DHR_Tags": "Tags",
        "DHR_Tai_bao_cao_(excel)": "Tải báo cáo (excel)",
        "DHR_Tai_khoan_Ngan_hang": "Tài khoản Ngân hàng",
        "DHR_Tai_khoan_bi_khoa_10_phut": "Tài khoản bị khoá, hãy thử lại sau 10 phút",
        "DHR_Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap": "Tài khoản chưa được thiết lập nhân viên. Bạn không thể đăng nhập",
        "DHR_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Tài khoản đăng nhập hoặc mật khẩu không đúng.",
        "DHR_Tai_khoan_khong_co_quyen": "Bạn không có quyền truy cập vào hệ thống.",
        "DHR_Tai_lieu": "Tài liệu",
        "DHR_Tai_lieu_dinh_kem": "Tài liệu đính kèm",
        "DHR_Tam_dap_ung_Can_cai_tien_them": "Tạm đáp ứng, cần phải cải tiến thêm",
        "DHR_Tam_ung": "Tạm ứng",
        "DHR_Tang_ca": "Tăng ca",
        "DHR_Tang_ca_den": "Tăng ca đến",
        "DHR_Tang_ca_giua": "Tăng ca giữa",
        "DHR_Tang_ca_sau": "Tăng ca sau",
        "DHR_Tang_ca_truoc": "Tăng ca trước",
        "DHR_Tang_ca_tu": "Tăng ca từ",
        "DHR_Tao_boi": "Tạo bởi",
        "DHR_Tao_muc_tieu_cho_chuc_danh_kiem_nhiem": "Tạo mục tiêu cho chức danh kiêm nhiệm",
        "DHR_Tao_review": "Tạo review",
        "DHR_Tao_review_thanh_cong": "Tạo review thành công",
        "DHR_Tao_tu_danh_muc_muc_tieu": "Tạo từ danh mục mục tiêu",
        "DHR_Tap_tin": "Tập tin",
        "DHR_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Tập tin đính kèm đang được xử lý",
        "DHR_Tap_tin_tai_len": "Tập tin tải lên",
        "DHR_Tat_ca": "Tất cả",
        "DHR_Tat_ca_danh_gia": "Tất cả đánh giá",
        "DHR_Tat_ca_don_tu": "Tất cả đơn từ",
        "DHR_Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD": "Tất cả nhân viên đã được cấp phát CCLĐ",
        "DHR_Tat_ca_thao_tac_nhap_lieu_truoc_do_se_bi_mat,ban_co_muon_tiep_tuc_khong?": "Tất cả thao tác nhập liệu trước đó sẽ bị mất, bạn có muốn tiếp tục không?",
        "DHR_Tat_ca_tin": "Tất cả tin",
        "DHR_Ten": "Tên",
        "DHR_Ten_CCLD": "Tên CCLĐ",
        "DHR_Ten_Dang_Nhap": "Tên đăng nhập",
        "DHR_Ten_Thanh_pho": "Tên Thành phố",
        "DHR_Ten_UV": "Tên UV",
        "DHR_Ten_bang_KQ_tong_hop": "Tên bảng KQ tổng hợp ",
        "DHR_Ten_bang_muc_tieu": "Tên bảng mục tiêu",
        "DHR_Ten_bang_tong_hop": "Tên bảng tổng hợp",
        "DHR_Ten_ca": "Tên ca",
        "DHR_Ten_chi_tieu": "Tên chỉ tiêu",
        "DHR_Ten_chuc_vu_moi": "Tên chức vụ mới",
        "DHR_Ten_doi_tuong": "Tên đối tượng",
        "DHR_Ten_giai_doan": "Tên giai đoạn",
        "DHR_Ten_ket_qua_then_chot": "Tên kết quả then chốt",
        "DHR_Ten_kho": "Tên kho",
        "DHR_Ten_loai_chi_phi": "Tên loại chi phí",
        "DHR_Ten_loai_tach": "Tên loại tách",
        "DHR_Ten_ly_do": "Tên lý do",
        "DHR_Ten_man_hinh": "Tên màn hình",
        "DHR_Ten_muc_tieu": "Tên mục tiêu",
        "DHR_Ten_nghiep_vu": "Tên nghiệp vụ",
        "DHR_Ten_nguoi_dung": "Tên người dùng",
        "DHR_Ten_nguoi_quan_he": "Tên người quan hệ",
        "DHR_Ten_nhan_su": "Tên nhân sự",
        "DHR_Ten_nhan_vien": "Tên nhân viên",
        "DHR_Ten_nhom": "Tên nhóm",
        "DHR_Ten_nhom_muc_tieu": "Tên nhóm mục tiêu",
        "DHR_Ten_phieu": "Tên phiếu",
        "DHR_Ten_phong_ban": "Tên phòng ban",
        "DHR_Ten_phong_ban_VI": "Tên phòng ban (VI)",
        "DHR_Ten_phuong": "Tên phường",
        "DHR_Ten_quan": "Tên quận",
        "DHR_Ten_quy_trinh": "Tên quy trình",
        "DHR_Ten_tach_tang_ca": "Tên tách tăng ca",
        "DHR_Ten_tinh_nang": "Tên tính năng",
        "DHR_Ten_trang_thai_duyet": "Tên trạng thái duyệt",
        "DHR_Ten_vai_tro": "Tên vai trò",
        "DHR_Ten_vat_tu": "Tên vật tư",
        "DHR_Tham_chieu_chuoi": "Tham chiếu chuỗi",
        "DHR_Tham_chieu_so": "Tham chiếu số",
        "DHR_Tham_chieu_xep_loai": "Tham chiếu xếp loại",
        "DHR_Tham_nien": "Thâm niên",
        "DHR_Tham_so_truyen_vao_khong_hop_le": "Tham số truyền vào không hợp lệ",
        "DHR_Thang": "Tháng",
        "DHR_Thang_nay": "Tháng này",
        "DHR_Thang_truoc": "Tháng trước",
        "DHR_Thanh_pho": "Thành phố",
        "DHR_Thanh_tien(USD)": "Thành tiền(USD)",
        "DHR_Thanh_tien(VND)": "Thành tiền(VND)",
        "DHR_Thanh_vien": "Thành viên",
        "DHR_Thanh_vien_cua_Nihon_Grouping_va_Pan": "Thành viên của Nihon Grouping và Pan Group",
        "DHR_Thanh_vien_duoc_chon": "Thành viên được chọn",
        "DHR_Thanh_vien_quan_ly": "Thành viên quản lý",
        "DHR_Thanh_vien_vai_tro_quan_tri": "Thành viên vai trò quản trị",
        "DHR_Thay_the_nguoi_duyet": "Thay thế người duyệt",
        "DHR_Thay_the_nguoi_duyet_thanh_cong": "Thay thế người duyệt thành công",
        "DHR_The_can_cuoc": "Thẻ căn cước",
        "DHR_The_luu_tru": "Thẻ lưu trú",
        "DHR_Them": "Thêm",
        "DHR_Them_binh_luan_thanh_cong": "Thêm bình luận thành công",
        "DHR_Them_dinh_kem_thanh_cong": "Thêm đính kèm thành công",
        "DHR_Them_dinh_kem_that_bai": "Thêm đính kèm thất bại",
        "DHR_Them_giai_doan": "Thêm giai đoạn",
        "DHR_Them_ket_qua_then_chot": "Thêm kết quả then chốt",
        "DHR_Them_ket_qua_then_chot_thanh_cong": "Thêm kết quả then chốt thành công",
        "DHR_Them_ket_qua_then_chot_that_bai": "Thêm kết quả then chốt thất bại",
        "DHR_Them_lich_phong_van": "Thêm lịch phỏng vấn",
        "DHR_Them_loai_tach_tang_ca": "Thêm loại tách tăng ca",
        "DHR_Them_moi": "<Thêm mới>",
        "DHR_Them_moi1": "Thêm mới",
        "DHR_Them_moi_tin_tuyen_dung": "Thêm mới tin tuyển dụng",
        "DHR_Them_moi_ung_vien": "Thêm mới ứng viên",
        "DHR_Them_muc_tieu": "Thêm mục tiêu",
        "DHR_Them_phong_van_moi": "Thêm phỏng vấn mới",
        "DHR_Them_tep_dinh_kem": "Thêm tệp đính kèm",
        "DHR_Them_thanh_vien": "Thêm thành viên",
        "DHR_Them_tieu_chi": "Thêm tiêu chí",
        "DHR_Them_tieu_chi_danh_gia": "Thêm tiêu chí đánh giá",
        "DHR_Them_ung_vien": "Thêm ứng viên",
        "DHR_Thiet_lap_cach_tinh_ket_qua": "Thiết lập cách tính kết quả",
        "DHR_Thiet_lap_cach_tinh_ket_qua_muc_tieu": "Thiết lập cách tính kết quả mục tiêu",
        "DHR_Thiet_lap_chi_tieu_tong_hop": "Thiết lập chỉ tiêu tổng hợp",
        "DHR_Thiet_lap_tong_hop": "Thiết lập tổng hợp",
        "DHR_Thoi_gian": "Thời gian",
        "DHR_Thoi_gian_bat_dau": "Thời gian bắt đầu",
        "DHR_Thoi_gian_can": "Thời gian cần",
        "DHR_Thoi_gian_can_nhan_su": "Thời gian cần nhân sự",
        "DHR_Thoi_gian_dang_ky:": "Thời gian đăng ký:",
        "DHR_Thoi_gian_dao_tao": "Thời gian đào tạo",
        "DHR_Thoi_gian_dong_BHXH": "Thời gian đóng BHXH",
        "DHR_Thoi_gian_hieu_luc": "Thời gian hiệu lực",
        "DHR_Thoi_gian_ket_thuc": "Thời gian kết thúc",
        "DHR_Thoi_gian_muc_tieu": "Thời gian mục tiêu",
        "DHR_Thoi_gian_nop_don": "Thời gian nộp đơn",
        "DHR_Thoi_gian_nop_hoac_deadline_khong_hop_le": "Thời gian nộp đơn hoặc deadline không hợp lệ",
        "DHR_Thoi_gian_phong_van": "Thời gian phỏng vấn",
        "DHR_Thoi_gian_ra_vao_VN": "Thời gian ra vào VN",
        "DHR_Thoi_gian_tao": "Thời gian tạo",
        "DHR_Thoi_gian_tham_gia_BH": "Thời gian tham gia BH",
        "DHR_Thoi_gian_thu_viec": "Thời gian thử việc",
        "DHR_Thoi_han_hop_dong": "Thời hạn hợp đồng",
        "DHR_Thoi_han_phu_luc": "Thời hạn phụ lục",
        "DHR_Thong_bao": "Thông báo",
        "DHR_Thong_bao1": "Thông Báo",
        "DHR_Thong_baoU": "Thông báo",
        "DHR_Thong_ke": "Thống kê",
        "DHR_Thong_ke_phong_van": "Thống kê phỏng vấn",
        "DHR_Thong_ke_ung_vien": "Thống kê ứng viên",
        "DHR_Thong_tin": "Thông tin",
        "DHR_Thong_tin_ca_nhan": "Thông tin cá nhân",
        "DHR_Thong_tin_ca_nhan_DESC": "Họ và tên, Giới tính, Số điện thoại, Phòng ban, CMND, CCCD, Ngày cấp, Nơi cấp, Địa chỉ",
        "DHR_Thong_tin_cap_phat_cho": "Thông tin cấp phát cho",
        "DHR_Thong_tin_chi_tiet": "Thông tin chi tiết",
        "DHR_Thong_tin_chinh": "Thông tin chính",
        "DHR_Thong_tin_chung": "Thông tin chung",
        "DHR_Thong_tin_cong_viec": "Thông tin công việc",
        "DHR_Thong_tin_cu": "Thông tin cũ",
        "DHR_Thong_tin_cung_cap_khong_hop_le": "Thông tin cung cấp không hợp lệ!",
        "DHR_Thong_tin_dieu_chinh": "Thông tin điều chỉnh",
        "DHR_Thong_tin_gia_dinh": "Thông tin gia đình",
        "DHR_Thong_tin_hien_tai": "Thông tin hiện tại",
        "DHR_Thong_tin_ket_qua_then_chot": "Thông tin kết quả then chốt",
        "DHR_Thong_tin_kiem_nhiem": "Thông tin kiêm nhiệm",
        "DHR_Thong_tin_lien_he": "Thông tin liên hệ",
        "DHR_Thong_tin_lien_lac": "Thông tin liên lạc",
        "DHR_Thong_tin_luong": "Thông tin lương",
        "DHR_Thong_tin_muc_tieu": "Thông tin mục tiêu",
        "DHR_Thong_tin_nguoi_dung": "Thông tin người dùng",
        "DHR_Thong_tin_nguoi_duyet": "Thông tin người duyệt",
        "DHR_Thong_tin_nhan_su": "Thông tin nhân sự",
        "DHR_Thong_tin_nhom": "Thông tin nhóm",
        "DHR_Thong_tin_phan_quyen": "Thông tin phân quyền",
        "DHR_Thong_tin_phep": "Thông tin phép",
        "DHR_Thong_tin_phong_van": "Thông tin phỏng vấn",
        "DHR_Thong_tin_tang_nhan_vien": "Thông tin tăng nhân viên",
        "DHR_Thong_tin_thu_hoi_cho": "Thông tin thu hồi cho",
        "DHR_Thong_tin_tra_ve_khi_luu_khong_dung": "Thông tin trả về khi lưu không đúng",
        "DHR_Thong_tin_tuyen_dung": "Thông tin tuyển dụng",
        "DHR_Thong_tin_vai_tro": "Thông tin vai trò",
        "DHR_Thong_tin_xep_loai": "Thông tin xếp loại",
        "DHR_Thu_ba": "Thứ ba",
        "DHR_Thu_bay": "Thứ bảy",
        "DHR_Thu_gon": "Thu gọn",
        "DHR_Thu_hai": "Thứ hai",
        "DHR_Thu_hoi": "Thu hồi",
        "DHR_Thu_hoi_trang_thiet_bi_lao_dong": "Thu hồi trang thiết bị lao động",
        "DHR_Thu_nam": "Thứ năm",
        "DHR_Thu_nho": "Thu nhỏ",
        "DHR_Thu_sau": "Thứ sáu",
        "DHR_Thu_tu": "Thứ tự",
        "DHR_Thu_tuU": "Thứ tư",
        "DHR_Thu_tu_hien_thi": "Thứ tự hiển thị",
        "DHR_Thuc_hien1": "Thực hiện",
        "DHR_Thuc_hien_thanh_cong": "Thực hiện thành công",
        "DHR_Tien_bao_hiem": "Tiền bảo hiểm",
        "DHR_Tien_chi_tam_ung": "Tiền chi tạm ứng",
        "DHR_Tien_do_thuc_hien": "Tiến độ thực hiện",
        "DHR_Tien_tro_cap": "Tiền trợ cấp",
        "DHR_Tiep_tuc": "Tiếp tục",
        "DHR_Tieu_chi": "Tiêu chí",
        "DHR_Tieu_chi_danh_gia": "Tiêu chí đánh giá",
        "DHR_Tieu_chi_loc": "Tiêu chí lọc",
        "DHR_Tieu_de": "Tiêu đề",
        "DHR_Tieu_de_mail": "Tiêu đề mail",
        "DHR_Tieu_de_phong_van": "Tiêu đề phỏng vấn",
        "DHR_Tieu_de_tin": "Tiêu đề tin",
        "DHR_Tieu_de_tin_tuyen_dung": "Tiêu đề tin tuyển dụng",
        "DHR_Tim_kiem": "Tìm kiếm",
        "DHR_Tim_kiem_cap_phat_trang_thiet_bi_lao_dong": "Tìm kiếm trang thiết bị lao động",
        "DHR_Tim_kiem_cham_cong": "Tìm kiếm chấm công",
        "DHR_Tim_kiem_che_do_bao_hiem": "Tìm kiếm chế độ bảo hiểm",
        "DHR_Tim_kiem_co_cau_to_chuc": "Tìm kiếm cơ cấu tổ chức",
        "DHR_Tim_kiem_ho_so_nhan_su": "Tìm kiếm hồ sơ nhân sự",
        "DHR_Tim_kiem_nhan_vien": "Tìm kiếm nhân viên",
        "DHR_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "Tìm kiếm tên nhân viên và mã nhân viên..",
        "DHR_Tim_theo_ma_va_ten": "Tìm theo mã và tên",
        "DHR_Tin_chua_duoc_dang_cong_khai": "Tin chưa được công khai",
        "DHR_Tin_hot": "Tin hot",
        "DHR_Tin_tuyen_dung": "Tin tuyển dụng",
        "DHR_Tinh_cach": "Tính cách",
        "DHR_Tinh_trang_hien_tai": "Tình trạng hiện tại",
        "DHR_Tinh_trang_hon_nhan": "Tình trạng hôn nhân",
        "DHR_Tinh_trang_thu_hoi": "Tình trạng thu hồi",
        "DHR_To_chuc": "Tổ chức",
        "DHR_To_chuc_Tuyen_dung_DESC": "Người quản lý trực tiếp, Ngày vào làm, Chức vụ, Dự án, Ghi chú, Đính kèm",
        "DHR_To_nhom": "Tổ nhóm",
        "DHR_To_nhom_moi": "Tổ nhóm mới",
        "DHR_Toan_thoi_gian": "Toàn thời gian",
        "DHR_Toan_tu": "Toán tử",
        "DHR_Toan_tu_so_cong": "Toán tử số công",
        "DHR_Toan_tu_so_ngay_hieu_luc": "Toán tử số ngày hiệu lực",
        "DHR_Toan_tu_so_phep": "Toán tử số phép",
        "DHR_Toi_da_tao": "Tôi đã tạo",
        "DHR_Toi_quan_ly": "Tôi quản lý",
        "DHR_Tom_tat_ung_vien": "Tóm tắt ứng viên",
        "DHR_Ton": "Tồn",
        "DHR_Ton_giao": "Tôn giáo",
        "DHR_Ton_kho": "Tồn kho",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec": "Tồn tại nhân viên chưa có ca làm việc",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu": "Tồn tại nhân viên chưa có ca làm việc. Bạn không được phép lưu",
        "DHR_Ton_tai_nhan_vien_chua_co_thoi_gian_dang_ky_tang_ca": "Tồn tại nhân viên chưa có thời gian đăng ký tăng ca",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD": "Tồn tại nhân viên chưa được cấp phát CCLD",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?": "Tồn tại nhân viên chưa được cấp phát CCLD.Bạn có muốn lưu không",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_Ban_co_muon_luu_khong": "Tồn tại nhân viên chưa được cấp phát CCLD. Bạn có muốn lưu không?",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_co_muon_luu_khong": "Tồn tại nhân viên chưa được thu hồi trang thiết bị. Bạn có muốn lưu không ?”",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_khong_duoc_phep_luu": "Tồn tại nhân viên chưa được thu hồi trang thiết bị. Bạn không được phép lưu.",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_,_khong_duoc_phep_xoa": "Tồn tại nhân viên được cấp phát, không được phép xóa",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa": "Tồn tại nhân viên được cấp phát CCLD, không được phép xóa",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec": "Tồn tại nhiều hơn 1 ca làm việc trong cùng một ngày làm việc",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec.Ban_khong_duoc_phep_luu": "Tồn tại nhiều hơn một ca làm việc trong cùng một ngày làm việc. Bạn không được phép lưu.",
        "DHR_Tong": "Tổng",
        "DHR_Tong_chi_phi": "Tổng chi phí",
        "DHR_Tong_chi_phi_cong_ty_thanh_toan": "Tổng chi phí công ty thanh toán",
        "DHR_Tong_chi_phi_du_kien": "Tổng chi phí dự kiến",
        "DHR_Tong_chi_phi_nhan_vien_su_dung": "Tổng chi phí nhân viên sử dụng",
        "DHR_Tong_chi_phi_quy_doi": "Tổng chi phí quy đổi",
        "DHR_Tong_chi_phi_thuc_te_chuyen_di": "Tổng chi phí thực tế chuyến đi",
        "DHR_Tong_cong": "Tổng cộng",
        "DHR_Tong_diem": "Tổng điểm",
        "DHR_Tong_hop": "Tổng hợp",
        "DHR_Tong_hop_chi_phi": "Tổng hợp chi phí",
        "DHR_Tong_hop_danh_gia": "Tổng hợp đánh giá",
        "DHR_Tong_hop_ung_vien_moi_theo_ngay": "Tổng hợp ứng viên theo ngày",
        "DHR_Tong_hop_ung_vien_moi_theo_tuan": "Tổng hợp ứng viên theo tuần",
        "DHR_Tong_hop_ung_vien_theo_nguon_tuyen": "Tổng hợp ứng viên theo nguồn tuyển",
        "DHR_Tong_phep": "Tổng phép",
        "DHR_Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai": "Tổng phép không đủ để đăng ký, vui lòng kiểm tra lại",
        "DHR_Tong_quan": "Tổng quan",
        "DHR_Tong_so_NV": "Tổng số NV",
        "DHR_Tong_so_cong": "Tổng số công",
        "DHR_Tong_so_luong": "Tổng số lượng",
        "DHR_Tong_so_ngay": "Tổng số ngày",
        "DHR_Tong_so_ngay_duoc_nghi": "Tổng số ngày được nghỉ",
        "DHR_Tong_so_nhan_vien": "Tổng số nhân viên",
        "DHR_Tong_so_phep": "Tổng số phép",
        "DHR_Tong_trong_so_muc_tieu": "Tổng trọng số mục tiêu",
        "DHR_Tong_ty_le": "Tổng tỷ lệ",
        "DHR_Trang": "Trang",
        "DHR_Trang_Chu": "Trang chủ",
        "DHR_Trang_thai": "Trạng thái",
        "DHR_Trang_thai_bang_muc_tieu": "Trạng thái bảng mục tiêu",
        "DHR_Trang_thai_danh_gia": "Trạng thái đánh giá",
        "DHR_Trang_thai_duyet": "Trạng thái duyệt",
        "DHR_Trang_thai_lam_viec": "Trạng thái làm việc",
        "DHR_Trang_thai_muc_tieu": "Trạng thái mục tiêu",
        "DHR_Trang_thai_phieu_danh_gia": "Trạng thái phiếu đánh giá",
        "DHR_Trang_thai_phong_van": "Trạng thái phỏng vấn",
        "DHR_Trang_thai_su_dung": "Trạng thái sử dụng",
        "DHR_Trang_thai_tin": "Trạng thái tin",
        "DHR_Trang_thiet_bi_lao_dong": "Trang thiết bị lao động",
        "DHR_Trao_doi_email": "Trao đổi email",
        "DHR_Trao_doi_gui_va_nhan_voi_ung_vien_ve_buoi_phong_van_nay": "Trao đổi (gửi và nhận) với ứng viên về buổi phỏng vấn này",
        "DHR_Tre_han": "Trễ hạn",
        "DHR_Tre_hen": "Trễ hẹn",
        "DHR_Trinh_do_chinh_tri": "Trình độ chính trị",
        "DHR_Trinh_do_chuyen_mon": "Trình độ chuyên môn",
        "DHR_Trinh_do_dao_tao": "Trình độ đào tạo",
        "DHR_Trinh_do_hoc_van": "Trình độ học vấn",
        "DHR_Trinh_do_ngoai_ngu": "Trình độ ngoại ngữ",
        "DHR_Trinh_do_tin_hoc": "Trình độ tin học",
        "DHR_Trinh_do_van_hoaU": "Trình độ văn hóa",
        "DHR_Tro_giup": "Trợ giúp",
        "DHR_Trong_nuoc": "Trong nước",
        "DHR_Trong_so": "Trọng số",
        "DHR_Truong": "Trường",
        "DHR_TruongB": "Trường",
        "DHR_Truong_Ngay_hieu_luc_bat buoc_nhap": "Trường Ngày hiệu lực bắt buộc nhập",
        "DHR_Truong_dieu_chinh": "Trường điều chỉnh",
        "DHR_Truong_nay_bat_buoc_nhap": "Trường này bắt buộc nhập",
        "DHR_Truong_nay_khong_hop_le": "Trường này không hợp lệ",
        "DHR_Truong_ngay_la_bat_buoc": "Trường ngày là bắt buộc",
        "DHR_Truy_cap_khong_hop_le": "Truy cập không hợp lệ",
        "DHR_Truy_van_don_tu": "Truy vấn đơn từ",
        "DHR_Truy_van_tang_nhan_vien": "Truy vấn tăng nhân viên",
        "DHR_Truy_van_thong_tin_cong_phep": "Truy vấn thông tin công phép",
        "DHR_Tu": "Từ",
        "DHR_Tu_choi": "Từ chối",
        "DHR_Tu_choi_W75": "Từ chối",
        "DHR_Tu_choi_khong_thanh_cong": "Từ chối không thành công!",
        "DHR_Tu_choi_thanh_cong": "Từ chối thành công",
        "DHR_Tu_ngay": "Từ ngày",
        "DHR_Tuan_nay": "Tuần này",
        "DHR_Tuan_truoc": "Tuần trước",
        "DHR_Tuoi": "Tuổi",
        "DHR_Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban": "Tuổi cao không nhỏ hơn tuổi cơ bản",
        "DHR_Tuoi_den_khong_duoc_nho_hon_tuoi_tu": "Tuổi đến không được nhỏ hơn tuổi từ",
        "DHR_Tuoi_tu_khong_duoc_lon_hon_tuoi_den": "Tuổi từ không được lớn hơn tuổi đến",
        "DHR_Tuoi_tu_phai_nho_hon_tuoi_den": "Tuổi từ phải nhỏ hơn tuổi đến",
        "DHR_Tuy_chon": "Tùy chọn",
        "DHR_Tuyen_dung": "Tuyển dụng",
        "DHR_Ty_gia": "Tỷ giá",
        "DHR_Ty_le": "Tỷ lệ",
        "DHR_Ty_le_cho_phep": "Tỷ lệ cho phép",
        "DHR_Ty_le_xep_loai_cua_bang_tham_chieu_ket_qua": "Tỷ lệ xếp loại của bảng tham chiếu kết quả",
        "DHR_URL": "URL",
        "DHR_URL_khong_hop_le": "URL không hợp lệ",
        "DHR_Ung_tuyen": "Ứng tuyển",
        "DHR_Ung_vien": "Ứng viên",
        "DHR_Ung_vien_chua_co_lich_phong_van": "Ứng viên chưa có lịch phỏng vấn",
        "DHR_Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay": "Ứng viên đã được cập nhật thông tin trúng tuyển, bạn không được phép chỉnh sửa mà chỉ được phép xem thông tin ứng viên này",
        "DHR_Ung_vien_khong_co_email": "Ứng viên không có email!",
        "DHR_Ung_vien_va_nguoi_phong_van": "Ứng viên và người phỏng vấn",
        "DHR_Ung_vien_xac_nhan": "Ứng viên xác nhận",
        "DHR_Username_va_Password_khong_hop_le!": "Username hoặc Password không đúng!",
        "DHR_Vai_tro": "Vai trò",
        "DHR_Vao_ra": "Vào - ra",
        "DHR_Vao_tre": "Vào trễ",
        "DHR_Ve_som": "Về sớm",
        "DHR_Vi_tri": "Vị trí",
        "DHR_Vi_tri_Cong_viec": "Vị trí/ Công việc",
        "DHR_Vi_tri_tuyen": "Vị trí tuyển",
        "DHR_Vi_tri_tuyen_dung": "Vị trí tuyển dụng",
        "DHR_Vi_tri_ung_tuyen": "Vị trí ứng tuyển",
        "DHR_VoucherID_khong_duoc_de_trong": "VoucherID không được để trống",
        "DHR_Vui_long_chon_ca": "Vui lòng chọn ca",
        "DHR_Vui_long_chon_ket_qua": "Vui lòng chọn kết quả.",
        "DHR_Vui_long_chon_nhan_vien": "Vui lòng chọn nhân viên",
        "DHR_Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon_o_tren": "Vui lòng chọn nhân viên thuộc cơ cấu tổ chức đã chọn ở trên",
        "DHR_Vui_long_kiem_tra_email_va_nhap_ma_de_doi_mat_khau": "Vui lòng kiểm tra email và nhập mã để đổi mật khẩu",
        "DHR_Vui_long_kiem_tra_lai_Ban_da_danh_gia_day_du_cac_chi_tieu_chua": "Vui lòng kiểm tra lại. Bạn đã đánh giá đầy đủ các chỉ tiêu chưa?",
        "DHR_Vui_long_nhap_email_de_doi_mat_khau": "Vui lòng nhập email để đổi mật khẩu",
        "DHR_Vui_long_truy_cap_sau": "Vui lòng truy cập sau!",
        "DHR_Vuot_dinh_bien": "Vượt định biên",
        "DHR_Vuot_qua_so_luong_tap_tin_cho_phep": "Vượt quá số lượng tập tin cho phép!",
        "DHR_Xac_nhan": "Xác nhận",
        "DHR_Xac_nhan_mat_khau_moi": "Xác nhận mật khẩu mới",
        "DHR_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "Xác nhận nhân viên nhận việc tại dự án",
        "DHR_Xac_nhan_tra_the_so_bao_hiem": "Xác nhận trả thẻ - sổ bảo hiểm",
        "DHR_Xay_ra_loi_khi_duyet_cho_cac_phieu_sau": "Xảy ra lỗi khi duyệt cho các phiếu sau",
        "DHR_Xem": "Xem",
        "DHR_Xem_chi_tiet": "Xem chi tiết",
        "DHR_Xem_chi_tiet_cac_dot_review": "Xem chi tiết các đợt review",
        "DHR_Xem_chi_tiet_muc_tieu_vua_tao": "Xem chi tiết mục tiêu vừa tạo",
        "DHR_Xem_muc_tieu_cua_co_cau_to_chuc_khac": "Xem mục tiêu của cơ cấu tổ chức khác",
        "DHR_Xem_muc_tieu_cua_nguoi_khac": "Xem mục tiêu của người khác",
        "DHR_Xem_nhanh": "Xem nhanh",
        "DHR_Xem_tap_tin": "Xem tập tin",
        "DHR_Xem_tat_ca": "Xem tất cả",
        "DHR_Xem_them": "Xem thêm",
        "DHR_Xem_tren_website_tuyen_dung": "Xem trên website tuyển dụng",
        "DHR_Xem_truoc": "Xem trước",
        "DHR_Xep_loai": "Xếp loại",
        "DHR_Xep_loai_danh_gia": "Xếp loại đánh giá",
        "DHR_Xep_loai_danh_gia_nhan_vien": "Xếp loại đánh giá nhân viên",
        "DHR_Xet_duyet_Offer": "Xét duyệt Offer",
        "DHR_Xet_duyet_bang_muc_tieu": "Xét duyệt bảng mục tiêu",
        "DHR_Xet_duyet_muc_tieu": "Xét duyệt mục tiêu",
        "DHR_Xet_duyet_muc_tieu_ket_qua_ca_nhan": "Xét duyệt kết quả mục tiêu cá nhân",
        "DHR_Xin_cam_on": "Xin cảm ơn",
        "DHR_Xoa": "Xóa",
        "DHR_Xoa_dinh_kem_thanh_cong": "Xóa đính kèm thành công",
        "DHR_Xoa_dinh_kem_that_bai": "Xóa đính kèm thành công",
        "DHR_Xoa_khoi_nhom": "Xóa khỏi nhóm",
        "DHR_Xoa_nhom_se_xoa_tat_ca_du_lieu_Ban_co_chac_muon_thuc_hien_dieu_nay": "Xóa nhóm sẽ xóa tất cả dữ liệu. Bạn có chắc muốn thực hiện điều này?",
        "DHR_Xoa_phong_van": "Xóa phỏng vấn",
        "DHR_Xoa_thanh_cong": "Xóa thành công",
        "DHR_Xoa_tin_tuyen_dung": "Xoá tin tuyển dụng",
        "DHR_Xu_ly_duyet": "Xử lý duyệt",
        "DHR_Xuat": "Xuất",
        "DHR_Xuat_Excel": "Xuất Excel",
        "DHR_Xuat_PDF": "Xuất PDF",
        "DHR_Xuat_du_lieu": "Xuất dữ liệu",
        "DHR_Xuat_du_lieu_thanh_cong": "Xuất dữ liệu thành công.",
        "DHR_Xuat_word": "Xuất Word",
        "DHR_Y_kien_dong_gop": "Ý kiến đóng góp",
        "DHR_Yes": "Có",
        "DHR_Yeu_cau_khac": "Yêu cầu khác",
        "DHR_cho_phep_NV_huy_muc_tieu": "Cho phép nhân viên hủy mục tiêu",
        "DHR_cho_phep_NV_sua_trong_so": "Cho phép nhân viên sửa trọng số",
        "DHR_cho_phep_sao_chep_KQ_cap_truoc": "Cho phép sao chép kết quả của cấp trước",
        "DHR_da_chot1": "Đã chốt",
        "DHR_dang_nhap_lai": "đăng nhập lại",
        "DHR_dong": "dòng",
        "DHR_ghi_chu_cap_%P": "Ghi chú cấp %p",
        "DHR_khong_co_du_lieu": "Không có dữ liệu",
        "DHR_ko_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "DHR_label_c1": "Label cấp 1",
        "DHR_label_c2": "Label cấp 2",
        "DHR_label_c3": "Label cấp 3",
        "DHR_muc_tieu_va_ket_qua_then_chot": "Mục tiêu và kết quả then chốt",
        "DHR_ngay_BD_xet_duyet": "Ngày bắt đầu xét duyệt",
        "DHR_nguoi_nay": "người này",
        "DHR_phut": "phút",
        "DHR_ra_khoi_danh_sach": "ra khỏi danh sách?",
        "DHR_trao_giai_phap_nhan_niem_tin": "Trao giải pháp, nhận niềm tin",
        "DHR_trong": "trống",
        "DHR_tuan": "Tuần",
        "DHR_ung_vien_nay": "ứng viên này",
        "DHR_xem_CV": "Xem CV"
    },
    "en": {
        "DHR_403_loi": "You do not have permission to view this page",
        "DHR_404_loi": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        "DHR_AC_vui_long_chon_y_kien_cua_minh": "Please let us know your evaluation on service level",
        "DHR_An_trua": "Lunch",
        "DHR_Anh_dai_dien": "Avatar",
        "DHR_Ap_dung_mot_toan_tu_cho_tat_ca_du_lieu_luong": "Using the same operator for all salary data.",
        "DHR_Ap_dung_toan_tu_theo_tung_du_lieu_luong": "Using different operator for each salary data.",
        "DHR_BANG_CONG_THANG": "Monthly Time Sheet",
        "DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Are you sure to copy this value?",
        "DHR_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "You should adjust down only 0.5 leave days.",
        "DHR_Ban_chua_chon_du_lieu_tren_luoi": "You have not selected the data on the grid.",
        "DHR_Ban_chua_chon_nhan_vien": "Please choose employees",
        "DHR_Ban_chua_nhap.Ban_co_muon_bo_sung_khong": "You have not entrered theh data. Do you want to add?",
        "DHR_Ban_chua_nhap_ngay_di_ca": "You have not entered attendance date.",
        "DHR_Ban_chua_nhap_so_thu_tu": "You have not entered the ordering number.",
        "DHR_Ban_co_chac_chan_bo_duyet": "Are you sure to cancel the approval?",
        "DHR_Ban_co_chac_chan_muon_duyet": "Are you sure to approve?",
        "DHR_Ban_co_chac_chan_muon_huy.Neu_huy_thi_se_khong_the_phuc_hoi": "Are you sure to cancel? Cancellation cannot be redone.",
        "DHR_Ban_co_chac_chan_muon_huy?_Neu_huy_thi_se_KHONG_THE_khoi_phuc_du_lieu_truoc_do": "Are you sure to cancel? After cancellation data cannot be recovered.",
        "DHR_Ban_co_chac_chan_muon_tu_choi": "Are you sure to reject?",
        "DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Are you sure to delete this data?",
        "DHR_Ban_co_chac_chan_xoa": "Are you sure you want to delete?",
        "DHR_Ban_co_chac_chan_xoa_ung_vien_khoi_tin_tuyen_dung": "Are you sure to remove this candidate from the job post?",
        "DHR_Ban_co_chac_cho_phep_nhan_vien_cap_nhat_muc_tieu_da_duyet?": "Are you sure you want to allow employees edit approved objectives?",
        "DHR_Ban_co_chac_muon_bo_trang_thai_nay?": "Are you sure to remove this status?",
        "DHR_Ban_co_chac_muon_cap_nhat?": "Are you sure to update?",
        "DHR_Ban_co_chac_muon_dang_ky_muc_tieu_khong?": "Do you want to register the objectives?",
        "DHR_Ban_co_chac_muon_gui_mail_nay": "Are you sure to send this mail?",
        "DHR_Ban_co_chac_muon_huy_phep?": "Are you sure to cancel the leave?",
        "DHR_Ban_co_chac_muon_luu_khong?": "Do you want to save this data?",
        "DHR_Ban_co_chac_muon_roi_nhom_khong": "Are you sure to quit the group?",
        "DHR_Ban_co_chac_muon_xoa?": "Are you sure to delete?",
        "DHR_Ban_co_chac_muon_xoa_(nhung)_dinh_kem_nay?": "Are you sure to delete these attached ?",
        "DHR_Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?": "Are you sure to delete objectives from the review?",
        "DHR_Ban_co_chac_muon_xoa_nhan_vien?": "Are you sure to delete the employees?",
        "DHR_Ban_co_chac_muon_xoa_thanh_vien_nay_khoi_nhom_khong": "Are you sure to delete the employees from the group?",
        "DHR_Ban_co_du_lieu_chua_duoc_luu_Ban_co_muon_tiep_tuc": "Data has not been saved. Do you want to continue?",
        "DHR_Ban_co_muon": "Do you want",
        "DHR_Ban_co_muon_cap_nhat_han_hoan_thanh": "Do you want to update the final result?",
        "DHR_Ban_co_muon_cap_nhat_lai_lich_di_ca_theo_ngay_hieu_luc?": "Do you want to update the shift schedule by the value date?",
        "DHR_Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien": "Do you want to update overtime data for the employees?",
        "DHR_Ban_co_muon_cap_nhat_trang_thai_muc_tieu": "Do you want to update the objective status?",
        "DHR_Ban_co_muon_cap_thong_tin_tang_ca_cho_nhan_vien": "Do you want to update employee overtime information?",
        "DHR_Ban_co_muon_gui_den_mail_nay": "Do you want to send to this mail account?",
        "DHR_Ban_co_muon_gui_mail_nay": "Do you want to send this mail?",
        "DHR_Ban_co_muon_gui_mail_nhac_lich_cho_ung_vien_nay": "Do you want to send reminding mail to this candidate?",
        "DHR_Ban_co_muon_thay_doi_trang_thai_phong_van": "Do you want to change the interview status?",
        "DHR_Ban_co_muon_thay_doi_trang_thai_ung_vien_xac_nhan": "Do you want to change the candidate confirmation status?",
        "DHR_Ban_co_muon_thay_the_nguoi_duyet": "Do you want to replace the approver?",
        "DHR_Ban_co_muon_tra_loi_den_mail_nay": "Do you want to reply to this mail?",
        "DHR_Ban_co_muon_xoa_lich_phong_van": "Do you want to delete the interview",
        "DHR_Ban_dang_xem_muc_tieu_cua": "You are viewing the objectives of",
        "DHR_Ban_do_muc_tieu": "Objective Map",
        "DHR_Ban_giao": "Handover",
        "DHR_Ban_muon": "Do you want",
        "DHR_Ban_muon_xoa": "Do you want to delete",
        "DHR_Ban_phai_chon_doanh_nghiep": "Ban_phai_chon_doanh_nghiep",
        "DHR_Ban_phai_chon_ket_qua_danh_gia": "You must select the appraisal result.",
        "DHR_Ban_phai_gan_ca_mac_dinh": "You have to assign the default shift",
        "DHR_Ban_phai_gan_mau_ca": "You must assign the shift pattern",
        "DHR_Ban_phai_nhap_cac_thong_tin": "You have to enter the information.",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_00h00m": "You have to enter data in 00h 00m format",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11": "You have use format like 11:11",
        "DHR_Ban_phai_nhap_ngay_hieu_luc": "You must enter the valid date.",
        "DHR_Ban_phai_nhap_thong_tin": "You have to enter the information.",
        "DHR_Ban_vui_long_chon_du_lieu_tren_luoi": "Please select data on the grid.",
        "DHR_Ban_vui_long_kiem_tra_Ngay_danh_gia_phai_trong_khoang_tu_den": "Please make sure that the appraisal date should be between the valid dates.",
        "DHR_Bang1": "Equal",
        "DHR_Bang_cau_hoi_phong_van": "Interview question list",
        "DHR_Bang_muc_tieu": "Objective table",
        "DHR_Bang_tham_chieu_KQ": "Reference tables",
        "DHR_Bang_tong_hop": "Summary doc",
        "DHR_Bao_cao": "Report",
        "DHR_Bao_cao_cong": "Attendance report",
        "DHR_Bao_cao_tuyen_dung": "Recruitment report",
        "DHR_Bao_hiem_xa_hoi": "Social Insurance",
        "DHR_Bao_hiem_y_te": "Health Insurance",
        "DHR_Bat_buoc": "Compulsory",
        "DHR_Bat_buoc_nhap": "Not blank",
        "DHR_Bat_buoc_nhapB": "Not blank",
        "DHR_Bat_buoc_nhap_du_lieu": "Input data required",
        "DHR_Benh_vien_KCB": "Hospitals",
        "DHR_Bo_duyet": "Cancel Approval",
        "DHR_Bo_duyet_thanh_cong": "Approval cancellation successfully",
        "DHR_Bo_qua": "Skip",
        "DHR_Bo_qua_thong_bao_thanh_cong": "Disable successful deletion message",
        "DHR_Bo_sao_chep": "Cancel Copying",
        "DHR_Bo_sung": "Addition",
        "DHR_Bo_sung_gio_vao_ra": "Add time in/out data",
        "DHR_Bo_sung_gio_vao_ra_thuc_te": "Add actual in/out times",
        "DHR_Bo_tat_ca": "Clear all",
        "DHR_Bo_xac_nhan": "Cancel confirmation",
        "DHR_Boi": "By",
        "DHR_Buoc": "Step",
        "DHR_CCLD": "Equipment",
        "DHR_CCLD_da_cap_phat": "Equipmet issued",
        "DHR_CDN_chua_san_sang": "CDN is not ready",
        "DHR_CMND": "ID Card",
        "DHR_CV_ung_vien": "Curriculum Vitae",
        "DHR_Ca": "Shift",
        "DHR_Ca_lam_viec": "Working Shifts",
        "DHR_Ca_mac_dinh": "Default shift",
        "DHR_Ca_nhan": "Personal",
        "DHR_Ca_ra_vao": "In/out shifts",
        "DHR_Ca_vao_ra": "Shift - In - Out",
        "DHR_Ca_xoay_vong": "Rotation Shifts",
        "DHR_Cac_cau_hoi_phong_van_su_dung_cho_vi_tri_tuyen_dung": "Interview questions for the recruitment post",
        "DHR_Cac_gop_y_khac": "Other feedback",
        "DHR_Cac_tham_so_la_bat_buoc": "The parameters are compulsory",
        "DHR_Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.": "The changed data will not be saved.",
        "DHR_Cac_tieu_chi_la_bat_buoc_nhap": "Compulsory criteria",
        "DHR_Cac_truong_khong_bang_nhau": "The fields are not equal",
        "DHR_Cach_tinh_ket_qua": "Result calculation methods",
        "DHR_Cai_dat": "Settings",
        "DHR_Cap": "Level",
        "DHR_Cap_khen_thuong": "Awards level",
        "DHR_Cap_ky_luat": "Discipline Level",
        "DHR_Cap_nhat": "Update",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong": "Objective results updated successfully",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_that_bai": "Objective results updated failed",
        "DHR_Cap_nhat_gia_tri_%p_thanh_cong": "Updated %p value successfully",
        "DHR_Cap_nhat_gia_tri_%p_that_bai": "Updated %p value fail",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_thanh_cong": "Objective deadline updated successfully",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_that_bai": "Objective deadline updated failed",
        "DHR_Cap_nhat_ket_qua": "Update Result",
        "DHR_Cap_nhat_ket_qua_muc_tieu_thanh_cong": "Objective result updated successfully",
        "DHR_Cap_nhat_ket_qua_muc_tieu_that_bai": "Objective result updated failed",
        "DHR_Cap_nhat_mo_ta_muc_tieu_thanh_cong": "Objective description updated successfully",
        "DHR_Cap_nhat_mo_ta_muc_tieu_that_bai": "Objective description updated failed",
        "DHR_Cap_nhat_ngay_hoan_thanh_thanh_cong": "Complete date updated successfully",
        "DHR_Cap_nhat_ngay_hoan_thanh_that_bai": "Complete date updated failed",
        "DHR_Cap_nhat_nhom_thanh_cong": "Group updated successfully",
        "DHR_Cap_nhat_nhom_that_bai": "Group updated failed",
        "DHR_Cap_nhat_sau_thai_san": "Updated after maternity",
        "DHR_Cap_nhat_ten_muc_tieu_thanh_cong": "Objective name updated successfully",
        "DHR_Cap_nhat_ten_muc_tieu_that_bai": "Objective name updated failed",
        "DHR_Cap_nhat_thanh_cong": "Data was updated successfully",
        "DHR_Cap_nhat_thong_tin_cho_phieu_danh_gia": "Update data for appraisal document",
        "DHR_Cap_nhat_thong_tin_khac_cho_phieu_danh_gia": "Update othe data for appraisal document",
        "DHR_Cap_nhat_thong_tin_nhanh": "Quick update information",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_thanh_cong": "Key result sort index updated successfully",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_that_bai": "Key result sort index updated failed",
        "DHR_Cap_nhat_trang_thai_hop_dong_lao_dong": "Update labor contract status",
        "DHR_Cap_nhat_trang_thai_muc_tieu_thanh_cong": "Objective status updated successfully",
        "DHR_Cap_nhat_trang_thai_muc_tieu_that_bai": "Objective status updated failed",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_thanh_cong": "Key result weight indicator updated successfully",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_that_bai": "Key result weight indicator updated failed",
        "DHR_Cap_nhat_trong_so_thanh_cong": "Ratio updated successfully",
        "DHR_Cap_nhat_trong_so_that_bai": "Ratio updated failed",
        "DHR_Cap_phat": "Issue",
        "DHR_Cap_phat_thiet_bi_lao_dong": "Working tool issues",
        "DHR_Cap_phat_trang_thiet_bi_lao_dong": "Working tool issues",
        "DHR_Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet": "Already approved by next level. You cannot remove the approval.",
        "DHR_Cau_hoi_phong_van": "Interview questions",
        "DHR_Cham_cong_chi_tiet": "Attendance details",
        "DHR_Cham_cong_theo_du_an": "Attendance by projects",
        "DHR_Cham_diem_theo_tieu_chi": "Evaluate by criteria",
        "DHR_Cham_tien_do": "Slow progress",
        "DHR_Chao_buoi_sang": "Good morning!",
        "DHR_Chao_mung_ban": "Welcome onboard!",
        "DHR_Chao_mung_login": "Welcome to DIGINET ecosystem",
        "DHR_Che_do_bao_hiem_duoc_duyet": "Insurance policies approved",
        "DHR_Che_do_con_nho": "Small children policy",
        "DHR_Che_do_sinh_con_nho": "Maternit2y policy",
        "DHR_Che_do_tro_cap": "Social Insurance plolices",
        "DHR_Chi_con_lai_can_quyet_toan": "Remaining expenses to be settled",
        "DHR_Chi_dinh_lam_quan_tri_vien": "Appoint administrator",
        "DHR_Chi_dinh_lam_thanh_vien": "Appoint to be the member",
        "DHR_Chi_dinh_lam_thanh_vien_vai_tro_quan_tri": "Appoint to be an admin role",
        "DHR_Chi_dinh_thanh_vien_vai_tro_quan_tri": "Appoint to be an admin role",
        "DHR_Chi_hien_thi_du_lieu_da_chon": "Show selected data only",
        "DHR_Chi_nguoi_phong_van": "Assign interviewer",
        "DHR_Chi_phi": "Costs",
        "DHR_Chi_phi_binh_quan": "Average cost",
        "DHR_Chi_phi_con_lai_can_quyet_toan": "Remaining expenses to be settled",
        "DHR_Chi_phi_cong_tac": "Travel expenses",
        "DHR_Chi_phi_tam_ung": "Advance expenses",
        "DHR_Chi_tam_ung": "Advance payment",
        "DHR_Chi_tiet": "Detail",
        "DHR_Chi_tiet_bang_muc_tieu": "Objective table detail",
        "DHR_Chi_tiet_cong_cu_lao_dong": "Labor tool detail",
        "DHR_Chi_tiet_dieu_chinh": "Detail adjustment",
        "DHR_Chi_tiet_dieu_chinh_thong_tin_gia_dinh": "Family info update detail",
        "DHR_Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD": "Detailed employee evaluation and employment contract",
        "DHR_Chi_tiet_nhan_vien": "Employee Detail",
        "DHR_Chi_tiet_nhom": "Group detail",
        "DHR_Chi_tiet_phep": "Leave Detail",
        "DHR_Chi_tiet_phieu": "Voucher Detail",
        "DHR_Chi_tiet_thong_tin_ung_tuyen_cua_ung_vien": "Candidate detail information",
        "DHR_Chi_tiet_tin_tuyen_dung": "Job post detail",
        "DHR_Chi_tiet_xep_loai_danh_gia_nhan_vien": "Detailed classification of employee appraisal ",
        "DHR_Chi_tieu": "Objectives",
        "DHR_Chi_ung_vien": "Only candidates",
        "DHR_Chinh_sua": "Edit",
        "DHR_Chinh_sua_tang_ca_thanh_cong": "Overtime update successfully",
        "DHR_Chinh_sua_thong_tin_tang_ca": "Edit overime info",
        "DHR_Chinh_sua_thong_tin_tuyen_dung": "Edit recruiting information",
        "DHR_Chinh_sua_tin_tuyen_dung": "Edit recruiting post",
        "DHR_Chinh_tri": "Political Level",
        "DHR_Cho_duyet": "Pending Approval",
        "DHR_Cho_phep": "Allow",
        "DHR_Cho_phep_cap_nhat_nhom_muc_tieu_da_duyet": "Allow to update the approved objective group",
        "DHR_Cho_phep_nhan_vien_dieu_chinh_chon_so": "Allow employees to edit weight indicator",
        "DHR_Cho_phep_nhan_vien_huy_muc_tieu": "Allow employees to cancel objectives",
        "DHR_Cho_phep_sao_chep_ket_qua_cua_cap_truoc": "Allow copy results from previous levels",
        "DHR_Cho_tat_ca_nhan_vien": "For all employees",
        "DHR_Chon": "Select",
        "DHR_Chon_CCLD": "Select tool/equipment",
        "DHR_Chon_ban_giao": "Select handover form",
        "DHR_Chon_bao_cao": "Select Report",
        "DHR_Chon_co_cau_to_chuc": "Select organization unit",
        "DHR_Chon_du_an": "Select project",
        "DHR_Chon_giai_doan": "Select phase",
        "DHR_Chon_ky": "Selct period",
        "DHR_Chon_ky_luong": "Select payroll period",
        "DHR_Chon_lai_mau_email_truoc_khi_gui": "Reselect email template before sending",
        "DHR_Chon_mau_bao_cao": "Select report format",
        "DHR_Chon_mau_mail_co_san": "Select mail template",
        "DHR_Chon_mau_mail_gui_cho_ung_vien": "Select mail template for employees",
        "DHR_Chon_ngay": "Select date",
        "DHR_Chon_ngay_den": "Select Date to",
        "DHR_Chon_ngay_tu": "Select Date from",
        "DHR_Chon_nguoi_duyet_thay_the": "Select Replacing Approver",
        "DHR_Chon_nguoi_phong_van": "Select interviewer",
        "DHR_Chon_nhan_vien": "Select Employees",
        "DHR_Chon_nhom_luong": "Select salary group",
        "DHR_Chon_nhom_nhan_vien": "Select employee group",
        "DHR_Chon_phong_ban": "Select Departments",
        "DHR_Chon_tat_ca": "Select all",
        "DHR_Chon_tu_danh_muc_muc_tieu": "Select from objectives list",
        "DHR_Chu_de": "Themes",
        "DHR_Chu_ky": "Frequency",
        "DHR_Chu_nhat": "Sunday",
        "DHR_Chu_so_huu": "Owner",
        "DHR_Chua_chon_ung_vien": "Not selecting candidate yet.",
        "DHR_Chua_chuyen_ket_qua": "Result not transferred",
        "DHR_Chua_co_lich_phong_van_nao": "No interview schedule",
        "DHR_Chua_co_lien_ket_nao": "There is no link",
        "DHR_Chua_dap_ung": "Not qualified",
        "DHR_Chua_dat": "Not qualified",
        "DHR_Chua_duoc_nhap_ban_co_muon_luu_khong": "was blank, would you like to save ?",
        "DHR_Chua_hoan_thanh": "Not completed",
        "DHR_Chua_thuoc_tin_tuyen_dung_nao": "No assigned to any recruitment post",
        "DHR_Chua_tinh_ket_qua": "Result not calculated",
        "DHR_Chuc_danh": "Title",
        "DHR_Chuc_danh_cong_viec": "Position",
        "DHR_Chuc_danh_kiem_nhiem": "Concurrent position",
        "DHR_Chuc_mung_sinh_nhat": "Happy birthday",
        "DHR_Chuc_vu": "Position",
        "DHR_Chuc_vu_bat_buoc_nhap": "Position is required",
        "DHR_Chuc_vu_kiem_nhiem": "Concurrent position",
        "DHR_Chuc_vu_moi": "New Position",
        "DHR_Chung_minh_nhan_dan": "ID card",
        "DHR_Chung_minh_thu_ho_chieu": "ID card, passport",
        "DHR_Chuyen_mon": "Qualifications",
        "DHR_Chuyen_nganh": "Major",
        "DHR_Chuyen_tiep": "Forwarded",
        "DHR_Chuyen_tin_tuyen_dung": "Transter job post",
        "DHR_Chuyen_tin_tuyen_dung_thanh_cong": "Job post transferred",
        "DHR_Co": "Credit",
        "DHR_Co1": "Yes",
        "DHR_Co_cau_chuc_danh": "Job Title",
        "DHR_Co_cau_to_chuc": "Organization Structure",
        "DHR_Co_du_lieu_da_duoc_sua_Ban_co_muon_tiep_tuc?": "There is data that has been entered. Do you want to continue?",
        "DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing.",
        "DHR_Co_review": "Reviewed",
        "DHR_Co_xet_duyet": "Approved",
        "DHR_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "There is empty row. Do you want to save?",
        "DHR_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "There is empty row. Do you want to save?",
        "DHR_Con_thong_tin_truong": "Missing field",
        "DHR_Con_truong": "These information",
        "DHR_Cong_cu_lao_dong": "Equipment",
        "DHR_Cong_hanh_chinh_trong_ngay": "Daily Manday",
        "DHR_Cong_thuc": "Formula",
        "DHR_Cong_trinh/Cong_ty": "Project/Company",
        "DHR_Cong_trinh_Cong_ty": "Project/Company",
        "DHR_Cong_ty": "Company",
        "DHR_Cong_ty_thanh_toan": "Company payment",
        "DHR_Cong_viec": "Job",
        "DHR_Cong_viec_moi": "New Job",
        "DHR_Cong_viec_truoc_day": "Previous Job",
        "DHR_Cu": "Old",
        "DHR_DRH_Ca_mac_dinh": "Default shift",
        "DHR_DRH_Danh_sach_phong_ban": "Department list",
        "DHR_DRH_Hoc_van_va_chuyen_mon": "Education and Qualifications",
        "DHR_DRH_Ten_phong_ban_EN": "Department name (EN)",
        "DHR_Da_chot": "Finalized",
        "DHR_Da_chuyen": "Transferred",
        "DHR_Da_chuyen1": "Transferred",
        "DHR_Da_chuyen_ket_qua": "Result transferred",
        "DHR_Da_chuyen_kq": "Result transferred",
        "DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu": "Error. Data cannot be saved.",
        "DHR_Da_dien_ra": "Occured",
        "DHR_Da_duyet": "Approved",
        "DHR_Da_hoan_thanh": "Completed",
        "DHR_Da_huy_thanh_cong": "Cancelled",
        "DHR_Da_nghi_viec": "Terminated",
        "DHR_Da_sao_chep_CCLD": "Already copied from tool/asset",
        "DHR_Da_sao_chep_cap_phat_truoc": "Already copied from the previous issue",
        "DHR_Da_tinh": "Calculated",
        "DHR_Da_tinh_ket_qua": "Result calculated",
        "DHR_Da_ton_tai_lich_phong_van_cua_ung_cu_vien_ban_co_muon_xoa_khong": "A candidate interview schedule already exists, do you want to delete it ?",
        "DHR_Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong": "Candidate already exists. Do you want to continue?",
        "DHR_Da_xac_nhan": "Confirmed",
        "DHR_Da_xay_ra_loi,_khong_the_luu_du_lieu": "An error occurred during processing. The data cannot be saved. ",
        "DHR_Dan_quyen": "Paste Permissions",
        "DHR_Dan_toc": "Ethnic",
        "DHR_Dang_Lam_Viec": "In working",
        "DHR_Dang_chuan_bi_du_lieu_Xin_vui_long_cho": "Data prepration is in process. Please wait.",
        "DHR_Dang_cong_khai": "Publish externally",
        "DHR_Dang_cong_khai_W25DESC": "Job Post is available in recruitment website and for all candidates.",
        "DHR_Dang_ky_cong_tac": "Travel registeration",
        "DHR_Dang_ky_di_tre_ve_som": "Late In / early Out registeration",
        "DHR_Dang_ky_gio_tang_ca_thuc_te": "Register actual overtime hours",
        "DHR_Dang_ky_muc_tieu": "Objective register",
        "DHR_Dang_ky_nghi": "Leave registeration",
        "DHR_Dang_ky_nghi_viec": "Resignation registeration",
        "DHR_Dang_ky_quyet_toan_chi_phi_cong_tac": "Travel expense settlement resiteration",
        "DHR_Dang_ky_sau": "Registration after",
        "DHR_Dang_ky_tang_ca": "Overtime Registration",
        "DHR_Dang_ky_tang_ca_ho": "Register overtime for other",
        "DHR_Dang_ky_tang_ca_thanh_cong": "OT registeration successfully",
        "DHR_Dang_ky_thai_san": "Management of maternity and small children policies",
        "DHR_Dang_ky_truoc": "Registration before",
        "DHR_Dang_ky_truoc_d": "Registration before",
        "DHR_Dang_nhap": "Log in",
        "DHR_Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "DHR_Dang_noi_bo": "Publish internally",
        "DHR_Dang_noi_bo_W25DESC": "Job Post is for internal candidates and not available in public recruitment website.",
        "DHR_Dang_review": "In review",
        "DHR_Dang_tham_gia": "In participation",
        "DHR_Dang_thuc_hien": "Pending",
        "DHR_Dang_tin_tuyen_dung": "Publish Job Posts",
        "DHR_Dang_xuat": "Export Type",
        "DHR_Dang_xuat1": "Logout",
        "DHR_Danh_gia": "Evaluation",
        "DHR_Danh_gia_chung": "Overall Assessments",
        "DHR_Danh_gia_hop_dong_thu_viec": "Evaluation of probationary contract",
        "DHR_Danh_gia_nhan_vien": "Performance Management",
        "DHR_Danh_gia_nhan_vien_dinh_ky": "Employee regular appraisal",
        "DHR_Danh_gia_nhan_vien_het_han_HDLD": "Evaluate employees with expired labor contracts",
        "DHR_Danh_gia_phong_ban": "Department Appraisal",
        "DHR_Danh_muc_loai_chi_phi": "Cost Elements List",
        "DHR_Danh_sach_bang_tinh_KQTH": "Summary result tables",
        "DHR_Danh_sach_bang_tong_hop": "Summary of Evaluation Results",
        "DHR_Danh_sach_cap_phat_thiet_bi_lao_dong": "List of allocations of labor equipment",
        "DHR_Danh_sach_duyet_bang_muc_tieu": "Objective table approvals",
        "DHR_Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan": "Approving Personal MBO Result",
        "DHR_Danh_sach_khen_thuong": "Lists of reward",
        "DHR_Danh_sach_ky_luat": "Discipline list",
        "DHR_Danh_sach_muc_tieu": "Objectives List",
        "DHR_Danh_sach_nhan_vien": "List of Employees",
        "DHR_Danh_sach_nhan_vien_danh_gia": "List of Employees",
        "DHR_Danh_sach_nhan_vien_tham_gia": "Employee list envolved",
        "DHR_Danh_sach_phong_ban": "Department List",
        "DHR_Danh_sach_tin_da_dang": "Released news",
        "DHR_Danh_sach_tin_tuyen_dung": "Recruitment posts",
        "DHR_Danh_sach_trang_thiet_bi_lao_dong": "Tool/Asset List",
        "DHR_Danh_sach_ung_vien": "Candidate List",
        "DHR_Danh_sach_vai_tro": "Role list",
        "DHR_Dap_ung": "Qualified",
        "DHR_Dat": "Qualified",
        "DHR_De_nghi_tuyen": "Hiring proposal",
        "DHR_De_nghi_tuyen_ung_vien_nay": "Propose to hire this candidate",
        "DHR_De_xuat_dao_tao": "Training Requests",
        "DHR_De_xuat_dieu_chinh_lao_dong": "Transfer Request",
        "DHR_De_xuat_dieu_chinh_luong": "Salary Review Proposal",
        "DHR_De_xuat_khen_thuong": "Reward Proposal",
        "DHR_De_xuat_kiem_nhiemU": "Proposal of concurrent position",
        "DHR_De_xuat_ky_luat": "Discipline Proposal",
        "DHR_De_xuat_offer": "Propose offers",
        "DHR_De_xuat_thay_doi_chuc_danh": "Proposal to change job title",
        "DHR_De_xuat_tuyen_dung": "Recruitment Request",
        "DHR_Deadline": "Deadline",
        "DHR_Den": "To",
        "DHR_Den_ngay": "Date to",
        "DHR_Di_dong": "Mobile",
        "DHR_Di_tre": "Late",
        "DHR_Di_xe": "By car",
        "DHR_Dia_chi": "Address",
        "DHR_Dia_chi_lien_lac": "Contact Address",
        "DHR_Dia_chi_tam_tru": "Temporary Address",
        "DHR_Dia_chi_thuong_tru": "Permanent Address",
        "DHR_Dia_diem": "Location",
        "DHR_Dia_diem_phong_van": "Interview location",
        "DHR_Diem": "Point",
        "DHR_Diem_den": "Destination",
        "DHR_Diem_di": "Departure",
        "DHR_Diem_ky_vong": "Expected points",
        "DHR_Diem_so_khong_hop_le": "Invalid points",
        "DHR_Diem_trung_binh": "Average points",
        "DHR_Dien_giai": "Description",
        "DHR_Dien_thoai": "Telephone",
        "DHR_Dien_thoai_noi_bo": "Internal Tel",
        "DHR_Dieu_chinh": "Adjust",
        "DHR_Dieu_chinh_luong": "Salary Adjustment",
        "DHR_Dieu_chinh_nguoi_phu_trach": "Adjust the person in charge",
        "DHR_Dieu_chinh_thong_tin": "Update info",
        "DHR_Dieu_chinh_thong_tin_gia_dinh": "Update family info",
        "DHR_Dieu_kien_tinh_huong": "Commission Conditions",
        "DHR_Dinh_dang_file_khong_hop_le": "Invalid file format",
        "DHR_Dinh_dang_mau_khong_hop_le.Cho_phep_cac_loai_mau_.xlxs_.docx_.html": "Invalid file types. Support only .xlsx, docx, html",
        "DHR_Dinh_dang_ngay_khong_dung": "Invalid date format!",
        "DHR_Dinh_kem": "Attachments",
        "DHR_Dinh_vi_khoa": "GPS is locked",
        "DHR_Do_tuoi": "Age",
        "DHR_Do_uu_tien": "Priority",
        "DHR_Doanh_nghiep": "Company",
        "DHR_Doi_mat_khau": "Change Password",
        "DHR_Doi_mat_khau_thanh_cong": "Password updated",
        "DHR_Doi_tuong": "Object",
        "DHR_Doi_tuong_ap_dung": "Apply for",
        "DHR_Doi_tuong_cong_phep": "Leave/Attendance object",
        "DHR_Doi_tuong_lao_dong": "Employment object",
        "DHR_Doi_tuong_tinh_luong": "Payroll object",
        "DHR_Don_tu": "Requests",
        "DHR_Don_tu_cua_toi": "My requests",
        "DHR_Don_tu_toi_duyet": "Requests for my Approval",
        "DHR_Don_ung_tuyen": "Application document",
        "DHR_Don_vi": "Business Unit",
        "DHR_Don_vi_da_lam_viec": "Former companies",
        "DHR_Don_vi_moi": "New Business Unit",
        "DHR_Don_vi_tinh": "Unit",
        "DHR_Dong1": "Close",
        "DHR_Dong_W25DESC": "Job Post is closed and nobody could apply for",
        "DHR_Dong_y": "OK",
        "DHR_Du_an": "Project",
        "DHR_Du_an_cong_trinh": "Project",
        "DHR_Du_an_moi": "New projects",
        "DHR_Du_lieu": "Data",
        "DHR_Du_lieu_ca_nhan,_ban_khong_co_quyen_xem": "Personal data. You do not have the permission to view.",
        "DHR_Du_lieu_cham_phep": "Record leave data",
        "DHR_Du_lieu_chua_duoc_luu._Ban_co_muon_luu?": "Data has been changed. Do you want to save?",
        "DHR_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data unsaved. Do you want to save it?",
        "DHR_Du_lieu_da_bi_thay_doi_ban_co_muon_luu_khong": "Data has been changed. Do you want to save it?",
        "DHR_Du_lieu_dac_biet": "Extra data",
        "DHR_Du_lieu_dau_vao_la_bat_buoc": "Data input is required",
        "DHR_Du_lieu_phep_tong_hop": "Leave summary data",
        "DHR_Du_lieu_xuat_excel_rong": "The report has no data",
        "DHR_Dung_luong_File_khong_duoc_lon_hon": "File size must not be larger than",
        "DHR_Duyet": "Approve",
        "DHR_Duyet_bang_muc_tieu": "Approve objective tables",
        "DHR_Duyet_bo_sung_thoi_gian_vao_ra": "Approve time in/out adjustments",
        "DHR_Duyet_chung": "General apporval",
        "DHR_Duyet_cong_tac": "Travel approval",
        "DHR_Duyet_dang_ky_tang_ca_ho": "Approve overtime registrations for other",
        "DHR_Duyet_danh_gia_nhan_vien_va_HDLD": "Approve employee appraisals and labor contracts",
        "DHR_Duyet_de_xuat_chinh_sach_che_do": "Approve C&B requests",
        "DHR_Duyet_de_xuat_dao_tao": "Approve training proposals",
        "DHR_Duyet_de_xuat_dieu_chuyen_lao_dong": "Approve labor transfer proposal",
        "DHR_Duyet_de_xuat_khen_thuong": "Approve reward proposals",
        "DHR_Duyet_de_xuat_kiem_nhiem": "Approve proposal of concurrent position",
        "DHR_Duyet_de_xuat_nghi_viec": "Approve job resignment requests",
        "DHR_Duyet_de_xuat_offer": "Approve offer proposals",
        "DHR_Duyet_de_xuat_thay_doi_chuc_danh": "Approve title changement proposals",
        "DHR_Duyet_de_xuat_tuyen_dung": "Approve recruitment proposals",
        "DHR_Duyet_di_tre_ve_som": "Approval Late Early",
        "DHR_Duyet_dieu_chinh_luong": "Approve salary adjustments",
        "DHR_Duyet_dieu_chinh_thong_tin": "Approve info updates",
        "DHR_Duyet_dieu_chinh_thu_nhap": "Approve income adjustments",
        "DHR_Duyet_gio_tang_ca_thuc_te": "Approve actual overtimes",
        "DHR_Duyet_gio_tang_ca_thuc_te_hang_loat": "Massly approving actual OT",
        "DHR_Duyet_gio_vao_ra": "Approve in/out times",
        "DHR_Duyet_hang_loat": "Batch approve",
        "DHR_Duyet_ket_qua_muc_tieu_ca_nhan": "Approving personal objective result.",
        "DHR_Duyet_khen_thuong_hang_loat": "Batch approve rewards",
        "DHR_Duyet_khong_thanh_cong": "Approve unsuccessful",
        "DHR_Duyet_ky_luat": "Approve disciplinary actions",
        "DHR_Duyet_ky_luat_hang_loat": "Batch approve disciplines",
        "DHR_Duyet_mien_nhiem": "Approval of Dismissal",
        "DHR_Duyet_muc_tieu": "Approve objectives",
        "DHR_Duyet_nghi_phep": "Leave approval",
        "DHR_Duyet_nghi_thai_san": "Approve materity leaves",
        "DHR_Duyet_quyet_toan_chi_phi": "Approve expenses settlements",
        "DHR_Duyet_quyet_toan_chi_phi_cong_tac": "Approve travel expenses settlements",
        "DHR_Duyet_sai_sot_cong": "Approval incorrect attandance",
        "DHR_Duyet_tang_ca": "Approval Overtime",
        "DHR_Duyet_tang_ca_hang_loat": "Batch approve overtimes",
        "DHR_Duyet_thanh_cong": "Approved successfully",
        "DHR_Email": "Email",
        "DHR_Email_ca_nhan": "Personal email",
        "DHR_Email_gui_den_ung_vien": "Email to candidates",
        "DHR_Email_ho_tro": "Support email",
        "DHR_Email_khong_hop_le": "Invalid email address",
        "DHR_Email_tu_dong": "Auto Email",
        "DHR_Export_danh_sach_ung_vien": "Export candidate list",
        "DHR_Facebook_profile": "Facebook profile",
        "DHR_File_da_duoc_dinh_kem": "Files attached",
        "DHR_File_khong_dung_dinh_dang": "Invalid-format files",
        "DHR_File_vuot_qua_dung_luong_cho_phep": "Over-sized files",
        "DHR_File_vuot_qua_so_luong_cho_phep": "Number of files is over limit",
        "DHR_Form_id": "Form ID",
        "DHR_Gan_ca": "Assign shifts",
        "DHR_Gan_ca_lam_viec": "Assign working shifts",
        "DHR_Gan_ca_mac_dinh": "Set default shift",
        "DHR_Gan_ca_xoay_vong": "Set rotation shifts",
        "DHR_Ghi_chep_noi_bo": "Internal notes",
        "DHR_Ghi_chu": "Notes",
        "DHR_Ghi_chu_duyet": "Approve Notes",
        "DHR_Ghi_chu_huy": "Remark removed",
        "DHR_Ghi_chu_phong_van": "Interview Notes",
        "DHR_Ghi_chu_rieng_tu_nguoi_tao_lich_phong_van": "Remark by Interview Schedule Creator",
        "DHR_Ghi_chu_rieng_tu_tu_nguoi_tao_phong_van_voi_ung_vien_nay": "Private note from the interview setter to candidates",
        "DHR_Ghi_chu_sau_thai_san": "Post-maternity remark",
        "DHR_Ghi_chu_tu_nguoi_tao_phong_van": "Remark by the interview setter",
        "DHR_Ghi_chu_xac_nhan": "Confirmation notes",
        "DHR_Gia_dinh": "Family",
        "DHR_Gia_tri": "Value",
        "DHR_Gia_tri_bat_buoc_nhap": "Value Obligatory",
        "DHR_Gia_tri_lon_nhat": "Max value",
        "DHR_Gia_tri_tu_phai_nho_hon_gia_tri_den": "From value must be smaller than to value",
        "DHR_Giai_doan": "Phase",
        "DHR_Giai_doan1": "Phase",
        "DHR_Giai_doanF": "Stage",
        "DHR_Giai_doan_hien_co": "Current Stage",
        "DHR_Giai_trinh_nguyen_nhan": "Reason explanation",
        "DHR_Giay_phep_lao_dong": "Work permit",
        "DHR_Gio_cong": "Man hours",
        "DHR_Gio_di_ca": "Shift times",
        "DHR_Gio_nghi_giua_ca": "Shift break times",
        "DHR_Gio_phong_van": "Interview time",
        "DHR_Gio_ra": "Time out",
        "DHR_Gio_tang_ca": "OT Hours",
        "DHR_Gio_tang_ca_den": "Overtime hours start to",
        "DHR_Gio_tang_ca_khong_hop_le": "Overtime time invalid",
        "DHR_Gio_tang_ca_tu": "Overtime hours start from",
        "DHR_Gio_vao": "Time In",
        "DHR_Gio_vao_ra": "Time in out",
        "DHR_Gioi_han_toi_da_tong_trong_so_muc_tieu": "Maximum limit of total objectives weight",
        "DHR_Gioi_thieu": "Introduction",
        "DHR_Gioi_thieu_ve_nhom": "Group introduction",
        "DHR_Gioi_tinh": "Gender",
        "DHR_Go_vai_tro_quan_tri_vien": "Remove administrator role",
        "DHR_Gui": "Send",
        "DHR_Gui_email": "Send mail",
        "DHR_Gui_lai_ma": "Resend code",
        "DHR_Gui_mail": "Send mail",
        "DHR_Gui_mail_thanh_cong": "Mail sent",
        "DHR_Gui_thanh_cong": "Sent",
        "DHR_HOP_DONG_LAO_DONG": "LABOR CONTRACT",
        "DHR_Hai_long_can_cai_tien_them": "Satifactory and needs improvement",
        "DHR_Han_hoan_thanh": "Completion deadline",
        "DHR_Han_review_cuoi": "Final review deadline",
        "DHR_Hang_loat": "Mass",
        "DHR_Hanh_dong": "Action",
        "DHR_Hanh_vi_vi_pham_ky_luat": "Discipline Violation Act",
        "DHR_He_so": "Coefficient",
        "DHR_He_so_luong": "Salary coefficient",
        "DHR_He_so_luong_%p": "",
        "DHR_He_thong_da_gui_ma_den_email": "The sysem has sent the code to email address",
        "DHR_He_thong_dang_trong_qua_trinh_nang_cap": "The system is currently undergoing maintenance.",
        "DHR_Hien_muc_luong": "Show Salary",
        "DHR_Hien_thi_cac_du_lieu_da_chon": "Displays the selected data",
        "DHR_Hien_thi_du_lieu_da_chon": "Show only selected data",
        "DHR_Hien_thi_khong_su_dung": "Display not used items",
        "DHR_Hien_thi_tat_ca": "Show all",
        "DHR_Hien_thi_theo_tuan": "Weekly display",
        "DHR_Hien_thi_vai_tro_khong_con_su_dung": "Show unused roles",
        "DHR_Hinh_thuc": "Type",
        "DHR_Hinh_thuc_huong_luong": "Payroll type",
        "DHR_Hinh_thuc_khen_thuong": "Reward type",
        "DHR_Hinh_thuc_ky_luat": "Disciplinary type",
        "DHR_Hinh_thuc_lam_viec": "Working type",
        "DHR_Hinh_thuc_nghi_viec": "Termination type",
        "DHR_Hired": "Hired",
        "DHR_Ho_chieu": "Passport",
        "DHR_Ho_so_bao_hiem": "Social insurance file",
        "DHR_Ho_so_nhan_vien": "Employee profile",
        "DHR_Ho_ten_ung_cu_vien": "Candidate profile",
        "DHR_Ho_tro": "Support",
        "DHR_Ho_va_ten": "Full name",
        "DHR_Hoan_tat": "Completed",
        "DHR_Hoan_thanh": "Completed",
        "DHR_Hoan_toan_hai_long": "Fully satisfied",
        "DHR_Hoc_van_chuyen_mon": "Education - Qualifications",
        "DHR_Hoc_van_va_chuyen_mon": "Education and qualifications",
        "DHR_Hom_nay": "Today",
        "DHR_Home_page": "Home Page",
        "DHR_Huong_dan_su_dung": "User manuals",
        "DHR_Huong_dan_xoa_nhan_vien": "Guide: press and hold on employee row to delete",
        "DHR_Huy": "Cancel",
        "DHR_Huy_bo": "Cancel",
        "DHR_Huy_dang_ky_tang_ca": "Cancel Overtime Registation",
        "DHR_Huy_duyet_thanh_cong": "Approval removed successfully.",
        "DHR_Huy_hoan_tat": "Cancel completion",
        "DHR_Huy_muc_tieu": "Cancel objectives",
        "DHR_Huy_muc_tieu_thanh_cong": "Objectives cancelled",
        "DHR_Huy_phep": "Cancel leaves",
        "DHR_Huy_phep_thanh_cong": "Leave cancelled successfully",
        "DHR_Huy_tang_ca": "Cancel Overtime Registation",
        "DHR_In_HDLD": "Print Labor Contract",
        "DHR_KSD": "Disabled",
        "DHR_Ke_hoach_dao_tao": "Training Plans",
        "DHR_Ke_hoach_tong_the": "Master plan",
        "DHR_Kenh_tuyen_dung": "Recruitment channel",
        "DHR_Keo_tap_tin_vao_day": "Drag the files here",
        "DHR_Keo_va_tha_tap_tin_dinh_kem_vao_day": "Drag & drop attachment files here",
        "DHR_Keo_va_tha_tep_tin_vao_day": "Drag and drop file to here.",
        "DHR_Ket_qua": "Result",
        "DHR_Ket_qua_%p": "",
        "DHR_Ket_qua_danh_gia": "Evaluation Result",
        "DHR_Ket_qua_danh_gia_chung": "General evaluation results",
        "DHR_Ket_qua_review": "Review result",
        "DHR_Ket_qua_then_chot": "Key results",
        "DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "The KeyID is empty or missing,failed to upload th attached files",
        "DHR_Khac": "Other",
        "DHR_Khao_sat_y_kien_khach_hang": "Customer survey",
        "DHR_Khen_thuong_hang_loat": "Batch Rewards",
        "DHR_Kho": "Ware house",
        "DHR_Kho_thu_hoi": "Recall warehouse",
        "DHR_Khoa_dao_tao": "Training courses",
        "DHR_Khoan_chi": "Expense amount",
        "DHR_Khoan_chi_de_nghi_quyet_toan": "Expense amount submitted to settlement",
        "DHR_Khoan_chi_phi_du_kien": "Planned expense amount",
        "DHR_Khoan_de_nghi_quyet_toan": "Amount submitted to settlement",
        "DHR_Khoan_dieu_chinh_thu_nhap": "Income Adjustment Type",
        "DHR_Khoang_dieu_chinh_thu_nhap": "Income adjustment type",
        "DHR_Khoang_thoi_gian": "Time period",
        "DHR_Khoi_la_bat_buoc_nhap": "Division is required",
        "DHR_Khong": "No",
        "DHR_Khong_cho_phep": "Not allow",
        "DHR_Khong_co_dong_du_lieu_duoc_cap_nhat": "No data row is updated",
        "DHR_Khong_co_tap_tin_dinh_kem": "No attachments",
        "DHR_Khong_co_thong_bao": "No notifications",
        "DHR_Khong_co_thong_bao_nhac_nho_nao": "No notification messages",
        "DHR_Khong_co_ung_vien_duoc_chon_moi": "No candidates newly selected",
        "DHR_Khong_dat": "Not qualified",
        "DHR_Khong_du_quyen": "Insufficient permissions",
        "DHR_Khong_duoc_phep_luu": "Stop",
        "DHR_Khong_duoc_phep_nghi_qua_mot_ngay": "Cannot take more than one leave day",
        "DHR_Khong_gui_email": "Not send mail",
        "DHR_Khong_hien_muc_luong": "Now Show Salary",
        "DHR_Khong_la_bang_tong_hop": "Not summary table",
        "DHR_Khong_luu": "Not saved",
        "DHR_Khong_review": "No review",
        "DHR_Khong_su_dung": "Disabled",
        "DHR_Khong_ten": "No name",
        "DHR_Khong_the_dinh_kem": "Cannot attach files",
        "DHR_Khong_the_duyet": "Cannot approve",
        "DHR_Khong_tim_thay_hoac_khong_du_tt": "Evaluation criteria not found or parameters not sufficient",
        "DHR_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "DHR_Khong_trang_thai": "No status",
        "DHR_Khong_xet_duyet": "No approval",
        "DHR_Kich_co_toi_da": "Max file size",
        "DHR_Kiem_nhiem": "Concurrent position",
        "DHR_Kien_thuc": "Knowledge",
        "DHR_Kinh_nghiem": "Experiences",
        "DHR_Kinh_nghiem_den_khong_duoc_nho_hon_kinh_nghiem_tu": "Experience to cannot be less than experience from",
        "DHR_Kinh_nghiem_tu_khong_duoc_lon_hon_kinh_nghiem_den": "Experience from cannot be greater than experience to",
        "DHR_Ky": "Period",
        "DHR_Ky_cong": "Attendance Period",
        "DHR_Ky_luat_hang_loat": "Batch Disciplines",
        "DHR_Ky_luong": "Salary Period",
        "DHR_Ky_nang": "Skills",
        "DHR_La_bang_tong_hop": "Summary table",
        "DHR_Lam_moi": "Refresh",
        "DHR_Lap_de_xuat_chinh_sach_che_do": "Create C&B Policy Request",
        "DHR_Lap_de_xuat_dao_tao": "Training Request",
        "DHR_Lap_de_xuat_dieu_chinh_thu_nhap": "Propose income adjustments",
        "DHR_Lap_de_xuat_dieu_chuyen_lao_dong": "Propose personnel transfers",
        "DHR_Lap_de_xuat_khen_thuong": "Propose awards",
        "DHR_Lap_de_xuat_kiem_nhiem": "Create proposal of concerrent position",
        "DHR_Lap_de_xuat_ky_luat": "Propose disciplinary actions",
        "DHR_Lap_de_xuat_mien_nhiem": "Lap_de_xuat_mien_nhiem",
        "DHR_Lap_de_xuat_thay_doi_chuc_danh": "Propose title changemens",
        "DHR_Lap_de_xuat_tuyen_dung": "Propose recruiment",
        "DHR_Lap_khen_thuong_hang_loat": "Create massive proposal of employee rewards",
        "DHR_Lap_ky_luat_hang_loat": "Create massive proposal of employee discipline.",
        "DHR_Lap_lich_di_ca": "Schedule shifts",
        "DHR_Lap_lich_hang_loat": "Batch scheduling",
        "DHR_Lap_lich_tang_ca": "Set up overtime schedule",
        "DHR_Lap_lich_tang_ca_hang_loat": "Batch set up overtime schedules",
        "DHR_Lap_loai_chi_phi": "Set up the cost type",
        "DHR_Lap_nghi_phep": "Record leave data",
        "DHR_Lap_phieu_danh_gia_nhan_vien": "Create employee appraisal doc",
        "DHR_Lap_phieu_danh_gia_phong_ban": "Create department appraisal doc",
        "DHR_Lay_ty_le_hoan_thanh_danh_gia_theo_cap": "Get an assessment completion rate by level",
        "DHR_Lich_su": "History",
        "DHR_Lich_su_BHXH": "Social insurance history",
        "DHR_Lich_su_dao_tao": "Training history",
        "DHR_Lich_su_dctt": "Info update history",
        "DHR_Lich_su_dieu_chinh": "Update history",
        "DHR_Lich_su_dieu_chinh_thong_tin": "Info update history",
        "DHR_Lich_su_duyet": "Browsing History",
        "DHR_Lich_su_lao_dong": "Working history",
        "DHR_Lich_su_nhan_su": "HR history",
        "DHR_Lich_su_tac_dong": "Transaction History",
        "DHR_Linh_vuc_dao_tao": "Training areas",
        "DHR_Linkedin_profile": "Linkedin Profile",
        "DHR_Loai": "Type",
        "DHR_Loai_HDLD": "Labor Contract Types",
        "DHR_Loai_che_do": "Subsidy Types",
        "DHR_Loai_danh_gia": "Evaluation Type",
        "DHR_Loai_doi_tuong_ap_dung": "Applied Object Types",
        "DHR_Loai_don": "Order type",
        "DHR_Loai_hinh_cong_viec": "Job Types",
        "DHR_Loai_hop_dong": "Contract Type",
        "DHR_Loai_hop_dong_lao_dong": "Labor contract types",
        "DHR_Loai_mail": "Mail types",
        "DHR_Loai_muc_tieu": "Objective type",
        "DHR_Loai_ngay": "Date Type",
        "DHR_Loai_nhanh": "Quick removal",
        "DHR_Loai_phan_bo": "Allocation types",
        "DHR_Loai_phep": "Leave Types",
        "DHR_Loai_phieu": "Voucher Type",
        "DHR_Loai_phong_van": "Interview type",
        "DHR_Loai_phu_luc": "Appendix type",
        "DHR_Loai_quy_trinh": "Workflow type",
        "DHR_Loai_tach_ca": "Shift splitting type",
        "DHR_Loai_tach_tang_ca": "Type of OT",
        "DHR_Loai_tach_tang_ca1": "Overtime split type",
        "DHR_Loai_tang_ca": "Overtime type",
        "DHR_Loai_tap_tin_ho_tro": "File types supported",
        "DHR_Loai_thu_nhap": "Income Type",
        "DHR_Loai_tien": "CUR",
        "DHR_Loc_chon_hanh_dong": "Select Action",
        "DHR_Loc_chon_nhan_vien": "Filters",
        "DHR_Loc_trang_thiet_bi_lao_dong": "Tool/assets filter",
        "DHR_Loi_chua_xac_dinh": "Error is not determined",
        "DHR_Loi_khong_lay_duoc_token_CDN": "Error while getting CDN token ",
        "DHR_Lon_hon_hoac_bang": "Greater or equal",
        "DHR_Lua_chon": "Selection",
        "DHR_Luong": "Salary",
        "DHR_Luong_co_ban": "Base Salary",
        "DHR_Luong_co_ban_%p": "",
        "DHR_Luong_cong_viec": "Workflow",
        "DHR_Luong_dong_bao_hiem": "Social insurance registration salary",
        "DHR_Luong_tu_phai_nho_hon_luong_den": "Salary from must be less than Salary to",
        "DHR_Luong_yeu_cau": "Suggested Salary",
        "DHR_Luu": "Save",
        "DHR_Luu_&_nhap_tiep": "Save and Add New",
        "DHR_Luu_dinh_kem_khong_thanh_cong": "Attachments cannot be saved!",
        "DHR_Luu_khong_thanh_cong": "Save is not successfully",
        "DHR_Luu_lich_su_khong_thanh_cong": "Save failed attempt histrory",
        "DHR_Luu_lich_su_thanh_cong": "Save successful attempt history",
        "DHR_Luu_thanh_cong": "Saved successfully",
        "DHR_Luu_thong_tin_chung_tu_khong_thanh_cong": "Document data cannot be saved",
        "DHR_Luu_va_cap_nhat_thanh_cong": "Save and update successfully",
        "DHR_Luu_va_nhap_tiep": "Save and Add New",
        "DHR_Ly_do": "Reason",
        "DHR_Ly_do_cap_phat": "Issue reason",
        "DHR_Ly_do_dao_tao": "Training reason",
        "DHR_Ly_do_huy_dang_ky_tang_ca": "Reason for cancel overtime",
        "DHR_Ly_do_huy_phep": "Leave cancellation reason",
        "DHR_Ly_do_tam_ung": "Advance reason",
        "DHR_Ly_do_tang_ca": "OT reason",
        "DHR_Ly_do_thu_hoi": "Recall reason",
        "DHR_Ly_do_tu_choi_2": "Reject reason",
        "DHR_Ly_do_tuyen": "Hiring reason",
        "DHR_Ma": "Code",
        "DHR_Ma_CCLD": "Equipment code",
        "DHR_Ma_HDLD": "Labor Contract Code",
        "DHR_Ma_NV": "Employee ID",
        "DHR_Ma_UV": "Candidate Code",
        "DHR_Ma_bang_muc_tieu": "Objective table code",
        "DHR_Ma_bang_tong_hop": "Code",
        "DHR_Ma_ca": "Shift Code",
        "DHR_Ma_cham_cong": "Attendance Type Code",
        "DHR_Ma_chuc_vu_moi": "New position code",
        "DHR_Ma_cong_tac": "Field Work Code",
        "DHR_Ma_doi_tuong": "Object Code",
        "DHR_Ma_du_an": "Project Code",
        "DHR_Ma_hop_dong": "Contract Code",
        "DHR_Ma_loai_tach": "Split type codde",
        "DHR_Ma_ly_do": "Reason code",
        "DHR_Ma_nghiep_vu": "Transaction Code",
        "DHR_Ma_nguoi_dung": "User ID",
        "DHR_Ma_nhan_vien": "Employee ID",
        "DHR_Ma_nhan_vien_Ten_nhan_vien": "Employee code, employee name",
        "DHR_Ma_nhan_vien_phu": "Sub Employee Code",
        "DHR_Ma_nhom_muc_tieu": "Objective group code",
        "DHR_Ma_phieu": "Voucher No",
        "DHR_Ma_phong_ban": "Department Code",
        "DHR_Ma_so_thue_ca_nhan": "Personal Tax Code",
        "DHR_Ma_the_cham_cong": "Attendance Card No",
        "DHR_Ma_thong_ke": "Statistic code",
        "DHR_Ma_ung_vien_Ten_ung_vien": "Candidate code, candidate name",
        "DHR_Ma_vai_tro": "Role Code",
        "DHR_Ma_vat_tu": "Item Code",
        "DHR_Ma_vat_tu_ten_vat_tu": "Item code, item name",
        "DHR_Mail_rong": "Empty Mail",
        "DHR_Man_hinh": "Form",
        "DHR_Man_hinh_mac_dinh_khi_dang_nhap": "Default screen after log in.",
        "DHR_Mat_khau": "Password",
        "DHR_Mat_khau_co_hieu_luc_trong_vong_5_phut": "Password is valid within 5 minutes",
        "DHR_Mat_khau_cu": "Old Password",
        "DHR_Mat_khau_khong_dung_vui_long_thu_lai": "Incorrect password, please try again",
        "DHR_Mat_khau_moi": "New Password",
        "DHR_Mat_khau_phai_co_it_nhat_8_ky_tu": "Password must have at least 8 characters, 1 upper case, 1 lower case, 1 special character",
        "DHR_Mat_khau_xac_nhan_khong_chinh_xac": "Confirmation password is incorrect",
        "DHR_Mau_bao_cao": "Report Code",
        "DHR_Mau_bao_cao_khong_duoc_tim_thay": "The report template is not found",
        "DHR_Mau_ca": "Shift Type",
        "DHR_Mau_email_hoac_phong_van": "Email Template or Interview",
        "DHR_Mau_giao_dien": "Theme color",
        "DHR_Mau_mail_co_san": "Available mail template",
        "DHR_Mau_sac": "Color",
        "DHR_Mau_sac_hien_thi": "Display color",
        "DHR_Menu_id": "Menu ID",
        "DHR_Mo_rong": "Extend",
        "DHR_Mo_ta": "Description",
        "DHR_Mo_ta_cong_viec": "Job Description",
        "DHR_Mo_ta_ly_do_khac": "Describe other reason",
        "DHR_Mo_ta_mau_sac": "Color description",
        "DHR_Mo_tat_ca": "Expand all",
        "DHR_Moi": "new",
        "DHR_Moi_bo_sung": "New addition",
        "DHR_Mot_thang_truoc": "Previous month",
        "DHR_Mot_tuan_truoc": "Previous week",
        "DHR_Muc_dich_cong_tac": "Travel purpose",
        "DHR_Muc_do_dong_y": "Agreement level",
        "DHR_Muc_luong": "Salary",
        "DHR_Muc_luong_den": "Salary to",
        "DHR_Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu": "Salary to cannot be less then salary from",
        "DHR_Muc_luong_du_kien": "Expected Salary",
        "DHR_Muc_luong_tu": "Salary from",
        "DHR_Muc_luong_tu_khong_duoc_lon_muc_luong_den": "Salary range from cannot be greater then salary to",
        "DHR_Muc_luong_va_phu_cap": "Salaries and benefits",
        "DHR_Muc_tieu1": "Objective",
        "DHR_Muc_tieu_ca_nhan": "Personal objectives",
        "DHR_Muc_tieu_co_cau_to_chuc": "Org unit objectives",
        "DHR_Muc_tieu_kiem_nhiem": "Objective of concurrent position",
        "DHR_Muc_tieu_nhan_vien": "Employee objectives",
        "DHR_NV_chua_tham_gia_khoa_dao_tao": "Employees not yet attended training courses",
        "DHR_Nam": "Year",
        "DHR_Nam1": "Male",
        "DHR_NamU": "Male",
        "DHR_Nam_sinh": "Year of Birth",
        "DHR_Ngan_hang": "Banks",
        "DHR_Ngay": "Date",
        "DHR_Ngay1": "Days",
        "DHR_Ngay3": "Date",
        "DHR_Ngay_bat_dau": "Start date",
        "DHR_Ngay_bat_dau_nghi_viec": "Retirement start date",
        "DHR_Ngay_bat_dau_thu_viec": "Probation start date",
        "DHR_Ngay_bo_sung": "Adjustment Date",
        "DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac": "This date has no shift. Please select other date.",
        "DHR_Ngay_cap": "Issue date",
        "DHR_Ngay_cap_BHXH": "SI book issue date",
        "DHR_Ngay_cap_CMND": "ID Card issue date",
        "DHR_Ngay_cap_ho_chieu": "Passport issue date",
        "DHR_Ngay_cap_phat": "Issue date",
        "DHR_Ngay_cap_phat_CCLD_truoc": "Previous tool issue date",
        "DHR_Ngay_cap_the_can_cuoc": "ID card issue date",
        "DHR_Ngay_cham_cong": "Attendance Date",
        "DHR_Ngay_chot_van_tay": "Finger Print Finalization Date",
        "DHR_Ngay_cong": "Man days",
        "DHR_Ngay_da_nghi_phep": "Leave days taken",
        "DHR_Ngay_dang_ky_KCB": "Health registration date",
        "DHR_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "Application from (to) cannot be greater then application date (to)",
        "DHR_Ngay_danh_gia": "Evaluation date",
        "DHR_Ngay_danh_gia_bat_buoc_nhap": "Evaluation date required",
        "DHR_Ngay_de_xuat": "Proposal date",
        "DHR_Ngay_de_xuat_tam_ung": "Advance Proposal date",
        "DHR_Ngay_den": "Arrival date",
        "DHR_Ngay_di": "Start Date",
        "DHR_Ngay_di/ve": "Dates start/return",
        "DHR_Ngay_di_/_ve": "Dates start/return",
        "DHR_Ngay_di_ca": "Shift date",
        "DHR_Ngay_di_lam_du_kien": "Planned return date",
        "DHR_Ngay_di_lam_thuc_te": "Actual return date",
        "DHR_Ngay_dieu_chinh": "Adjust date",
        "DHR_Ngay_du_sinh": "Planned DOB",
        "DHR_Ngay_duyet": "Approval date",
        "DHR_Ngay_het_han": "Expiry date",
        "DHR_Ngay_het_han_ho_chieu": "Passport expiry date",
        "DHR_Ngay_hieu_luc": "Valid date",
        "DHR_Ngay_hieu_luc_den": "Valid date to",
        "DHR_Ngay_hieu_luc_tu": "Valid date from",
        "DHR_Ngay_hoan_thanh": "Finish Date",
        "DHR_Ngay_ket_thuc": "Finish date",
        "DHR_Ngay_ket_thuc_che_do": "Policy end date",
        "DHR_Ngay_ket_thuc_thu_viec": "Probation end date",
        "DHR_Ngay_khoi_hanh": "Departure date",
        "DHR_Ngay_ky_phu_luc": "Appendix Signing date",
        "DHR_Ngay_lam_viec": "Working Date",
        "DHR_Ngay_lap": "Created date",
        "DHR_Ngay_nghi": "Day off",
        "DHR_Ngay_nghi_viec": "Termination date",
        "DHR_Ngay_nhan": "Received date",
        "DHR_Ngay_nhan_ho_so": "Received date",
        "DHR_Ngay_nop_don": "Date submitted",
        "DHR_Ngay_phep": "Leave date",
        "DHR_Ngay_phieu": "Voucher Date",
        "DHR_Ngay_phong_van": "Interview date",
        "DHR_Ngay_sinh": "Date of Birth",
        "DHR_Ngay_sinh_con": "Child Birt Date",
        "DHR_Ngay_tac_dong": "Trans Date",
        "DHR_Ngay_tach_tang_ca": "Overtime split date",
        "DHR_Ngay_tang_ca": "OT Date",
        "DHR_Ngay_tao": "Created date",
        "DHR_Ngay_tham_gia_BHXH": "SI join date",
        "DHR_Ngay_tham_gia_bao_hiem": "Insurance Participation Date",
        "DHR_Ngay_thu_hoi": "Recall date",
        "DHR_Ngay_thuc_hien": "Execution Date",
        "DHR_Ngay_tu": "Date from",
        "DHR_Ngay_vao": "Start Date",
        "DHR_Ngay_vao_lam": "Start Date",
        "DHR_Ngay_vao_lam_du_kien": "Planned wort start date",
        "DHR_Ngay_ve": "Return Date",
        "DHR_Ngay_xin_nghi_viec": "Date of resignation",
        "DHR_Ngay_xin_thoi_viec": "Resignation Date",
        "DHR_Nghi_phep": "Leave",
        "DHR_Nghi_phep_trong_ngay": "Take Leave on the Day",
        "DHR_Nghi_thai_san": "Marternity Leave",
        "DHR_Nghi_viec": "Resignation",
        "DHR_Nghiep_vu": "Transaction",
        "DHR_Ngoai_te": "Currency",
        "DHR_Nguoi_PV": "Interviewer",
        "DHR_Nguoi_dai_dien": "Representative person",
        "DHR_Nguoi_danh_gia": "Evaluation Person",
        "DHR_Nguoi_danh_gia_bat_buoc_nhap": "Evaluation person is required",
        "DHR_Nguoi_dao_tao": "Training person",
        "DHR_Nguoi_dieu_chinh": "Adjuster",
        "DHR_Nguoi_dung": "User",
        "DHR_Nguoi_duyet": "Approver",
        "DHR_Nguoi_lap": "Created by",
        "DHR_Nguoi_lien_he": "Contact",
        "DHR_Nguoi_lien_he_so_1": "Contact #1",
        "DHR_Nguoi_lien_he_so_2": "Contact #2",
        "DHR_Nguoi_nhan_email": "Email receiver",
        "DHR_Nguoi_nuoc_ngoai": "Foreigner",
        "DHR_Nguoi_phong_van": "Interviewer",
        "DHR_Nguoi_phu_trach": "Person in Charge",
        "DHR_Nguoi_quan_ly": "Person in Charge",
        "DHR_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "DHR_Nguoi_tao": "Created by",
        "DHR_Nguoi_tao_tin": "News creater",
        "DHR_Nguoi_thuc_hien": "Execute Employee",
        "DHR_Nguoi_uy_quyen": "Authorizing person",
        "DHR_Nguoi_xem_phong_van": "Interview viewer",
        "DHR_Nguon": "Source",
        "DHR_Nguon_ung_tuyen": "Recruitment sources",
        "DHR_Nguon_ung_vien": "Candidate sources",
        "DHR_Nguyen_quan": "Origin place",
        "DHR_Nhac_lich": "Schedule reminder",
        "DHR_Nhac_nho1": "Remind",
        "DHR_Nhan_Vien_Chua_co_ca": "Employees without shifts",
        "DHR_Nhan_vao_de_tai_hinh_hoac_keo_tha_hinh_vao_day": "Click here to upload image or drag and drop image",
        "DHR_Nhan_vien": "Employee",
        "DHR_Nhan_vien_chua_duoc_cap_phat_CCLD": "Employees without tool issues",
        "DHR_Nhan_vien_da_duoc_thu_hoi_ccld_khong_duoc_phep_xoa": "The employee has returned working tools, you cannot delete.",
        "DHR_Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen": "This employee is in charge of many positions. Please identify objectives of main position for approval process.",
        "DHR_Nhan_vien_tu_danh_gia_het_HDLD": "Employee labor contract self evaluation",
        "DHR_Nhan_xet_chung": "General remark",
        "DHR_Nhan_xet_ung_vien": "Candidate review",
        "DHR_Nhap": "Text",
        "DHR_Nhap_": "Draft",
        "DHR_Nhap_Ten_Dang_Nhap": "Enter username",
        "DHR_Nhap_W25DESC": "Job Post is in process and is not available for the public",
        "DHR_Nhap_de_tim_kiem": "Enter to search",
        "DHR_Nhap_email": "Enter email",
        "DHR_Nhap_ma_bao_mat": "Enter security code",
        "DHR_Nhap_mat_khau": "Enter password",
        "DHR_Nhap_tiep": "Next",
        "DHR_Nhap_tu_tim_kiem": "Enter search key words",
        "DHR_Nhat_ky_cong_tac": "Travel diary",
        "DHR_Nhieu_ngay": "Many Days",
        "DHR_Nho_hon_hoac_bang1": "Less or equal",
        "DHR_Nho_mat_khau": "Save login and password",
        "DHR_Nho_mat_khau1": "Remember me",
        "DHR_Nhom": "Groups",
        "DHR_Nhom_chi_tieu": "Criterions Group",
        "DHR_Nhom_hanh_vi_vi_pham_ky_luat": "Violation Act Group",
        "DHR_Nhom_luong": "Salary Group",
        "DHR_Nhom_muc_tieu": "Objective group",
        "DHR_Nhom_nhan_vien": "Employee Group",
        "DHR_Nhung_nguoi_co_quyen_xem_toan_bo_TTUV_va_xu_ly_quy_trinh_tuyen_dung": "",
        "DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "This feedback will be taken by our company in oder to improve our service quality level and provide better service to our customers.",
        "DHR_No": "Debt",
        "DHR_No_data": "No Data",
        "DHR_Noi_bo": "Internal",
        "DHR_Noi_cap": "Issue Place",
        "DHR_Noi_cap_BHXH": "SI Book issue place",
        "DHR_Noi_cap_CMND": "ID Card Issue Place",
        "DHR_Noi_cap_han_ho_chieu": "Passport renewal place",
        "DHR_Noi_cap_ho_chieu": "Passport issue place",
        "DHR_Noi_cap_the_can_cuoc": "ID card issue place",
        "DHR_Noi_dang_ky_KCB": "Health check registration place",
        "DHR_Noi_dung": "Content",
        "DHR_Noi_dung_can_tim": "Content to searching",
        "DHR_Noi_dung_can_tim_kiem": "Content to search",
        "DHR_Noi_dung_dao_tao": "Training Content",
        "DHR_Noi_dung_mail_khong_duoc_de_trong": "Mail content cannot be blank",
        "DHR_Noi_dung_nhat_ky": "Diary Content",
        "DHR_Noi_dung_tim_kiem": "Search Content",
        "DHR_Noi_dung_tin_tuyen_dung": "Job Post Content",
        "DHR_Noi_sinh": "Place of Birth",
        "DHR_Nu": "Female",
        "DHR_Nuoc_ngoai": "Foreign",
        "DHR_Phai_chon_it_nhat_1_trang_thai": "You have to select at least one status",
        "DHR_Phai_la_so_lon_hon_hoac_bang_khong": "It must be greater or equal zero",
        "DHR_Phai_la_so_lon_hon_khong": "It must be greater than zero",
        "DHR_Phai_la_so_nguyen_duong": "It must be an positive integer",
        "DHR_Phai_lon_hon_gia_tri_tu": "Must greater then value of from",
        "DHR_Phai_nhap_so_phut_cho_it_nhat_mot_loai_xin_phep": "Minutes must be entered for at least one type of request",
        "DHR_Phai_trong_khoang_tu_0_den_100": "It must be in between 0 to 100",
        "DHR_Phan_bo_chi_phi": "Cost Allocation",
        "DHR_Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong": "Assign one operator for all payroll data",
        "DHR_Phan_bo_toan_tu": "Assign operator",
        "DHR_Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong": "Assign operator for all payroll data",
        "DHR_Phan_bo_toan_tu_theo_tung_du_lieu_luong": "Assign operator for each payroll data",
        "DHR_Phan_quyen": "Permission",
        "DHR_Phan_quyen_chuc_nang": "Functional Permisstion",
        "DHR_Phan_quyen_du_lieu": "Data Permission",
        "DHR_Phan_quyen_ess": "ESS Permissions",
        "DHR_Phan_quyen_tat_ca": "Permission for all",
        "DHR_Phan_quyen_theo_vai_tro": "Permission by roles",
        "DHR_Phan_tram_hoan_thanh": "Completion percentage",
        "DHR_Phep": "Leave",
        "DHR_Phep_bu": "Compensation Leave",
        "DHR_Phep_con_lai": "Remaining Annual Leave",
        "DHR_Phep_da_nghi": "Used Leave",
        "DHR_Phep_da_nghi_/_het_han": "Annual leave has been used or expired ",
        "DHR_Phep_het_han_chua_su_dung": "Annual leave had not been used but expired yet",
        "DHR_Phep_nam": "Annual Leave",
        "DHR_Phep_tham_nien": "Seniority Leave",
        "DHR_Phep_ton": "Remaining leave",
        "DHR_Phien_ban": "Version",
        "DHR_Phieu_KCB_den_ngay": "Health check date (to)",
        "DHR_Phieu_KCB_tu_ngay": "Health check date (from)",
        "DHR_Phieu_cham_cong": "Timesheet voucher",
        "DHR_Phieu_cua_toi": "My vouchers",
        "DHR_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "Application is approved, you cannot edit.",
        "DHR_Phieu_danh_gia": "Evaluation Vouchers",
        "DHR_Phieu_danh_gia_nhan_vien": "Employee Appraisal vouchers",
        "DHR_Phieu_huy": "Cancellation vouchers",
        "DHR_Phong_ban": "Department",
        "DHR_Phong_ban_moi": "New Department",
        "DHR_Phong_van": "Interview",
        "DHR_Phong_van_online": "Online interview",
        "DHR_Phong_van_qua_dien_thoai": "Telephone interview",
        "DHR_Phong_van_tai_van_phong": "In office interview",
        "DHR_Phu_luc": "Appendix",
        "DHR_Phuong": "Ward",
        "DHR_Phuong_phap_danh_gia": "Appraisal Methods",
        "DHR_Phuong_phap_dieu_chinh_luong": "Salary Adjustment Method",
        "DHR_Phuong_phap_do": "Measuring Method",
        "DHR_Phuong_phap_tinh": "Calculation Method",
        "DHR_Qua_han": "Overdue",
        "DHR_Quan": "District",
        "DHR_Quan_he": "Relationship",
        "DHR_Quan_he_gia_dinh": "Family Relationships",
        "DHR_Quan_ly": "Management",
        "DHR_Quan_ly_cong_tac_va_chi_phi": "Business travel and expense management",
        "DHR_Quan_tri": "Management",
        "DHR_Quan_tri_vien": "Administrators",
        "DHR_Que_quan": "Origin",
        "DHR_Quen_mat_khau": "Forget passwod",
        "DHR_Quoc_gia": "Country",
        "DHR_Quoc_tich": "Citizenship",
        "DHR_Quy": "Quarter",
        "DHR_Quy_doi_phep": "Leave conversion",
        "DHR_Quy_nay": "This quarter",
        "DHR_Quy_trinh": "Process",
        "DHR_Quy_trinh_ap_dung": "Workflow",
        "DHR_Quy_trinh_tao_muc_tieu": "Objectives Setting Workflow",
        "DHR_Quy_trinh_tuyen_dung_Workflow": "Recruitment Workflows",
        "DHR_Quy_truoc": "Previous quarter",
        "DHR_Quy_uoc_nhu_sau": "Agreed as follows",
        "DHR_Quyen_han": "Rights",
        "DHR_Quyet_toan": "Finalization",
        "DHR_Ra_som": "Out soon",
        "DHR_Review_muc_tieu": "Review objectives",
        "DHR_Roi_khoi_nhom": "Leave the group",
        "DHR_SDT_ho_tro": "Support phone number",
        "DHR_SLA": "SLA",
        "DHR_STT": "No.",
        "DHR_Sai_sot_cong": "Attendance Errors",
        "DHR_Sang_loc_ung_vien": "Filter Candidates",
        "DHR_Sao_chep_CCLD": "Copy tool/asset",
        "DHR_Sao_chep_cap_phat_truoc": "Copy previous issues",
        "DHR_Sao_chep_quyen": "Copy Permissions",
        "DHR_Sao_chep_thanh_cong": "Copied successfully.",
        "DHR_Sao_chep_tin_dang": "Copy Job Post",
        "DHR_Sau_thai_san:": "After maternity:",
        "DHR_Scheduled": "Scheduled",
        "DHR_Sinh_nhat": "Birthday",
        "DHR_Size_ao": "Shir size",
        "DHR_Size_giay": "Shoe size",
        "DHR_Size_quan": "Pant size",
        "DHR_Sl_nam": "Male headcount",
        "DHR_Sl_nu": "Female headcount",
        "DHR_So": "Number",
        "DHR_So_CMND": "Identity card number",
        "DHR_So_Fax": "Fax No",
        "DHR_So_HDLD": "Labor Contract No",
        "DHR_So_bao_hiem": "Insurance Book",
        "DHR_So_can_cuoc": "ID card number",
        "DHR_So_cap_review": "Number of review levels",
        "DHR_So_cong": "Number of",
        "DHR_So_cong_va_so_phep_phai_co_gia_tri": "Manday and leave quantity must have value",
        "DHR_So_dien_thoai": "Tel no.",
        "DHR_So_dien_thoai_khong_hop_le": "Phone number is invalid",
        "DHR_So_dong": "Number of rows",
        "DHR_So_gio": "Hours",
        "DHR_So_gio_dao_tao": "Number of training hours",
        "DHR_So_gio_tang_ca": "OT hours",
        "DHR_So_gio_vuot": "Exceeding hours",
        "DHR_So_ho_chieu": "Passport number",
        "DHR_So_luong": "Quantity",
        "DHR_So_luong_can_tuyen": "Required quantity for hiring",
        "DHR_So_luong_cho_phep": "Available QTY",
        "DHR_So_luong_cong": "Manday quantity",
        "DHR_So_luong_da_nghi_den_ky_hien_tai": "Taken quantity until current period",
        "DHR_So_luong_de_xuat": "Proposed Quantity",
        "DHR_So_luong_duoc_cap": "Issued Quantity",
        "DHR_So_luong_nhan_vien_khong_bang_de_xuat": "The number of employees does not match the number of proposals. Do you want to save?",
        "DHR_So_luong_phai_lon_hon_0": "Quantity must be greater than zero",
        "DHR_So_luong_phep": "Leave quantity",
        "DHR_So_luong_phep_khong_hop_le": "Leave quantity is not valid",
        "DHR_So_luong_ton_den_cuoi_nam": "Leave year end balance",
        "DHR_So_luong_ton_den_ky_hien_tai": "Leave balance until current period",
        "DHR_So_luong_ton_kho_khong_du_cap_phat": "Stock balance is not sufficient to issue",
        "DHR_So_luong_tuyen": "Number Of Recruitment",
        "DHR_So_luong_ung_vien": "Number of candidates",
        "DHR_So_nam_kinh_nghiem": "Years of experience",
        "DHR_So_ngay": "Number of Days",
        "DHR_So_ngay_con_lai": "Remaining days",
        "DHR_So_ngay_cong": "Manday quantity",
        "DHR_So_ngay_da_nghi": "Used Days",
        "DHR_So_ngay_hieu_luc": "Valid Days",
        "DHR_So_ngay_hieu_luc_phep": "Number of valid leave days",
        "DHR_So_ngay_nghi_trong_ky": "Period Leave Days",
        "DHR_So_ngay_vi_pham": "Number of days of violations",
        "DHR_So_nha": "Home address number",
        "DHR_So_phep": "Total leave",
        "DHR_So_phu_luc": "Appendix No.",
        "DHR_So_phut": "Minutes",
        "DHR_So_quyet_dinh": "Decision No",
        "DHR_So_so_BHXH": "SI book No.",
        "DHR_So_so_bao_hiem_xa_hoi": "SI book No.",
        "DHR_So_thang": "Number of months",
        "DHR_So_thang_da_su_dung": "Number of months used",
        "DHR_So_thang_dao_tao": "Number of training months",
        "DHR_So_thang_khong_duoc_qua_lon": "Number of months is too big",
        "DHR_So_thang_su_dung": "Number of months used",
        "DHR_So_the_BHYT": "Health insurrance card number",
        "DHR_So_the_bao_hiem_y_te": "SI Card No.",
        "DHR_So_the_can_cuoc": "ID card number",
        "DHR_So_thu_tu": "Order No",
        "DHR_So_tien": "Amount",
        "DHR_So_tien_de_xuat_tam_ung": "Proposed advance amount",
        "DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu": "Amount to cannot be less than amount from",
        "DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den": "Amount from cannot be greater than amount to",
        "DHR_Started": "Started",
        "DHR_Su_dung": "Used",
        "DHR_Sua": "Edit",
        "DHR_Sua_binh_luan_khong_thanh_cong": "Comment update failed",
        "DHR_Sua_binh_luan_thanh_cong": "Comment update successful",
        "DHR_Sua_du_lieu": "Edit data",
        "DHR_Sua_phong_van": "Edit interview",
        "DHR_Tags": "Tags",
        "DHR_Tai_bao_cao_(excel)": "Dowload report (Excel)",
        "DHR_Tai_khoan_Ngan_hang": "Bank Account",
        "DHR_Tai_khoan_bi_khoa_10_phut": "Your account is locked, please try again in 10 minutes",
        "DHR_Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap": "The account has not been linked to employee profile. You cannot login.",
        "DHR_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Incorrect login or password",
        "DHR_Tai_khoan_khong_co_quyen": "You doo not have the permissioin to access the system.",
        "DHR_Tai_lieu": "Document",
        "DHR_Tai_lieu_dinh_kem": "Attachments",
        "DHR_Tam_dap_ung_Can_cai_tien_them": "Temporarily satisfied, needs improvement",
        "DHR_Tam_ung": "Advance",
        "DHR_Tang_ca": "Overtime",
        "DHR_Tang_ca_den": "Overtime to",
        "DHR_Tang_ca_giua": "Mid shift overtime",
        "DHR_Tang_ca_sau": "After overtime",
        "DHR_Tang_ca_truoc": "Before overtime",
        "DHR_Tang_ca_tu": "Overtime from",
        "DHR_Tao_boi": "Create By",
        "DHR_Tao_muc_tieu_cho_chuc_danh_kiem_nhiem": "Create objectives for concurrent duty",
        "DHR_Tao_review": "Create review",
        "DHR_Tao_review_thanh_cong": "Review created",
        "DHR_Tao_tu_danh_muc_muc_tieu": "Generate from objectives list",
        "DHR_Tap_tin": "File",
        "DHR_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Attachments in processing",
        "DHR_Tap_tin_tai_len": "Upload files",
        "DHR_Tat_ca": "All",
        "DHR_Tat_ca_danh_gia": "All evaluations",
        "DHR_Tat_ca_don_tu": "All Requests",
        "DHR_Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD": "All employees with tool issues",
        "DHR_Tat_ca_thao_tac_nhap_lieu_truoc_do_se_bi_mat,ban_co_muon_tiep_tuc_khong?": "All entried data will be removed as you change another type of process. Do you want to continue? ",
        "DHR_Tat_ca_tin": "All news",
        "DHR_Ten": "Name",
        "DHR_Ten_CCLD": "Equipment name",
        "DHR_Ten_Dang_Nhap": "Username",
        "DHR_Ten_Thanh_pho": "City name",
        "DHR_Ten_UV": "Candidate name",
        "DHR_Ten_bang_KQ_tong_hop": "Result summary name",
        "DHR_Ten_bang_muc_tieu": "Objective table name",
        "DHR_Ten_bang_tong_hop": "Summary of evaluation results's description",
        "DHR_Ten_ca": "Shift Name",
        "DHR_Ten_chi_tieu": "Criteria name",
        "DHR_Ten_chuc_vu_moi": "New position",
        "DHR_Ten_doi_tuong": "Object Name",
        "DHR_Ten_giai_doan": "Phase Name",
        "DHR_Ten_ket_qua_then_chot": "Key result name",
        "DHR_Ten_kho": "Warehouse name",
        "DHR_Ten_loai_chi_phi": "Cost Type Name",
        "DHR_Ten_loai_tach": "Split type name",
        "DHR_Ten_ly_do": "Reason",
        "DHR_Ten_man_hinh": "Form Name",
        "DHR_Ten_muc_tieu": "Objective name",
        "DHR_Ten_nghiep_vu": "Transaction name",
        "DHR_Ten_nguoi_dung": "User Name",
        "DHR_Ten_nguoi_quan_he": "Contact Person",
        "DHR_Ten_nhan_su": "HR name",
        "DHR_Ten_nhan_vien": "Employee Name",
        "DHR_Ten_nhom": "Group Name",
        "DHR_Ten_nhom_muc_tieu": "Objective group name",
        "DHR_Ten_phieu": "Name",
        "DHR_Ten_phong_ban": "Department Name",
        "DHR_Ten_phong_ban_VI": "Department Name (VI)",
        "DHR_Ten_phuong": "Ward name",
        "DHR_Ten_quan": "District name",
        "DHR_Ten_quy_trinh": "Workflow name",
        "DHR_Ten_tach_tang_ca": "Overtime split name",
        "DHR_Ten_tinh_nang": "Feature Name",
        "DHR_Ten_trang_thai_duyet": "Approval status",
        "DHR_Ten_vai_tro": "Role name",
        "DHR_Ten_vat_tu": "Item Name",
        "DHR_Tham_chieu_chuoi": "Reference string",
        "DHR_Tham_chieu_so": "Reference nummber",
        "DHR_Tham_chieu_xep_loai": "Reference classify",
        "DHR_Tham_nien": "Seniority",
        "DHR_Tham_so_truyen_vao_khong_hop_le": "Input parameters invalid",
        "DHR_Thang": "Month",
        "DHR_Thang_nay": "This month",
        "DHR_Thang_truoc": "Last month",
        "DHR_Thanh_pho": "City",
        "DHR_Thanh_tien(USD)": "Amount (USD)",
        "DHR_Thanh_tien(VND)": "Amount (VND)",
        "DHR_Thanh_vien": "Member",
        "DHR_Thanh_vien_cua_Nihon_Grouping_va_Pan": "Members of Nihon Group and PAN Group",
        "DHR_Thanh_vien_duoc_chon": "Selected members",
        "DHR_Thanh_vien_quan_ly": "Management Member",
        "DHR_Thanh_vien_vai_tro_quan_tri": "Admin role",
        "DHR_Thay_the_nguoi_duyet": "Replace Approver",
        "DHR_Thay_the_nguoi_duyet_thanh_cong": "Approver Replacement completed",
        "DHR_The_can_cuoc": "Citizen ID Card",
        "DHR_The_luu_tru": "Residence Card",
        "DHR_Them": "Add",
        "DHR_Them_binh_luan_thanh_cong": "Comment added",
        "DHR_Them_dinh_kem_thanh_cong": "Attachment added",
        "DHR_Them_dinh_kem_that_bai": "Attachment adding failed",
        "DHR_Them_giai_doan": "Add Stage",
        "DHR_Them_ket_qua_then_chot": "Add key result",
        "DHR_Them_ket_qua_then_chot_thanh_cong": "Key result added",
        "DHR_Them_ket_qua_then_chot_that_bai": "Key result adding failed",
        "DHR_Them_lich_phong_van": "Add an interview schedule",
        "DHR_Them_loai_tach_tang_ca": "Add overtime split type",
        "DHR_Them_moi": "<Add new>",
        "DHR_Them_moi1": "Add new",
        "DHR_Them_moi_tin_tuyen_dung": "Add Job Post",
        "DHR_Them_moi_ung_vien": "Add Candidate",
        "DHR_Them_muc_tieu": "Add objective",
        "DHR_Them_phong_van_moi": "Add new interview",
        "DHR_Them_tep_dinh_kem": "Add attachment",
        "DHR_Them_thanh_vien": "Add member",
        "DHR_Them_tieu_chi": "Add Criteria",
        "DHR_Them_tieu_chi_danh_gia": "Add evaluation criteria",
        "DHR_Them_ung_vien": "Add candidates",
        "DHR_Thiet_lap_cach_tinh_ket_qua": "Set up result calculation method",
        "DHR_Thiet_lap_cach_tinh_ket_qua_muc_tieu": "Set up objective result calculation method",
        "DHR_Thiet_lap_chi_tieu_tong_hop": "Set up summary criteria ",
        "DHR_Thiet_lap_tong_hop": "Set up summary",
        "DHR_Thoi_gian": "Time",
        "DHR_Thoi_gian_bat_dau": "Start Time",
        "DHR_Thoi_gian_can": "Required Time",
        "DHR_Thoi_gian_can_nhan_su": "Requiring time for staff",
        "DHR_Thoi_gian_dang_ky:": "Register time:",
        "DHR_Thoi_gian_dao_tao": "Training Time",
        "DHR_Thoi_gian_dong_BHXH": "SI payment time",
        "DHR_Thoi_gian_hieu_luc": "Valid Time",
        "DHR_Thoi_gian_ket_thuc": "End Time",
        "DHR_Thoi_gian_muc_tieu": "Period",
        "DHR_Thoi_gian_nop_don": "Request Submit Time",
        "DHR_Thoi_gian_nop_hoac_deadline_khong_hop_le": "Submit Time or Deadline is invalid",
        "DHR_Thoi_gian_phong_van": "Interview Date",
        "DHR_Thoi_gian_ra_vao_VN": "Time in and out VN",
        "DHR_Thoi_gian_tao": "Created date",
        "DHR_Thoi_gian_tham_gia_BH": "SI participation time",
        "DHR_Thoi_gian_thu_viec": "Probation period",
        "DHR_Thoi_han_hop_dong": "Contract valid times",
        "DHR_Thoi_han_phu_luc": "Appendix valid times",
        "DHR_Thong_bao": "Announcement",
        "DHR_Thong_bao1": "Alert",
        "DHR_Thong_baoU": "Notifications",
        "DHR_Thong_ke": "Statistics",
        "DHR_Thong_ke_phong_van": "Interview statistics",
        "DHR_Thong_ke_ung_vien": "Candidate statistics",
        "DHR_Thong_tin": "Information",
        "DHR_Thong_tin_ca_nhan": "Personal Information",
        "DHR_Thong_tin_ca_nhan_DESC": "Name, Gender, Phone Number, Department, ID Cards, Address",
        "DHR_Thong_tin_cap_phat_cho": "Issue infor for",
        "DHR_Thong_tin_chi_tiet": "Detail info",
        "DHR_Thong_tin_chinh": "Main",
        "DHR_Thong_tin_chung": "General Information",
        "DHR_Thong_tin_cong_viec": "Job information",
        "DHR_Thong_tin_cu": "Old information",
        "DHR_Thong_tin_cung_cap_khong_hop_le": "Provided data invalid",
        "DHR_Thong_tin_dieu_chinh": "Adjustment information",
        "DHR_Thong_tin_gia_dinh": "Family information",
        "DHR_Thong_tin_hien_tai": "Current information",
        "DHR_Thong_tin_ket_qua_then_chot": "Key result information",
        "DHR_Thong_tin_kiem_nhiem": "Information of concurrent position",
        "DHR_Thong_tin_lien_he": "Contact Inforamtion",
        "DHR_Thong_tin_lien_lac": "Contact Information",
        "DHR_Thong_tin_luong": "Salary information",
        "DHR_Thong_tin_muc_tieu": "Objective information",
        "DHR_Thong_tin_nguoi_dung": "User information",
        "DHR_Thong_tin_nguoi_duyet": "Approver Info",
        "DHR_Thong_tin_nhan_su": "Person Info",
        "DHR_Thong_tin_nhom": "Group information",
        "DHR_Thong_tin_phan_quyen": "Permission information",
        "DHR_Thong_tin_phep": "Leave Information",
        "DHR_Thong_tin_phong_van": "Interview information",
        "DHR_Thong_tin_tang_nhan_vien": "Staff Increase information",
        "DHR_Thong_tin_thu_hoi_cho": "Recall information for",
        "DHR_Thong_tin_tra_ve_khi_luu_khong_dung": "Feedback Info when saved incorrectly",
        "DHR_Thong_tin_tuyen_dung": "Recruiment Information",
        "DHR_Thong_tin_vai_tro": "Role information",
        "DHR_Thong_tin_xep_loai": "Classification information",
        "DHR_Thu_ba": "Tuesday",
        "DHR_Thu_bay": "Saturday",
        "DHR_Thu_gon": "Collapse",
        "DHR_Thu_hai": "Monday",
        "DHR_Thu_hoi": "Recall",
        "DHR_Thu_hoi_trang_thiet_bi_lao_dong": "Recall labor tools",
        "DHR_Thu_nam": "Thursday",
        "DHR_Thu_nho": "Collapse",
        "DHR_Thu_sau": "Friday",
        "DHR_Thu_tu": "Order",
        "DHR_Thu_tuU": "Wednesday",
        "DHR_Thu_tu_hien_thi": "Display order",
        "DHR_Thuc_hien1": "Execute",
        "DHR_Thuc_hien_thanh_cong": "Successfully saved",
        "DHR_Tien_bao_hiem": "Insurance amount",
        "DHR_Tien_chi_tam_ung": "Advance admount",
        "DHR_Tien_do_thuc_hien": "Execution progress",
        "DHR_Tien_tro_cap": "Subsidy Amount",
        "DHR_Tiep_tuc": "Continue",
        "DHR_Tieu_chi": "Criteria",
        "DHR_Tieu_chi_danh_gia": "Evaluation Criteria",
        "DHR_Tieu_chi_loc": "Filter parameters",
        "DHR_Tieu_de": "Title",
        "DHR_Tieu_de_mail": "Mail Title",
        "DHR_Tieu_de_phong_van": "Interview title",
        "DHR_Tieu_de_tin": "News Title",
        "DHR_Tieu_de_tin_tuyen_dung": "Job post title",
        "DHR_Tim_kiem": "Finding Information",
        "DHR_Tim_kiem_cap_phat_trang_thiet_bi_lao_dong": "Search for labor equipment",
        "DHR_Tim_kiem_cham_cong": "Search timesheet",
        "DHR_Tim_kiem_che_do_bao_hiem": "Search insurance policies",
        "DHR_Tim_kiem_co_cau_to_chuc": "Search org units",
        "DHR_Tim_kiem_ho_so_nhan_su": "Search for HR profiles",
        "DHR_Tim_kiem_nhan_vien": "Search employees",
        "DHR_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "Search with employee name and employee id..",
        "DHR_Tim_theo_ma_va_ten": "Search by codes and names",
        "DHR_Tin_chua_duoc_dang_cong_khai": "Unpublished posts",
        "DHR_Tin_hot": "Hot news",
        "DHR_Tin_tuyen_dung": "Job posts",
        "DHR_Tinh_cach": "Character",
        "DHR_Tinh_trang_hien_tai": "Current Status",
        "DHR_Tinh_trang_hon_nhan": "Marital Status",
        "DHR_Tinh_trang_thu_hoi": "Recover status",
        "DHR_To_chuc": "Organization",
        "DHR_To_chuc_Tuyen_dung_DESC": "Direct Manager, Start Date, Job Position, Project, Remark, Attachment",
        "DHR_To_nhom": "Team",
        "DHR_To_nhom_moi": "New Team",
        "DHR_Toan_thoi_gian": "Full time",
        "DHR_Toan_tu": "Operator",
        "DHR_Toan_tu_so_cong": "Manday quantity operator",
        "DHR_Toan_tu_so_ngay_hieu_luc": "Valid day number operator",
        "DHR_Toan_tu_so_phep": "Leave number operator",
        "DHR_Toi_da_tao": "I have created",
        "DHR_Toi_quan_ly": "I manage",
        "DHR_Tom_tat_ung_vien": "Candidate summary",
        "DHR_Ton": "Balance",
        "DHR_Ton_giao": "Religion",
        "DHR_Ton_kho": "Inventory balance",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec": "There are employees without shift",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu": "There are employees without shift. You cannot save.",
        "DHR_Ton_tai_nhan_vien_chua_co_thoi_gian_dang_ky_tang_ca": "There are employees without overtime registration",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD": "There are employees without tool/asset allocation.",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?": "There are employees without tool/asset allocation. Do you want to save?",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_Ban_co_muon_luu_khong": "There are employees without tool/asset allocation. Do you want to save?",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_co_muon_luu_khong": "There are employees with pending labor tools. Dou you want to save?",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_khong_duoc_phep_luu": "There are employees with pending labor tools. Dou you cannot save?",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_,_khong_duoc_phep_xoa": "The are employees with labor equipment allocation, deletion is not allowed.",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa": "The are employees with labor equipment allocation, deletion is not allowed.",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec": "There are more than 1 shift in the same working date.",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec.Ban_khong_duoc_phep_luu": "There are more than 1 shift in the same working date. You cannot save.",
        "DHR_Tong": "Total",
        "DHR_Tong_chi_phi": "Total Cost",
        "DHR_Tong_chi_phi_cong_ty_thanh_toan": "Total paid expenses",
        "DHR_Tong_chi_phi_du_kien": "Total Planned expenses",
        "DHR_Tong_chi_phi_nhan_vien_su_dung": "Total employee used expenses",
        "DHR_Tong_chi_phi_quy_doi": "Total Expenses (Converted)",
        "DHR_Tong_chi_phi_thuc_te_chuyen_di": "Total actual trip expenses",
        "DHR_Tong_cong": "Total",
        "DHR_Tong_diem": "Total points",
        "DHR_Tong_hop": "Summary",
        "DHR_Tong_hop_chi_phi": "Expenses Summary",
        "DHR_Tong_hop_danh_gia": "Evaluation Summary",
        "DHR_Tong_hop_ung_vien_moi_theo_ngay": "Candidate summary by dates",
        "DHR_Tong_hop_ung_vien_moi_theo_tuan": "Candidate summary by weeks",
        "DHR_Tong_hop_ung_vien_theo_nguon_tuyen": "Candidate summary by sources",
        "DHR_Tong_phep": "Total Leave",
        "DHR_Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai": "Leave balance is not sufficient, please check again",
        "DHR_Tong_quan": "Overview",
        "DHR_Tong_so_NV": "Total headcount",
        "DHR_Tong_so_cong": "Attendance Total",
        "DHR_Tong_so_luong": "Total quantity",
        "DHR_Tong_so_ngay": "Total days",
        "DHR_Tong_so_ngay_duoc_nghi": "Total off days",
        "DHR_Tong_so_nhan_vien": "Total employees",
        "DHR_Tong_so_phep": "Leave Total",
        "DHR_Tong_trong_so_muc_tieu": "Total objectives weight",
        "DHR_Tong_ty_le": "Total percentage",
        "DHR_Trang": "Page",
        "DHR_Trang_Chu": "Home Page",
        "DHR_Trang_thai": "Status",
        "DHR_Trang_thai_bang_muc_tieu": "Status of Objective Table ",
        "DHR_Trang_thai_danh_gia": "Evaluation status",
        "DHR_Trang_thai_duyet": "Approval Status",
        "DHR_Trang_thai_lam_viec": "Working Status",
        "DHR_Trang_thai_muc_tieu": "Objective status",
        "DHR_Trang_thai_phieu_danh_gia": "Status of Appraisal Doc",
        "DHR_Trang_thai_phong_van": "Interview status",
        "DHR_Trang_thai_su_dung": "Usage status",
        "DHR_Trang_thai_tin": "News status",
        "DHR_Trang_thiet_bi_lao_dong": "Assets & Tools",
        "DHR_Trao_doi_email": "Email exchange",
        "DHR_Trao_doi_gui_va_nhan_voi_ung_vien_ve_buoi_phong_van_nay": "Information exchange with candidate about this interview",
        "DHR_Tre_han": "Late",
        "DHR_Tre_hen": "Late",
        "DHR_Trinh_do_chinh_tri": "Political Level",
        "DHR_Trinh_do_chuyen_mon": "Professional Levels",
        "DHR_Trinh_do_dao_tao": "Training Levels",
        "DHR_Trinh_do_hoc_van": "Educational Level",
        "DHR_Trinh_do_ngoai_ngu": "Language Skill",
        "DHR_Trinh_do_tin_hoc": "IT Level",
        "DHR_Trinh_do_van_hoaU": "Education Level",
        "DHR_Tro_giup": "Help",
        "DHR_Trong_nuoc": "Domestic",
        "DHR_Trong_so": "Weight",
        "DHR_Truong": "Information of",
        "DHR_TruongB": "Information of",
        "DHR_Truong_Ngay_hieu_luc_bat buoc_nhap": "Valid date is required",
        "DHR_Truong_dieu_chinh": "Adjust field",
        "DHR_Truong_nay_bat_buoc_nhap": "This field is required",
        "DHR_Truong_nay_khong_hop_le": "This field is invalid",
        "DHR_Truong_ngay_la_bat_buoc": "Date is required",
        "DHR_Truy_cap_khong_hop_le": "Invalid access",
        "DHR_Truy_van_don_tu": "Request Inquiry",
        "DHR_Truy_van_tang_nhan_vien": "Query increase staff",
        "DHR_Truy_van_thong_tin_cong_phep": "Query attendance information",
        "DHR_Tu": "From",
        "DHR_Tu_choi": "Reject",
        "DHR_Tu_choi_W75": "Refuse",
        "DHR_Tu_choi_khong_thanh_cong": "Reject unsuccessfully!",
        "DHR_Tu_choi_thanh_cong": "Reject successfully!",
        "DHR_Tu_ngay": "Date from",
        "DHR_Tuan_nay": "This week",
        "DHR_Tuan_truoc": "Last week",
        "DHR_Tuoi": "Age",
        "DHR_Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban": "Senior age to cannot be less than base age",
        "DHR_Tuoi_den_khong_duoc_nho_hon_tuoi_tu": "Age to cannot be less than age from",
        "DHR_Tuoi_tu_khong_duoc_lon_hon_tuoi_den": "Age from cannot be greater than age to",
        "DHR_Tuoi_tu_phai_nho_hon_tuoi_den": "Age from must be less than age to",
        "DHR_Tuy_chon": "Options",
        "DHR_Tuyen_dung": "Recruitment",
        "DHR_Ty_gia": "Exchange Rate",
        "DHR_Ty_le": "Percentage",
        "DHR_Ty_le_cho_phep": "Ratio of Approvals",
        "DHR_Ty_le_xep_loai_cua_bang_tham_chieu_ket_qua": "Classification percentage of reference table",
        "DHR_URL": "URL",
        "DHR_URL_khong_hop_le": "Invalid URL",
        "DHR_Ung_tuyen": "Job application",
        "DHR_Ung_vien": "Candidate",
        "DHR_Ung_vien_chua_co_lich_phong_van": "Interview schedule has not arranged for candidate.",
        "DHR_Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay": "Candidate is hired. You can view but cannot edit",
        "DHR_Ung_vien_khong_co_email": "Canidiate has not email",
        "DHR_Ung_vien_va_nguoi_phong_van": "Candidate and Interviewer",
        "DHR_Ung_vien_xac_nhan": "Candidate confirmation",
        "DHR_Username_va_Password_khong_hop_le!": "Username or Password is incorrect!",
        "DHR_Vai_tro": "Roles",
        "DHR_Vao_ra": "In - Out",
        "DHR_Vao_tre": "In Late",
        "DHR_Ve_som": "Go back early",
        "DHR_Vi_tri": "Job position",
        "DHR_Vi_tri_Cong_viec": "Position/Work",
        "DHR_Vi_tri_tuyen": "Recruitment Position",
        "DHR_Vi_tri_tuyen_dung": "Recruitment Position",
        "DHR_Vi_tri_ung_tuyen": "Recruiment Position",
        "DHR_VoucherID_khong_duoc_de_trong": "VoucherID cannot be blank",
        "DHR_Vui_long_chon_ca": "Please select the shift.",
        "DHR_Vui_long_chon_ket_qua": "Please select the result",
        "DHR_Vui_long_chon_nhan_vien": "Please select employees",
        "DHR_Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon_o_tren": "Please select employees of the above org unit",
        "DHR_Vui_long_kiem_tra_email_va_nhap_ma_de_doi_mat_khau": "Please check your mail box and enter code to change password",
        "DHR_Vui_long_kiem_tra_lai_Ban_da_danh_gia_day_du_cac_chi_tieu_chua": "Please check again on evaluation for all criteria",
        "DHR_Vui_long_nhap_email_de_doi_mat_khau": "Please enter your mail to change passworrd",
        "DHR_Vui_long_truy_cap_sau": "Vui_long_truy_cap_sau",
        "DHR_Vuot_dinh_bien": "Quotas exceeded",
        "DHR_Vuot_qua_so_luong_tap_tin_cho_phep": "Number of allowed files exceedeed",
        "DHR_Xac_nhan": "Confirm",
        "DHR_Xac_nhan_mat_khau_moi": "Confirm New Password",
        "DHR_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "Confirm emloyees onboard in project",
        "DHR_Xac_nhan_tra_the_so_bao_hiem": "Insurance book/card return confirmation",
        "DHR_Xay_ra_loi_khi_duyet_cho_cac_phieu_sau": "There are some errors as approving following documents",
        "DHR_Xem": "View",
        "DHR_Xem_chi_tiet": "View Detail",
        "DHR_Xem_chi_tiet_cac_dot_review": "View details of reviews",
        "DHR_Xem_chi_tiet_muc_tieu_vua_tao": "View details of newly created objectives",
        "DHR_Xem_muc_tieu_cua_co_cau_to_chuc_khac": "View objectives of other org units",
        "DHR_Xem_muc_tieu_cua_nguoi_khac": "View objectives of other employees",
        "DHR_Xem_nhanh": "Quick View",
        "DHR_Xem_tap_tin": "View files",
        "DHR_Xem_tat_ca": "Search all",
        "DHR_Xem_them": "See more",
        "DHR_Xem_tren_website_tuyen_dung": "View on recruitment website",
        "DHR_Xem_truoc": "Preview",
        "DHR_Xep_loai": "Classify",
        "DHR_Xep_loai_danh_gia": "Classification of appraisal",
        "DHR_Xep_loai_danh_gia_nhan_vien": "Classify employee appraisal",
        "DHR_Xet_duyet_Offer": "Approve Job Offer",
        "DHR_Xet_duyet_bang_muc_tieu": "Review and approve objective tables",
        "DHR_Xet_duyet_muc_tieu": "Approving objectives ",
        "DHR_Xet_duyet_muc_tieu_ket_qua_ca_nhan": "Approving Personal MBO Result",
        "DHR_Xin_cam_on": "Xin_cam_on",
        "DHR_Xoa": "Delete",
        "DHR_Xoa_dinh_kem_thanh_cong": "Attachments deleted",
        "DHR_Xoa_dinh_kem_that_bai": "Attachment deletion failed",
        "DHR_Xoa_khoi_nhom": "Remove from group",
        "DHR_Xoa_nhom_se_xoa_tat_ca_du_lieu_Ban_co_chac_muon_thuc_hien_dieu_nay": "Group deleting will delete all data. Do you want to continue?",
        "DHR_Xoa_phong_van": "Delete interviewe",
        "DHR_Xoa_thanh_cong": "Deleted successfully",
        "DHR_Xoa_tin_tuyen_dung": "Delete job post",
        "DHR_Xu_ly_duyet": "Process approval",
        "DHR_Xuat": "Export",
        "DHR_Xuat_Excel": "Export To Excel",
        "DHR_Xuat_PDF": "Export in PDF",
        "DHR_Xuat_du_lieu": "Export Data",
        "DHR_Xuat_du_lieu_thanh_cong": "Export data is success",
        "DHR_Xuat_word": "Eport in Word",
        "DHR_Y_kien_dong_gop": "Feedback",
        "DHR_Yes": "Yes",
        "DHR_Yeu_cau_khac": "Other Requirements",
        "DHR_cho_phep_NV_huy_muc_tieu": "Allow employees to edit weight indicator",
        "DHR_cho_phep_NV_sua_trong_so": "Allow employees to cancel objectives",
        "DHR_cho_phep_sao_chep_KQ_cap_truoc": "Allow copy results from previous levels",
        "DHR_da_chot1": "Finalized",
        "DHR_dang_nhap_lai": "Login again",
        "DHR_dong": "lines",
        "DHR_ghi_chu_cap_%P": "Remark",
        "DHR_khong_co_du_lieu": "No data",
        "DHR_ko_du_quyen": "Insufficient permissions. Please log in again.",
        "DHR_label_c1": "Level 1 label",
        "DHR_label_c2": "Level 2 label",
        "DHR_label_c3": "Level 3 label",
        "DHR_muc_tieu_va_ket_qua_then_chot": "Objectives and key results",
        "DHR_ngay_BD_xet_duyet": "Approval start date",
        "DHR_nguoi_nay": "this person",
        "DHR_phut": "minute(s)",
        "DHR_ra_khoi_danh_sach": "out of the list?",
        "DHR_trao_giai_phap_nhan_niem_tin": "Provide Solution, Receive Trust",
        "DHR_trong": "empty",
        "DHR_tuan": "Week",
        "DHR_ung_vien_nay": "this candidate",
        "DHR_xem_CV": "CV review"
    },
    "ja": {
        "DHR_403_loi": "",
        "DHR_404_loi": "",
        "DHR_AC_vui_long_chon_y_kien_cua_minh": "",
        "DHR_An_trua": "",
        "DHR_Anh_dai_dien": "",
        "DHR_Ap_dung_mot_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Ap_dung_toan_tu_theo_tung_du_lieu_luong": "",
        "DHR_BANG_CONG_THANG": "",
        "DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "DHR_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "",
        "DHR_Ban_chua_chon_du_lieu_tren_luoi": "",
        "DHR_Ban_chua_chon_nhan_vien": "",
        "DHR_Ban_chua_nhap.Ban_co_muon_bo_sung_khong": "",
        "DHR_Ban_chua_nhap_ngay_di_ca": "",
        "DHR_Ban_chua_nhap_so_thu_tu": "",
        "DHR_Ban_co_chac_chan_bo_duyet": "",
        "DHR_Ban_co_chac_chan_muon_duyet": "",
        "DHR_Ban_co_chac_chan_muon_huy.Neu_huy_thi_se_khong_the_phuc_hoi": "",
        "DHR_Ban_co_chac_chan_muon_huy?_Neu_huy_thi_se_KHONG_THE_khoi_phuc_du_lieu_truoc_do": "",
        "DHR_Ban_co_chac_chan_muon_tu_choi": "",
        "DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "DHR_Ban_co_chac_chan_xoa": "",
        "DHR_Ban_co_chac_chan_xoa_ung_vien_khoi_tin_tuyen_dung": "",
        "DHR_Ban_co_chac_cho_phep_nhan_vien_cap_nhat_muc_tieu_da_duyet?": "",
        "DHR_Ban_co_chac_muon_bo_trang_thai_nay?": "",
        "DHR_Ban_co_chac_muon_cap_nhat?": "",
        "DHR_Ban_co_chac_muon_dang_ky_muc_tieu_khong?": "",
        "DHR_Ban_co_chac_muon_gui_mail_nay": "",
        "DHR_Ban_co_chac_muon_huy_phep?": "",
        "DHR_Ban_co_chac_muon_luu_khong?": "",
        "DHR_Ban_co_chac_muon_roi_nhom_khong": "",
        "DHR_Ban_co_chac_muon_xoa?": "",
        "DHR_Ban_co_chac_muon_xoa_(nhung)_dinh_kem_nay?": "",
        "DHR_Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?": "",
        "DHR_Ban_co_chac_muon_xoa_nhan_vien?": "",
        "DHR_Ban_co_chac_muon_xoa_thanh_vien_nay_khoi_nhom_khong": "",
        "DHR_Ban_co_du_lieu_chua_duoc_luu_Ban_co_muon_tiep_tuc": "",
        "DHR_Ban_co_muon": "",
        "DHR_Ban_co_muon_cap_nhat_han_hoan_thanh": "",
        "DHR_Ban_co_muon_cap_nhat_lai_lich_di_ca_theo_ngay_hieu_luc?": "",
        "DHR_Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien": "",
        "DHR_Ban_co_muon_cap_nhat_trang_thai_muc_tieu": "",
        "DHR_Ban_co_muon_cap_thong_tin_tang_ca_cho_nhan_vien": "Bạn có muốn cập nhật thông tin tăng ca nhân viên",
        "DHR_Ban_co_muon_gui_den_mail_nay": "",
        "DHR_Ban_co_muon_gui_mail_nay": "",
        "DHR_Ban_co_muon_gui_mail_nhac_lich_cho_ung_vien_nay": "",
        "DHR_Ban_co_muon_thay_doi_trang_thai_phong_van": "",
        "DHR_Ban_co_muon_thay_doi_trang_thai_ung_vien_xac_nhan": "",
        "DHR_Ban_co_muon_thay_the_nguoi_duyet": "",
        "DHR_Ban_co_muon_tra_loi_den_mail_nay": "",
        "DHR_Ban_co_muon_xoa_lich_phong_van": "",
        "DHR_Ban_dang_xem_muc_tieu_cua": "",
        "DHR_Ban_do_muc_tieu": "",
        "DHR_Ban_giao": "",
        "DHR_Ban_muon": "",
        "DHR_Ban_muon_xoa": "",
        "DHR_Ban_phai_chon_doanh_nghiep": "",
        "DHR_Ban_phai_chon_ket_qua_danh_gia": "",
        "DHR_Ban_phai_gan_ca_mac_dinh": "",
        "DHR_Ban_phai_gan_mau_ca": "",
        "DHR_Ban_phai_nhap_cac_thong_tin": "",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_00h00m": "",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11": "",
        "DHR_Ban_phai_nhap_ngay_hieu_luc": "",
        "DHR_Ban_phai_nhap_thong_tin": "",
        "DHR_Ban_vui_long_chon_du_lieu_tren_luoi": "",
        "DHR_Ban_vui_long_kiem_tra_Ngay_danh_gia_phai_trong_khoang_tu_den": "",
        "DHR_Bang1": "",
        "DHR_Bang_cau_hoi_phong_van": "",
        "DHR_Bang_muc_tieu": "",
        "DHR_Bang_tham_chieu_KQ": "",
        "DHR_Bang_tong_hop": "",
        "DHR_Bao_cao": "",
        "DHR_Bao_cao_cong": "",
        "DHR_Bao_cao_tuyen_dung": "Báo cáo tuyển dụng",
        "DHR_Bao_hiem_xa_hoi": "Social Insurance",
        "DHR_Bao_hiem_y_te": "Health Insurance",
        "DHR_Bat_buoc": "",
        "DHR_Bat_buoc_nhap": "",
        "DHR_Bat_buoc_nhapB": "",
        "DHR_Bat_buoc_nhap_du_lieu": "",
        "DHR_Benh_vien_KCB": "",
        "DHR_Bo_duyet": "",
        "DHR_Bo_duyet_thanh_cong": "",
        "DHR_Bo_qua": "",
        "DHR_Bo_qua_thong_bao_thanh_cong": "",
        "DHR_Bo_sao_chep": "",
        "DHR_Bo_sung": "",
        "DHR_Bo_sung_gio_vao_ra": "",
        "DHR_Bo_sung_gio_vao_ra_thuc_te": "",
        "DHR_Bo_tat_ca": "",
        "DHR_Bo_xac_nhan": "",
        "DHR_Boi": "",
        "DHR_Buoc": "",
        "DHR_CCLD": "",
        "DHR_CCLD_da_cap_phat": "",
        "DHR_CDN_chua_san_sang": "",
        "DHR_CMND": "",
        "DHR_CV_ung_vien": "",
        "DHR_Ca": "シフト",
        "DHR_Ca_lam_viec": "",
        "DHR_Ca_mac_dinh": "",
        "DHR_Ca_nhan": "Personal",
        "DHR_Ca_ra_vao": "",
        "DHR_Ca_vao_ra": "",
        "DHR_Ca_xoay_vong": "",
        "DHR_Cac_cau_hoi_phong_van_su_dung_cho_vi_tri_tuyen_dung": "",
        "DHR_Cac_gop_y_khac": "",
        "DHR_Cac_tham_so_la_bat_buoc": "",
        "DHR_Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.": "",
        "DHR_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "DHR_Cac_truong_khong_bang_nhau": "",
        "DHR_Cach_tinh_ket_qua": "",
        "DHR_Cai_dat": "",
        "DHR_Cap": "レベル",
        "DHR_Cap_khen_thuong": "",
        "DHR_Cap_ky_luat": "",
        "DHR_Cap_nhat": "",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_gia_tri_%p_thanh_cong": "",
        "DHR_Cap_nhat_gia_tri_%p_that_bai": "",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_ket_qua": "",
        "DHR_Cap_nhat_ket_qua_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_ket_qua_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_mo_ta_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_mo_ta_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_ngay_hoan_thanh_thanh_cong": "",
        "DHR_Cap_nhat_ngay_hoan_thanh_that_bai": "",
        "DHR_Cap_nhat_nhom_thanh_cong": "",
        "DHR_Cap_nhat_nhom_that_bai": "",
        "DHR_Cap_nhat_sau_thai_san": "",
        "DHR_Cap_nhat_ten_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_ten_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_thanh_cong": "",
        "DHR_Cap_nhat_thong_tin_cho_phieu_danh_gia": "",
        "DHR_Cap_nhat_thong_tin_khac_cho_phieu_danh_gia": "",
        "DHR_Cap_nhat_thong_tin_nhanh": "",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_thanh_cong": "",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_that_bai": "",
        "DHR_Cap_nhat_trang_thai_hop_dong_lao_dong": "",
        "DHR_Cap_nhat_trang_thai_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_trang_thai_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_thanh_cong": "",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_that_bai": "",
        "DHR_Cap_nhat_trong_so_thanh_cong": "",
        "DHR_Cap_nhat_trong_so_that_bai": "",
        "DHR_Cap_phat": "",
        "DHR_Cap_phat_thiet_bi_lao_dong": "",
        "DHR_Cap_phat_trang_thiet_bi_lao_dong": "",
        "DHR_Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet": "",
        "DHR_Cau_hoi_phong_van": "",
        "DHR_Cham_cong_chi_tiet": "",
        "DHR_Cham_cong_theo_du_an": "",
        "DHR_Cham_diem_theo_tieu_chi": "",
        "DHR_Cham_tien_do": "",
        "DHR_Chao_buoi_sang": "",
        "DHR_Chao_mung_ban": "",
        "DHR_Chao_mung_login": "",
        "DHR_Che_do_bao_hiem_duoc_duyet": "",
        "DHR_Che_do_con_nho": "",
        "DHR_Che_do_sinh_con_nho": "",
        "DHR_Che_do_tro_cap": "",
        "DHR_Chi_con_lai_can_quyet_toan": "",
        "DHR_Chi_dinh_lam_quan_tri_vien": "",
        "DHR_Chi_dinh_lam_thanh_vien": "",
        "DHR_Chi_dinh_lam_thanh_vien_vai_tro_quan_tri": "",
        "DHR_Chi_dinh_thanh_vien_vai_tro_quan_tri": "",
        "DHR_Chi_hien_thi_du_lieu_da_chon": "",
        "DHR_Chi_nguoi_phong_van": "",
        "DHR_Chi_phi": "コスト",
        "DHR_Chi_phi_binh_quan": "",
        "DHR_Chi_phi_con_lai_can_quyet_toan": "",
        "DHR_Chi_phi_cong_tac": "Bussiness cost",
        "DHR_Chi_phi_tam_ung": "",
        "DHR_Chi_tam_ung": "",
        "DHR_Chi_tiet": "詳細",
        "DHR_Chi_tiet_bang_muc_tieu": "",
        "DHR_Chi_tiet_cong_cu_lao_dong": "",
        "DHR_Chi_tiet_dieu_chinh": "",
        "DHR_Chi_tiet_dieu_chinh_thong_tin_gia_dinh": "",
        "DHR_Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD": "",
        "DHR_Chi_tiet_nhan_vien": "",
        "DHR_Chi_tiet_nhom": "",
        "DHR_Chi_tiet_phep": "",
        "DHR_Chi_tiet_phieu": "Voucher Detail",
        "DHR_Chi_tiet_thong_tin_ung_tuyen_cua_ung_vien": "",
        "DHR_Chi_tiet_tin_tuyen_dung": "",
        "DHR_Chi_tiet_xep_loai_danh_gia_nhan_vien": "",
        "DHR_Chi_tieu": "Target",
        "DHR_Chi_ung_vien": "",
        "DHR_Chinh_sua": "",
        "DHR_Chinh_sua_tang_ca_thanh_cong": "",
        "DHR_Chinh_sua_thong_tin_tang_ca": "",
        "DHR_Chinh_sua_thong_tin_tuyen_dung": "",
        "DHR_Chinh_sua_tin_tuyen_dung": "",
        "DHR_Chinh_tri": "",
        "DHR_Cho_duyet": "承認待ち",
        "DHR_Cho_phep": "",
        "DHR_Cho_phep_cap_nhat_nhom_muc_tieu_da_duyet": "",
        "DHR_Cho_phep_nhan_vien_dieu_chinh_chon_so": "",
        "DHR_Cho_phep_nhan_vien_huy_muc_tieu": "",
        "DHR_Cho_phep_sao_chep_ket_qua_cua_cap_truoc": "",
        "DHR_Cho_tat_ca_nhan_vien": "",
        "DHR_Chon": "Select",
        "DHR_Chon_CCLD": "",
        "DHR_Chon_ban_giao": "",
        "DHR_Chon_bao_cao": "",
        "DHR_Chon_co_cau_to_chuc": "",
        "DHR_Chon_du_an": "",
        "DHR_Chon_giai_doan": "",
        "DHR_Chon_ky": "",
        "DHR_Chon_ky_luong": "",
        "DHR_Chon_lai_mau_email_truoc_khi_gui": "",
        "DHR_Chon_mau_bao_cao": "",
        "DHR_Chon_mau_mail_co_san": "",
        "DHR_Chon_mau_mail_gui_cho_ung_vien": "",
        "DHR_Chon_ngay": "",
        "DHR_Chon_ngay_den": "",
        "DHR_Chon_ngay_tu": "",
        "DHR_Chon_nguoi_duyet_thay_the": "",
        "DHR_Chon_nguoi_phong_van": "",
        "DHR_Chon_nhan_vien": "",
        "DHR_Chon_nhom_luong": "",
        "DHR_Chon_nhom_nhan_vien": "",
        "DHR_Chon_phong_ban": "",
        "DHR_Chon_tat_ca": "",
        "DHR_Chon_tu_danh_muc_muc_tieu": "",
        "DHR_Chu_de": "",
        "DHR_Chu_ky": "",
        "DHR_Chu_nhat": "",
        "DHR_Chu_so_huu": "",
        "DHR_Chua_chon_ung_vien": "",
        "DHR_Chua_chuyen_ket_qua": "",
        "DHR_Chua_co_lich_phong_van_nao": "",
        "DHR_Chua_co_lien_ket_nao": "",
        "DHR_Chua_dap_ung": "",
        "DHR_Chua_dat": "",
        "DHR_Chua_duoc_nhap_ban_co_muon_luu_khong": "",
        "DHR_Chua_hoan_thanh": "",
        "DHR_Chua_thuoc_tin_tuyen_dung_nao": "",
        "DHR_Chua_tinh_ket_qua": "",
        "DHR_Chuc_danh": "",
        "DHR_Chuc_danh_cong_viec": "",
        "DHR_Chuc_danh_kiem_nhiem": "",
        "DHR_Chuc_mung_sinh_nhat": "",
        "DHR_Chuc_vu": "位置",
        "DHR_Chuc_vu_bat_buoc_nhap": "",
        "DHR_Chuc_vu_kiem_nhiem": "",
        "DHR_Chuc_vu_moi": "",
        "DHR_Chung_minh_nhan_dan": "",
        "DHR_Chung_minh_thu_ho_chieu": "",
        "DHR_Chuyen_mon": "",
        "DHR_Chuyen_nganh": "",
        "DHR_Chuyen_tiep": "",
        "DHR_Chuyen_tin_tuyen_dung": "Chuyển tin tuyển dụng",
        "DHR_Chuyen_tin_tuyen_dung_thanh_cong": "Chuyển tin tuyển dụng thành công",
        "DHR_Co": "貸方",
        "DHR_Co1": "",
        "DHR_Co_cau_chuc_danh": "",
        "DHR_Co_cau_to_chuc": "",
        "DHR_Co_du_lieu_da_duoc_sua_Ban_co_muon_tiep_tuc?": "",
        "DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "DHR_Co_review": "",
        "DHR_Co_xet_duyet": "",
        "DHR_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "",
        "DHR_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "",
        "DHR_Con_thong_tin_truong": "",
        "DHR_Con_truong": "",
        "DHR_Cong_cu_lao_dong": "",
        "DHR_Cong_hanh_chinh_trong_ngay": "",
        "DHR_Cong_thuc": "",
        "DHR_Cong_trinh/Cong_ty": "",
        "DHR_Cong_trinh_Cong_ty": "",
        "DHR_Cong_ty": "会社",
        "DHR_Cong_ty_thanh_toan": "",
        "DHR_Cong_viec": "仕事",
        "DHR_Cong_viec_moi": "",
        "DHR_Cong_viec_truoc_day": "",
        "DHR_Cu": "",
        "DHR_DRH_Ca_mac_dinh": "",
        "DHR_DRH_Danh_sach_phong_ban": "",
        "DHR_DRH_Hoc_van_va_chuyen_mon": "",
        "DHR_DRH_Ten_phong_ban_EN": "",
        "DHR_Da_chot": "",
        "DHR_Da_chuyen": "",
        "DHR_Da_chuyen1": "",
        "DHR_Da_chuyen_ket_qua": "",
        "DHR_Da_chuyen_kq": "",
        "DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu": "",
        "DHR_Da_dien_ra": "",
        "DHR_Da_duyet": "承認された",
        "DHR_Da_hoan_thanh": "",
        "DHR_Da_huy_thanh_cong": "",
        "DHR_Da_nghi_viec": "",
        "DHR_Da_sao_chep_CCLD": "",
        "DHR_Da_sao_chep_cap_phat_truoc": "",
        "DHR_Da_tinh": "",
        "DHR_Da_tinh_ket_qua": "",
        "DHR_Da_ton_tai_lich_phong_van_cua_ung_cu_vien_ban_co_muon_xoa_khong": "",
        "DHR_Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong": "",
        "DHR_Da_xac_nhan": "",
        "DHR_Da_xay_ra_loi,_khong_the_luu_du_lieu": "",
        "DHR_Dan_quyen": "",
        "DHR_Dan_toc": "民族",
        "DHR_Dang_Lam_Viec": "",
        "DHR_Dang_chuan_bi_du_lieu_Xin_vui_long_cho": "",
        "DHR_Dang_cong_khai": "",
        "DHR_Dang_cong_khai_W25DESC": "",
        "DHR_Dang_ky_cong_tac": "",
        "DHR_Dang_ky_di_tre_ve_som": "",
        "DHR_Dang_ky_gio_tang_ca_thuc_te": "",
        "DHR_Dang_ky_muc_tieu": "",
        "DHR_Dang_ky_nghi": "",
        "DHR_Dang_ky_nghi_viec": "",
        "DHR_Dang_ky_quyet_toan_chi_phi_cong_tac": "",
        "DHR_Dang_ky_sau": "Registration after",
        "DHR_Dang_ky_tang_ca": "Overtime Registration",
        "DHR_Dang_ky_tang_ca_ho": "Đăng ký tăng ca hộ",
        "DHR_Dang_ky_tang_ca_thanh_cong": "",
        "DHR_Dang_ky_thai_san": "",
        "DHR_Dang_ky_truoc": "",
        "DHR_Dang_ky_truoc_d": "Registration before",
        "DHR_Dang_nhap": "ログイン",
        "DHR_Dang_nhap_lai2": "",
        "DHR_Dang_noi_bo": "",
        "DHR_Dang_noi_bo_W25DESC": "",
        "DHR_Dang_review": "",
        "DHR_Dang_tham_gia": "",
        "DHR_Dang_thuc_hien": "",
        "DHR_Dang_tin_tuyen_dung": "",
        "DHR_Dang_xuat": "",
        "DHR_Dang_xuat1": "",
        "DHR_Danh_gia": "",
        "DHR_Danh_gia_chung": "",
        "DHR_Danh_gia_hop_dong_thu_viec": "",
        "DHR_Danh_gia_nhan_vien": "",
        "DHR_Danh_gia_nhan_vien_dinh_ky": "Đánh giá nhân viên định kỳ",
        "DHR_Danh_gia_nhan_vien_het_han_HDLD": "",
        "DHR_Danh_gia_phong_ban": "",
        "DHR_Danh_muc_loai_chi_phi": "",
        "DHR_Danh_sach_bang_tinh_KQTH": "",
        "DHR_Danh_sach_bang_tong_hop": "",
        "DHR_Danh_sach_cap_phat_thiet_bi_lao_dong": "",
        "DHR_Danh_sach_duyet_bang_muc_tieu": "",
        "DHR_Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan": "",
        "DHR_Danh_sach_khen_thuong": "",
        "DHR_Danh_sach_ky_luat": "",
        "DHR_Danh_sach_muc_tieu": "",
        "DHR_Danh_sach_nhan_vien": "従業員のリスト",
        "DHR_Danh_sach_nhan_vien_danh_gia": "",
        "DHR_Danh_sach_nhan_vien_tham_gia": "",
        "DHR_Danh_sach_phong_ban": "",
        "DHR_Danh_sach_tin_da_dang": "",
        "DHR_Danh_sach_tin_tuyen_dung": "",
        "DHR_Danh_sach_trang_thiet_bi_lao_dong": "",
        "DHR_Danh_sach_ung_vien": "",
        "DHR_Danh_sach_vai_tro": "",
        "DHR_Dap_ung": "",
        "DHR_Dat": "Pass",
        "DHR_De_nghi_tuyen": "",
        "DHR_De_nghi_tuyen_ung_vien_nay": "",
        "DHR_De_xuat_dao_tao": "",
        "DHR_De_xuat_dieu_chinh_lao_dong": "",
        "DHR_De_xuat_dieu_chinh_luong": "",
        "DHR_De_xuat_khen_thuong": "",
        "DHR_De_xuat_kiem_nhiemU": "",
        "DHR_De_xuat_ky_luat": "",
        "DHR_De_xuat_offer": "",
        "DHR_De_xuat_thay_doi_chuc_danh": "",
        "DHR_De_xuat_tuyen_dung": "",
        "DHR_Deadline": "",
        "DHR_Den": "まで",
        "DHR_Den_ngay": "Date to",
        "DHR_Di_dong": "携帯電話",
        "DHR_Di_tre": "",
        "DHR_Di_xe": "",
        "DHR_Dia_chi": "住所",
        "DHR_Dia_chi_lien_lac": "連絡先住所",
        "DHR_Dia_chi_tam_tru": "一時アドレス",
        "DHR_Dia_chi_thuong_tru": "本籍地",
        "DHR_Dia_diem": "",
        "DHR_Dia_diem_phong_van": "",
        "DHR_Diem": "",
        "DHR_Diem_den": "",
        "DHR_Diem_di": "",
        "DHR_Diem_ky_vong": "",
        "DHR_Diem_so_khong_hop_le": "",
        "DHR_Diem_trung_binh": "",
        "DHR_Dien_giai": "説明",
        "DHR_Dien_thoai": "電話",
        "DHR_Dien_thoai_noi_bo": "",
        "DHR_Dieu_chinh": "",
        "DHR_Dieu_chinh_luong": "",
        "DHR_Dieu_chinh_nguoi_phu_trach": "",
        "DHR_Dieu_chinh_thong_tin": "",
        "DHR_Dieu_chinh_thong_tin_gia_dinh": "",
        "DHR_Dieu_kien_tinh_huong": "",
        "DHR_Dinh_dang_file_khong_hop_le": "",
        "DHR_Dinh_dang_mau_khong_hop_le.Cho_phep_cac_loai_mau_.xlxs_.docx_.html": "",
        "DHR_Dinh_dang_ngay_khong_dung": "",
        "DHR_Dinh_kem": "Attachments",
        "DHR_Dinh_vi_khoa": "",
        "DHR_Do_tuoi": "年齢",
        "DHR_Do_uu_tien": "",
        "DHR_Doanh_nghiep": "ビジネス",
        "DHR_Doi_mat_khau": "",
        "DHR_Doi_mat_khau_thanh_cong": "",
        "DHR_Doi_tuong": "オブジェクト",
        "DHR_Doi_tuong_ap_dung": "",
        "DHR_Doi_tuong_cong_phep": "Leave object",
        "DHR_Doi_tuong_lao_dong": "",
        "DHR_Doi_tuong_tinh_luong": "",
        "DHR_Don_tu": "",
        "DHR_Don_tu_cua_toi": "",
        "DHR_Don_tu_toi_duyet": "",
        "DHR_Don_ung_tuyen": "",
        "DHR_Don_vi": "単位",
        "DHR_Don_vi_da_lam_viec": "Đơn vị đã làm việc",
        "DHR_Don_vi_moi": "",
        "DHR_Don_vi_tinh": "",
        "DHR_Dong1": "",
        "DHR_Dong_W25DESC": "",
        "DHR_Dong_y": "",
        "DHR_Du_an": "Project",
        "DHR_Du_an_cong_trinh": "",
        "DHR_Du_an_moi": "",
        "DHR_Du_lieu": "データ",
        "DHR_Du_lieu_ca_nhan,_ban_khong_co_quyen_xem": "",
        "DHR_Du_lieu_cham_phep": "Record Leave Data",
        "DHR_Du_lieu_chua_duoc_luu._Ban_co_muon_luu?": "",
        "DHR_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "DHR_Du_lieu_da_bi_thay_doi_ban_co_muon_luu_khong": "",
        "DHR_Du_lieu_dac_biet": "",
        "DHR_Du_lieu_dau_vao_la_bat_buoc": "",
        "DHR_Du_lieu_phep_tong_hop": "",
        "DHR_Du_lieu_xuat_excel_rong": "",
        "DHR_Dung_luong_File_khong_duoc_lon_hon": "",
        "DHR_Duyet": "承認",
        "DHR_Duyet_bang_muc_tieu": "",
        "DHR_Duyet_bo_sung_thoi_gian_vao_ra": "",
        "DHR_Duyet_chung": "",
        "DHR_Duyet_cong_tac": "",
        "DHR_Duyet_dang_ky_tang_ca_ho": "",
        "DHR_Duyet_danh_gia_nhan_vien_va_HDLD": "",
        "DHR_Duyet_de_xuat_chinh_sach_che_do": "",
        "DHR_Duyet_de_xuat_dao_tao": "",
        "DHR_Duyet_de_xuat_dieu_chuyen_lao_dong": "",
        "DHR_Duyet_de_xuat_khen_thuong": "",
        "DHR_Duyet_de_xuat_kiem_nhiem": "",
        "DHR_Duyet_de_xuat_nghi_viec": "",
        "DHR_Duyet_de_xuat_offer": "",
        "DHR_Duyet_de_xuat_thay_doi_chuc_danh": "",
        "DHR_Duyet_de_xuat_tuyen_dung": "",
        "DHR_Duyet_di_tre_ve_som": "",
        "DHR_Duyet_dieu_chinh_luong": "",
        "DHR_Duyet_dieu_chinh_thong_tin": "",
        "DHR_Duyet_dieu_chinh_thu_nhap": "",
        "DHR_Duyet_gio_tang_ca_thuc_te": "",
        "DHR_Duyet_gio_tang_ca_thuc_te_hang_loat": "",
        "DHR_Duyet_gio_vao_ra": "",
        "DHR_Duyet_hang_loat": "",
        "DHR_Duyet_ket_qua_muc_tieu_ca_nhan": "",
        "DHR_Duyet_khen_thuong_hang_loat": "",
        "DHR_Duyet_khong_thanh_cong": "",
        "DHR_Duyet_ky_luat": "",
        "DHR_Duyet_ky_luat_hang_loat": "",
        "DHR_Duyet_mien_nhiem": "",
        "DHR_Duyet_muc_tieu": "",
        "DHR_Duyet_nghi_phep": "",
        "DHR_Duyet_nghi_thai_san": "",
        "DHR_Duyet_quyet_toan_chi_phi": "",
        "DHR_Duyet_quyet_toan_chi_phi_cong_tac": "",
        "DHR_Duyet_sai_sot_cong": "",
        "DHR_Duyet_tang_ca": "",
        "DHR_Duyet_tang_ca_hang_loat": "",
        "DHR_Duyet_thanh_cong": "",
        "DHR_Email": "メール",
        "DHR_Email_ca_nhan": "",
        "DHR_Email_gui_den_ung_vien": "",
        "DHR_Email_ho_tro": "",
        "DHR_Email_khong_hop_le": "",
        "DHR_Email_tu_dong": "",
        "DHR_Export_danh_sach_ung_vien": "",
        "DHR_Facebook_profile": "",
        "DHR_File_da_duoc_dinh_kem": "",
        "DHR_File_khong_dung_dinh_dang": "",
        "DHR_File_vuot_qua_dung_luong_cho_phep": "",
        "DHR_File_vuot_qua_so_luong_cho_phep": "",
        "DHR_Form_id": "",
        "DHR_Gan_ca": "",
        "DHR_Gan_ca_lam_viec": "",
        "DHR_Gan_ca_mac_dinh": "",
        "DHR_Gan_ca_xoay_vong": "",
        "DHR_Ghi_chep_noi_bo": "",
        "DHR_Ghi_chu": " 言い草",
        "DHR_Ghi_chu_duyet": "Approve Notes",
        "DHR_Ghi_chu_huy": "",
        "DHR_Ghi_chu_phong_van": "",
        "DHR_Ghi_chu_rieng_tu_nguoi_tao_lich_phong_van": "",
        "DHR_Ghi_chu_rieng_tu_tu_nguoi_tao_phong_van_voi_ung_vien_nay": "",
        "DHR_Ghi_chu_sau_thai_san": "",
        "DHR_Ghi_chu_tu_nguoi_tao_phong_van": "",
        "DHR_Ghi_chu_xac_nhan": "",
        "DHR_Gia_dinh": "",
        "DHR_Gia_tri": "",
        "DHR_Gia_tri_bat_buoc_nhap": "",
        "DHR_Gia_tri_lon_nhat": "",
        "DHR_Gia_tri_tu_phai_nho_hon_gia_tri_den": "",
        "DHR_Giai_doan": "",
        "DHR_Giai_doan1": "",
        "DHR_Giai_doanF": "",
        "DHR_Giai_doan_hien_co": "",
        "DHR_Giai_trinh_nguyen_nhan": "",
        "DHR_Giay_phep_lao_dong": "",
        "DHR_Gio_cong": "",
        "DHR_Gio_di_ca": "",
        "DHR_Gio_nghi_giua_ca": "",
        "DHR_Gio_phong_van": "",
        "DHR_Gio_ra": "去る",
        "DHR_Gio_tang_ca": "",
        "DHR_Gio_tang_ca_den": "",
        "DHR_Gio_tang_ca_khong_hop_le": "",
        "DHR_Gio_tang_ca_tu": "",
        "DHR_Gio_vao": "入る",
        "DHR_Gio_vao_ra": "",
        "DHR_Gioi_han_toi_da_tong_trong_so_muc_tieu": "",
        "DHR_Gioi_thieu": "",
        "DHR_Gioi_thieu_ve_nhom": "",
        "DHR_Gioi_tinh": "性別",
        "DHR_Go_vai_tro_quan_tri_vien": "",
        "DHR_Gui": "Send",
        "DHR_Gui_email": "Send Mail",
        "DHR_Gui_lai_ma": "",
        "DHR_Gui_mail": "",
        "DHR_Gui_mail_thanh_cong": "Send mail success",
        "DHR_Gui_thanh_cong": "",
        "DHR_HOP_DONG_LAO_DONG": "",
        "DHR_Hai_long_can_cai_tien_them": "",
        "DHR_Han_hoan_thanh": "",
        "DHR_Han_review_cuoi": "",
        "DHR_Hang_loat": "",
        "DHR_Hanh_dong": "",
        "DHR_Hanh_vi_vi_pham_ky_luat": "",
        "DHR_He_so": "",
        "DHR_He_so_luong": "Salary Coefficient",
        "DHR_He_so_luong_%p": "",
        "DHR_He_thong_da_gui_ma_den_email": "",
        "DHR_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "DHR_Hien_muc_luong": "",
        "DHR_Hien_thi_cac_du_lieu_da_chon": "",
        "DHR_Hien_thi_du_lieu_da_chon": "",
        "DHR_Hien_thi_khong_su_dung": "",
        "DHR_Hien_thi_tat_ca": "",
        "DHR_Hien_thi_theo_tuan": "",
        "DHR_Hien_thi_vai_tro_khong_con_su_dung": "",
        "DHR_Hinh_thuc": "タイプ",
        "DHR_Hinh_thuc_huong_luong": "",
        "DHR_Hinh_thuc_khen_thuong": "",
        "DHR_Hinh_thuc_ky_luat": "",
        "DHR_Hinh_thuc_lam_viec": "作業タイプ",
        "DHR_Hinh_thuc_nghi_viec": "",
        "DHR_Hired": "",
        "DHR_Ho_chieu": "",
        "DHR_Ho_so_bao_hiem": "",
        "DHR_Ho_so_nhan_vien": "従業員ファイル",
        "DHR_Ho_ten_ung_cu_vien": "",
        "DHR_Ho_tro": "",
        "DHR_Ho_va_ten": "姓名",
        "DHR_Hoan_tat": "完了",
        "DHR_Hoan_thanh": "",
        "DHR_Hoan_toan_hai_long": "",
        "DHR_Hoc_van_chuyen_mon": "",
        "DHR_Hoc_van_va_chuyen_mon": "",
        "DHR_Hom_nay": "今日",
        "DHR_Home_page": "",
        "DHR_Huong_dan_su_dung": "",
        "DHR_Huong_dan_xoa_nhan_vien": "",
        "DHR_Huy": "Cancel",
        "DHR_Huy_bo": "",
        "DHR_Huy_dang_ky_tang_ca": "",
        "DHR_Huy_duyet_thanh_cong": "",
        "DHR_Huy_hoan_tat": "",
        "DHR_Huy_muc_tieu": "",
        "DHR_Huy_muc_tieu_thanh_cong": "Hủy mục tiêu thành công",
        "DHR_Huy_phep": "",
        "DHR_Huy_phep_thanh_cong": "",
        "DHR_Huy_tang_ca": "",
        "DHR_In_HDLD": "",
        "DHR_KSD": "非活動",
        "DHR_Ke_hoach_dao_tao": "",
        "DHR_Ke_hoach_tong_the": "",
        "DHR_Kenh_tuyen_dung": "",
        "DHR_Keo_tap_tin_vao_day": "",
        "DHR_Keo_va_tha_tap_tin_dinh_kem_vao_day": "",
        "DHR_Keo_va_tha_tep_tin_vao_day": "",
        "DHR_Ket_qua": "Result",
        "DHR_Ket_qua_%p": "",
        "DHR_Ket_qua_danh_gia": "",
        "DHR_Ket_qua_danh_gia_chung": "",
        "DHR_Ket_qua_review": "",
        "DHR_Ket_qua_then_chot": "",
        "DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "",
        "DHR_Khac": "その他",
        "DHR_Khao_sat_y_kien_khach_hang": "",
        "DHR_Khen_thuong_hang_loat": "",
        "DHR_Kho": "",
        "DHR_Kho_thu_hoi": "",
        "DHR_Khoa_dao_tao": "Training Courses",
        "DHR_Khoan_chi": "",
        "DHR_Khoan_chi_de_nghi_quyet_toan": "",
        "DHR_Khoan_chi_phi_du_kien": "",
        "DHR_Khoan_de_nghi_quyet_toan": "",
        "DHR_Khoan_dieu_chinh_thu_nhap": "",
        "DHR_Khoang_dieu_chinh_thu_nhap": "",
        "DHR_Khoang_thoi_gian": "",
        "DHR_Khoi_la_bat_buoc_nhap": "",
        "DHR_Khong": "いいえ",
        "DHR_Khong_cho_phep": "",
        "DHR_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "DHR_Khong_co_tap_tin_dinh_kem": "",
        "DHR_Khong_co_thong_bao": "",
        "DHR_Khong_co_thong_bao_nhac_nho_nao": "",
        "DHR_Khong_co_ung_vien_duoc_chon_moi": "",
        "DHR_Khong_dat": "",
        "DHR_Khong_du_quyen": "",
        "DHR_Khong_duoc_phep_luu": "",
        "DHR_Khong_duoc_phep_nghi_qua_mot_ngay": "",
        "DHR_Khong_gui_email": "",
        "DHR_Khong_hien_muc_luong": "",
        "DHR_Khong_la_bang_tong_hop": "",
        "DHR_Khong_luu": "Not saved",
        "DHR_Khong_review": "",
        "DHR_Khong_su_dung": "非活動",
        "DHR_Khong_ten": "",
        "DHR_Khong_the_dinh_kem": "",
        "DHR_Khong_the_duyet": "",
        "DHR_Khong_tim_thay_hoac_khong_du_tt": "",
        "DHR_Khong_tim_thay_trang": "",
        "DHR_Khong_trang_thai": "",
        "DHR_Khong_xet_duyet": "",
        "DHR_Kich_co_toi_da": "",
        "DHR_Kiem_nhiem": "",
        "DHR_Kien_thuc": "",
        "DHR_Kinh_nghiem": "",
        "DHR_Kinh_nghiem_den_khong_duoc_nho_hon_kinh_nghiem_tu": "",
        "DHR_Kinh_nghiem_tu_khong_duoc_lon_hon_kinh_nghiem_den": "",
        "DHR_Ky": "期間",
        "DHR_Ky_cong": "",
        "DHR_Ky_luat_hang_loat": "",
        "DHR_Ky_luong": "",
        "DHR_Ky_nang": "スキル",
        "DHR_La_bang_tong_hop": "",
        "DHR_Lam_moi": "",
        "DHR_Lap_de_xuat_chinh_sach_che_do": "",
        "DHR_Lap_de_xuat_dao_tao": "",
        "DHR_Lap_de_xuat_dieu_chinh_thu_nhap": "",
        "DHR_Lap_de_xuat_dieu_chuyen_lao_dong": "",
        "DHR_Lap_de_xuat_khen_thuong": "",
        "DHR_Lap_de_xuat_kiem_nhiem": "",
        "DHR_Lap_de_xuat_ky_luat": "",
        "DHR_Lap_de_xuat_mien_nhiem": "",
        "DHR_Lap_de_xuat_thay_doi_chuc_danh": "",
        "DHR_Lap_de_xuat_tuyen_dung": "",
        "DHR_Lap_khen_thuong_hang_loat": "",
        "DHR_Lap_ky_luat_hang_loat": "",
        "DHR_Lap_lich_di_ca": "",
        "DHR_Lap_lich_hang_loat": "",
        "DHR_Lap_lich_tang_ca": "",
        "DHR_Lap_lich_tang_ca_hang_loat": "",
        "DHR_Lap_loai_chi_phi": "",
        "DHR_Lap_nghi_phep": "",
        "DHR_Lap_phieu_danh_gia_nhan_vien": "",
        "DHR_Lap_phieu_danh_gia_phong_ban": "",
        "DHR_Lay_ty_le_hoan_thanh_danh_gia_theo_cap": "",
        "DHR_Lich_su": "歴史",
        "DHR_Lich_su_BHXH": "Lịch sử BHXH",
        "DHR_Lich_su_dao_tao": "トレーニングの歴史",
        "DHR_Lich_su_dctt": "",
        "DHR_Lich_su_dieu_chinh": "",
        "DHR_Lich_su_dieu_chinh_thong_tin": "Lịch sử điều chỉnh thông tin",
        "DHR_Lich_su_duyet": "閲覧の履歴",
        "DHR_Lich_su_lao_dong": "Lịch sử lao động",
        "DHR_Lich_su_nhan_su": "",
        "DHR_Lich_su_tac_dong": "",
        "DHR_Linh_vuc_dao_tao": "Training Subjects",
        "DHR_Linkedin_profile": "",
        "DHR_Loai": "",
        "DHR_Loai_HDLD": "",
        "DHR_Loai_che_do": "",
        "DHR_Loai_danh_gia": "",
        "DHR_Loai_doi_tuong_ap_dung": "",
        "DHR_Loai_don": "",
        "DHR_Loai_hinh_cong_viec": "",
        "DHR_Loai_hop_dong": "契約形式",
        "DHR_Loai_hop_dong_lao_dong": "労働契約の種類",
        "DHR_Loai_mail": "",
        "DHR_Loai_muc_tieu": "",
        "DHR_Loai_ngay": "Date Type",
        "DHR_Loai_nhanh": "",
        "DHR_Loai_phan_bo": "",
        "DHR_Loai_phep": "Leave Types",
        "DHR_Loai_phieu": "ジャーナルタイプ",
        "DHR_Loai_phong_van": "",
        "DHR_Loai_phu_luc": "",
        "DHR_Loai_quy_trinh": "",
        "DHR_Loai_tach_ca": "",
        "DHR_Loai_tach_tang_ca": "",
        "DHR_Loai_tach_tang_ca1": "",
        "DHR_Loai_tang_ca": "",
        "DHR_Loai_tap_tin_ho_tro": "",
        "DHR_Loai_thu_nhap": "",
        "DHR_Loai_tien": "通貨",
        "DHR_Loc_chon_hanh_dong": "",
        "DHR_Loc_chon_nhan_vien": "",
        "DHR_Loc_trang_thiet_bi_lao_dong": "",
        "DHR_Loi_chua_xac_dinh": "",
        "DHR_Loi_khong_lay_duoc_token_CDN": "",
        "DHR_Lon_hon_hoac_bang": "",
        "DHR_Lua_chon": "",
        "DHR_Luong": "",
        "DHR_Luong_co_ban": "",
        "DHR_Luong_co_ban_%p": "",
        "DHR_Luong_cong_viec": "",
        "DHR_Luong_dong_bao_hiem": "",
        "DHR_Luong_tu_phai_nho_hon_luong_den": "",
        "DHR_Luong_yeu_cau": "",
        "DHR_Luu": "保存",
        "DHR_Luu_&_nhap_tiep": "",
        "DHR_Luu_dinh_kem_khong_thanh_cong": "",
        "DHR_Luu_khong_thanh_cong": "",
        "DHR_Luu_lich_su_khong_thanh_cong": "",
        "DHR_Luu_lich_su_thanh_cong": "",
        "DHR_Luu_thanh_cong": "",
        "DHR_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "DHR_Luu_va_cap_nhat_thanh_cong": "Lưu và cập nhật thành công",
        "DHR_Luu_va_nhap_tiep": "",
        "DHR_Ly_do": "理由",
        "DHR_Ly_do_cap_phat": "",
        "DHR_Ly_do_dao_tao": "Training Reason",
        "DHR_Ly_do_huy_dang_ky_tang_ca": "",
        "DHR_Ly_do_huy_phep": "",
        "DHR_Ly_do_tam_ung": "",
        "DHR_Ly_do_tang_ca": "",
        "DHR_Ly_do_thu_hoi": "",
        "DHR_Ly_do_tu_choi_2": "",
        "DHR_Ly_do_tuyen": "",
        "DHR_Ma": "Code",
        "DHR_Ma_CCLD": "",
        "DHR_Ma_HDLD": "",
        "DHR_Ma_NV": "従業員コード",
        "DHR_Ma_UV": "",
        "DHR_Ma_bang_muc_tieu": "",
        "DHR_Ma_bang_tong_hop": "",
        "DHR_Ma_ca": "",
        "DHR_Ma_cham_cong": "",
        "DHR_Ma_chuc_vu_moi": "",
        "DHR_Ma_cong_tac": "",
        "DHR_Ma_doi_tuong": "オブジェクトコード",
        "DHR_Ma_du_an": "",
        "DHR_Ma_hop_dong": "",
        "DHR_Ma_loai_tach": "",
        "DHR_Ma_ly_do": "",
        "DHR_Ma_nghiep_vu": "",
        "DHR_Ma_nguoi_dung": "ユーザーID",
        "DHR_Ma_nhan_vien": "従業員コード",
        "DHR_Ma_nhan_vien_Ten_nhan_vien": "",
        "DHR_Ma_nhan_vien_phu": "",
        "DHR_Ma_nhom_muc_tieu": "",
        "DHR_Ma_phieu": "",
        "DHR_Ma_phong_ban": "Department Code",
        "DHR_Ma_so_thue_ca_nhan": "個人の税コード",
        "DHR_Ma_the_cham_cong": "",
        "DHR_Ma_thong_ke": "",
        "DHR_Ma_ung_vien_Ten_ung_vien": "",
        "DHR_Ma_vai_tro": "",
        "DHR_Ma_vat_tu": "",
        "DHR_Ma_vat_tu_ten_vat_tu": "",
        "DHR_Mail_rong": "",
        "DHR_Man_hinh": "",
        "DHR_Man_hinh_mac_dinh_khi_dang_nhap": "",
        "DHR_Mat_khau": "パスワード",
        "DHR_Mat_khau_co_hieu_luc_trong_vong_5_phut": "",
        "DHR_Mat_khau_cu": "",
        "DHR_Mat_khau_khong_dung_vui_long_thu_lai": "",
        "DHR_Mat_khau_moi": "Change Password",
        "DHR_Mat_khau_phai_co_it_nhat_8_ky_tu": "",
        "DHR_Mat_khau_xac_nhan_khong_chinh_xac": "",
        "DHR_Mau_bao_cao": "レポートコード",
        "DHR_Mau_bao_cao_khong_duoc_tim_thay": "",
        "DHR_Mau_ca": "",
        "DHR_Mau_email_hoac_phong_van": "",
        "DHR_Mau_giao_dien": "",
        "DHR_Mau_mail_co_san": "",
        "DHR_Mau_sac": "",
        "DHR_Mau_sac_hien_thi": "",
        "DHR_Menu_id": "",
        "DHR_Mo_rong": "",
        "DHR_Mo_ta": "",
        "DHR_Mo_ta_cong_viec": "",
        "DHR_Mo_ta_ly_do_khac": "",
        "DHR_Mo_ta_mau_sac": "",
        "DHR_Mo_tat_ca": "",
        "DHR_Moi": "",
        "DHR_Moi_bo_sung": "",
        "DHR_Mot_thang_truoc": "",
        "DHR_Mot_tuan_truoc": "",
        "DHR_Muc_dich_cong_tac": "",
        "DHR_Muc_do_dong_y": "",
        "DHR_Muc_luong": "Salary",
        "DHR_Muc_luong_den": "",
        "DHR_Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu": "",
        "DHR_Muc_luong_du_kien": "",
        "DHR_Muc_luong_tu": "",
        "DHR_Muc_luong_tu_khong_duoc_lon_muc_luong_den": "",
        "DHR_Muc_luong_va_phu_cap": "Mức lương và phụ cấp",
        "DHR_Muc_tieu1": "",
        "DHR_Muc_tieu_ca_nhan": "",
        "DHR_Muc_tieu_co_cau_to_chuc": "",
        "DHR_Muc_tieu_kiem_nhiem": "",
        "DHR_Muc_tieu_nhan_vien": "",
        "DHR_NV_chua_tham_gia_khoa_dao_tao": "",
        "DHR_Nam": "Year",
        "DHR_Nam1": "",
        "DHR_NamU": "男性",
        "DHR_Nam_sinh": "生年",
        "DHR_Ngan_hang": "",
        "DHR_Ngay": "年月日 \t",
        "DHR_Ngay1": "",
        "DHR_Ngay3": "",
        "DHR_Ngay_bat_dau": "開始日",
        "DHR_Ngay_bat_dau_nghi_viec": "",
        "DHR_Ngay_bat_dau_thu_viec": "",
        "DHR_Ngay_bo_sung": "",
        "DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac": "",
        "DHR_Ngay_cap": "発行日",
        "DHR_Ngay_cap_BHXH": "",
        "DHR_Ngay_cap_CMND": "",
        "DHR_Ngay_cap_ho_chieu": "",
        "DHR_Ngay_cap_phat": "",
        "DHR_Ngay_cap_phat_CCLD_truoc": "",
        "DHR_Ngay_cap_the_can_cuoc": "",
        "DHR_Ngay_cham_cong": "",
        "DHR_Ngay_chot_van_tay": "",
        "DHR_Ngay_cong": "",
        "DHR_Ngay_da_nghi_phep": "",
        "DHR_Ngay_dang_ky_KCB": "",
        "DHR_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "",
        "DHR_Ngay_danh_gia": "",
        "DHR_Ngay_danh_gia_bat_buoc_nhap": "",
        "DHR_Ngay_de_xuat": "Proposal Date",
        "DHR_Ngay_de_xuat_tam_ung": "",
        "DHR_Ngay_den": "",
        "DHR_Ngay_di": "",
        "DHR_Ngay_di/ve": "",
        "DHR_Ngay_di_/_ve": "",
        "DHR_Ngay_di_ca": "",
        "DHR_Ngay_di_lam_du_kien": "",
        "DHR_Ngay_di_lam_thuc_te": "",
        "DHR_Ngay_dieu_chinh": "",
        "DHR_Ngay_du_sinh": "",
        "DHR_Ngay_duyet": "承認年月日",
        "DHR_Ngay_het_han": "有効期限",
        "DHR_Ngay_het_han_ho_chieu": "",
        "DHR_Ngay_hieu_luc": "有効な日付",
        "DHR_Ngay_hieu_luc_den": "",
        "DHR_Ngay_hieu_luc_tu": "",
        "DHR_Ngay_hoan_thanh": "Finish Date",
        "DHR_Ngay_ket_thuc": "終了日",
        "DHR_Ngay_ket_thuc_che_do": "",
        "DHR_Ngay_ket_thuc_thu_viec": "",
        "DHR_Ngay_khoi_hanh": "",
        "DHR_Ngay_ky_phu_luc": "",
        "DHR_Ngay_lam_viec": "Working Date",
        "DHR_Ngay_lap": "Created Date",
        "DHR_Ngay_nghi": "",
        "DHR_Ngay_nghi_viec": "",
        "DHR_Ngay_nhan": "Received Date",
        "DHR_Ngay_nhan_ho_so": "",
        "DHR_Ngay_nop_don": "",
        "DHR_Ngay_phep": "Leave date",
        "DHR_Ngay_phieu": "バウチャー日",
        "DHR_Ngay_phong_van": "Interview Date",
        "DHR_Ngay_sinh": "誕生日",
        "DHR_Ngay_sinh_con": "",
        "DHR_Ngay_tac_dong": "",
        "DHR_Ngay_tach_tang_ca": "Ngày tách tăng ca",
        "DHR_Ngay_tang_ca": "",
        "DHR_Ngay_tao": "",
        "DHR_Ngay_tham_gia_BHXH": "",
        "DHR_Ngay_tham_gia_bao_hiem": "",
        "DHR_Ngay_thu_hoi": "",
        "DHR_Ngay_thuc_hien": "",
        "DHR_Ngay_tu": "",
        "DHR_Ngay_vao": "開始日",
        "DHR_Ngay_vao_lam": "開始日",
        "DHR_Ngay_vao_lam_du_kien": "",
        "DHR_Ngay_ve": "",
        "DHR_Ngay_xin_nghi_viec": "",
        "DHR_Ngay_xin_thoi_viec": "",
        "DHR_Nghi_phep": "",
        "DHR_Nghi_phep_trong_ngay": "",
        "DHR_Nghi_thai_san": "",
        "DHR_Nghi_viec": "",
        "DHR_Nghiep_vu": "",
        "DHR_Ngoai_te": "",
        "DHR_Nguoi_PV": "Interviewer",
        "DHR_Nguoi_dai_dien": "Agency",
        "DHR_Nguoi_danh_gia": "",
        "DHR_Nguoi_danh_gia_bat_buoc_nhap": "",
        "DHR_Nguoi_dao_tao": "",
        "DHR_Nguoi_dieu_chinh": "",
        "DHR_Nguoi_dung": "ユーザー",
        "DHR_Nguoi_duyet": "承認者",
        "DHR_Nguoi_lap": "によって作成された",
        "DHR_Nguoi_lien_he": "",
        "DHR_Nguoi_lien_he_so_1": "",
        "DHR_Nguoi_lien_he_so_2": "",
        "DHR_Nguoi_nhan_email": "",
        "DHR_Nguoi_nuoc_ngoai": "",
        "DHR_Nguoi_phong_van": "",
        "DHR_Nguoi_phu_trach": "",
        "DHR_Nguoi_quan_ly": "",
        "DHR_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "DHR_Nguoi_tao": "",
        "DHR_Nguoi_tao_tin": "",
        "DHR_Nguoi_thuc_hien": "",
        "DHR_Nguoi_uy_quyen": "",
        "DHR_Nguoi_xem_phong_van": "",
        "DHR_Nguon": "",
        "DHR_Nguon_ung_tuyen": "",
        "DHR_Nguon_ung_vien": "",
        "DHR_Nguyen_quan": "",
        "DHR_Nhac_lich": "",
        "DHR_Nhac_nho1": "",
        "DHR_Nhan_Vien_Chua_co_ca": "",
        "DHR_Nhan_vao_de_tai_hinh_hoac_keo_tha_hinh_vao_day": "",
        "DHR_Nhan_vien": "",
        "DHR_Nhan_vien_chua_duoc_cap_phat_CCLD": "",
        "DHR_Nhan_vien_da_duoc_thu_hoi_ccld_khong_duoc_phep_xoa": "",
        "DHR_Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen": "",
        "DHR_Nhan_vien_tu_danh_gia_het_HDLD": "",
        "DHR_Nhan_xet_chung": "",
        "DHR_Nhan_xet_ung_vien": "",
        "DHR_Nhap": "",
        "DHR_Nhap_": "",
        "DHR_Nhap_Ten_Dang_Nhap": "Enter username",
        "DHR_Nhap_W25DESC": "",
        "DHR_Nhap_de_tim_kiem": "",
        "DHR_Nhap_email": "",
        "DHR_Nhap_ma_bao_mat": "",
        "DHR_Nhap_mat_khau": "パスワードを入力",
        "DHR_Nhap_tiep": "",
        "DHR_Nhap_tu_tim_kiem": "",
        "DHR_Nhat_ky_cong_tac": "",
        "DHR_Nhieu_ngay": "",
        "DHR_Nho_hon_hoac_bang1": "",
        "DHR_Nho_mat_khau": "",
        "DHR_Nho_mat_khau1": "",
        "DHR_Nhom": "Groups",
        "DHR_Nhom_chi_tieu": "",
        "DHR_Nhom_hanh_vi_vi_pham_ky_luat": "",
        "DHR_Nhom_luong": "",
        "DHR_Nhom_muc_tieu": "",
        "DHR_Nhom_nhan_vien": "",
        "DHR_Nhung_nguoi_co_quyen_xem_toan_bo_TTUV_va_xu_ly_quy_trinh_tuyen_dung": "",
        "DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "DHR_No": "借方",
        "DHR_No_data": "",
        "DHR_Noi_bo": "",
        "DHR_Noi_cap": "発行場所",
        "DHR_Noi_cap_BHXH": "",
        "DHR_Noi_cap_CMND": "",
        "DHR_Noi_cap_han_ho_chieu": "",
        "DHR_Noi_cap_ho_chieu": "Passport Place",
        "DHR_Noi_cap_the_can_cuoc": "",
        "DHR_Noi_dang_ky_KCB": "",
        "DHR_Noi_dung": "内容",
        "DHR_Noi_dung_can_tim": "",
        "DHR_Noi_dung_can_tim_kiem": "",
        "DHR_Noi_dung_dao_tao": "",
        "DHR_Noi_dung_mail_khong_duoc_de_trong": "",
        "DHR_Noi_dung_nhat_ky": "",
        "DHR_Noi_dung_tim_kiem": "",
        "DHR_Noi_dung_tin_tuyen_dung": "",
        "DHR_Noi_sinh": "出生地",
        "DHR_Nu": "",
        "DHR_Nuoc_ngoai": "",
        "DHR_Phai_chon_it_nhat_1_trang_thai": "",
        "DHR_Phai_la_so_lon_hon_hoac_bang_khong": "",
        "DHR_Phai_la_so_lon_hon_khong": "",
        "DHR_Phai_la_so_nguyen_duong": "",
        "DHR_Phai_lon_hon_gia_tri_tu": "",
        "DHR_Phai_nhap_so_phut_cho_it_nhat_mot_loai_xin_phep": "",
        "DHR_Phai_trong_khoang_tu_0_den_100": "",
        "DHR_Phan_bo_chi_phi": "",
        "DHR_Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Phan_bo_toan_tu": "",
        "DHR_Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Phan_bo_toan_tu_theo_tung_du_lieu_luong": "",
        "DHR_Phan_quyen": "",
        "DHR_Phan_quyen_chuc_nang": "",
        "DHR_Phan_quyen_du_lieu": "",
        "DHR_Phan_quyen_ess": "",
        "DHR_Phan_quyen_tat_ca": "",
        "DHR_Phan_quyen_theo_vai_tro": "",
        "DHR_Phan_tram_hoan_thanh": "",
        "DHR_Phep": "休暇",
        "DHR_Phep_bu": "",
        "DHR_Phep_con_lai": "",
        "DHR_Phep_da_nghi": "",
        "DHR_Phep_da_nghi_/_het_han": "Phép đã nghỉ / hết hạn",
        "DHR_Phep_het_han_chua_su_dung": "Phép hết hạn chưa sử dụng",
        "DHR_Phep_nam": "",
        "DHR_Phep_tham_nien": "",
        "DHR_Phep_ton": "",
        "DHR_Phien_ban": "",
        "DHR_Phieu_KCB_den_ngay": "",
        "DHR_Phieu_KCB_tu_ngay": "",
        "DHR_Phieu_cham_cong": "",
        "DHR_Phieu_cua_toi": "",
        "DHR_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "",
        "DHR_Phieu_danh_gia": "",
        "DHR_Phieu_danh_gia_nhan_vien": "",
        "DHR_Phieu_huy": "",
        "DHR_Phong_ban": "部門",
        "DHR_Phong_ban_moi": "",
        "DHR_Phong_van": "",
        "DHR_Phong_van_online": "",
        "DHR_Phong_van_qua_dien_thoai": "",
        "DHR_Phong_van_tai_van_phong": "",
        "DHR_Phu_luc": "",
        "DHR_Phuong": "",
        "DHR_Phuong_phap_danh_gia": "",
        "DHR_Phuong_phap_dieu_chinh_luong": "",
        "DHR_Phuong_phap_do": "",
        "DHR_Phuong_phap_tinh": "",
        "DHR_Qua_han": "",
        "DHR_Quan": "",
        "DHR_Quan_he": "関係",
        "DHR_Quan_he_gia_dinh": "家族関係",
        "DHR_Quan_ly": "",
        "DHR_Quan_ly_cong_tac_va_chi_phi": "",
        "DHR_Quan_tri": "",
        "DHR_Quan_tri_vien": "",
        "DHR_Que_quan": "故郷",
        "DHR_Quen_mat_khau": "",
        "DHR_Quoc_gia": "",
        "DHR_Quoc_tich": "",
        "DHR_Quy": "Quater",
        "DHR_Quy_doi_phep": "",
        "DHR_Quy_nay": "",
        "DHR_Quy_trinh": "",
        "DHR_Quy_trinh_ap_dung": "",
        "DHR_Quy_trinh_tao_muc_tieu": "",
        "DHR_Quy_trinh_tuyen_dung_Workflow": "",
        "DHR_Quy_truoc": "",
        "DHR_Quy_uoc_nhu_sau": "",
        "DHR_Quyen_han": "",
        "DHR_Quyet_toan": "",
        "DHR_Ra_som": "",
        "DHR_Review_muc_tieu": "",
        "DHR_Roi_khoi_nhom": "",
        "DHR_SDT_ho_tro": "",
        "DHR_SLA": "",
        "DHR_STT": "次",
        "DHR_Sai_sot_cong": "",
        "DHR_Sang_loc_ung_vien": "",
        "DHR_Sao_chep_CCLD": "",
        "DHR_Sao_chep_cap_phat_truoc": "",
        "DHR_Sao_chep_quyen": "",
        "DHR_Sao_chep_thanh_cong": "",
        "DHR_Sao_chep_tin_dang": "",
        "DHR_Sau_thai_san:": "",
        "DHR_Scheduled": "",
        "DHR_Sinh_nhat": "",
        "DHR_Size_ao": "",
        "DHR_Size_giay": "",
        "DHR_Size_quan": "",
        "DHR_Sl_nam": "",
        "DHR_Sl_nu": "",
        "DHR_So": "数",
        "DHR_So_CMND": "IDカード番号",
        "DHR_So_Fax": "",
        "DHR_So_HDLD": "Labor Contract No",
        "DHR_So_bao_hiem": "",
        "DHR_So_can_cuoc": "",
        "DHR_So_cap_review": "",
        "DHR_So_cong": "",
        "DHR_So_cong_va_so_phep_phai_co_gia_tri": "",
        "DHR_So_dien_thoai": "Tel no.",
        "DHR_So_dien_thoai_khong_hop_le": "",
        "DHR_So_dong": "",
        "DHR_So_gio": "",
        "DHR_So_gio_dao_tao": "",
        "DHR_So_gio_tang_ca": "",
        "DHR_So_gio_vuot": "",
        "DHR_So_ho_chieu": "",
        "DHR_So_luong": "数量",
        "DHR_So_luong_can_tuyen": "",
        "DHR_So_luong_cho_phep": "",
        "DHR_So_luong_cong": "",
        "DHR_So_luong_da_nghi_den_ky_hien_tai": "",
        "DHR_So_luong_de_xuat": "",
        "DHR_So_luong_duoc_cap": "Quantity",
        "DHR_So_luong_nhan_vien_khong_bang_de_xuat": "",
        "DHR_So_luong_phai_lon_hon_0": "",
        "DHR_So_luong_phep": "",
        "DHR_So_luong_phep_khong_hop_le": "",
        "DHR_So_luong_ton_den_cuoi_nam": "Leave Quantity To End Year",
        "DHR_So_luong_ton_den_ky_hien_tai": "",
        "DHR_So_luong_ton_kho_khong_du_cap_phat": "",
        "DHR_So_luong_tuyen": "",
        "DHR_So_luong_ung_vien": "Số lượng ứng viên",
        "DHR_So_nam_kinh_nghiem": "",
        "DHR_So_ngay": "日数",
        "DHR_So_ngay_con_lai": "",
        "DHR_So_ngay_cong": "",
        "DHR_So_ngay_da_nghi": "",
        "DHR_So_ngay_hieu_luc": "",
        "DHR_So_ngay_hieu_luc_phep": "",
        "DHR_So_ngay_nghi_trong_ky": "",
        "DHR_So_ngay_vi_pham": "",
        "DHR_So_nha": "",
        "DHR_So_phep": "",
        "DHR_So_phu_luc": "",
        "DHR_So_phut": "",
        "DHR_So_quyet_dinh": "決定番号",
        "DHR_So_so_BHXH": "保険予約番号",
        "DHR_So_so_bao_hiem_xa_hoi": "",
        "DHR_So_thang": "月数",
        "DHR_So_thang_da_su_dung": "",
        "DHR_So_thang_dao_tao": "",
        "DHR_So_thang_khong_duoc_qua_lon": "",
        "DHR_So_thang_su_dung": "",
        "DHR_So_the_BHYT": "",
        "DHR_So_the_bao_hiem_y_te": "",
        "DHR_So_the_can_cuoc": "",
        "DHR_So_thu_tu": "",
        "DHR_So_tien": "量",
        "DHR_So_tien_de_xuat_tam_ung": "",
        "DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu": "",
        "DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den": "",
        "DHR_Started": "",
        "DHR_Su_dung": "",
        "DHR_Sua": "Edit",
        "DHR_Sua_binh_luan_khong_thanh_cong": "",
        "DHR_Sua_binh_luan_thanh_cong": "",
        "DHR_Sua_du_lieu": "",
        "DHR_Sua_phong_van": "",
        "DHR_Tags": "",
        "DHR_Tai_bao_cao_(excel)": "Tải báo cáo (excel)",
        "DHR_Tai_khoan_Ngan_hang": "",
        "DHR_Tai_khoan_bi_khoa_10_phut": "",
        "DHR_Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap": "",
        "DHR_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "DHR_Tai_khoan_khong_co_quyen": "",
        "DHR_Tai_lieu": "",
        "DHR_Tai_lieu_dinh_kem": "",
        "DHR_Tam_dap_ung_Can_cai_tien_them": "",
        "DHR_Tam_ung": "Advance",
        "DHR_Tang_ca": "残業",
        "DHR_Tang_ca_den": "",
        "DHR_Tang_ca_giua": "",
        "DHR_Tang_ca_sau": "",
        "DHR_Tang_ca_truoc": "",
        "DHR_Tang_ca_tu": "",
        "DHR_Tao_boi": "",
        "DHR_Tao_muc_tieu_cho_chuc_danh_kiem_nhiem": "",
        "DHR_Tao_review": "",
        "DHR_Tao_review_thanh_cong": "",
        "DHR_Tao_tu_danh_muc_muc_tieu": "",
        "DHR_Tap_tin": "",
        "DHR_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "DHR_Tap_tin_tai_len": "",
        "DHR_Tat_ca": "",
        "DHR_Tat_ca_danh_gia": "",
        "DHR_Tat_ca_don_tu": "",
        "DHR_Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD": "",
        "DHR_Tat_ca_thao_tac_nhap_lieu_truoc_do_se_bi_mat,ban_co_muon_tiep_tuc_khong?": "",
        "DHR_Tat_ca_tin": "",
        "DHR_Ten": "名前",
        "DHR_Ten_CCLD": "",
        "DHR_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "DHR_Ten_Thanh_pho": "",
        "DHR_Ten_UV": "",
        "DHR_Ten_bang_KQ_tong_hop": "",
        "DHR_Ten_bang_muc_tieu": "",
        "DHR_Ten_bang_tong_hop": "",
        "DHR_Ten_ca": "",
        "DHR_Ten_chi_tieu": "",
        "DHR_Ten_chuc_vu_moi": "",
        "DHR_Ten_doi_tuong": "オブジェクト名",
        "DHR_Ten_giai_doan": "",
        "DHR_Ten_ket_qua_then_chot": "",
        "DHR_Ten_kho": "倉庫名",
        "DHR_Ten_loai_chi_phi": "",
        "DHR_Ten_loai_tach": "",
        "DHR_Ten_ly_do": "",
        "DHR_Ten_man_hinh": "スクリーンネーム",
        "DHR_Ten_muc_tieu": "",
        "DHR_Ten_nghiep_vu": "",
        "DHR_Ten_nguoi_dung": "ユーザー名",
        "DHR_Ten_nguoi_quan_he": "",
        "DHR_Ten_nhan_su": "",
        "DHR_Ten_nhan_vien": "",
        "DHR_Ten_nhom": "",
        "DHR_Ten_nhom_muc_tieu": "",
        "DHR_Ten_phieu": "",
        "DHR_Ten_phong_ban": "部署名",
        "DHR_Ten_phong_ban_VI": "",
        "DHR_Ten_phuong": "",
        "DHR_Ten_quan": "",
        "DHR_Ten_quy_trinh": "",
        "DHR_Ten_tach_tang_ca": "",
        "DHR_Ten_tinh_nang": "",
        "DHR_Ten_trang_thai_duyet": "",
        "DHR_Ten_vai_tro": "",
        "DHR_Ten_vat_tu": "",
        "DHR_Tham_chieu_chuoi": "",
        "DHR_Tham_chieu_so": "",
        "DHR_Tham_chieu_xep_loai": "",
        "DHR_Tham_nien": "年功序列",
        "DHR_Tham_so_truyen_vao_khong_hop_le": "",
        "DHR_Thang": "",
        "DHR_Thang_nay": "今月",
        "DHR_Thang_truoc": "",
        "DHR_Thanh_pho": "",
        "DHR_Thanh_tien(USD)": "",
        "DHR_Thanh_tien(VND)": "",
        "DHR_Thanh_vien": "",
        "DHR_Thanh_vien_cua_Nihon_Grouping_va_Pan": "",
        "DHR_Thanh_vien_duoc_chon": "",
        "DHR_Thanh_vien_quan_ly": "",
        "DHR_Thanh_vien_vai_tro_quan_tri": "",
        "DHR_Thay_the_nguoi_duyet": "",
        "DHR_Thay_the_nguoi_duyet_thanh_cong": "",
        "DHR_The_can_cuoc": "",
        "DHR_The_luu_tru": "",
        "DHR_Them": "Add",
        "DHR_Them_binh_luan_thanh_cong": "",
        "DHR_Them_dinh_kem_thanh_cong": "",
        "DHR_Them_dinh_kem_that_bai": "",
        "DHR_Them_giai_doan": "",
        "DHR_Them_ket_qua_then_chot": "",
        "DHR_Them_ket_qua_then_chot_thanh_cong": "",
        "DHR_Them_ket_qua_then_chot_that_bai": "",
        "DHR_Them_lich_phong_van": "",
        "DHR_Them_loai_tach_tang_ca": "",
        "DHR_Them_moi": "",
        "DHR_Them_moi1": "新しく作る",
        "DHR_Them_moi_tin_tuyen_dung": "",
        "DHR_Them_moi_ung_vien": "",
        "DHR_Them_muc_tieu": "",
        "DHR_Them_phong_van_moi": "",
        "DHR_Them_tep_dinh_kem": "",
        "DHR_Them_thanh_vien": "",
        "DHR_Them_tieu_chi": "",
        "DHR_Them_tieu_chi_danh_gia": "",
        "DHR_Them_ung_vien": "",
        "DHR_Thiet_lap_cach_tinh_ket_qua": "",
        "DHR_Thiet_lap_cach_tinh_ket_qua_muc_tieu": "",
        "DHR_Thiet_lap_chi_tieu_tong_hop": "",
        "DHR_Thiet_lap_tong_hop": "",
        "DHR_Thoi_gian": "時間",
        "DHR_Thoi_gian_bat_dau": "",
        "DHR_Thoi_gian_can": "",
        "DHR_Thoi_gian_can_nhan_su": "",
        "DHR_Thoi_gian_dang_ky:": "",
        "DHR_Thoi_gian_dao_tao": "",
        "DHR_Thoi_gian_dong_BHXH": "",
        "DHR_Thoi_gian_hieu_luc": "",
        "DHR_Thoi_gian_ket_thuc": "",
        "DHR_Thoi_gian_muc_tieu": "",
        "DHR_Thoi_gian_nop_don": "",
        "DHR_Thoi_gian_nop_hoac_deadline_khong_hop_le": "",
        "DHR_Thoi_gian_phong_van": "",
        "DHR_Thoi_gian_ra_vao_VN": "",
        "DHR_Thoi_gian_tao": "",
        "DHR_Thoi_gian_tham_gia_BH": "Thời gian tham gia BH",
        "DHR_Thoi_gian_thu_viec": "",
        "DHR_Thoi_han_hop_dong": "",
        "DHR_Thoi_han_phu_luc": "",
        "DHR_Thong_bao": "Announcement",
        "DHR_Thong_bao1": "",
        "DHR_Thong_baoU": "",
        "DHR_Thong_ke": "統計",
        "DHR_Thong_ke_phong_van": "",
        "DHR_Thong_ke_ung_vien": "",
        "DHR_Thong_tin": "情報",
        "DHR_Thong_tin_ca_nhan": "個人情報",
        "DHR_Thong_tin_ca_nhan_DESC": "",
        "DHR_Thong_tin_cap_phat_cho": "",
        "DHR_Thong_tin_chi_tiet": "",
        "DHR_Thong_tin_chinh": "",
        "DHR_Thong_tin_chung": "一般的な情報",
        "DHR_Thong_tin_cong_viec": "",
        "DHR_Thong_tin_cu": "",
        "DHR_Thong_tin_cung_cap_khong_hop_le": "",
        "DHR_Thong_tin_dieu_chinh": "",
        "DHR_Thong_tin_gia_dinh": "",
        "DHR_Thong_tin_hien_tai": "",
        "DHR_Thong_tin_ket_qua_then_chot": "",
        "DHR_Thong_tin_kiem_nhiem": "",
        "DHR_Thong_tin_lien_he": "",
        "DHR_Thong_tin_lien_lac": "連絡先",
        "DHR_Thong_tin_luong": "給与情報",
        "DHR_Thong_tin_muc_tieu": "",
        "DHR_Thong_tin_nguoi_dung": "",
        "DHR_Thong_tin_nguoi_duyet": "",
        "DHR_Thong_tin_nhan_su": "",
        "DHR_Thong_tin_nhom": "",
        "DHR_Thong_tin_phan_quyen": "",
        "DHR_Thong_tin_phep": "年次休暇情報",
        "DHR_Thong_tin_phong_van": "",
        "DHR_Thong_tin_tang_nhan_vien": "",
        "DHR_Thong_tin_thu_hoi_cho": "",
        "DHR_Thong_tin_tra_ve_khi_luu_khong_dung": "",
        "DHR_Thong_tin_tuyen_dung": "",
        "DHR_Thong_tin_vai_tro": "",
        "DHR_Thong_tin_xep_loai": "",
        "DHR_Thu_ba": "",
        "DHR_Thu_bay": "",
        "DHR_Thu_gon": "",
        "DHR_Thu_hai": "",
        "DHR_Thu_hoi": "",
        "DHR_Thu_hoi_trang_thiet_bi_lao_dong": "",
        "DHR_Thu_nam": "",
        "DHR_Thu_nho": "",
        "DHR_Thu_sau": "",
        "DHR_Thu_tu": "",
        "DHR_Thu_tuU": "",
        "DHR_Thu_tu_hien_thi": "シーケンス番号",
        "DHR_Thuc_hien1": "",
        "DHR_Thuc_hien_thanh_cong": "",
        "DHR_Tien_bao_hiem": "",
        "DHR_Tien_chi_tam_ung": "",
        "DHR_Tien_do_thuc_hien": "",
        "DHR_Tien_tro_cap": "",
        "DHR_Tiep_tuc": "",
        "DHR_Tieu_chi": "",
        "DHR_Tieu_chi_danh_gia": "Evaluation criteria",
        "DHR_Tieu_chi_loc": "",
        "DHR_Tieu_de": "Title",
        "DHR_Tieu_de_mail": "",
        "DHR_Tieu_de_phong_van": "",
        "DHR_Tieu_de_tin": "",
        "DHR_Tieu_de_tin_tuyen_dung": "",
        "DHR_Tim_kiem": "",
        "DHR_Tim_kiem_cap_phat_trang_thiet_bi_lao_dong": "",
        "DHR_Tim_kiem_cham_cong": "",
        "DHR_Tim_kiem_che_do_bao_hiem": "",
        "DHR_Tim_kiem_co_cau_to_chuc": "",
        "DHR_Tim_kiem_ho_so_nhan_su": "",
        "DHR_Tim_kiem_nhan_vien": "",
        "DHR_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "",
        "DHR_Tim_theo_ma_va_ten": "",
        "DHR_Tin_chua_duoc_dang_cong_khai": "",
        "DHR_Tin_hot": "",
        "DHR_Tin_tuyen_dung": "",
        "DHR_Tinh_cach": "Character",
        "DHR_Tinh_trang_hien_tai": "",
        "DHR_Tinh_trang_hon_nhan": "婚姻状況",
        "DHR_Tinh_trang_thu_hoi": "",
        "DHR_To_chuc": "組織",
        "DHR_To_chuc_Tuyen_dung_DESC": "",
        "DHR_To_nhom": " 組",
        "DHR_To_nhom_moi": "",
        "DHR_Toan_thoi_gian": "",
        "DHR_Toan_tu": "",
        "DHR_Toan_tu_so_cong": "",
        "DHR_Toan_tu_so_ngay_hieu_luc": "",
        "DHR_Toan_tu_so_phep": "",
        "DHR_Toi_da_tao": "",
        "DHR_Toi_quan_ly": "",
        "DHR_Tom_tat_ung_vien": "",
        "DHR_Ton": "",
        "DHR_Ton_giao": "宗教",
        "DHR_Ton_kho": "",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec": "Tồn tại nhân viên chưa có ca làm việc",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu": "",
        "DHR_Ton_tai_nhan_vien_chua_co_thoi_gian_dang_ky_tang_ca": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_Ban_co_muon_luu_khong": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_co_muon_luu_khong": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_khong_duoc_phep_luu": "",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_,_khong_duoc_phep_xoa": "",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa": "",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec": "Tồn tại nhiều hơn 1 ca làm việc trong cùng một ngày làm việc",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec.Ban_khong_duoc_phep_luu": "",
        "DHR_Tong": "",
        "DHR_Tong_chi_phi": "",
        "DHR_Tong_chi_phi_cong_ty_thanh_toan": "",
        "DHR_Tong_chi_phi_du_kien": "",
        "DHR_Tong_chi_phi_nhan_vien_su_dung": "",
        "DHR_Tong_chi_phi_quy_doi": "",
        "DHR_Tong_chi_phi_thuc_te_chuyen_di": "",
        "DHR_Tong_cong": "合計",
        "DHR_Tong_diem": "",
        "DHR_Tong_hop": "合成",
        "DHR_Tong_hop_chi_phi": "",
        "DHR_Tong_hop_danh_gia": "",
        "DHR_Tong_hop_ung_vien_moi_theo_ngay": "Tổng hợp ứng viên theo ngày",
        "DHR_Tong_hop_ung_vien_moi_theo_tuan": "Tổng hợp ứng viên theo tuần",
        "DHR_Tong_hop_ung_vien_theo_nguon_tuyen": "Tổng hợp ứng viên theo nguồn tuyển",
        "DHR_Tong_phep": "",
        "DHR_Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai": "",
        "DHR_Tong_quan": "",
        "DHR_Tong_so_NV": "",
        "DHR_Tong_so_cong": "",
        "DHR_Tong_so_luong": "",
        "DHR_Tong_so_ngay": "",
        "DHR_Tong_so_ngay_duoc_nghi": "",
        "DHR_Tong_so_nhan_vien": "",
        "DHR_Tong_so_phep": "",
        "DHR_Tong_trong_so_muc_tieu": "",
        "DHR_Tong_ty_le": "",
        "DHR_Trang": "",
        "DHR_Trang_Chu": "ホームページ",
        "DHR_Trang_thai": "ステータス",
        "DHR_Trang_thai_bang_muc_tieu": "",
        "DHR_Trang_thai_danh_gia": "",
        "DHR_Trang_thai_duyet": "承認状況",
        "DHR_Trang_thai_lam_viec": "",
        "DHR_Trang_thai_muc_tieu": "",
        "DHR_Trang_thai_phieu_danh_gia": "",
        "DHR_Trang_thai_phong_van": "",
        "DHR_Trang_thai_su_dung": "",
        "DHR_Trang_thai_tin": "",
        "DHR_Trang_thiet_bi_lao_dong": "",
        "DHR_Trao_doi_email": "",
        "DHR_Trao_doi_gui_va_nhan_voi_ung_vien_ve_buoi_phong_van_nay": "",
        "DHR_Tre_han": "",
        "DHR_Tre_hen": "",
        "DHR_Trinh_do_chinh_tri": "政治レベル",
        "DHR_Trinh_do_chuyen_mon": "",
        "DHR_Trinh_do_dao_tao": "",
        "DHR_Trinh_do_hoc_van": "教育レベル",
        "DHR_Trinh_do_ngoai_ngu": "",
        "DHR_Trinh_do_tin_hoc": "",
        "DHR_Trinh_do_van_hoaU": "",
        "DHR_Tro_giup": "",
        "DHR_Trong_nuoc": "",
        "DHR_Trong_so": "Weight",
        "DHR_Truong": "",
        "DHR_TruongB": "",
        "DHR_Truong_Ngay_hieu_luc_bat buoc_nhap": "",
        "DHR_Truong_dieu_chinh": "",
        "DHR_Truong_nay_bat_buoc_nhap": "",
        "DHR_Truong_nay_khong_hop_le": "",
        "DHR_Truong_ngay_la_bat_buoc": "",
        "DHR_Truy_cap_khong_hop_le": "",
        "DHR_Truy_van_don_tu": "",
        "DHR_Truy_van_tang_nhan_vien": "",
        "DHR_Truy_van_thong_tin_cong_phep": "",
        "DHR_Tu": "から",
        "DHR_Tu_choi": "拒絶",
        "DHR_Tu_choi_W75": "",
        "DHR_Tu_choi_khong_thanh_cong": "",
        "DHR_Tu_choi_thanh_cong": "",
        "DHR_Tu_ngay": "Date from",
        "DHR_Tuan_nay": "Konshū",
        "DHR_Tuan_truoc": "",
        "DHR_Tuoi": "年齢",
        "DHR_Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban": "",
        "DHR_Tuoi_den_khong_duoc_nho_hon_tuoi_tu": "",
        "DHR_Tuoi_tu_khong_duoc_lon_hon_tuoi_den": "",
        "DHR_Tuoi_tu_phai_nho_hon_tuoi_den": "",
        "DHR_Tuy_chon": "オプション",
        "DHR_Tuyen_dung": "募集",
        "DHR_Ty_gia": "為替レート",
        "DHR_Ty_le": "百分率",
        "DHR_Ty_le_cho_phep": "",
        "DHR_Ty_le_xep_loai_cua_bang_tham_chieu_ket_qua": "",
        "DHR_URL": "",
        "DHR_URL_khong_hop_le": "",
        "DHR_Ung_tuyen": "",
        "DHR_Ung_vien": "",
        "DHR_Ung_vien_chua_co_lich_phong_van": "",
        "DHR_Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay": "",
        "DHR_Ung_vien_khong_co_email": "",
        "DHR_Ung_vien_va_nguoi_phong_van": "",
        "DHR_Ung_vien_xac_nhan": "",
        "DHR_Username_va_Password_khong_hop_le!": "",
        "DHR_Vai_tro": "",
        "DHR_Vao_ra": "",
        "DHR_Vao_tre": "",
        "DHR_Ve_som": "",
        "DHR_Vi_tri": "Position",
        "DHR_Vi_tri_Cong_viec": "",
        "DHR_Vi_tri_tuyen": "",
        "DHR_Vi_tri_tuyen_dung": "",
        "DHR_Vi_tri_ung_tuyen": "",
        "DHR_VoucherID_khong_duoc_de_trong": "",
        "DHR_Vui_long_chon_ca": "Vui lòng chọn ca",
        "DHR_Vui_long_chon_ket_qua": "",
        "DHR_Vui_long_chon_nhan_vien": "Vui lòng chọn nhân viên",
        "DHR_Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon_o_tren": "",
        "DHR_Vui_long_kiem_tra_email_va_nhap_ma_de_doi_mat_khau": "",
        "DHR_Vui_long_kiem_tra_lai_Ban_da_danh_gia_day_du_cac_chi_tieu_chua": "",
        "DHR_Vui_long_nhap_email_de_doi_mat_khau": "",
        "DHR_Vui_long_truy_cap_sau": "",
        "DHR_Vuot_dinh_bien": "",
        "DHR_Vuot_qua_so_luong_tap_tin_cho_phep": "",
        "DHR_Xac_nhan": "Confirm",
        "DHR_Xac_nhan_mat_khau_moi": "",
        "DHR_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "",
        "DHR_Xac_nhan_tra_the_so_bao_hiem": "",
        "DHR_Xay_ra_loi_khi_duyet_cho_cac_phieu_sau": "",
        "DHR_Xem": "表示",
        "DHR_Xem_chi_tiet": "View Detail",
        "DHR_Xem_chi_tiet_cac_dot_review": "",
        "DHR_Xem_chi_tiet_muc_tieu_vua_tao": "",
        "DHR_Xem_muc_tieu_cua_co_cau_to_chuc_khac": "",
        "DHR_Xem_muc_tieu_cua_nguoi_khac": "",
        "DHR_Xem_nhanh": "",
        "DHR_Xem_tap_tin": "",
        "DHR_Xem_tat_ca": "",
        "DHR_Xem_them": "",
        "DHR_Xem_tren_website_tuyen_dung": "",
        "DHR_Xem_truoc": "",
        "DHR_Xep_loai": "",
        "DHR_Xep_loai_danh_gia": "",
        "DHR_Xep_loai_danh_gia_nhan_vien": "",
        "DHR_Xet_duyet_Offer": "",
        "DHR_Xet_duyet_bang_muc_tieu": "",
        "DHR_Xet_duyet_muc_tieu": "Xét duyệt mục tiêu",
        "DHR_Xet_duyet_muc_tieu_ket_qua_ca_nhan": "Xét duyệt kết quả mục tiêu cá nhân",
        "DHR_Xin_cam_on": "",
        "DHR_Xoa": "Delete",
        "DHR_Xoa_dinh_kem_thanh_cong": "",
        "DHR_Xoa_dinh_kem_that_bai": "",
        "DHR_Xoa_khoi_nhom": "",
        "DHR_Xoa_nhom_se_xoa_tat_ca_du_lieu_Ban_co_chac_muon_thuc_hien_dieu_nay": "",
        "DHR_Xoa_phong_van": "",
        "DHR_Xoa_thanh_cong": "",
        "DHR_Xoa_tin_tuyen_dung": "",
        "DHR_Xu_ly_duyet": "",
        "DHR_Xuat": "",
        "DHR_Xuat_Excel": "",
        "DHR_Xuat_PDF": "",
        "DHR_Xuat_du_lieu": "",
        "DHR_Xuat_du_lieu_thanh_cong": "",
        "DHR_Xuat_word": "",
        "DHR_Y_kien_dong_gop": "",
        "DHR_Yes": "",
        "DHR_Yeu_cau_khac": "",
        "DHR_cho_phep_NV_huy_muc_tieu": "",
        "DHR_cho_phep_NV_sua_trong_so": "",
        "DHR_cho_phep_sao_chep_KQ_cap_truoc": "",
        "DHR_da_chot1": "",
        "DHR_dang_nhap_lai": "再度ログオンを",
        "DHR_dong": "",
        "DHR_ghi_chu_cap_%P": "",
        "DHR_khong_co_du_lieu": "",
        "DHR_ko_du_quyen": "",
        "DHR_label_c1": "",
        "DHR_label_c2": "",
        "DHR_label_c3": "",
        "DHR_muc_tieu_va_ket_qua_then_chot": "",
        "DHR_ngay_BD_xet_duyet": "",
        "DHR_nguoi_nay": "",
        "DHR_phut": "",
        "DHR_ra_khoi_danh_sach": "",
        "DHR_trao_giai_phap_nhan_niem_tin": "",
        "DHR_trong": "",
        "DHR_tuan": "",
        "DHR_ung_vien_nay": "",
        "DHR_xem_CV": ""
    },
    "zh": {
        "DHR_403_loi": "",
        "DHR_404_loi": "",
        "DHR_AC_vui_long_chon_y_kien_cua_minh": "",
        "DHR_An_trua": "",
        "DHR_Anh_dai_dien": "",
        "DHR_Ap_dung_mot_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Ap_dung_toan_tu_theo_tung_du_lieu_luong": "",
        "DHR_BANG_CONG_THANG": "",
        "DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "DHR_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "",
        "DHR_Ban_chua_chon_du_lieu_tren_luoi": "",
        "DHR_Ban_chua_chon_nhan_vien": "",
        "DHR_Ban_chua_nhap.Ban_co_muon_bo_sung_khong": "",
        "DHR_Ban_chua_nhap_ngay_di_ca": "",
        "DHR_Ban_chua_nhap_so_thu_tu": "",
        "DHR_Ban_co_chac_chan_bo_duyet": "",
        "DHR_Ban_co_chac_chan_muon_duyet": "",
        "DHR_Ban_co_chac_chan_muon_huy.Neu_huy_thi_se_khong_the_phuc_hoi": "",
        "DHR_Ban_co_chac_chan_muon_huy?_Neu_huy_thi_se_KHONG_THE_khoi_phuc_du_lieu_truoc_do": "",
        "DHR_Ban_co_chac_chan_muon_tu_choi": "",
        "DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "DHR_Ban_co_chac_chan_xoa": "",
        "DHR_Ban_co_chac_chan_xoa_ung_vien_khoi_tin_tuyen_dung": "",
        "DHR_Ban_co_chac_cho_phep_nhan_vien_cap_nhat_muc_tieu_da_duyet?": "",
        "DHR_Ban_co_chac_muon_bo_trang_thai_nay?": "",
        "DHR_Ban_co_chac_muon_cap_nhat?": "",
        "DHR_Ban_co_chac_muon_dang_ky_muc_tieu_khong?": "",
        "DHR_Ban_co_chac_muon_gui_mail_nay": "",
        "DHR_Ban_co_chac_muon_huy_phep?": "",
        "DHR_Ban_co_chac_muon_luu_khong?": "",
        "DHR_Ban_co_chac_muon_roi_nhom_khong": "",
        "DHR_Ban_co_chac_muon_xoa?": "",
        "DHR_Ban_co_chac_muon_xoa_(nhung)_dinh_kem_nay?": "",
        "DHR_Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?": "",
        "DHR_Ban_co_chac_muon_xoa_nhan_vien?": "",
        "DHR_Ban_co_chac_muon_xoa_thanh_vien_nay_khoi_nhom_khong": "",
        "DHR_Ban_co_du_lieu_chua_duoc_luu_Ban_co_muon_tiep_tuc": "",
        "DHR_Ban_co_muon": "",
        "DHR_Ban_co_muon_cap_nhat_han_hoan_thanh": "",
        "DHR_Ban_co_muon_cap_nhat_lai_lich_di_ca_theo_ngay_hieu_luc?": "",
        "DHR_Ban_co_muon_cap_nhat_thong_tin_tang_ca_nhan_vien": "",
        "DHR_Ban_co_muon_cap_nhat_trang_thai_muc_tieu": "",
        "DHR_Ban_co_muon_cap_thong_tin_tang_ca_cho_nhan_vien": "Bạn có muốn cập nhật thông tin tăng ca nhân viên",
        "DHR_Ban_co_muon_gui_den_mail_nay": "",
        "DHR_Ban_co_muon_gui_mail_nay": "",
        "DHR_Ban_co_muon_gui_mail_nhac_lich_cho_ung_vien_nay": "",
        "DHR_Ban_co_muon_thay_doi_trang_thai_phong_van": "",
        "DHR_Ban_co_muon_thay_doi_trang_thai_ung_vien_xac_nhan": "",
        "DHR_Ban_co_muon_thay_the_nguoi_duyet": "",
        "DHR_Ban_co_muon_tra_loi_den_mail_nay": "",
        "DHR_Ban_co_muon_xoa_lich_phong_van": "",
        "DHR_Ban_dang_xem_muc_tieu_cua": "",
        "DHR_Ban_do_muc_tieu": "",
        "DHR_Ban_giao": "",
        "DHR_Ban_muon": "",
        "DHR_Ban_muon_xoa": "",
        "DHR_Ban_phai_chon_doanh_nghiep": "",
        "DHR_Ban_phai_chon_ket_qua_danh_gia": "",
        "DHR_Ban_phai_gan_ca_mac_dinh": "",
        "DHR_Ban_phai_gan_mau_ca": "",
        "DHR_Ban_phai_nhap_cac_thong_tin": "",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_00h00m": "",
        "DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11": "",
        "DHR_Ban_phai_nhap_ngay_hieu_luc": "",
        "DHR_Ban_phai_nhap_thong_tin": "",
        "DHR_Ban_vui_long_chon_du_lieu_tren_luoi": "",
        "DHR_Ban_vui_long_kiem_tra_Ngay_danh_gia_phai_trong_khoang_tu_den": "",
        "DHR_Bang1": "",
        "DHR_Bang_cau_hoi_phong_van": "",
        "DHR_Bang_muc_tieu": "",
        "DHR_Bang_tham_chieu_KQ": "",
        "DHR_Bang_tong_hop": "",
        "DHR_Bao_cao": "",
        "DHR_Bao_cao_cong": "",
        "DHR_Bao_cao_tuyen_dung": "Báo cáo tuyển dụng",
        "DHR_Bao_hiem_xa_hoi": "Social Insurance",
        "DHR_Bao_hiem_y_te": "Health Insurance",
        "DHR_Bat_buoc": "",
        "DHR_Bat_buoc_nhap": "",
        "DHR_Bat_buoc_nhapB": "",
        "DHR_Bat_buoc_nhap_du_lieu": "",
        "DHR_Benh_vien_KCB": "",
        "DHR_Bo_duyet": "",
        "DHR_Bo_duyet_thanh_cong": "",
        "DHR_Bo_qua": "",
        "DHR_Bo_qua_thong_bao_thanh_cong": "",
        "DHR_Bo_sao_chep": "",
        "DHR_Bo_sung": "",
        "DHR_Bo_sung_gio_vao_ra": "",
        "DHR_Bo_sung_gio_vao_ra_thuc_te": "",
        "DHR_Bo_tat_ca": "",
        "DHR_Bo_xac_nhan": "",
        "DHR_Boi": "",
        "DHR_Buoc": "",
        "DHR_CCLD": "",
        "DHR_CCLD_da_cap_phat": "",
        "DHR_CDN_chua_san_sang": "",
        "DHR_CMND": "",
        "DHR_CV_ung_vien": "",
        "DHR_Ca": "班次",
        "DHR_Ca_lam_viec": "",
        "DHR_Ca_mac_dinh": "",
        "DHR_Ca_nhan": "Personal",
        "DHR_Ca_ra_vao": "",
        "DHR_Ca_vao_ra": "",
        "DHR_Ca_xoay_vong": "",
        "DHR_Cac_cau_hoi_phong_van_su_dung_cho_vi_tri_tuyen_dung": "",
        "DHR_Cac_gop_y_khac": "",
        "DHR_Cac_tham_so_la_bat_buoc": "",
        "DHR_Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.": "",
        "DHR_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "DHR_Cac_truong_khong_bang_nhau": "",
        "DHR_Cach_tinh_ket_qua": "",
        "DHR_Cai_dat": "",
        "DHR_Cap": "级",
        "DHR_Cap_khen_thuong": "",
        "DHR_Cap_ky_luat": "",
        "DHR_Cap_nhat": "",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_cach_tinh_ket_qua_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_gia_tri_%p_thanh_cong": "",
        "DHR_Cap_nhat_gia_tri_%p_that_bai": "",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_han_hoan_thanh_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_ket_qua": "",
        "DHR_Cap_nhat_ket_qua_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_ket_qua_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_mo_ta_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_mo_ta_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_ngay_hoan_thanh_thanh_cong": "",
        "DHR_Cap_nhat_ngay_hoan_thanh_that_bai": "",
        "DHR_Cap_nhat_nhom_thanh_cong": "",
        "DHR_Cap_nhat_nhom_that_bai": "",
        "DHR_Cap_nhat_sau_thai_san": "",
        "DHR_Cap_nhat_ten_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_ten_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_thanh_cong": "",
        "DHR_Cap_nhat_thong_tin_cho_phieu_danh_gia": "",
        "DHR_Cap_nhat_thong_tin_khac_cho_phieu_danh_gia": "",
        "DHR_Cap_nhat_thong_tin_nhanh": "",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_thanh_cong": "",
        "DHR_Cap_nhat_thu_tu_ket_qua_then_chot_that_bai": "",
        "DHR_Cap_nhat_trang_thai_hop_dong_lao_dong": "",
        "DHR_Cap_nhat_trang_thai_muc_tieu_thanh_cong": "",
        "DHR_Cap_nhat_trang_thai_muc_tieu_that_bai": "",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_thanh_cong": "",
        "DHR_Cap_nhat_trong_so_ket_qua_then_chot_that_bai": "",
        "DHR_Cap_nhat_trong_so_thanh_cong": "",
        "DHR_Cap_nhat_trong_so_that_bai": "",
        "DHR_Cap_phat": "",
        "DHR_Cap_phat_thiet_bi_lao_dong": "",
        "DHR_Cap_phat_trang_thiet_bi_lao_dong": "",
        "DHR_Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet": "",
        "DHR_Cau_hoi_phong_van": "",
        "DHR_Cham_cong_chi_tiet": "",
        "DHR_Cham_cong_theo_du_an": "",
        "DHR_Cham_diem_theo_tieu_chi": "",
        "DHR_Cham_tien_do": "",
        "DHR_Chao_buoi_sang": "",
        "DHR_Chao_mung_ban": "",
        "DHR_Chao_mung_login": "",
        "DHR_Che_do_bao_hiem_duoc_duyet": "",
        "DHR_Che_do_con_nho": "",
        "DHR_Che_do_sinh_con_nho": "",
        "DHR_Che_do_tro_cap": "",
        "DHR_Chi_con_lai_can_quyet_toan": "",
        "DHR_Chi_dinh_lam_quan_tri_vien": "",
        "DHR_Chi_dinh_lam_thanh_vien": "Chỉ định làm thành viên",
        "DHR_Chi_dinh_lam_thanh_vien_vai_tro_quan_tri": "Chỉ định làm thành viên vai trò quản trị",
        "DHR_Chi_dinh_thanh_vien_vai_tro_quan_tri": "Chỉ định thành viên vai trò quản trị",
        "DHR_Chi_hien_thi_du_lieu_da_chon": "",
        "DHR_Chi_nguoi_phong_van": "",
        "DHR_Chi_phi": "成本",
        "DHR_Chi_phi_binh_quan": "",
        "DHR_Chi_phi_con_lai_can_quyet_toan": "",
        "DHR_Chi_phi_cong_tac": "Bussiness cost",
        "DHR_Chi_phi_tam_ung": "",
        "DHR_Chi_tam_ung": "",
        "DHR_Chi_tiet": "细节",
        "DHR_Chi_tiet_bang_muc_tieu": "",
        "DHR_Chi_tiet_cong_cu_lao_dong": "",
        "DHR_Chi_tiet_dieu_chinh": "",
        "DHR_Chi_tiet_dieu_chinh_thong_tin_gia_dinh": "",
        "DHR_Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD": "",
        "DHR_Chi_tiet_nhan_vien": "",
        "DHR_Chi_tiet_nhom": "",
        "DHR_Chi_tiet_phep": "",
        "DHR_Chi_tiet_phieu": "Voucher Detail",
        "DHR_Chi_tiet_thong_tin_ung_tuyen_cua_ung_vien": "",
        "DHR_Chi_tiet_tin_tuyen_dung": "",
        "DHR_Chi_tiet_xep_loai_danh_gia_nhan_vien": "",
        "DHR_Chi_tieu": "Target",
        "DHR_Chi_ung_vien": "",
        "DHR_Chinh_sua": "",
        "DHR_Chinh_sua_tang_ca_thanh_cong": "",
        "DHR_Chinh_sua_thong_tin_tang_ca": "",
        "DHR_Chinh_sua_thong_tin_tuyen_dung": "",
        "DHR_Chinh_sua_tin_tuyen_dung": "",
        "DHR_Chinh_tri": "",
        "DHR_Cho_duyet": "待批准",
        "DHR_Cho_phep": "",
        "DHR_Cho_phep_cap_nhat_nhom_muc_tieu_da_duyet": "",
        "DHR_Cho_phep_nhan_vien_dieu_chinh_chon_so": "",
        "DHR_Cho_phep_nhan_vien_huy_muc_tieu": "",
        "DHR_Cho_phep_sao_chep_ket_qua_cua_cap_truoc": "",
        "DHR_Cho_tat_ca_nhan_vien": "",
        "DHR_Chon": "Select",
        "DHR_Chon_CCLD": "",
        "DHR_Chon_ban_giao": "",
        "DHR_Chon_bao_cao": "",
        "DHR_Chon_co_cau_to_chuc": "",
        "DHR_Chon_du_an": "",
        "DHR_Chon_giai_doan": "",
        "DHR_Chon_ky": "",
        "DHR_Chon_ky_luong": "",
        "DHR_Chon_lai_mau_email_truoc_khi_gui": "",
        "DHR_Chon_mau_bao_cao": "",
        "DHR_Chon_mau_mail_co_san": "",
        "DHR_Chon_mau_mail_gui_cho_ung_vien": "",
        "DHR_Chon_ngay": "",
        "DHR_Chon_ngay_den": "",
        "DHR_Chon_ngay_tu": "",
        "DHR_Chon_nguoi_duyet_thay_the": "",
        "DHR_Chon_nguoi_phong_van": "",
        "DHR_Chon_nhan_vien": "",
        "DHR_Chon_nhom_luong": "",
        "DHR_Chon_nhom_nhan_vien": "",
        "DHR_Chon_phong_ban": "",
        "DHR_Chon_tat_ca": "",
        "DHR_Chon_tu_danh_muc_muc_tieu": "",
        "DHR_Chu_de": "",
        "DHR_Chu_ky": "",
        "DHR_Chu_nhat": "",
        "DHR_Chu_so_huu": "",
        "DHR_Chua_chon_ung_vien": "",
        "DHR_Chua_chuyen_ket_qua": "",
        "DHR_Chua_co_lich_phong_van_nao": "",
        "DHR_Chua_co_lien_ket_nao": "",
        "DHR_Chua_dap_ung": "",
        "DHR_Chua_dat": "",
        "DHR_Chua_duoc_nhap_ban_co_muon_luu_khong": "",
        "DHR_Chua_hoan_thanh": "",
        "DHR_Chua_thuoc_tin_tuyen_dung_nao": "",
        "DHR_Chua_tinh_ket_qua": "",
        "DHR_Chuc_danh": "",
        "DHR_Chuc_danh_cong_viec": "",
        "DHR_Chuc_danh_kiem_nhiem": "",
        "DHR_Chuc_mung_sinh_nhat": "",
        "DHR_Chuc_vu": "位置",
        "DHR_Chuc_vu_bat_buoc_nhap": "",
        "DHR_Chuc_vu_kiem_nhiem": "",
        "DHR_Chuc_vu_moi": "",
        "DHR_Chung_minh_nhan_dan": "",
        "DHR_Chung_minh_thu_ho_chieu": "",
        "DHR_Chuyen_mon": "",
        "DHR_Chuyen_nganh": "",
        "DHR_Chuyen_tiep": "",
        "DHR_Chuyen_tin_tuyen_dung": "Chuyển tin tuyển dụng",
        "DHR_Chuyen_tin_tuyen_dung_thanh_cong": "Chuyển tin tuyển dụng thành công",
        "DHR_Co": "贷方",
        "DHR_Co1": "",
        "DHR_Co_cau_chuc_danh": "",
        "DHR_Co_cau_to_chuc": "",
        "DHR_Co_du_lieu_da_duoc_sua_Ban_co_muon_tiep_tuc?": "",
        "DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "DHR_Co_review": "",
        "DHR_Co_xet_duyet": "",
        "DHR_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "",
        "DHR_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "",
        "DHR_Con_thong_tin_truong": "",
        "DHR_Con_truong": "",
        "DHR_Cong_cu_lao_dong": "",
        "DHR_Cong_hanh_chinh_trong_ngay": "",
        "DHR_Cong_thuc": "",
        "DHR_Cong_trinh/Cong_ty": "",
        "DHR_Cong_trinh_Cong_ty": "",
        "DHR_Cong_ty": "公司",
        "DHR_Cong_ty_thanh_toan": "",
        "DHR_Cong_viec": "工作",
        "DHR_Cong_viec_moi": "",
        "DHR_Cong_viec_truoc_day": "",
        "DHR_Cu": "",
        "DHR_DRH_Ca_mac_dinh": "",
        "DHR_DRH_Danh_sach_phong_ban": "",
        "DHR_DRH_Hoc_van_va_chuyen_mon": "",
        "DHR_DRH_Ten_phong_ban_EN": "",
        "DHR_Da_chot": "",
        "DHR_Da_chuyen": "",
        "DHR_Da_chuyen1": "",
        "DHR_Da_chuyen_ket_qua": "",
        "DHR_Da_chuyen_kq": "",
        "DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu": "",
        "DHR_Da_dien_ra": "",
        "DHR_Da_duyet": "已批准",
        "DHR_Da_hoan_thanh": "",
        "DHR_Da_huy_thanh_cong": "",
        "DHR_Da_nghi_viec": "",
        "DHR_Da_sao_chep_CCLD": "",
        "DHR_Da_sao_chep_cap_phat_truoc": "",
        "DHR_Da_tinh": "",
        "DHR_Da_tinh_ket_qua": "",
        "DHR_Da_ton_tai_lich_phong_van_cua_ung_cu_vien_ban_co_muon_xoa_khong": "",
        "DHR_Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong": "",
        "DHR_Da_xac_nhan": "",
        "DHR_Da_xay_ra_loi,_khong_the_luu_du_lieu": "",
        "DHR_Dan_quyen": "",
        "DHR_Dan_toc": "民族",
        "DHR_Dang_Lam_Viec": "",
        "DHR_Dang_chuan_bi_du_lieu_Xin_vui_long_cho": "",
        "DHR_Dang_cong_khai": "",
        "DHR_Dang_cong_khai_W25DESC": "",
        "DHR_Dang_ky_cong_tac": "",
        "DHR_Dang_ky_di_tre_ve_som": "",
        "DHR_Dang_ky_gio_tang_ca_thuc_te": "",
        "DHR_Dang_ky_muc_tieu": "",
        "DHR_Dang_ky_nghi": "",
        "DHR_Dang_ky_nghi_viec": "",
        "DHR_Dang_ky_quyet_toan_chi_phi_cong_tac": "",
        "DHR_Dang_ky_sau": "Registration after",
        "DHR_Dang_ky_tang_ca": "Overtime Registration",
        "DHR_Dang_ky_tang_ca_ho": "Đăng ký tăng ca hộ",
        "DHR_Dang_ky_tang_ca_thanh_cong": "",
        "DHR_Dang_ky_thai_san": "",
        "DHR_Dang_ky_truoc": "",
        "DHR_Dang_ky_truoc_d": "Registration before",
        "DHR_Dang_nhap": "登录",
        "DHR_Dang_nhap_lai2": "",
        "DHR_Dang_noi_bo": "",
        "DHR_Dang_noi_bo_W25DESC": "",
        "DHR_Dang_review": "",
        "DHR_Dang_tham_gia": "",
        "DHR_Dang_thuc_hien": "",
        "DHR_Dang_tin_tuyen_dung": "",
        "DHR_Dang_xuat": "",
        "DHR_Dang_xuat1": "",
        "DHR_Danh_gia": "",
        "DHR_Danh_gia_chung": "",
        "DHR_Danh_gia_hop_dong_thu_viec": "",
        "DHR_Danh_gia_nhan_vien": "",
        "DHR_Danh_gia_nhan_vien_dinh_ky": "Đánh giá nhân viên định kỳ",
        "DHR_Danh_gia_nhan_vien_het_han_HDLD": "",
        "DHR_Danh_gia_phong_ban": "",
        "DHR_Danh_muc_loai_chi_phi": "",
        "DHR_Danh_sach_bang_tinh_KQTH": "",
        "DHR_Danh_sach_bang_tong_hop": "",
        "DHR_Danh_sach_cap_phat_thiet_bi_lao_dong": "",
        "DHR_Danh_sach_duyet_bang_muc_tieu": "",
        "DHR_Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan": "",
        "DHR_Danh_sach_khen_thuong": "",
        "DHR_Danh_sach_ky_luat": "Danh sách kỷ luật",
        "DHR_Danh_sach_muc_tieu": "",
        "DHR_Danh_sach_nhan_vien": "雇员名单",
        "DHR_Danh_sach_nhan_vien_danh_gia": "",
        "DHR_Danh_sach_nhan_vien_tham_gia": "",
        "DHR_Danh_sach_phong_ban": "",
        "DHR_Danh_sach_tin_da_dang": "",
        "DHR_Danh_sach_tin_tuyen_dung": "",
        "DHR_Danh_sach_trang_thiet_bi_lao_dong": "",
        "DHR_Danh_sach_ung_vien": "",
        "DHR_Danh_sach_vai_tro": "",
        "DHR_Dap_ung": "",
        "DHR_Dat": "Pass",
        "DHR_De_nghi_tuyen": "",
        "DHR_De_nghi_tuyen_ung_vien_nay": "",
        "DHR_De_xuat_dao_tao": "",
        "DHR_De_xuat_dieu_chinh_lao_dong": "",
        "DHR_De_xuat_dieu_chinh_luong": "",
        "DHR_De_xuat_khen_thuong": "",
        "DHR_De_xuat_kiem_nhiemU": "",
        "DHR_De_xuat_ky_luat": "",
        "DHR_De_xuat_offer": "",
        "DHR_De_xuat_thay_doi_chuc_danh": "",
        "DHR_De_xuat_tuyen_dung": "",
        "DHR_Deadline": "",
        "DHR_Den": "至",
        "DHR_Den_ngay": "Date to",
        "DHR_Di_dong": "手机",
        "DHR_Di_tre": "",
        "DHR_Di_xe": "",
        "DHR_Dia_chi": "地址",
        "DHR_Dia_chi_lien_lac": "联系地址",
        "DHR_Dia_chi_tam_tru": "临时地址",
        "DHR_Dia_chi_thuong_tru": "永久地址",
        "DHR_Dia_diem": "",
        "DHR_Dia_diem_phong_van": "",
        "DHR_Diem": "",
        "DHR_Diem_den": "",
        "DHR_Diem_di": "",
        "DHR_Diem_ky_vong": "",
        "DHR_Diem_so_khong_hop_le": "",
        "DHR_Diem_trung_binh": "",
        "DHR_Dien_giai": "描述",
        "DHR_Dien_thoai": "电话",
        "DHR_Dien_thoai_noi_bo": "",
        "DHR_Dieu_chinh": "",
        "DHR_Dieu_chinh_luong": "",
        "DHR_Dieu_chinh_nguoi_phu_trach": "",
        "DHR_Dieu_chinh_thong_tin": "",
        "DHR_Dieu_chinh_thong_tin_gia_dinh": "",
        "DHR_Dieu_kien_tinh_huong": "",
        "DHR_Dinh_dang_file_khong_hop_le": "",
        "DHR_Dinh_dang_mau_khong_hop_le.Cho_phep_cac_loai_mau_.xlxs_.docx_.html": "",
        "DHR_Dinh_dang_ngay_khong_dung": "",
        "DHR_Dinh_kem": "Attachments",
        "DHR_Dinh_vi_khoa": "",
        "DHR_Do_tuoi": "年龄",
        "DHR_Do_uu_tien": "",
        "DHR_Doanh_nghiep": "業務",
        "DHR_Doi_mat_khau": "",
        "DHR_Doi_mat_khau_thanh_cong": "",
        "DHR_Doi_tuong": "对象",
        "DHR_Doi_tuong_ap_dung": "",
        "DHR_Doi_tuong_cong_phep": "Leave object",
        "DHR_Doi_tuong_lao_dong": "",
        "DHR_Doi_tuong_tinh_luong": "",
        "DHR_Don_tu": "",
        "DHR_Don_tu_cua_toi": "",
        "DHR_Don_tu_toi_duyet": "",
        "DHR_Don_ung_tuyen": "",
        "DHR_Don_vi": "单位",
        "DHR_Don_vi_da_lam_viec": "Đơn vị đã làm việc",
        "DHR_Don_vi_moi": "",
        "DHR_Don_vi_tinh": "",
        "DHR_Dong1": "",
        "DHR_Dong_W25DESC": "",
        "DHR_Dong_y": "",
        "DHR_Du_an": "Project",
        "DHR_Du_an_cong_trinh": "",
        "DHR_Du_an_moi": "",
        "DHR_Du_lieu": "数据",
        "DHR_Du_lieu_ca_nhan,_ban_khong_co_quyen_xem": "",
        "DHR_Du_lieu_cham_phep": "Record Leave Data",
        "DHR_Du_lieu_chua_duoc_luu._Ban_co_muon_luu?": "",
        "DHR_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "DHR_Du_lieu_da_bi_thay_doi_ban_co_muon_luu_khong": "",
        "DHR_Du_lieu_dac_biet": "",
        "DHR_Du_lieu_dau_vao_la_bat_buoc": "",
        "DHR_Du_lieu_phep_tong_hop": "",
        "DHR_Du_lieu_xuat_excel_rong": "",
        "DHR_Dung_luong_File_khong_duoc_lon_hon": "",
        "DHR_Duyet": "批准",
        "DHR_Duyet_bang_muc_tieu": "",
        "DHR_Duyet_bo_sung_thoi_gian_vao_ra": "",
        "DHR_Duyet_chung": "",
        "DHR_Duyet_cong_tac": "",
        "DHR_Duyet_dang_ky_tang_ca_ho": "",
        "DHR_Duyet_danh_gia_nhan_vien_va_HDLD": "",
        "DHR_Duyet_de_xuat_chinh_sach_che_do": "",
        "DHR_Duyet_de_xuat_dao_tao": "",
        "DHR_Duyet_de_xuat_dieu_chuyen_lao_dong": "",
        "DHR_Duyet_de_xuat_khen_thuong": "",
        "DHR_Duyet_de_xuat_kiem_nhiem": "",
        "DHR_Duyet_de_xuat_nghi_viec": "",
        "DHR_Duyet_de_xuat_offer": "",
        "DHR_Duyet_de_xuat_thay_doi_chuc_danh": "",
        "DHR_Duyet_de_xuat_tuyen_dung": "",
        "DHR_Duyet_di_tre_ve_som": "",
        "DHR_Duyet_dieu_chinh_luong": "",
        "DHR_Duyet_dieu_chinh_thong_tin": "Duyệt điều chỉnh thông tin",
        "DHR_Duyet_dieu_chinh_thu_nhap": "",
        "DHR_Duyet_gio_tang_ca_thuc_te": "",
        "DHR_Duyet_gio_tang_ca_thuc_te_hang_loat": "",
        "DHR_Duyet_gio_vao_ra": "",
        "DHR_Duyet_hang_loat": "",
        "DHR_Duyet_ket_qua_muc_tieu_ca_nhan": "",
        "DHR_Duyet_khen_thuong_hang_loat": "",
        "DHR_Duyet_khong_thanh_cong": "",
        "DHR_Duyet_ky_luat": "",
        "DHR_Duyet_ky_luat_hang_loat": "",
        "DHR_Duyet_mien_nhiem": "",
        "DHR_Duyet_muc_tieu": "",
        "DHR_Duyet_nghi_phep": "",
        "DHR_Duyet_nghi_thai_san": "",
        "DHR_Duyet_quyet_toan_chi_phi": "",
        "DHR_Duyet_quyet_toan_chi_phi_cong_tac": "",
        "DHR_Duyet_sai_sot_cong": "",
        "DHR_Duyet_tang_ca": "",
        "DHR_Duyet_tang_ca_hang_loat": "",
        "DHR_Duyet_thanh_cong": "",
        "DHR_Email": "电子邮件",
        "DHR_Email_ca_nhan": "",
        "DHR_Email_gui_den_ung_vien": "",
        "DHR_Email_ho_tro": "",
        "DHR_Email_khong_hop_le": "",
        "DHR_Email_tu_dong": "",
        "DHR_Export_danh_sach_ung_vien": "",
        "DHR_Facebook_profile": "",
        "DHR_File_da_duoc_dinh_kem": "",
        "DHR_File_khong_dung_dinh_dang": "",
        "DHR_File_vuot_qua_dung_luong_cho_phep": "",
        "DHR_File_vuot_qua_so_luong_cho_phep": "",
        "DHR_Form_id": "",
        "DHR_Gan_ca": "",
        "DHR_Gan_ca_lam_viec": "",
        "DHR_Gan_ca_mac_dinh": "",
        "DHR_Gan_ca_xoay_vong": "",
        "DHR_Ghi_chep_noi_bo": "",
        "DHR_Ghi_chu": "注解 ",
        "DHR_Ghi_chu_duyet": "Approve Notes",
        "DHR_Ghi_chu_huy": "",
        "DHR_Ghi_chu_phong_van": "",
        "DHR_Ghi_chu_rieng_tu_nguoi_tao_lich_phong_van": "",
        "DHR_Ghi_chu_rieng_tu_tu_nguoi_tao_phong_van_voi_ung_vien_nay": "",
        "DHR_Ghi_chu_sau_thai_san": "",
        "DHR_Ghi_chu_tu_nguoi_tao_phong_van": "",
        "DHR_Ghi_chu_xac_nhan": "",
        "DHR_Gia_dinh": "",
        "DHR_Gia_tri": "",
        "DHR_Gia_tri_bat_buoc_nhap": "",
        "DHR_Gia_tri_lon_nhat": "",
        "DHR_Gia_tri_tu_phai_nho_hon_gia_tri_den": "",
        "DHR_Giai_doan": "",
        "DHR_Giai_doan1": "",
        "DHR_Giai_doanF": "",
        "DHR_Giai_doan_hien_co": "",
        "DHR_Giai_trinh_nguyen_nhan": "",
        "DHR_Giay_phep_lao_dong": "",
        "DHR_Gio_cong": "",
        "DHR_Gio_di_ca": "",
        "DHR_Gio_nghi_giua_ca": "",
        "DHR_Gio_phong_van": "",
        "DHR_Gio_ra": "去",
        "DHR_Gio_tang_ca": "",
        "DHR_Gio_tang_ca_den": "",
        "DHR_Gio_tang_ca_khong_hop_le": "",
        "DHR_Gio_tang_ca_tu": "",
        "DHR_Gio_vao": "进来",
        "DHR_Gio_vao_ra": "",
        "DHR_Gioi_han_toi_da_tong_trong_so_muc_tieu": "",
        "DHR_Gioi_thieu": "",
        "DHR_Gioi_thieu_ve_nhom": "",
        "DHR_Gioi_tinh": "性别",
        "DHR_Go_vai_tro_quan_tri_vien": "",
        "DHR_Gui": "Send",
        "DHR_Gui_email": "Send Mail",
        "DHR_Gui_lai_ma": "",
        "DHR_Gui_mail": "",
        "DHR_Gui_mail_thanh_cong": "Send mail success",
        "DHR_Gui_thanh_cong": "",
        "DHR_HOP_DONG_LAO_DONG": "",
        "DHR_Hai_long_can_cai_tien_them": "",
        "DHR_Han_hoan_thanh": "",
        "DHR_Han_review_cuoi": "",
        "DHR_Hang_loat": "",
        "DHR_Hanh_dong": "",
        "DHR_Hanh_vi_vi_pham_ky_luat": "",
        "DHR_He_so": "",
        "DHR_He_so_luong": "Salary Coefficient",
        "DHR_He_so_luong_%p": "",
        "DHR_He_thong_da_gui_ma_den_email": "",
        "DHR_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "DHR_Hien_muc_luong": "",
        "DHR_Hien_thi_cac_du_lieu_da_chon": "",
        "DHR_Hien_thi_du_lieu_da_chon": "",
        "DHR_Hien_thi_khong_su_dung": "",
        "DHR_Hien_thi_tat_ca": "",
        "DHR_Hien_thi_theo_tuan": "",
        "DHR_Hien_thi_vai_tro_khong_con_su_dung": "",
        "DHR_Hinh_thuc": "类型",
        "DHR_Hinh_thuc_huong_luong": "",
        "DHR_Hinh_thuc_khen_thuong": "",
        "DHR_Hinh_thuc_ky_luat": "",
        "DHR_Hinh_thuc_lam_viec": "工作类型",
        "DHR_Hinh_thuc_nghi_viec": "",
        "DHR_Hired": "",
        "DHR_Ho_chieu": "",
        "DHR_Ho_so_bao_hiem": "",
        "DHR_Ho_so_nhan_vien": "员工文件",
        "DHR_Ho_ten_ung_cu_vien": "",
        "DHR_Ho_tro": "",
        "DHR_Ho_va_ten": "全名",
        "DHR_Hoan_tat": "完成",
        "DHR_Hoan_thanh": "",
        "DHR_Hoan_toan_hai_long": "",
        "DHR_Hoc_van_chuyen_mon": "",
        "DHR_Hoc_van_va_chuyen_mon": "",
        "DHR_Hom_nay": "今天",
        "DHR_Home_page": "",
        "DHR_Huong_dan_su_dung": "",
        "DHR_Huong_dan_xoa_nhan_vien": "",
        "DHR_Huy": "Cancel",
        "DHR_Huy_bo": "",
        "DHR_Huy_dang_ky_tang_ca": "",
        "DHR_Huy_duyet_thanh_cong": "",
        "DHR_Huy_hoan_tat": "",
        "DHR_Huy_muc_tieu": "Hủy mục tiêu",
        "DHR_Huy_muc_tieu_thanh_cong": "Hủy mục tiêu thành công",
        "DHR_Huy_phep": "",
        "DHR_Huy_phep_thanh_cong": "",
        "DHR_Huy_tang_ca": "",
        "DHR_In_HDLD": "",
        "DHR_KSD": "迟顿 \t",
        "DHR_Ke_hoach_dao_tao": "",
        "DHR_Ke_hoach_tong_the": "",
        "DHR_Kenh_tuyen_dung": "",
        "DHR_Keo_tap_tin_vao_day": "",
        "DHR_Keo_va_tha_tap_tin_dinh_kem_vao_day": "",
        "DHR_Keo_va_tha_tep_tin_vao_day": "",
        "DHR_Ket_qua": "Result",
        "DHR_Ket_qua_%p": "",
        "DHR_Ket_qua_danh_gia": "",
        "DHR_Ket_qua_danh_gia_chung": "",
        "DHR_Ket_qua_review": "",
        "DHR_Ket_qua_then_chot": "",
        "DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "",
        "DHR_Khac": "其他",
        "DHR_Khao_sat_y_kien_khach_hang": "",
        "DHR_Khen_thuong_hang_loat": "",
        "DHR_Kho": "",
        "DHR_Kho_thu_hoi": "",
        "DHR_Khoa_dao_tao": "Training Courses",
        "DHR_Khoan_chi": "",
        "DHR_Khoan_chi_de_nghi_quyet_toan": "",
        "DHR_Khoan_chi_phi_du_kien": "",
        "DHR_Khoan_de_nghi_quyet_toan": "",
        "DHR_Khoan_dieu_chinh_thu_nhap": "",
        "DHR_Khoang_dieu_chinh_thu_nhap": "",
        "DHR_Khoang_thoi_gian": "",
        "DHR_Khoi_la_bat_buoc_nhap": "",
        "DHR_Khong": " 不是",
        "DHR_Khong_cho_phep": "",
        "DHR_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "DHR_Khong_co_tap_tin_dinh_kem": "",
        "DHR_Khong_co_thong_bao": "",
        "DHR_Khong_co_thong_bao_nhac_nho_nao": "Không có thông báo nhắc nhở nào",
        "DHR_Khong_co_ung_vien_duoc_chon_moi": "",
        "DHR_Khong_dat": "",
        "DHR_Khong_du_quyen": "",
        "DHR_Khong_duoc_phep_luu": "",
        "DHR_Khong_duoc_phep_nghi_qua_mot_ngay": "",
        "DHR_Khong_gui_email": "",
        "DHR_Khong_hien_muc_luong": "",
        "DHR_Khong_la_bang_tong_hop": "",
        "DHR_Khong_luu": "Not saved",
        "DHR_Khong_review": "",
        "DHR_Khong_su_dung": "迟顿 ",
        "DHR_Khong_ten": "",
        "DHR_Khong_the_dinh_kem": "",
        "DHR_Khong_the_duyet": "",
        "DHR_Khong_tim_thay_hoac_khong_du_tt": "",
        "DHR_Khong_tim_thay_trang": "",
        "DHR_Khong_trang_thai": "",
        "DHR_Khong_xet_duyet": "",
        "DHR_Kich_co_toi_da": "",
        "DHR_Kiem_nhiem": "",
        "DHR_Kien_thuc": "",
        "DHR_Kinh_nghiem": "",
        "DHR_Kinh_nghiem_den_khong_duoc_nho_hon_kinh_nghiem_tu": "",
        "DHR_Kinh_nghiem_tu_khong_duoc_lon_hon_kinh_nghiem_den": "",
        "DHR_Ky": "期间",
        "DHR_Ky_cong": "",
        "DHR_Ky_luat_hang_loat": "",
        "DHR_Ky_luong": "",
        "DHR_Ky_nang": "技巧",
        "DHR_La_bang_tong_hop": "",
        "DHR_Lam_moi": "",
        "DHR_Lap_de_xuat_chinh_sach_che_do": "",
        "DHR_Lap_de_xuat_dao_tao": "",
        "DHR_Lap_de_xuat_dieu_chinh_thu_nhap": "",
        "DHR_Lap_de_xuat_dieu_chuyen_lao_dong": "",
        "DHR_Lap_de_xuat_khen_thuong": "",
        "DHR_Lap_de_xuat_kiem_nhiem": "",
        "DHR_Lap_de_xuat_ky_luat": "",
        "DHR_Lap_de_xuat_mien_nhiem": "",
        "DHR_Lap_de_xuat_thay_doi_chuc_danh": "",
        "DHR_Lap_de_xuat_tuyen_dung": "",
        "DHR_Lap_khen_thuong_hang_loat": "",
        "DHR_Lap_ky_luat_hang_loat": "Lập kỷ luật hàng loạt",
        "DHR_Lap_lich_di_ca": "",
        "DHR_Lap_lich_hang_loat": "",
        "DHR_Lap_lich_tang_ca": "",
        "DHR_Lap_lich_tang_ca_hang_loat": "",
        "DHR_Lap_loai_chi_phi": "",
        "DHR_Lap_nghi_phep": "",
        "DHR_Lap_phieu_danh_gia_nhan_vien": "",
        "DHR_Lap_phieu_danh_gia_phong_ban": "",
        "DHR_Lay_ty_le_hoan_thanh_danh_gia_theo_cap": "",
        "DHR_Lich_su": "历史",
        "DHR_Lich_su_BHXH": "Lịch sử BHXH",
        "DHR_Lich_su_dao_tao": "训练的历史",
        "DHR_Lich_su_dctt": "",
        "DHR_Lich_su_dieu_chinh": "",
        "DHR_Lich_su_dieu_chinh_thong_tin": "Lịch sử điều chỉnh thông tin",
        "DHR_Lich_su_duyet": "瀏覽歷史",
        "DHR_Lich_su_lao_dong": "Lịch sử lao động",
        "DHR_Lich_su_nhan_su": "",
        "DHR_Lich_su_tac_dong": "",
        "DHR_Linh_vuc_dao_tao": "Training Subjects",
        "DHR_Linkedin_profile": "",
        "DHR_Loai": "",
        "DHR_Loai_HDLD": "",
        "DHR_Loai_che_do": "",
        "DHR_Loai_danh_gia": "",
        "DHR_Loai_doi_tuong_ap_dung": "",
        "DHR_Loai_don": "Loại đơn",
        "DHR_Loai_hinh_cong_viec": "",
        "DHR_Loai_hop_dong": "合同类型",
        "DHR_Loai_hop_dong_lao_dong": "劳动合同类型",
        "DHR_Loai_mail": "",
        "DHR_Loai_muc_tieu": "",
        "DHR_Loai_ngay": "Date Type",
        "DHR_Loai_nhanh": "",
        "DHR_Loai_phan_bo": "",
        "DHR_Loai_phep": "Leave Types",
        "DHR_Loai_phieu": "日志类型",
        "DHR_Loai_phong_van": "",
        "DHR_Loai_phu_luc": "",
        "DHR_Loai_quy_trinh": "",
        "DHR_Loai_tach_ca": "",
        "DHR_Loai_tach_tang_ca": "",
        "DHR_Loai_tach_tang_ca1": "",
        "DHR_Loai_tang_ca": "",
        "DHR_Loai_tap_tin_ho_tro": "",
        "DHR_Loai_thu_nhap": "",
        "DHR_Loai_tien": "货币",
        "DHR_Loc_chon_hanh_dong": "",
        "DHR_Loc_chon_nhan_vien": "",
        "DHR_Loc_trang_thiet_bi_lao_dong": "",
        "DHR_Loi_chua_xac_dinh": "",
        "DHR_Loi_khong_lay_duoc_token_CDN": "",
        "DHR_Lon_hon_hoac_bang": "",
        "DHR_Lua_chon": "",
        "DHR_Luong": "",
        "DHR_Luong_co_ban": "",
        "DHR_Luong_co_ban_%p": "",
        "DHR_Luong_cong_viec": "",
        "DHR_Luong_dong_bao_hiem": "",
        "DHR_Luong_tu_phai_nho_hon_luong_den": "",
        "DHR_Luong_yeu_cau": "",
        "DHR_Luu": "保存",
        "DHR_Luu_&_nhap_tiep": "",
        "DHR_Luu_dinh_kem_khong_thanh_cong": "",
        "DHR_Luu_khong_thanh_cong": "",
        "DHR_Luu_lich_su_khong_thanh_cong": "",
        "DHR_Luu_lich_su_thanh_cong": "",
        "DHR_Luu_thanh_cong": "",
        "DHR_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "DHR_Luu_va_cap_nhat_thanh_cong": "Lưu và cập nhật thành công",
        "DHR_Luu_va_nhap_tiep": "",
        "DHR_Ly_do": "原因",
        "DHR_Ly_do_cap_phat": "",
        "DHR_Ly_do_dao_tao": "Training Reason",
        "DHR_Ly_do_huy_dang_ky_tang_ca": "",
        "DHR_Ly_do_huy_phep": "",
        "DHR_Ly_do_tam_ung": "",
        "DHR_Ly_do_tang_ca": "",
        "DHR_Ly_do_thu_hoi": "",
        "DHR_Ly_do_tu_choi_2": "",
        "DHR_Ly_do_tuyen": "",
        "DHR_Ma": "Code",
        "DHR_Ma_CCLD": "",
        "DHR_Ma_HDLD": "",
        "DHR_Ma_NV": "员工代码",
        "DHR_Ma_UV": "",
        "DHR_Ma_bang_muc_tieu": "",
        "DHR_Ma_bang_tong_hop": "",
        "DHR_Ma_ca": "",
        "DHR_Ma_cham_cong": "",
        "DHR_Ma_chuc_vu_moi": "",
        "DHR_Ma_cong_tac": "",
        "DHR_Ma_doi_tuong": "对象代码",
        "DHR_Ma_du_an": "",
        "DHR_Ma_hop_dong": "",
        "DHR_Ma_loai_tach": "",
        "DHR_Ma_ly_do": "",
        "DHR_Ma_nghiep_vu": "",
        "DHR_Ma_nguoi_dung": "用户代码",
        "DHR_Ma_nhan_vien": "员工代码",
        "DHR_Ma_nhan_vien_Ten_nhan_vien": "",
        "DHR_Ma_nhan_vien_phu": "",
        "DHR_Ma_nhom_muc_tieu": "",
        "DHR_Ma_phieu": "",
        "DHR_Ma_phong_ban": "Department Code",
        "DHR_Ma_so_thue_ca_nhan": "个人所得税码",
        "DHR_Ma_the_cham_cong": "",
        "DHR_Ma_thong_ke": "",
        "DHR_Ma_ung_vien_Ten_ung_vien": "",
        "DHR_Ma_vai_tro": "",
        "DHR_Ma_vat_tu": "",
        "DHR_Ma_vat_tu_ten_vat_tu": "",
        "DHR_Mail_rong": "",
        "DHR_Man_hinh": "",
        "DHR_Man_hinh_mac_dinh_khi_dang_nhap": "",
        "DHR_Mat_khau": "密码",
        "DHR_Mat_khau_co_hieu_luc_trong_vong_5_phut": "",
        "DHR_Mat_khau_cu": "",
        "DHR_Mat_khau_khong_dung_vui_long_thu_lai": "",
        "DHR_Mat_khau_moi": "Change Password",
        "DHR_Mat_khau_phai_co_it_nhat_8_ky_tu": "",
        "DHR_Mat_khau_xac_nhan_khong_chinh_xac": "",
        "DHR_Mau_bao_cao": "報告碼",
        "DHR_Mau_bao_cao_khong_duoc_tim_thay": "",
        "DHR_Mau_ca": "",
        "DHR_Mau_email_hoac_phong_van": "",
        "DHR_Mau_giao_dien": "",
        "DHR_Mau_mail_co_san": "",
        "DHR_Mau_sac": "",
        "DHR_Mau_sac_hien_thi": "",
        "DHR_Menu_id": "",
        "DHR_Mo_rong": "",
        "DHR_Mo_ta": "",
        "DHR_Mo_ta_cong_viec": "",
        "DHR_Mo_ta_ly_do_khac": "",
        "DHR_Mo_ta_mau_sac": "",
        "DHR_Mo_tat_ca": "",
        "DHR_Moi": "",
        "DHR_Moi_bo_sung": "",
        "DHR_Mot_thang_truoc": "",
        "DHR_Mot_tuan_truoc": "",
        "DHR_Muc_dich_cong_tac": "",
        "DHR_Muc_do_dong_y": "",
        "DHR_Muc_luong": "Salary",
        "DHR_Muc_luong_den": "",
        "DHR_Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu": "",
        "DHR_Muc_luong_du_kien": "",
        "DHR_Muc_luong_tu": "",
        "DHR_Muc_luong_tu_khong_duoc_lon_muc_luong_den": "",
        "DHR_Muc_luong_va_phu_cap": "Mức lương và phụ cấp",
        "DHR_Muc_tieu1": "",
        "DHR_Muc_tieu_ca_nhan": "",
        "DHR_Muc_tieu_co_cau_to_chuc": "",
        "DHR_Muc_tieu_kiem_nhiem": "",
        "DHR_Muc_tieu_nhan_vien": "",
        "DHR_NV_chua_tham_gia_khoa_dao_tao": "",
        "DHR_Nam": "Year",
        "DHR_Nam1": "",
        "DHR_NamU": "男",
        "DHR_Nam_sinh": "出生年份",
        "DHR_Ngan_hang": "",
        "DHR_Ngay": "日期",
        "DHR_Ngay1": "",
        "DHR_Ngay3": "",
        "DHR_Ngay_bat_dau": "开始日期",
        "DHR_Ngay_bat_dau_nghi_viec": "",
        "DHR_Ngay_bat_dau_thu_viec": "",
        "DHR_Ngay_bo_sung": "",
        "DHR_Ngay_bo_sung_gio_vao_ra_khong_co_ca_._Ban_vui_long_chon_ngay_khac": "",
        "DHR_Ngay_cap": "发行日期",
        "DHR_Ngay_cap_BHXH": "",
        "DHR_Ngay_cap_CMND": "",
        "DHR_Ngay_cap_ho_chieu": "",
        "DHR_Ngay_cap_phat": "",
        "DHR_Ngay_cap_phat_CCLD_truoc": "",
        "DHR_Ngay_cap_the_can_cuoc": "",
        "DHR_Ngay_cham_cong": "",
        "DHR_Ngay_chot_van_tay": "",
        "DHR_Ngay_cong": "",
        "DHR_Ngay_da_nghi_phep": "",
        "DHR_Ngay_dang_ky_KCB": "",
        "DHR_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "",
        "DHR_Ngay_danh_gia": "",
        "DHR_Ngay_danh_gia_bat_buoc_nhap": "",
        "DHR_Ngay_de_xuat": "Proposal Date",
        "DHR_Ngay_de_xuat_tam_ung": "",
        "DHR_Ngay_den": "",
        "DHR_Ngay_di": "",
        "DHR_Ngay_di/ve": "",
        "DHR_Ngay_di_/_ve": "",
        "DHR_Ngay_di_ca": "",
        "DHR_Ngay_di_lam_du_kien": "",
        "DHR_Ngay_di_lam_thuc_te": "",
        "DHR_Ngay_dieu_chinh": "",
        "DHR_Ngay_du_sinh": "",
        "DHR_Ngay_duyet": "批准日期",
        "DHR_Ngay_het_han": "到期日",
        "DHR_Ngay_het_han_ho_chieu": "",
        "DHR_Ngay_hieu_luc": "有效期",
        "DHR_Ngay_hieu_luc_den": "",
        "DHR_Ngay_hieu_luc_tu": "",
        "DHR_Ngay_hoan_thanh": "Finish Date",
        "DHR_Ngay_ket_thuc": "完成日期",
        "DHR_Ngay_ket_thuc_che_do": "",
        "DHR_Ngay_ket_thuc_thu_viec": "",
        "DHR_Ngay_khoi_hanh": "",
        "DHR_Ngay_ky_phu_luc": "",
        "DHR_Ngay_lam_viec": "Working Date",
        "DHR_Ngay_lap": "Created Date",
        "DHR_Ngay_nghi": "",
        "DHR_Ngay_nghi_viec": "",
        "DHR_Ngay_nhan": "Received Date",
        "DHR_Ngay_nhan_ho_so": "",
        "DHR_Ngay_nop_don": "",
        "DHR_Ngay_phep": "Leave date",
        "DHR_Ngay_phieu": "傳票日期",
        "DHR_Ngay_phong_van": "Interview Date",
        "DHR_Ngay_sinh": "出生日期",
        "DHR_Ngay_sinh_con": "",
        "DHR_Ngay_tac_dong": "",
        "DHR_Ngay_tach_tang_ca": "Ngày tách tăng ca",
        "DHR_Ngay_tang_ca": "",
        "DHR_Ngay_tao": "",
        "DHR_Ngay_tham_gia_BHXH": "",
        "DHR_Ngay_tham_gia_bao_hiem": "",
        "DHR_Ngay_thu_hoi": "",
        "DHR_Ngay_thuc_hien": "",
        "DHR_Ngay_tu": "",
        "DHR_Ngay_vao": "开始日期",
        "DHR_Ngay_vao_lam": "开始日期",
        "DHR_Ngay_vao_lam_du_kien": "",
        "DHR_Ngay_ve": "",
        "DHR_Ngay_xin_nghi_viec": "",
        "DHR_Ngay_xin_thoi_viec": "",
        "DHR_Nghi_phep": "",
        "DHR_Nghi_phep_trong_ngay": "",
        "DHR_Nghi_thai_san": "",
        "DHR_Nghi_viec": "",
        "DHR_Nghiep_vu": "",
        "DHR_Ngoai_te": "",
        "DHR_Nguoi_PV": "Interviewer",
        "DHR_Nguoi_dai_dien": "Agency",
        "DHR_Nguoi_danh_gia": "",
        "DHR_Nguoi_danh_gia_bat_buoc_nhap": "",
        "DHR_Nguoi_dao_tao": "",
        "DHR_Nguoi_dieu_chinh": "",
        "DHR_Nguoi_dung": "用戶",
        "DHR_Nguoi_duyet": "批准者",
        "DHR_Nguoi_lap": "创建者",
        "DHR_Nguoi_lien_he": "",
        "DHR_Nguoi_lien_he_so_1": "",
        "DHR_Nguoi_lien_he_so_2": "",
        "DHR_Nguoi_nhan_email": "",
        "DHR_Nguoi_nuoc_ngoai": "",
        "DHR_Nguoi_phong_van": "",
        "DHR_Nguoi_phu_trach": "",
        "DHR_Nguoi_quan_ly": "",
        "DHR_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "DHR_Nguoi_tao": "",
        "DHR_Nguoi_tao_tin": "",
        "DHR_Nguoi_thuc_hien": "",
        "DHR_Nguoi_uy_quyen": "",
        "DHR_Nguoi_xem_phong_van": "",
        "DHR_Nguon": "",
        "DHR_Nguon_ung_tuyen": "",
        "DHR_Nguon_ung_vien": "",
        "DHR_Nguyen_quan": "",
        "DHR_Nhac_lich": "",
        "DHR_Nhac_nho1": "Nhắc nhở",
        "DHR_Nhan_Vien_Chua_co_ca": "",
        "DHR_Nhan_vao_de_tai_hinh_hoac_keo_tha_hinh_vao_day": "",
        "DHR_Nhan_vien": "",
        "DHR_Nhan_vien_chua_duoc_cap_phat_CCLD": "",
        "DHR_Nhan_vien_da_duoc_thu_hoi_ccld_khong_duoc_phep_xoa": "",
        "DHR_Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen": "",
        "DHR_Nhan_vien_tu_danh_gia_het_HDLD": "",
        "DHR_Nhan_xet_chung": "",
        "DHR_Nhan_xet_ung_vien": "",
        "DHR_Nhap": "",
        "DHR_Nhap_": "",
        "DHR_Nhap_Ten_Dang_Nhap": "Enter username",
        "DHR_Nhap_W25DESC": "",
        "DHR_Nhap_de_tim_kiem": "",
        "DHR_Nhap_email": "",
        "DHR_Nhap_ma_bao_mat": "",
        "DHR_Nhap_mat_khau": "输入密码",
        "DHR_Nhap_tiep": "",
        "DHR_Nhap_tu_tim_kiem": "",
        "DHR_Nhat_ky_cong_tac": "",
        "DHR_Nhieu_ngay": "",
        "DHR_Nho_hon_hoac_bang1": "",
        "DHR_Nho_mat_khau": "",
        "DHR_Nho_mat_khau1": "",
        "DHR_Nhom": "Groups",
        "DHR_Nhom_chi_tieu": "",
        "DHR_Nhom_hanh_vi_vi_pham_ky_luat": "",
        "DHR_Nhom_luong": "",
        "DHR_Nhom_muc_tieu": "",
        "DHR_Nhom_nhan_vien": "",
        "DHR_Nhung_nguoi_co_quyen_xem_toan_bo_TTUV_va_xu_ly_quy_trinh_tuyen_dung": "",
        "DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "DHR_No": "借方",
        "DHR_No_data": "",
        "DHR_Noi_bo": "",
        "DHR_Noi_cap": "发行地点",
        "DHR_Noi_cap_BHXH": "",
        "DHR_Noi_cap_CMND": "",
        "DHR_Noi_cap_han_ho_chieu": "",
        "DHR_Noi_cap_ho_chieu": "Passport Place",
        "DHR_Noi_cap_the_can_cuoc": "",
        "DHR_Noi_dang_ky_KCB": "",
        "DHR_Noi_dung": "内容",
        "DHR_Noi_dung_can_tim": "",
        "DHR_Noi_dung_can_tim_kiem": "",
        "DHR_Noi_dung_dao_tao": "",
        "DHR_Noi_dung_mail_khong_duoc_de_trong": "",
        "DHR_Noi_dung_nhat_ky": "",
        "DHR_Noi_dung_tim_kiem": "",
        "DHR_Noi_dung_tin_tuyen_dung": "",
        "DHR_Noi_sinh": "出生地",
        "DHR_Nu": "",
        "DHR_Nuoc_ngoai": "",
        "DHR_Phai_chon_it_nhat_1_trang_thai": "",
        "DHR_Phai_la_so_lon_hon_hoac_bang_khong": "",
        "DHR_Phai_la_so_lon_hon_khong": "",
        "DHR_Phai_la_so_nguyen_duong": "",
        "DHR_Phai_lon_hon_gia_tri_tu": "",
        "DHR_Phai_nhap_so_phut_cho_it_nhat_mot_loai_xin_phep": "",
        "DHR_Phai_trong_khoang_tu_0_den_100": "",
        "DHR_Phan_bo_chi_phi": "",
        "DHR_Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Phan_bo_toan_tu": "",
        "DHR_Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong": "",
        "DHR_Phan_bo_toan_tu_theo_tung_du_lieu_luong": "",
        "DHR_Phan_quyen": "",
        "DHR_Phan_quyen_chuc_nang": "",
        "DHR_Phan_quyen_du_lieu": "",
        "DHR_Phan_quyen_ess": "",
        "DHR_Phan_quyen_tat_ca": "",
        "DHR_Phan_quyen_theo_vai_tro": "",
        "DHR_Phan_tram_hoan_thanh": "",
        "DHR_Phep": "年假",
        "DHR_Phep_bu": "",
        "DHR_Phep_con_lai": "",
        "DHR_Phep_da_nghi": "",
        "DHR_Phep_da_nghi_/_het_han": "Phép đã nghỉ / hết hạn",
        "DHR_Phep_het_han_chua_su_dung": "Phép hết hạn chưa sử dụng",
        "DHR_Phep_nam": "",
        "DHR_Phep_tham_nien": "",
        "DHR_Phep_ton": "",
        "DHR_Phien_ban": "",
        "DHR_Phieu_KCB_den_ngay": "",
        "DHR_Phieu_KCB_tu_ngay": "",
        "DHR_Phieu_cham_cong": "",
        "DHR_Phieu_cua_toi": "",
        "DHR_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "",
        "DHR_Phieu_danh_gia": "",
        "DHR_Phieu_danh_gia_nhan_vien": "",
        "DHR_Phieu_huy": "",
        "DHR_Phong_ban": "部门",
        "DHR_Phong_ban_moi": "",
        "DHR_Phong_van": "",
        "DHR_Phong_van_online": "",
        "DHR_Phong_van_qua_dien_thoai": "",
        "DHR_Phong_van_tai_van_phong": "",
        "DHR_Phu_luc": "",
        "DHR_Phuong": "",
        "DHR_Phuong_phap_danh_gia": "",
        "DHR_Phuong_phap_dieu_chinh_luong": "",
        "DHR_Phuong_phap_do": "",
        "DHR_Phuong_phap_tinh": "",
        "DHR_Qua_han": "",
        "DHR_Quan": "",
        "DHR_Quan_he": "关系",
        "DHR_Quan_he_gia_dinh": "家庭关系",
        "DHR_Quan_ly": "",
        "DHR_Quan_ly_cong_tac_va_chi_phi": "",
        "DHR_Quan_tri": "",
        "DHR_Quan_tri_vien": "",
        "DHR_Que_quan": "家乡",
        "DHR_Quen_mat_khau": "",
        "DHR_Quoc_gia": "",
        "DHR_Quoc_tich": "",
        "DHR_Quy": "Quater",
        "DHR_Quy_doi_phep": "",
        "DHR_Quy_nay": "",
        "DHR_Quy_trinh": "",
        "DHR_Quy_trinh_ap_dung": "",
        "DHR_Quy_trinh_tao_muc_tieu": "",
        "DHR_Quy_trinh_tuyen_dung_Workflow": "",
        "DHR_Quy_truoc": "",
        "DHR_Quy_uoc_nhu_sau": "",
        "DHR_Quyen_han": "",
        "DHR_Quyet_toan": "",
        "DHR_Ra_som": "",
        "DHR_Review_muc_tieu": "",
        "DHR_Roi_khoi_nhom": "",
        "DHR_SDT_ho_tro": "",
        "DHR_SLA": "",
        "DHR_STT": " 序",
        "DHR_Sai_sot_cong": "",
        "DHR_Sang_loc_ung_vien": "",
        "DHR_Sao_chep_CCLD": "",
        "DHR_Sao_chep_cap_phat_truoc": "",
        "DHR_Sao_chep_quyen": "",
        "DHR_Sao_chep_thanh_cong": "",
        "DHR_Sao_chep_tin_dang": "",
        "DHR_Sau_thai_san:": "",
        "DHR_Scheduled": "",
        "DHR_Sinh_nhat": "",
        "DHR_Size_ao": "",
        "DHR_Size_giay": "",
        "DHR_Size_quan": "",
        "DHR_Sl_nam": "",
        "DHR_Sl_nu": "",
        "DHR_So": "数",
        "DHR_So_CMND": "身份证号码",
        "DHR_So_Fax": "",
        "DHR_So_HDLD": "Labor Contract No",
        "DHR_So_bao_hiem": "",
        "DHR_So_can_cuoc": "",
        "DHR_So_cap_review": "",
        "DHR_So_cong": "",
        "DHR_So_cong_va_so_phep_phai_co_gia_tri": "",
        "DHR_So_dien_thoai": "Tel no.",
        "DHR_So_dien_thoai_khong_hop_le": "",
        "DHR_So_dong": "",
        "DHR_So_gio": "",
        "DHR_So_gio_dao_tao": "",
        "DHR_So_gio_tang_ca": "",
        "DHR_So_gio_vuot": "",
        "DHR_So_ho_chieu": "",
        "DHR_So_luong": "数量",
        "DHR_So_luong_can_tuyen": "",
        "DHR_So_luong_cho_phep": "",
        "DHR_So_luong_cong": "",
        "DHR_So_luong_da_nghi_den_ky_hien_tai": "",
        "DHR_So_luong_de_xuat": "",
        "DHR_So_luong_duoc_cap": "Quantity",
        "DHR_So_luong_nhan_vien_khong_bang_de_xuat": "",
        "DHR_So_luong_phai_lon_hon_0": "",
        "DHR_So_luong_phep": "",
        "DHR_So_luong_phep_khong_hop_le": "",
        "DHR_So_luong_ton_den_cuoi_nam": "Leave Quantity To End Year",
        "DHR_So_luong_ton_den_ky_hien_tai": "",
        "DHR_So_luong_ton_kho_khong_du_cap_phat": "",
        "DHR_So_luong_tuyen": "",
        "DHR_So_luong_ung_vien": "Số lượng ứng viên",
        "DHR_So_nam_kinh_nghiem": "",
        "DHR_So_ngay": "天数",
        "DHR_So_ngay_con_lai": "",
        "DHR_So_ngay_cong": "",
        "DHR_So_ngay_da_nghi": "",
        "DHR_So_ngay_hieu_luc": "",
        "DHR_So_ngay_hieu_luc_phep": "",
        "DHR_So_ngay_nghi_trong_ky": "",
        "DHR_So_ngay_vi_pham": "",
        "DHR_So_nha": "",
        "DHR_So_phep": "",
        "DHR_So_phu_luc": "",
        "DHR_So_phut": "",
        "DHR_So_quyet_dinh": "决策数",
        "DHR_So_so_BHXH": "保险手册编号",
        "DHR_So_so_bao_hiem_xa_hoi": "",
        "DHR_So_thang": "月数",
        "DHR_So_thang_da_su_dung": "",
        "DHR_So_thang_dao_tao": "",
        "DHR_So_thang_khong_duoc_qua_lon": "",
        "DHR_So_thang_su_dung": "",
        "DHR_So_the_BHYT": "",
        "DHR_So_the_bao_hiem_y_te": "",
        "DHR_So_the_can_cuoc": "",
        "DHR_So_thu_tu": "",
        "DHR_So_tien": "量",
        "DHR_So_tien_de_xuat_tam_ung": "",
        "DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu": "",
        "DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den": "",
        "DHR_Started": "",
        "DHR_Su_dung": "",
        "DHR_Sua": "Edit",
        "DHR_Sua_binh_luan_khong_thanh_cong": "",
        "DHR_Sua_binh_luan_thanh_cong": "",
        "DHR_Sua_du_lieu": "",
        "DHR_Sua_phong_van": "",
        "DHR_Tags": "",
        "DHR_Tai_bao_cao_(excel)": "Tải báo cáo (excel)",
        "DHR_Tai_khoan_Ngan_hang": "",
        "DHR_Tai_khoan_bi_khoa_10_phut": "",
        "DHR_Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap": "",
        "DHR_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "DHR_Tai_khoan_khong_co_quyen": "",
        "DHR_Tai_lieu": "",
        "DHR_Tai_lieu_dinh_kem": "",
        "DHR_Tam_dap_ung_Can_cai_tien_them": "",
        "DHR_Tam_ung": "Advance",
        "DHR_Tang_ca": "加时",
        "DHR_Tang_ca_den": "",
        "DHR_Tang_ca_giua": "",
        "DHR_Tang_ca_sau": "",
        "DHR_Tang_ca_truoc": "",
        "DHR_Tang_ca_tu": "",
        "DHR_Tao_boi": "",
        "DHR_Tao_muc_tieu_cho_chuc_danh_kiem_nhiem": "",
        "DHR_Tao_review": "",
        "DHR_Tao_review_thanh_cong": "",
        "DHR_Tao_tu_danh_muc_muc_tieu": "",
        "DHR_Tap_tin": "",
        "DHR_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "DHR_Tap_tin_tai_len": "",
        "DHR_Tat_ca": "",
        "DHR_Tat_ca_danh_gia": "",
        "DHR_Tat_ca_don_tu": "",
        "DHR_Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD": "",
        "DHR_Tat_ca_thao_tac_nhap_lieu_truoc_do_se_bi_mat,ban_co_muon_tiep_tuc_khong?": "",
        "DHR_Tat_ca_tin": "",
        "DHR_Ten": "名称 \t",
        "DHR_Ten_CCLD": "",
        "DHR_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "DHR_Ten_Thanh_pho": "",
        "DHR_Ten_UV": "",
        "DHR_Ten_bang_KQ_tong_hop": "",
        "DHR_Ten_bang_muc_tieu": "",
        "DHR_Ten_bang_tong_hop": "",
        "DHR_Ten_ca": "",
        "DHR_Ten_chi_tieu": "",
        "DHR_Ten_chuc_vu_moi": "",
        "DHR_Ten_doi_tuong": "对象名称",
        "DHR_Ten_giai_doan": "",
        "DHR_Ten_ket_qua_then_chot": "",
        "DHR_Ten_kho": "仓库名称",
        "DHR_Ten_loai_chi_phi": "",
        "DHR_Ten_loai_tach": "",
        "DHR_Ten_ly_do": "",
        "DHR_Ten_man_hinh": "用户名称",
        "DHR_Ten_muc_tieu": "",
        "DHR_Ten_nghiep_vu": "",
        "DHR_Ten_nguoi_dung": "用户名",
        "DHR_Ten_nguoi_quan_he": "",
        "DHR_Ten_nhan_su": "",
        "DHR_Ten_nhan_vien": "",
        "DHR_Ten_nhom": "",
        "DHR_Ten_nhom_muc_tieu": "",
        "DHR_Ten_phieu": "",
        "DHR_Ten_phong_ban": "部门名称",
        "DHR_Ten_phong_ban_VI": "",
        "DHR_Ten_phuong": "",
        "DHR_Ten_quan": "",
        "DHR_Ten_quy_trinh": "",
        "DHR_Ten_tach_tang_ca": "",
        "DHR_Ten_tinh_nang": "",
        "DHR_Ten_trang_thai_duyet": "",
        "DHR_Ten_vai_tro": "",
        "DHR_Ten_vat_tu": "",
        "DHR_Tham_chieu_chuoi": "",
        "DHR_Tham_chieu_so": "",
        "DHR_Tham_chieu_xep_loai": "",
        "DHR_Tham_nien": "排行",
        "DHR_Tham_so_truyen_vao_khong_hop_le": "",
        "DHR_Thang": "",
        "DHR_Thang_nay": "本月",
        "DHR_Thang_truoc": "",
        "DHR_Thanh_pho": "",
        "DHR_Thanh_tien(USD)": "",
        "DHR_Thanh_tien(VND)": "",
        "DHR_Thanh_vien": "",
        "DHR_Thanh_vien_cua_Nihon_Grouping_va_Pan": "",
        "DHR_Thanh_vien_duoc_chon": "",
        "DHR_Thanh_vien_quan_ly": "",
        "DHR_Thanh_vien_vai_tro_quan_tri": "",
        "DHR_Thay_the_nguoi_duyet": "",
        "DHR_Thay_the_nguoi_duyet_thanh_cong": "",
        "DHR_The_can_cuoc": "",
        "DHR_The_luu_tru": "",
        "DHR_Them": "Add",
        "DHR_Them_binh_luan_thanh_cong": "",
        "DHR_Them_dinh_kem_thanh_cong": "",
        "DHR_Them_dinh_kem_that_bai": "",
        "DHR_Them_giai_doan": "",
        "DHR_Them_ket_qua_then_chot": "",
        "DHR_Them_ket_qua_then_chot_thanh_cong": "",
        "DHR_Them_ket_qua_then_chot_that_bai": "",
        "DHR_Them_lich_phong_van": "",
        "DHR_Them_loai_tach_tang_ca": "",
        "DHR_Them_moi": "",
        "DHR_Them_moi1": "添新",
        "DHR_Them_moi_tin_tuyen_dung": "",
        "DHR_Them_moi_ung_vien": "",
        "DHR_Them_muc_tieu": "",
        "DHR_Them_phong_van_moi": "",
        "DHR_Them_tep_dinh_kem": "",
        "DHR_Them_thanh_vien": "",
        "DHR_Them_tieu_chi": "",
        "DHR_Them_tieu_chi_danh_gia": "",
        "DHR_Them_ung_vien": "",
        "DHR_Thiet_lap_cach_tinh_ket_qua": "",
        "DHR_Thiet_lap_cach_tinh_ket_qua_muc_tieu": "",
        "DHR_Thiet_lap_chi_tieu_tong_hop": "",
        "DHR_Thiet_lap_tong_hop": "",
        "DHR_Thoi_gian": "时间",
        "DHR_Thoi_gian_bat_dau": "",
        "DHR_Thoi_gian_can": "",
        "DHR_Thoi_gian_can_nhan_su": "",
        "DHR_Thoi_gian_dang_ky:": "",
        "DHR_Thoi_gian_dao_tao": "",
        "DHR_Thoi_gian_dong_BHXH": "",
        "DHR_Thoi_gian_hieu_luc": "",
        "DHR_Thoi_gian_ket_thuc": "",
        "DHR_Thoi_gian_muc_tieu": "",
        "DHR_Thoi_gian_nop_don": "",
        "DHR_Thoi_gian_nop_hoac_deadline_khong_hop_le": "",
        "DHR_Thoi_gian_phong_van": "",
        "DHR_Thoi_gian_ra_vao_VN": "",
        "DHR_Thoi_gian_tao": "",
        "DHR_Thoi_gian_tham_gia_BH": "Thời gian tham gia BH",
        "DHR_Thoi_gian_thu_viec": "",
        "DHR_Thoi_han_hop_dong": "",
        "DHR_Thoi_han_phu_luc": "",
        "DHR_Thong_bao": "Announcement",
        "DHR_Thong_bao1": "",
        "DHR_Thong_baoU": "",
        "DHR_Thong_ke": "统计",
        "DHR_Thong_ke_phong_van": "",
        "DHR_Thong_ke_ung_vien": "",
        "DHR_Thong_tin": "信息",
        "DHR_Thong_tin_ca_nhan": "个人信息",
        "DHR_Thong_tin_ca_nhan_DESC": "",
        "DHR_Thong_tin_cap_phat_cho": "",
        "DHR_Thong_tin_chi_tiet": "",
        "DHR_Thong_tin_chinh": "",
        "DHR_Thong_tin_chung": "一般资料",
        "DHR_Thong_tin_cong_viec": "",
        "DHR_Thong_tin_cu": "",
        "DHR_Thong_tin_cung_cap_khong_hop_le": "",
        "DHR_Thong_tin_dieu_chinh": "",
        "DHR_Thong_tin_gia_dinh": "",
        "DHR_Thong_tin_hien_tai": "",
        "DHR_Thong_tin_ket_qua_then_chot": "",
        "DHR_Thong_tin_kiem_nhiem": "",
        "DHR_Thong_tin_lien_he": "",
        "DHR_Thong_tin_lien_lac": "联系信息",
        "DHR_Thong_tin_luong": "工资信息",
        "DHR_Thong_tin_muc_tieu": "",
        "DHR_Thong_tin_nguoi_dung": "",
        "DHR_Thong_tin_nguoi_duyet": "",
        "DHR_Thong_tin_nhan_su": "",
        "DHR_Thong_tin_nhom": "",
        "DHR_Thong_tin_phan_quyen": "",
        "DHR_Thong_tin_phep": "年假信息",
        "DHR_Thong_tin_phong_van": "",
        "DHR_Thong_tin_tang_nhan_vien": "",
        "DHR_Thong_tin_thu_hoi_cho": "",
        "DHR_Thong_tin_tra_ve_khi_luu_khong_dung": "",
        "DHR_Thong_tin_tuyen_dung": "",
        "DHR_Thong_tin_vai_tro": "",
        "DHR_Thong_tin_xep_loai": "",
        "DHR_Thu_ba": "",
        "DHR_Thu_bay": "",
        "DHR_Thu_gon": "",
        "DHR_Thu_hai": "",
        "DHR_Thu_hoi": "",
        "DHR_Thu_hoi_trang_thiet_bi_lao_dong": "",
        "DHR_Thu_nam": "",
        "DHR_Thu_nho": "",
        "DHR_Thu_sau": "",
        "DHR_Thu_tu": "",
        "DHR_Thu_tuU": "",
        "DHR_Thu_tu_hien_thi": "序列号",
        "DHR_Thuc_hien1": "",
        "DHR_Thuc_hien_thanh_cong": "",
        "DHR_Tien_bao_hiem": "",
        "DHR_Tien_chi_tam_ung": "",
        "DHR_Tien_do_thuc_hien": "",
        "DHR_Tien_tro_cap": "",
        "DHR_Tiep_tuc": "",
        "DHR_Tieu_chi": "",
        "DHR_Tieu_chi_danh_gia": "Evaluation criteria",
        "DHR_Tieu_chi_loc": "",
        "DHR_Tieu_de": "Title",
        "DHR_Tieu_de_mail": "",
        "DHR_Tieu_de_phong_van": "",
        "DHR_Tieu_de_tin": "",
        "DHR_Tieu_de_tin_tuyen_dung": "",
        "DHR_Tim_kiem": "",
        "DHR_Tim_kiem_cap_phat_trang_thiet_bi_lao_dong": "",
        "DHR_Tim_kiem_cham_cong": "",
        "DHR_Tim_kiem_che_do_bao_hiem": "",
        "DHR_Tim_kiem_co_cau_to_chuc": "",
        "DHR_Tim_kiem_ho_so_nhan_su": "",
        "DHR_Tim_kiem_nhan_vien": "",
        "DHR_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "",
        "DHR_Tim_theo_ma_va_ten": "",
        "DHR_Tin_chua_duoc_dang_cong_khai": "",
        "DHR_Tin_hot": "",
        "DHR_Tin_tuyen_dung": "",
        "DHR_Tinh_cach": "Character",
        "DHR_Tinh_trang_hien_tai": "",
        "DHR_Tinh_trang_hon_nhan": "婚姻状况",
        "DHR_Tinh_trang_thu_hoi": "",
        "DHR_To_chuc": "组织",
        "DHR_To_chuc_Tuyen_dung_DESC": "",
        "DHR_To_nhom": "团队",
        "DHR_To_nhom_moi": "",
        "DHR_Toan_thoi_gian": "",
        "DHR_Toan_tu": "",
        "DHR_Toan_tu_so_cong": "",
        "DHR_Toan_tu_so_ngay_hieu_luc": "",
        "DHR_Toan_tu_so_phep": "",
        "DHR_Toi_da_tao": "",
        "DHR_Toi_quan_ly": "",
        "DHR_Tom_tat_ung_vien": "",
        "DHR_Ton": "",
        "DHR_Ton_giao": "宗教",
        "DHR_Ton_kho": "",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec": "Tồn tại nhân viên chưa có ca làm việc",
        "DHR_Ton_tai_nhan_vien_chua_co_ca_lam_viec.Ban_khong_duoc_phep_luu": "",
        "DHR_Ton_tai_nhan_vien_chua_co_thoi_gian_dang_ky_tang_ca": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_Ban_co_muon_luu_khong": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_co_muon_luu_khong": "",
        "DHR_Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_khong_duoc_phep_luu": "",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_,_khong_duoc_phep_xoa": "",
        "DHR_Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa": "",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec": "Tồn tại nhiều hơn 1 ca làm việc trong cùng một ngày làm việc",
        "DHR_Ton_tai_nhieu_hon_1_ca_lam_viec_trong_cung_mot_ngay_lam_viec.Ban_khong_duoc_phep_luu": "",
        "DHR_Tong": "",
        "DHR_Tong_chi_phi": "",
        "DHR_Tong_chi_phi_cong_ty_thanh_toan": "",
        "DHR_Tong_chi_phi_du_kien": "",
        "DHR_Tong_chi_phi_nhan_vien_su_dung": "",
        "DHR_Tong_chi_phi_quy_doi": "",
        "DHR_Tong_chi_phi_thuc_te_chuyen_di": "",
        "DHR_Tong_cong": "总额",
        "DHR_Tong_diem": "",
        "DHR_Tong_hop": "合成",
        "DHR_Tong_hop_chi_phi": "",
        "DHR_Tong_hop_danh_gia": "",
        "DHR_Tong_hop_ung_vien_moi_theo_ngay": "Tổng hợp ứng viên theo ngày",
        "DHR_Tong_hop_ung_vien_moi_theo_tuan": "Tổng hợp ứng viên theo tuần",
        "DHR_Tong_hop_ung_vien_theo_nguon_tuyen": "Tổng hợp ứng viên theo nguồn tuyển",
        "DHR_Tong_phep": "",
        "DHR_Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai": "",
        "DHR_Tong_quan": "",
        "DHR_Tong_so_NV": "",
        "DHR_Tong_so_cong": "",
        "DHR_Tong_so_luong": "",
        "DHR_Tong_so_ngay": "",
        "DHR_Tong_so_ngay_duoc_nghi": "",
        "DHR_Tong_so_nhan_vien": "",
        "DHR_Tong_so_phep": "",
        "DHR_Tong_trong_so_muc_tieu": "",
        "DHR_Tong_ty_le": "",
        "DHR_Trang": "",
        "DHR_Trang_Chu": "首页",
        "DHR_Trang_thai": "状态",
        "DHR_Trang_thai_bang_muc_tieu": "",
        "DHR_Trang_thai_danh_gia": "",
        "DHR_Trang_thai_duyet": "审批状态",
        "DHR_Trang_thai_lam_viec": "",
        "DHR_Trang_thai_muc_tieu": "",
        "DHR_Trang_thai_phieu_danh_gia": "",
        "DHR_Trang_thai_phong_van": "",
        "DHR_Trang_thai_su_dung": "",
        "DHR_Trang_thai_tin": "",
        "DHR_Trang_thiet_bi_lao_dong": "",
        "DHR_Trao_doi_email": "",
        "DHR_Trao_doi_gui_va_nhan_voi_ung_vien_ve_buoi_phong_van_nay": "",
        "DHR_Tre_han": "",
        "DHR_Tre_hen": "",
        "DHR_Trinh_do_chinh_tri": "政治水平",
        "DHR_Trinh_do_chuyen_mon": "",
        "DHR_Trinh_do_dao_tao": "",
        "DHR_Trinh_do_hoc_van": "教育程度",
        "DHR_Trinh_do_ngoai_ngu": "",
        "DHR_Trinh_do_tin_hoc": "",
        "DHR_Trinh_do_van_hoaU": "",
        "DHR_Tro_giup": "",
        "DHR_Trong_nuoc": "",
        "DHR_Trong_so": "Weight",
        "DHR_Truong": "",
        "DHR_TruongB": "",
        "DHR_Truong_Ngay_hieu_luc_bat buoc_nhap": "",
        "DHR_Truong_dieu_chinh": "",
        "DHR_Truong_nay_bat_buoc_nhap": "",
        "DHR_Truong_nay_khong_hop_le": "",
        "DHR_Truong_ngay_la_bat_buoc": "",
        "DHR_Truy_cap_khong_hop_le": "",
        "DHR_Truy_van_don_tu": "",
        "DHR_Truy_van_tang_nhan_vien": "",
        "DHR_Truy_van_thong_tin_cong_phep": "",
        "DHR_Tu": "从",
        "DHR_Tu_choi": "拒绝",
        "DHR_Tu_choi_W75": "",
        "DHR_Tu_choi_khong_thanh_cong": "",
        "DHR_Tu_choi_thanh_cong": "",
        "DHR_Tu_ngay": "Date from",
        "DHR_Tuan_nay": "本星期",
        "DHR_Tuan_truoc": "",
        "DHR_Tuoi": "年龄",
        "DHR_Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban": "",
        "DHR_Tuoi_den_khong_duoc_nho_hon_tuoi_tu": "",
        "DHR_Tuoi_tu_khong_duoc_lon_hon_tuoi_den": "",
        "DHR_Tuoi_tu_phai_nho_hon_tuoi_den": "",
        "DHR_Tuy_chon": "选项",
        "DHR_Tuyen_dung": "招聘",
        "DHR_Ty_gia": "汇率",
        "DHR_Ty_le": "百分",
        "DHR_Ty_le_cho_phep": "",
        "DHR_Ty_le_xep_loai_cua_bang_tham_chieu_ket_qua": "",
        "DHR_URL": "",
        "DHR_URL_khong_hop_le": "",
        "DHR_Ung_tuyen": "",
        "DHR_Ung_vien": "",
        "DHR_Ung_vien_chua_co_lich_phong_van": "",
        "DHR_Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay": "Ứng viên đã được cập nhật thông tin trúng tuyển, bạn không được phép chỉnh sửa mà chỉ được phép xem thông tin ứng viên này",
        "DHR_Ung_vien_khong_co_email": "",
        "DHR_Ung_vien_va_nguoi_phong_van": "",
        "DHR_Ung_vien_xac_nhan": "",
        "DHR_Username_va_Password_khong_hop_le!": "",
        "DHR_Vai_tro": "",
        "DHR_Vao_ra": "",
        "DHR_Vao_tre": "",
        "DHR_Ve_som": "",
        "DHR_Vi_tri": "Position",
        "DHR_Vi_tri_Cong_viec": "",
        "DHR_Vi_tri_tuyen": "",
        "DHR_Vi_tri_tuyen_dung": "",
        "DHR_Vi_tri_ung_tuyen": "",
        "DHR_VoucherID_khong_duoc_de_trong": "",
        "DHR_Vui_long_chon_ca": "Vui lòng chọn ca",
        "DHR_Vui_long_chon_ket_qua": "",
        "DHR_Vui_long_chon_nhan_vien": "Vui lòng chọn nhân viên",
        "DHR_Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon_o_tren": "",
        "DHR_Vui_long_kiem_tra_email_va_nhap_ma_de_doi_mat_khau": "",
        "DHR_Vui_long_kiem_tra_lai_Ban_da_danh_gia_day_du_cac_chi_tieu_chua": "",
        "DHR_Vui_long_nhap_email_de_doi_mat_khau": "",
        "DHR_Vui_long_truy_cap_sau": "",
        "DHR_Vuot_dinh_bien": "",
        "DHR_Vuot_qua_so_luong_tap_tin_cho_phep": "",
        "DHR_Xac_nhan": "Confirm",
        "DHR_Xac_nhan_mat_khau_moi": "",
        "DHR_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "",
        "DHR_Xac_nhan_tra_the_so_bao_hiem": "",
        "DHR_Xay_ra_loi_khi_duyet_cho_cac_phieu_sau": "",
        "DHR_Xem": "看",
        "DHR_Xem_chi_tiet": "View Detail",
        "DHR_Xem_chi_tiet_cac_dot_review": "",
        "DHR_Xem_chi_tiet_muc_tieu_vua_tao": "",
        "DHR_Xem_muc_tieu_cua_co_cau_to_chuc_khac": "",
        "DHR_Xem_muc_tieu_cua_nguoi_khac": "",
        "DHR_Xem_nhanh": "",
        "DHR_Xem_tap_tin": "",
        "DHR_Xem_tat_ca": "",
        "DHR_Xem_them": "",
        "DHR_Xem_tren_website_tuyen_dung": "",
        "DHR_Xem_truoc": "",
        "DHR_Xep_loai": "",
        "DHR_Xep_loai_danh_gia": "",
        "DHR_Xep_loai_danh_gia_nhan_vien": "",
        "DHR_Xet_duyet_Offer": "",
        "DHR_Xet_duyet_bang_muc_tieu": "",
        "DHR_Xet_duyet_muc_tieu": "Xét duyệt mục tiêu",
        "DHR_Xet_duyet_muc_tieu_ket_qua_ca_nhan": "Xét duyệt kết quả mục tiêu cá nhân",
        "DHR_Xin_cam_on": "",
        "DHR_Xoa": "Delete",
        "DHR_Xoa_dinh_kem_thanh_cong": "",
        "DHR_Xoa_dinh_kem_that_bai": "",
        "DHR_Xoa_khoi_nhom": "",
        "DHR_Xoa_nhom_se_xoa_tat_ca_du_lieu_Ban_co_chac_muon_thuc_hien_dieu_nay": "",
        "DHR_Xoa_phong_van": "",
        "DHR_Xoa_thanh_cong": "",
        "DHR_Xoa_tin_tuyen_dung": "",
        "DHR_Xu_ly_duyet": "",
        "DHR_Xuat": "",
        "DHR_Xuat_Excel": "",
        "DHR_Xuat_PDF": "",
        "DHR_Xuat_du_lieu": "",
        "DHR_Xuat_du_lieu_thanh_cong": "",
        "DHR_Xuat_word": "",
        "DHR_Y_kien_dong_gop": "",
        "DHR_Yes": "",
        "DHR_Yeu_cau_khac": "",
        "DHR_cho_phep_NV_huy_muc_tieu": "",
        "DHR_cho_phep_NV_sua_trong_so": "",
        "DHR_cho_phep_sao_chep_KQ_cap_truoc": "",
        "DHR_da_chot1": "",
        "DHR_dang_nhap_lai": "以再次登陆",
        "DHR_dong": "",
        "DHR_ghi_chu_cap_%P": "",
        "DHR_khong_co_du_lieu": "",
        "DHR_ko_du_quyen": "",
        "DHR_label_c1": "",
        "DHR_label_c2": "",
        "DHR_label_c3": "",
        "DHR_muc_tieu_va_ket_qua_then_chot": "",
        "DHR_ngay_BD_xet_duyet": "",
        "DHR_nguoi_nay": "",
        "DHR_phut": "",
        "DHR_ra_khoi_danh_sach": "",
        "DHR_trao_giai_phap_nhan_niem_tin": "",
        "DHR_trong": "",
        "DHR_tuan": "",
        "DHR_ung_vien_nay": "",
        "DHR_xem_CV": ""
    },
    "timestamps": 1634181451954
};