import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/routes.js';
import './style/fontawesome-pro/web-fonts-with-css/css/fontawesome-all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'devextreme/dist/css/dx.common.css';
import './style/index.scss';

function render(Component) {
    ReactDOM.render(
        (
            <Component/>
        ),
        document.getElementById('root')
    );
}

render(Routes);

if (module.hot) {
    module.hot.accept('./routes/routes.js', () => {
        const NextApp = require('./routes/routes.js').default;
        render(NextApp);
    });
}
